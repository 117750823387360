
    export default class Constants {        
        public static readonly STORAGE = {
        LANG: 'lang',
        ROLE: 'IsAdmin',        
        TOKEN: 'token',
        EMAIL_ADDRESS: 'email',
        IDTOKEN: 'IdToken',
        EXPIRED_TIME: 'expired_time',
          
        BUCKET:'bucket',
        TERMS:'term',
        GROUPNAME:'groupname',       
        CLIENTID:'clientid',
        SESSIONTOKEN:'session_token',
        ACCESSSESSIONTOKEN:'sessiontoken',
        REFRESHTOKEN:'refeshtoken',
        ACCESSKEYID:'accesskeyid',
        SECRETACCESSKEY:'secrectaccesskey',
        ACCESSTOKEN:'accesstoken',
        DOMAINNAME:'domain'
    };
  
public static readonly Octave_V_FC=[1
    ,1.2
    ,1.6
    ,2
    ,2.5
    ,3.1
    ,3.9
    ,4.9
    ,6.2
    ,7.8
    ,9.8
    ,12.4
    ,15.6
    ,19.7
    ,24.8
    ,31.3
    ,39.4
    ,49.6
    ,62.5
    ,78.7
    ,99.2
    ,125
    ,157.5
    ,198.4
    ,250
    ,315
    ,396.9
    ,500
    ,630
    ,793.7
    ,1000
    ,1259.9
    ,1587.4
    ,2000
    ,2519.8
    ,3174.8
    ,4000
    ,5039.7
    ,6349.6
    ,8000
    ,10079.4
    ,12699.2
    ,16000
    ,20158.7
]
      public static readonly Octave_N_FC = [
        10
,12
,16
,20
,25
,31
,39
,50
,63
,79
,99
,125
,157
,198
,250
,315
,397
,500
,630
,794
,1000
,1260
,1587
,2000
,2520
,3175
,4000
,5040
,6350
,8000
,10079
,12699
,16000
,20159
    
]
public static readonly FFTMin_nv=0;
public static readonly FFTMax_nv=20000;

public static readonly FFTThreshMin_n=0;
public static readonly FFTThreshMax_n=20000;
public static readonly FFTThreshMin_v=0;
public static readonly FFTThreshMax_v=999;

public static readonly SpecPromMin_n=125;
public static readonly SpecPromMax_n=20000;
public static readonly SpecPromThreshMin_n=-99;
public static readonly SpecPromThresMax_n=99;

public static readonly OverallMin_n=0;
public static readonly OverallMax_n=140;

public static readonly OverallMin_v=0;
public static readonly OverallMax_v=1000;
public static readonly Default_Color= '#007E84';

public static readonly GenRootConstantName='**rootcause**';
    public static readonly UserInfo = {
        USERNAME: 'username',
        DISPLAYNAME: 'displayName',      
        PASSWORD: 'password', 
        BUSINESS_UNIT:'businessunit',
        ATTRIBUTES_COMPANYNAME: 'companyname',
        ATTRIBUTES_PLANTNAME: 'custom:divisionanme',
        ATTRIBUTES_ACCESSRIGHTS: 'custom:accessrights',
        ATTRIBUTES_EMAIL:"email",
        ATTRIBUTES_NAME:"name",
        ATTRIBUTES_USER_ROLE:"user_role",
        EDL:"edl",
        EOL:"eol",
        SD:"sd",
        EDLADMIN:"edlAdmin",
        EOLADMIN:"eolAdmin",
        SDADMIN:"sdAdmin",
        ULLASATATUS:"UllaStatus",
        APPVERSION:"appVersion"
    };
}


