import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AdminService } from '../auth/admin.service';
import Constants from '../commonFiles/constant/Constants';
import { ConfirmationData } from '../commonFiles/modalwindow/confirmation-window/confirmation-window.component';
import { AppConfigService } from '../commonFiles/services/app-initializer.service';
import { SpinnerService } from '../commonFiles/services/spinner.service';

@Component({
  selector: 'app-data-protection',
  templateUrl: './data-protection.component.html',
  styleUrls: ['./data-protection.component.scss']
})
export class DataProtectionComponent implements OnInit {
  s3: any;
  isLoading=true;
  isHideButton=true;
  constructor(
  public appconfig: AppConfigService, 
  public adminService:  AdminService,
  public spinnerService: SpinnerService, 
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationData) { }

      ngOnInit() {
        this.disableBtn =true;
     
    this.adminService.getDataProtection().subscribe(res=>{
      this.rawData = (res.success.Message);
     
      
      this.rawData=this.rawData.replace(/\\r\\n/g, "");
      
this.rawData
    });
    
      if( this.data !=null && this.data.header != undefined){
        this.isHideButton=true;
      }else{
        this.isHideButton =false;
      }
     this.isLoading =false;
  }
  rawData
   

  disableBtn:boolean=true; 
  @HostListener('scroll', ['$event']) 
  scrollHandler(event) {
    let scrollHeight = Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
    if(event.srcElement.scrollTop>2400){
      this.disableBtn =false;
    }
   
  }
  calculateClasses(){
    if(this.disableBtn)
    return "gray";
  }
  
}
