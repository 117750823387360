import { Component, OnInit, ViewChild, Inject, ViewContainerRef, } from '@angular/core';
import { Router } from '@angular/router';
import { dashboardService } from '../dashboard/dashboard.service';
import { DataService } from '../shared/data.service';
import { AuthService } from '../auth/auth.service';
import { TranslatePipe } from '../commonFiles/pipe/translate.pipe';
import { AppConfigService } from '../commonFiles/services/app-initializer.service';
import { TranslateService } from '../commonFiles/services/translate.service';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatCheckbox } from '@angular/material';
import { EDLDashboard } from '../dashboard/dashboardModels';
import Constants from '../commonFiles/constant/Constants';
import { FormGroup, FormBuilder, } from '@angular/forms';
import { DateTime } from 'aws-sdk/clients/devicefarm';
import { SelectionModel } from '@angular/cdk/collections';
import { AdminService } from '../auth/admin.service';
import { ConfirmationWindowComponent } from '../commonFiles/modalwindow/confirmation-window/confirmation-window.component';
import { ErrorService } from '../commonFiles/services/error.service';
import { Observable } from 'rxjs/Rx';
import { ShowAccessComponent } from '../commonFiles/modalwindow/show-access/show-access.component';
import { ResizeEvent } from 'angular-resizable-element';
import { FileDownloadComponent } from '../commonFiles/modalwindow/file-download/file-download.component';
import { Overlay, OverlayConfig, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

export interface PeriodicElement {
  'Id': number; 'Project': string; 'Test Type': string; 'Vehicle Model': string; 'Machine Id': string; 'User Id': string; 'Status': string; 'Data Type': string;
}

export interface Paginator {
  lastevaltime: string; lastevalmachine: string; lastevalemail: string;
}


export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})

export class AdminDashboardComponent implements OnInit {

  form: FormGroup;
  displayedColumns: string[] = ['id', 'datetime', 'Projectname', 'Testtype', 'Machineid', 'tags', 'Userid', 'DataType', 'Status'];
  filterValues = {};
  showDelete = true;

  EDLdataSource = new MatTableDataSource<any | Group>([]);


  paginators: Paginator[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isDeleteVisible = false;
  filterSelectObj = [];
  selection = new SelectionModel<PeriodicElement>(true, []);
  index = 0;
  isPageNext = false;
  isPagePrev = true;
  isCompare = false;
  columns: any[];
  groupByColumns: string[] = [];
  showPaginator = true;
  public dataSource = new MatTableDataSource<any | Group>([]);
  //columnWidths: { Id: number; Project: number; TestType: number; VehicleModel: number; MachineId: number; Userid: number; DataTypes: number; Status: number; };
  // dialogdata: any;
  isFilterApplied = false;

  constructor(public router: Router, private fb: FormBuilder,
    public dialog: MatDialog,
    public service: dashboardService,
    public dataservice: DataService,
    public authService: AuthService,
    public translate: TranslatePipe,
    public appConfigService: AppConfigService,
    public translateService: TranslateService,
    private adminService: AdminService,
    private errorService: ErrorService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<any>,
    public overlay: Overlay, public viewContainerRef: ViewContainerRef,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.errorService.getResponsePostMeassage().subscribe(res => {
      if (res !== true) {
        this.snackBar.open(res, '', {
          duration: 2500,
          verticalPosition: 'bottom'
        });
      }
    });


    this.columns = [
      {
        field: 'Id'
      },
      {
        field: 'Project'
      },
      {
        field: 'TestType'
      },
      {
        field: 'VehicleModel'
      },
      {
        field: 'MachineId'
      },
      {
        field: 'Userid'
      },
      {
        field: 'DataTypes'
      },
      {
        field: 'Status'
      }
    ];
    this.displayedColumns = this.columns.map(column => column.field);
    this.groupByColumns = ['datetime'];

    //this.columnWidths = {
    //  'Id': 40, 'Project': 187, 'TestType': 188, 'VehicleModel': 170 , 'MachineId': 170, 'Userid': 294, 'DataTypes': 110, 'Status': 80
    // };
  }
  public styles =
    {
      'flex': '0 0 93px'
    }
  ngOnInit() {

    this.paginators = [];
    this.paginators.push({ lastevaltime: '', lastevalemail: '', lastevalmachine: '' });
    this.isPushArray = true;
    if (this.dialogdata && this.dialogdata.isCompare) {
      this.displayedColumns = ['Id', 'Project', 'TestType', 'VehicleModel', 'MachineId', 'Userid', 'DataTypes', 'Status'];
      this.isCompare = true;
    }
    if (localStorage.getItem(Constants.UserInfo.ATTRIBUTES_USER_ROLE) === 'superadmin' || localStorage.getItem(Constants.UserInfo.ATTRIBUTES_USER_ROLE) === 'admin') {
      this.errorService.setResponseHeaderMessage(true);
      this.isDeleteVisible = true;
    }
    else {
      this.isDeleteVisible = false;
      this.errorService.setResponseHeaderMessage(true);
    }

    this.authService.validateUser();
    this.fillStorage();
    if (localStorage.getItem('filter') !== '' && localStorage.getItem('filter') != null) {
      const filterData = JSON.parse(localStorage.getItem('filter'));

      this.Loaddata();

      this.form = this.fb.group({
        FromDate: [filterData.FromDate, []],
        ToDate: [filterData.ToDate, []],
        ProjectName: [filterData.ProjectName, []],
        Testtype: [filterData.Testtype, []],
        VehicleModel: [filterData.VehicleModel, []],
        MachineId: [filterData.MachineId, []],
        UserId: [filterData.UserId, []],
        Status: [filterData.Status, []]
      });
      this.LoadEDLData();
    }
    else {
      this.Loaddata();
      this.form = this.fb.group({
        FromDate: ['', []],
        ToDate: ['', []],
        ProjectName: ['', []],
        Testtype: ['', []],
        VehicleModel: ['', []],
        MachineId: ['', []],
        UserId: ['', []],
        Status: ['', []]
      });
      this.LoadEDLData();
    }



    // this._alldata =  this.service.EDLdataSource.data;



  }

  getRecord(row) {
    const data = new EDLDashboard();

    data.epochdate = row.epochdate;
    data.datetime = row.datetime;
    data.Userid = row.Userid;
    data.Testtype = row.TestType;
    data.Projectname = row.Project;
    data.Model = row.VehicleModel;
    data.Machineid = row.MachineId;
    data.DataTags = row.tags;
    data.DataTypes = row.DataTypes;
    if (row.Comments) {
      data.Comments = row.Comments;
    }
    else {
      data.Comments = '';
    }
    data.id = row.id;
    data.isEDL = true;
    if (this.router.url !== '/edl/comparemeasurement') {
      this.router.navigate(['/edl/measurement']);
    }
    else {
      this.dialogRef.close({ data: data });
    }
    this.dataservice.setVehicleResponsePostMessage(data);
  }
  storage;
  Loaddata() {

    this.index = 0;

    if (localStorage.getItem('EDLMaxStorage') != null) {
      this.storage = 'Storage  :' + localStorage.getItem('EDLUseStorage') === 'null' ? '0' : localStorage.getItem('EDLUseStorage') + '/' + localStorage.getItem('EDLMaxStorage') + ' GB used';
    }
    this.selection.clear();

  }
  applyFilter(event: Event) {
    this.selection.clear();
    if (this.form.valid) {
      this.LoadEDLData();
      this.isFilterApplied = true;
      // this.service.applyFilter(this.form.value);
    }
  }
  resetFilter(event: Event) {
    this.selection.clear();
    this.form.reset();
    this.LoadEDLData();
    this.isFilterApplied = false;
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    const text = this.isAllSelected();
    for (let i = 0; i < this.dataSource.data.length - 1; i++) {
    }
    if (this.isAllSelected()) {
      this.selection.clear();
      this.showDelete = true;
    } else {
      this.dataSource.data.forEach(row => {
        this.selection.select(row);
        this.showDelete = false;
      });
    }
  }

  // You can then access properties of myCheckbox
  // e.g. this.myCheckbox.checked
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return '${this.isAllSelected() ? \'select\' : \'deselect\'} all';
    } else {

      return '${this.selection.isSelected(row) ? \'deselect\' : \'select\'} row ${row.id + 1}';
    }
  }
  DeleteProject() {
    if (this.selection.selected.length > 0) {
      this.index = 0;
      const dialogRef = this.dialog.open(ConfirmationWindowComponent, {
        data: {
          header: 'Delete Test',
          primarybtn: 'Delete',
          message: 'Are you sure, want to delete?',
          cancelbtn: 'Cancel',
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res.isOk) {
          const observables = [];

          this.selection.selected.forEach(project => {

            observables.push(this.adminService.DeleteProject(project));
          });
          Observable.forkJoin(observables)
            .subscribe((data) => {
              this.snackBar.open('Selected tests are deleted', '', {
                duration: 2500,
                verticalPosition: 'top'
              });
              this.selection.clear();
              this.LoadEDLData();
            },
              err => {
                this.snackBar.open('partially deleted due to error', '', {
                  duration: 2500,
                  verticalPosition: 'top'
                });
                this.Loaddata();
              });

        }
      });
    }
    else {
      if (this.selection.hasValue()) {


        this.snackBar.open('Please select a test case to delete', '', {
          duration: 2500,
          verticalPosition: 'top'
        });
      }
    }
  }
  close() {
    this.dialogRef.close();
  }
  usedStorage;
  maxStorage;
  storageLabel;
  fillStorage() {
    if (localStorage.getItem('EDLMaxStorage') === null) {
      this.adminService.getStorage('edl').subscribe(res => {
        res.forEach(element => {
          if (element.cb.split('_')[1] === localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT)) {

            localStorage.setItem('EDLMaxStorage', element.license['edl'].maxstorage);
            localStorage.setItem('EDLUseStorage', element.license['edl'].usedstorage === null ? '0 GB' : element.license['edl'].usedstorage);
            this.storageLabel = 'Storage used  :' + localStorage.getItem('EDLUseStorage') + '/' + localStorage.getItem('EDLMaxStorage');
          }
        });
      });
    } else {
      this.storageLabel = 'Storage used  :' + localStorage.getItem('EDLUseStorage') + '/' + localStorage.getItem('EDLMaxStorage');
    }
  }





  _alldata: any[] = [];


  // below is for grid row grouping
  customFilterPredicate(data: any | Group, filter: string): boolean {

    return data instanceof Group ? data.visible : this.getDataRowVisible(data);
  }

  getDataRowVisible(data: any): boolean {

    const groupRows = this.dataSource.data.filter(row => {
      if (!(row instanceof Group)) {
        return false;
      }
      let match = true;
      this.groupByColumns.forEach(column => {
        if (!row[column] || !data[column] || row[column] !== data[column]) {
          match = false;
        }
      });
      return match;
    });

    if (groupRows.length === 0) {
      return true;
    }
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  groupHeaderClick(row) {

    row.expanded = !row.expanded;
    if (row.expanded) {
      //setTimeout(() => {
      //  this.displayedColumns.forEach(column => {
      //    const cssValue = this.columnWidths[column] + 'px';
      //    const columnElts = document.getElementsByClassName('mat-column-' + column);
      //    for (let i = 0; i < columnElts.length; i++) {
      //      const currentEl = columnElts[i] as HTMLDivElement;
      //      currentEl.style.width = cssValue;
      //    }
      //  });
      //}, 100);
    }
    this.dataSource.filter = performance.now().toString(); // bug here need to fix
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(
    data: any[],
    level: number,
    groupByColumns: string[],
    parent: Group
  ): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map(row => {
        const result = new Group();
        result.level = level + 1;
        result.parent = parent;
        for (let i = 0; i <= level; i++) {
          result[groupByColumns[i]] = row[groupByColumns[i]];
        }
        return result;
      }),
      JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(
        row => group[currentColumn] === row[currentColumn]
      );
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(
        rowsInGroup,
        level + 1,
        groupByColumns,
        group
      );
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter(item => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    return item.level;
  }

  getColour(i, row) {
    if (i === 7) {
      if (row === 'OK')
        return 'green';
      if (row === 'NOK')
        return 'red';
      else
        return 'gray';
    }
  }

  LoadEDLData() {

    this.service.EDLDashboardData(this.form.value.FromDate, this.form.value.ToDate, this.form.value.ProjectName, this.form.value.Testtype, this.form.value.MachineId, this.form.value.UserId, this.lastevalMachineId, this.lastevalTime, this.lastevaluserId, this.form.value.VehicleModel).subscribe(data => {

      this._alldata = [];
      this.dataSource.data = [];
      for (let index = 0; index < data.Data.length; index++) {
        const epoch = data.Data[index].tme.split('%')[0];
        const date = new Date(parseInt(data.Data[index].tme.split('_')[0]) * 1000);
        const formatdate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();

        const projectname = data.Data[index].p;
        const vehicleModelname = data.Data[index].vm;
        const TestType = data.Data[index].tt;
        const Machineid = data.Data[index].tme.split('%')[1];
        const Userid = data.Data[index].tme.split('%')[2];
        const Comments = data.Data[index].c;
        let status = '';
        let dataType = 'NA';

        if (data.Data[index].t_status !== undefined) {
          if (data.Data[index].t_status === 0)
            status = 'OK';
          else if (data.Data[index].t_status === 1) {
            status = 'NOK';
          }
          else {
            status = '___';
          }
        } else {
          status = '___';
        }

        const datatags = data.Data[index].datatag;
        if (data.Data[index].datatype !== undefined) {
          dataType = data.Data[index].datatype;
        }

        this._alldata.push({
          'id': index, 'datetime': formatdate, 'epochdate': epoch, 'Project': projectname, 'VehicleModel': vehicleModelname, 'TestType': TestType, 'MachineId': Machineid, 'Userid': Userid, 'Comments': Comments, 'Status': status, 'DataTypes': dataType, 'tags': datatags
        });

      }


      this.showPaginator = false;

      if (data.LastEvaluatedKey !== undefined) {
        console.log(data.LastEvaluatedKey)
        if (this.isPushArray) {
          this.paginators.push({ lastevaltime: data.LastEvaluatedKey.tme.split('%')[0], lastevalmachine: data.LastEvaluatedKey.tme.split('%')[1], lastevalemail: data.LastEvaluatedKey.tme.split('%')[2] });
          this.isPageNext = false;
          this.isPushArray = false;
        }
      } else {

      }
      console.log(data)
      if (data.Data.length <= 0) {
        this.isPageNext = true;
      }
      else {
        this.isPageNext = false;
      }
      //if (data.Data.length>= 50) {
      //  this.isPageNext = false;
      //} else {
      //  this.isPageNext = true;
      //}
      this._alldata = this._alldata.sort((a, b) => parseInt(b.epochdate) - parseInt(a.epochdate));
      this.dataSource.data = this.addGroups(
        this._alldata,
        this.groupByColumns
      );
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
      this.dataSource.filter = performance.now().toString();
    });

    //setTimeout(() => {
    //  this.displayedColumns.forEach(column => {
    //    const cssValue = this.columnWidths[column] + 'px';
    //    const columnElts = document.getElementsByClassName('mat-column-' + column);
    //    for (let i = 0; i < columnElts.length; i++) {
    //      const currentEl = columnElts[i] as HTMLDivElement;
    //      currentEl.style.width = cssValue;
    //    }
    //  });
    //}, 10);
  }
  noDataCheck() {

    if (this._alldata.length === 0)
      return true;
    else
      return false;
  }
  changeHeader(header) {
    switch (header) {
      case 'Project':
        return 'Project';
      case 'TestType':
        return 'Test Type';
      case 'VehicleModel':
        return 'Vehicle Model';
      case 'MachineId':
        return 'Machine Id';
      case 'Userid':
        return 'Email Id';
      case 'DataTypes':
        return 'Data Type';
      case 'Status':
        return 'Status';
    }
  }
  NextPage() {
    this.isPushArray = true;
    this.isPagePrev = false;
    const page = this.paginators[this.paginators.length - 1];
    console.log(page)
    if (page.lastevalmachine != "" && page.lastevalmachine != null && page.lastevalmachine != undefined) {
      this.lastevalMachineId = page.lastevalmachine;
      localStorage.setItem('lastevalMachineId', this.lastevalMachineId);
    } else {
      var machineid = localStorage.getItem('lastevalMachineId');
      this.lastevalMachineId = machineid;
    }
    if (page.lastevaltime != "" && page.lastevaltime != null && page.lastevaltime != undefined) {
      this.lastevalTime = page.lastevaltime;
      localStorage.setItem('lastevalTime', this.lastevalTime);
    } else {
      var lastevaltime = localStorage.getItem('lastevalTime');
      this.lastevalTime = lastevaltime;
    }
    if (page.lastevalemail != "" && page.lastevalemail != null && page.lastevalemail != undefined) {
      this.lastevaluserId = page.lastevalemail;
      localStorage.setItem('lastevaluserId', this.lastevaluserId);
    } else {
      var lastevalemail = localStorage.getItem('lastevaluserId');
      this.lastevaluserId = lastevalemail;
    }

    this.selection.clear();
    this.LoadEDLData();
    const element = document.getElementById("Gridscrollbar");
    element.scrollTop = 0;
  }
  isPushArray = false;
  PrevPage() {
    this.isPushArray = false;
    this.paginators.pop();
    const page = this.paginators[this.paginators.length - 2];
    console.log(page)
    //if (page != undefined) {
    this.lastevalMachineId = page.lastevalmachine;
    this.lastevalTime = page.lastevaltime;
    this.lastevaluserId = page.lastevalemail;
    //  this.isPagePrev = false;
    //} else {
    //  this.isPagePrev = true;
    //}
    //if (page.lastevalmachine != "" && page.lastevalmachine != null && page.lastevalmachine != undefined) {
    //  this.lastevalMachineId = page.lastevalmachine;
    //  localStorage.setItem('lastevalMachineId', this.lastevalMachineId);
    //} else {
    //  var machineid = localStorage.getItem('lastevalMachineId');
    //  this.lastevalMachineId = machineid;
    //}
    //if (page.lastevaltime != "" && page.lastevaltime != null && page.lastevaltime != undefined) {
    //  this.lastevalTime = page.lastevaltime;
    //  localStorage.setItem('lastevalTime', this.lastevalTime);
    //} else {
    //  var lastevaltime = localStorage.getItem('lastevalTime');
    //  this.lastevalTime = lastevaltime;
    //}
    //if (page.lastevalemail != "" && page.lastevalemail != null && page.lastevalemail != undefined) {
    //  this.lastevaluserId = page.lastevalemail;
    //  localStorage.setItem('lastevaluserId', this.lastevaluserId);
    //} else {
    //  var lastevalemail = localStorage.getItem('lastevaluserId');
    //  this.lastevaluserId = lastevalemail;
    //}

    if (page.lastevalmachine === '' && page.lastevaltime === '') {
      this.isPagePrev = true;
      this.isPageNext = false;
    }
    this.selection.clear();
    this.LoadEDLData();
    //let elements = document.getElementsByClassName('scrollbar-primary');
    const element = document.getElementById("Gridscrollbar");
    element.scrollTop = 0;
    //document.documentElement.scrollTop = 0;
    //window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  lastevalTime; lastevalMachineId; lastevaluserId;

  onResizeEnd(event: ResizeEvent, columnName): void {

    if (event.edges.right) {
      const cssValue = event.rectangle.width + 'px';
      const columnElts = document.getElementsByClassName('mat-column-' + columnName.field);
      //this.columnWidths[columnName.field] = event.rectangle.width;
      for (let i = 0; i < columnElts.length; i++) {
        const currentEl = columnElts[i] as HTMLDivElement;
        currentEl.style.width = cssValue;
      }
      //  //if (columnName == 'DataTypes') {

      ////  const cssValue = event.rectangle.width + 'px';
      ////  const columnElts = document.getElementsByClassName('mat-column-' + columnName.field);
      ////  this.columnWidths[columnName.field] = event.rectangle.width.toFixed;
      ////  for (let i = 0; i < columnElts.length; i++) {
      ////    const currentEl = columnElts[i] as HTMLDivElement;
      ////    currentEl.style.width = cssValue;
      ////  }
      ////}
      //}
      //if (columnName.field == "Userid") {
      //  
      //  const cssValue = event.rectangle.width.toFixed;/*+ 'px';*/
      //    const columnElts = document.getElementsByClassName('mat-column-' + columnName.field);
      //  this.columnWidths[columnName.field] = event.rectangle.width.toFixed;
      //    for (let i = 0; i < columnElts.length; i++) {
      //      const currentEl = columnElts[i] as HTMLDivElement;
      //      currentEl.style.width = cssValue.toString();
      //    }
      //}
      ////else if (columnName.field == "Userid") {
      ////  const cssValue = event.rectangle.width.toFixed;/*+ 'px';*/
      ////  const columnElts = document.getElementsByClassName('mat-column-' + columnName.field);
      ////  this.columnWidths[columnName.field] = event.rectangle.width.toFixed;
      ////  for (let i = 0; i < columnElts.length; i++) {
      ////    const currentEl = columnElts[i] as HTMLDivElement;
      ////    currentEl.style.width = cssValue.toString();
      ////  }
      ////}
      //  else {
      //    const cssValue = event.rectangle.width + 'px';
      //    const columnElts = document.getElementsByClassName('mat-column-' + columnName.field);
      //    this.columnWidths[columnName.field] = event.rectangle.width;
      //    for (let i = 0; i < columnElts.length; i++) {
      //      const currentEl = columnElts[i] as HTMLDivElement;
      //      currentEl.style.width = cssValue;
      //    }

      //}
    }
  }

  initDownload() {
    if (this.selection.selected.length > 0) {
      const fileList: any[] = [];
      this.selection.selected.forEach(item => {
        const measurement = {};
        measurement['url'] = 'public/'
          + localStorage.getItem(Constants.STORAGE.BUCKET)
          + '/'
          + item['epochdate']
          + '_'
          + item['MachineId']
          + '/'
          + item['Project']
          + '_'
          + item['TestType'];
        measurement['name'] = item['epochdate'] + '_' + item['MachineId'];
        fileList.push(measurement);
      });
      const dialogRef = this.dialog.open(FileDownloadComponent, {
        width: '800px',
        data: fileList
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }


}
