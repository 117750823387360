
export class MeasuementInformation{
  ProjectName:string;
  TestType:string;
  MachineId:string;
  TestBy:string;
  TestDate:string;  
  Comments:string;
}


export interface TestLevels
{
  Testname:string;
  Testval:string;
}