import { PipeTransform, Pipe, Injectable } from '@angular/core';
import { TranslateService } from '../services/translate.service'

@Injectable({
    providedIn: 'root'
  })
  
@Pipe({
    name: 'translate',
    pure: false
})
export class TranslatePipe implements PipeTransform {
    transform(key: any) {
        return this.translate.data ? (this.translate.data[key] || key ) : key;
    }
    constructor(private translate: TranslateService) {

    }
}
