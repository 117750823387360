import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from '../auth/auth.service'

import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '../commonFiles/services/translate.service';
import { AppConfigService } from '../commonFiles/services/app-initializer.service';
import { MustMatch } from '../commonFiles/utility/common.validator';
import { environment } from "../../environments/environment";
@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './forgotPassword.html',
    styleUrls: ['./forgotPassword.component.scss'],
})
export class ForgotPasswordStep1Component {
    private emailRegex = "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
    ErrorMessage;
    email: string;
    form: FormGroup;
    private formSubmitAttempt: boolean;
    constructor(public router: Router,
        public authService: AuthService,
        private translateService: TranslateService,
        private appConfigService: AppConfigService,
        private route: Router, private fb: FormBuilder,) {

        this.form = this.fb.group({
            userName: ['', [Validators.required, Validators.pattern(this.emailRegex)]]
        });


        this.authService.getError().subscribe(errormessagedisplay => {
            this.ErrorMessage = errormessagedisplay;
        });
    }

    onNext() {

        if (this.form.valid) {
            this.email = this.form.value.userName;
            this.authService.forgetPasswordEmail(this.email).subscribe(data => {

                if (data) {
                    this.router.navigate(['/confirm-password', this.email]);
                }
                else
                    this.ErrorMessage = "Failed";

            });
        }
    }

    cancel(){
        this.router.navigate(['/login']);
    }
    isFieldInvalid(field: string) {
        return (
            (!this.form.get(field).valid && this.form.get(field).touched) ||
            (this.form.get(field).untouched && this.formSubmitAttempt)
        );
    }

}


@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './forgotPasswordStep2.html',
    styleUrls: ['./forgotPassword2.component.scss'],
})

export class ForgotPassword2Component implements OnInit, OnDestroy {

    verificationCode: string;
    email: string;
    password: string;
    repassword: string;
    ErrorMessage
    private sub: any;

    constructor(public router: Router, public route: ActivatedRoute,
        public authService: AuthService,
        private formBuilder: FormBuilder,) {

    }

    deviceRegistrationForm = this.formBuilder.group({
        accessCode: ['', Validators.required],
        password: ['',Validators.compose([Validators.required,Validators.pattern(new RegExp(environment.PasswordPattern))])],
        confirmpassword: ['',Validators.compose([Validators.required,Validators.pattern(new RegExp(environment.PasswordPattern))])]
    }, {
        validator: [MustMatch('password', 'confirmpassword')]
    });
    get f() {

        return this.deviceRegistrationForm.controls;
    }
    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            this.email = params['email'];

        });
        this.deviceRegistrationForm.patchValue({
            accessCode: '',
            password: '',
            confirmpassword: ''
        });

    }



    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onNext() {
        this.ErrorMessage = null;
        if (this.deviceRegistrationForm.valid) {
            this.password = this.deviceRegistrationForm.value.password;
            this.repassword = this.deviceRegistrationForm.value.confirmpassword;
            this.verificationCode = this.deviceRegistrationForm.value.accessCode;
            if (this.repassword == this.password)
                this.authService.resetPassword(this.email, this.verificationCode, this.password).subscribe(data => {
                    if (data) {
                        this.router.navigate(['/']);
                    }
                });
            else {
                this.ErrorMessage = 'Password not matched';
            }
        }
    }
    resendCode() {
        this.deviceRegistrationForm.value.accessCode = "";
        this.verificationCode = "";
    }
    cancel(){
        this.router.navigate(['/login']);
    }
}