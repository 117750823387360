import { Component, OnInit } from '@angular/core';  
import { SpinnerService } from '../commonFiles/services/spinner.service';
 
export interface Response {
  message: string;
  color: string;
  isOk?: boolean;
}
@Component({
  selector: 'app-notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss']
})
export class NotifierComponent implements OnInit {
  constructor(private spinnerService: SpinnerService ) {
    setTimeout(()=>{
      for( var i =0 ; i< this.responseMsg.length; i++)
      {
        const lastIndex = this.responseMsg[i];
        this.removeSuccess(lastIndex);
      }
  }, 2000);
  }
  responseMsg: Response[] = [];
  removable = true;
  showChip=false;
  data:any;
  ngOnInit() {    


    this.spinnerService.showErrorMsg.subscribe(visibility => {
      
      this.showChip =true;
      var chipDetails: Response = {
        message:  visibility,
        color: 'green',
        isOk: true
      };
      this.responseMsg.push(chipDetails);      
   
    });
     
  
  }

  remove(i) {
    this.responseMsg.splice(i, 1);
  }

  removeSuccess(index) {
       return setTimeout(() => {
          this.responseMsg.splice(index, 1);
        }, 8000);
  }
}
