export class CarModels {
    Model: string;
    ModelValue: string;
}

export class ChartData {
    modelname: string;
    TrialData: TrialData[];
    lasttupdate: string;
}
export class TrialData {
    vinid: number;
    vin: string;
    value: number;
    Testlevel: number;
    Testcase: string;
    Testdate: string
}
export  class Sharingdata{
    Testcases:any;
    Model:string;
    Date:string;
    Vin:string;

 }
 export class EDLDashboard{
    datetime:Date;
    epochdate:string;
   Projectname: string;
    Testtype:string;
    Machineid:string;
    Userid:string;
    id:number;
    Comments:string;
    isEDL:boolean;
    Testcases:string;
    Model:string;
    Vin:string;
    Date:string;
    DataTags:string;
    DataTypes:string;
 }
