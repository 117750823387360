import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MustMatch } from '../../commonFiles/utility/common.validator';
import { MatSnackBar } from '@angular/material';
import { AuthService } from '../../auth/auth.service';
import { ErrorService } from '../../commonFiles/services/error.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  ErrorMessage
  changepswdForm: FormGroup;
  constructor(private formBuilder: FormBuilder,    
    private authService: AuthService,
    private errorService: ErrorService,
    private route: Router,
    public snackBar: MatSnackBar) {
      this.errorService.getResponsePostMeassage().subscribe(errormessagedisplay => {    
        this.ErrorMessage = errormessagedisplay;
      });
     }

  ngOnInit() {  
    this.changepswdForm = this.formBuilder.group({
      oldpasswprd: ['',Validators.compose([Validators.required,Validators.pattern(new RegExp(environment.PasswordPattern))])],
      newpassword: ['',Validators.compose([Validators.required,Validators.pattern(new RegExp(environment.PasswordPattern))])],
      repassword: ['',Validators.compose([Validators.required,Validators.pattern(new RegExp(environment.PasswordPattern))])]
    } , {
      validator: [MustMatch('newpassword', 'repassword')]    
    });
 
    this.authService.validateUser();
  } 
  
  ChangePassword() { 
    if (this.changepswdForm.valid) { 
      if (this.changepswdForm.value.newpassword == this.changepswdForm.value.repassword) {
          this.authService.changePassword(this.changepswdForm.value.oldpasswprd, this.changepswdForm.value.newpassword).then(data=>{        
          if(data.isOk)
          { 
            this.authService.signOut();
            this.route.navigate(['/login']);           
          }else
          {
            this.ErrorMessage = data.Message;
          }
        });
      }
    }
  }

  get f() { 
    return this.changepswdForm.controls; 
  }

}
