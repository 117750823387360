import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { spectrogramSettings,PRspectrogramSettings } from '../spectrogram-filter/spectrogram-filter-model';


@Injectable({
  providedIn: 'root'
})


export class SpectrogramFilterServiceService {

  FilteredSettings:spectrogramSettings;
  FilteredSettingsSubject = (new BehaviorSubject<spectrogramSettings>(null));
  FilteredSettingsObservable = this.FilteredSettingsSubject.asObservable();

  PRFilteredSettings:PRspectrogramSettings;
  PRFilteredSettingsSubject = (new BehaviorSubject<PRspectrogramSettings>(null));
  PRFilteredSettingsObservable = this.PRFilteredSettingsSubject.asObservable();
  
  OriginalSettings:spectrogramSettings;
  OriginalSettingsSubject= (new BehaviorSubject<spectrogramSettings>(null));
  OriginalSettingsObservable = this.OriginalSettingsSubject.asObservable();

 
  constructor() { }

  SetFilteredSettings(filteredSettings:spectrogramSettings){
    this.FilteredSettingsSubject.next(filteredSettings);
  }
  PRSetFilteredSettings(PRFilteredSettings:PRspectrogramSettings){
    this.PRFilteredSettingsSubject.next(PRFilteredSettings);
  }
  SetOriginalSettings(originalSettings:spectrogramSettings){
    this.OriginalSettingsSubject.next(originalSettings);
  }
  
}
