import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  public responsePost = new Subject<any>();  
  public responseAdminHeader = new Subject<any>(); 
  public responseHeaderMenu = new Subject<any>();  
  public responseAdminMenu = new Subject<any>(); 
  public responseEdlMenu = new Subject<any>(); 
  public responseProfileName = new Subject<any>(); 
  public responseMeasurementVisible =  new Subject<any>(); 
  public responseEdlAdminMenu =  new Subject<any>(); 

  public responseHeader = new Subject<any>();  
  constructor() { }

  
  setResponsePostMessage(message) {   
    this.responsePost.next(message);
  }
  getResponsePostMeassage() {
    return this.responsePost.asObservable();
  }
  setResponseHeaderMessage(val) {
    this.responseHeader.next(val);
  }
  getResponseHeaderMeassage() {
    return this.responseHeader.asObservable();
  }
 

  

  setResponseEdlMessage(val) { 
    this.responseEdlMenu.next(val);
  }
  getResponseEdlMeassage() {
    return this.responseEdlMenu.asObservable();
  }
  
  setResponseEdlAdmin(val) { 
    this.responseEdlAdminMenu.next(val);
  }
  getResponseEdlAdmin() {
    return this.responseEdlAdminMenu.asObservable();
  }
}

