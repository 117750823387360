import { AngularWebWorker, bootstrapWorker, Callable, OnWorkerInit, Subscribable } from 'angular-web-worker';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
/// <reference lib="webworker" />

@AngularWebWorker()
export class DownloadWorker implements OnWorkerInit {

    @Subscribable() downloadEvent: BehaviorSubject<any>;
    @Subscribable() progressEvent: BehaviorSubject<any>;
    constructor() { }

    onWorkerInit() {
        this.downloadEvent = new BehaviorSubject(null);
        this.progressEvent = new BehaviorSubject(null);
    }


    @Callable()
    downloadFile(url: string, uniqueId: string): void {
      
        const self = this;
            fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw Error(response.status + ' ' + response.statusText)
                }

                if (!response.body) {
                    throw Error('ReadableStream not yet supported in this browser.')
                }

                const contentEncoding = response.headers.get('content-encoding');
                const contentLength = response.headers.get(contentEncoding ? 'x-file-size' : 'content-length');
                if (contentLength === null) {
                    throw Error('Response size header unavailable');
                }
                const total = parseInt(contentLength, 10);
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read().then(({ done, value }) => {
                                    if (done) {
                                        controller.close();
                                        return;
                                    }
                                    loaded += value.byteLength;
                                    progress(uniqueId, loaded, total);
                                    controller.enqueue(value);
                                    read();
                                }).catch(error => {
                                    console.error(error);
                                    controller.error(error);
                                });
                            }
                        }
                    })
                );
            })
            .then(response => response.blob())
            .then(data => {
                this.onDownloadCompletion(data, uniqueId);
            })
            .catch(error => {
                console.error(error);
            });
            // tslint:disable-next-line:no-shadowed-variable
            function progress(uniqueId: string, loaded: number, total: number) {
                self.updateProgress(uniqueId, Math.round(loaded / total * 100));
            }
    }


    onDownloadCompletion(file: any, uniqueId: string) {
        this.downloadEvent.next({file, uniqueId});
    }

    updateProgress(uniqueId: string, progress: number) {
        this.progressEvent.next({uniqueId, progress});
    }

}
bootstrapWorker(DownloadWorker);
