import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AdminService } from '../auth/admin.service';
import Constants from '../commonFiles/constant/Constants';
import { ConfirmationData } from '../commonFiles/modalwindow/confirmation-window/confirmation-window.component';
import { AppConfigService } from '../commonFiles/services/app-initializer.service';
import { SpinnerService } from '../commonFiles/services/spinner.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  s3: any;
  isLoading = true;
  isHideButton = true;
  constructor(
    public appconfig: AppConfigService,
    public adminService: AdminService,
    public spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationData) { }

  ngOnInit() {

    this.adminService.getPrivacy().subscribe(res => {
      //console.log(res)
      //this.rawData = JSON.parse(res.success.Message);
      this.rawData = (res.success.Message);
      //this.rawData = this.rawData.replace(/\n|\r/g, "");
      //let email = res.success.Message.eula;

      console.log(this.rawData)
       this.rawData=this.rawData.replace(/\\r\\n/g, "");

      // console.log(result)

      this.rawData
    });


    this.isLoading = false;



  }
  rawData



}
