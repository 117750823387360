import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {  MeasuementInformation } from './measurement.model';

@Injectable()
export class MeasurementService {

   
    Measurementdata: MeasuementInformation = new MeasuementInformation();

    constructor(
    ) {

    }

    private messageSource = new BehaviorSubject('default message');
    currentMessage = this.messageSource.asObservable();

    changeMessage(message: string) {
        this.messageSource.next(message)
    }




}