import { OnInit } from '@angular/core';
import {Component } from '@angular/core';
import { AppConfigService } from '../../commonFiles/services/app-initializer.service';

export class AppConfigs{ 
  "AWSINFO":{
    "EDLDashboardRoles":any[],
        "InviteUserRoles":any[],
        "SuperAdminRoles":any[]
  }
}
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  providers:[AppConfigService],
  styleUrls: []
})
export class FullComponent implements OnInit {

  constructor() {
  }


  ngOnInit() {   
  }
}