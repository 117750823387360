import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-geo-location',
  templateUrl: './geo-location.component.html' 
})
export class GeoLocationComponent implements OnInit {
  start_end_mark = [];
  latlng = [
    [
      11.004556,
      76.961632
    ],
    [
      12.972442,
      77.580643
    ],
    [
      13.067439,
    	80.237617
    ]
    ,
    [
      11.004556,
      76.961632
    ],
  ];
  
  constructor() {
    // this i write because to display a marks on first place and last place
    this.start_end_mark.push(this.latlng[0]);
    this.start_end_mark.push(this.latlng[this.latlng.length - 1]);
   }

  ngOnInit() {}



   
}
