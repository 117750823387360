import { Component, OnInit } from '@angular/core';
import {  Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ProfileService } from '../auth/profile.service'
import {  MatDialog, MatSnackBar } from "@angular/material";
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { ErrorService } from '../commonFiles/services/error.service';
import Constants from '../commonFiles/constant/Constants';
import { AdminService } from '../auth/admin.service';
import { ConfirmationWindowComponent } from '../commonFiles/modalwindow/confirmation-window/confirmation-window.component';

export class Profiledata {
  firstname: string;
  lastname: string;
  username: string;
  phone: string;
  picture: any;
}
@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit { 
  data: Profiledata;
  profileForm: FormGroup;
  ErrorMessage=null;
  Nodata;
  
  SuccessMessage=null;
  constructor( public router: Router,private profileService: ProfileService, 
    public snackBar: MatSnackBar, 
    private formBuilder: FormBuilder,    
    public authService:AuthService,
    private dialog: MatDialog,    
    public adminService:AdminService,
    public errorService:ErrorService) {
    this.data = new Profiledata();
    this.errorService.getResponsePostMeassage().subscribe(errormessagedisplay => {    
   
        if(errormessagedisplay!=true)
      this.ErrorMessage = errormessagedisplay;
    }); 
   
  }

  ngOnInit() { 
    
    this.profileForm = this.formBuilder.group({
      firstname: ['', [Validators.required,Validators.minLength(3), Validators.maxLength(50)]],
      lastname: ['',Validators.required,Validators.minLength(3), Validators.maxLength(50)],
      company:  [localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),Validators.required],
      username: [localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL), Validators.required],
      businessunit: [localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT), Validators.required],
    });    
    
    
      this.authService.validateUser();
      this.GetuserProfile();   
     
    //this.errorService.setResponseHeaderMessage(true);
  }
  fileProgress(fileInput: any) {
    this.data.picture = <File>fileInput.target.files[0];
  }

  UpdateUserProfile() {
    this.SuccessMessage = null;    
    if (this.profileForm.valid) { 

      this.data.firstname = this.profileForm.value.firstname;
      this.data.lastname = this.profileForm.value.lastname; 
      this.data.username = this.profileForm.value.username;
      this.data.phone= "+919999999999";
      this.adminService.updateProfile(this.profileForm.value.firstname,this.profileForm.value.lastname,this.data.phone).subscribe(data => {   
         
        if (data.ResponseMetadata.HTTPStatusCode ==200){
          this.GetuserProfile();
          this.SuccessMessage ="Updated profile successfully.";
         
          localStorage.setItem(Constants.UserInfo.USERNAME,this.profileForm.value.firstname+' '+this.profileForm.value.lastname );                   
        }
        else
        {
          this.SuccessMessage ="Updated profile successfully.";
        }
        this.errorService.setResponsePostMessage(true);
        
      },
      error=>{ 
        this.ErrorMessage=error.Message;
      }
      );
    }
  }
 
  GetuserProfile() {
     this.profileService.GetUserProfile().subscribe(data => {     
      if (data) {
        for (let attribute of data.UserAttributes) {
          if (attribute.Name == "given_name") {
            this.data.firstname = attribute.Value;
          }
          else if (attribute.Name == "family_name") {
            this.data.lastname = attribute.Value;
          }
          else if (attribute.Name == "email") {
            this.data.username = attribute.Value;
          }
          else if (attribute.Name == "custom:edlvalidity") {
            localStorage.setItem("EDLValidity",attribute.Value);
          
          }
          else if (attribute.Name == "custom:eolvalidity") {
            localStorage.setItem("EOLValidity",attribute.Value);
            
          }
          else if (attribute.Name == "custom:sdvalidity") {
            localStorage.setItem("SDValidity",attribute.Value);            
          }
        }
        this.profileForm = this.formBuilder.group({
          firstname: [this.data.firstname, Validators.required],
          lastname: [this.data.lastname],
          company: [localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME)],
          username: [this.data.username],
          businessunit: [localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT)],
        });
              
      }
    });

  }
 
  // static CountryCodeMissing(c: FormGroup): ValidationErrors | null {
  //   if (c.get("phoneno")){ 
  //       return { empty: true};
  //   }
  //  return null;
  // }

  get f() { 
        return this.profileForm.controls; 
  }

 userNameDisable =true;

 deleteAccount() {
  var dialogRef = this.dialog.open(ConfirmationWindowComponent, {    
    data: {
      header: 'Delete subscription',
      primarybtn: 'Yes',
      message: "Are you sure, want to deactivate. You cannot recover account?",
      cancelbtn: 'No',
    }
  });
  dialogRef.afterClosed().subscribe(res => {
    if (res.isOk) {
      this.adminService.deleteCurrentaccount().subscribe(result=>{
        if(result.ResponseMetadata.HTTPStatusCode===200){        
          this.router.navigate(['/login']);
        }
      });
    }
  });
}



}
