import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminService } from '../../../auth/admin.service';
import { AuthService } from '../../../auth/auth.service';
import { ErrorService } from '../../services/error.service';
import Constants from '../../constant/Constants';
import { environment } from '../../../../environments/environment';
import { CheckValue } from '../../utility/common.validator';

export interface CompanyData {
  header: string;
  cancelbtn: string;
  primarybtn: string;
  CompanyName: string;
  access: string;
  web: number;
  mobile: number;
  businessunit: string;
  isCompanydialogue: boolean;
  data: any;
}
@Component({
  selector: 'app-CompanyRegisteration',
  templateUrl: './companyregisteration.component.html',
  styleUrls: ['./companyregisteration.component.scss']
})
export class CompanyRegisterationComponent implements OnInit {
  CustomerForm: FormGroup;
  disbaleBU = true;
  companylabel = "Enter New Company"
  Edl
  ErrorMessage
  isDisabled;
  companyName;
  constructor(
    private dialogRef: MatDialogRef<CompanyRegisterationComponent>,
    private fb: FormBuilder,
    private adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: CompanyData,
    private authService: AuthService,
    public errorService: ErrorService
  ) {
    // Diasable/ Enable modal dialog close, when click out side the modal
    dialogRef.disableClose = true;
    this.CustomerForm = this.fb.group({
      Company: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      BU: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      mobileCountEdl: ['', [ Validators.min(1), Validators.max(10000)]],
      webCountEdl: ['', [ Validators.min(1), Validators.max(10000)]],
      mobileCountEol: ['', [ Validators.min(1), Validators.max(10000)]],
      webCountEol: ['', [ Validators.min(1), Validators.max(10000)]],
      mobileCountSd: ['', [ Validators.min(1), Validators.max(10000)]],
      webCountSd: ['', [ Validators.min(1), Validators.max(10000)]],
      storageSd:['',[ Validators.min(1), Validators.max(100)]],
      storageEol:['',[ Validators.min(1), Validators.max(100)]],
      storageEdl:['',[Validators.min(1), Validators.max(100)]],
      isSd:['true'],
      isEdl:['false'],
      isEol:['false']
    },{
      validators:CheckValue("isEdl","isEol","isSd","Company","BU","mobileCountEdl","webCountEdl","mobileCountEol","webCountEol","mobileCountSd","webCountSd")
    })
    this.Edl = true;

    this.CustomerForm.controls['isSd'].setValue(false);
    this.CustomerForm.controls['isEdl'].setValue(false);
    this.CustomerForm.controls['isEol'].setValue(false);

    this.isDisabled = true;
    this.errorService.getResponsePostMeassage().subscribe(errormessagedisplay => {
      this.ErrorMessage = errormessagedisplay;
    });
  }
  companyDisable = false;
  ngOnInit() {
    if (!this.data.isCompanydialogue) {
      this.companylabel = "Selected company";      
      this.companyName =this.data.CompanyName;       
      this.companyDisable = true;
      this.CustomerForm.controls['Company'].disable();      
    }
    else
      {
    this.authService.getError().subscribe(errormessagedisplay => {
      this.ErrorMessage = errormessagedisplay;
    });
  }
  }
 //:TODO changes eol, Sd
  accept() {
   /**  if(this.CustomerForm.controls.mobileCountEdl.value==""){
      this.ErrorMessage="Please enter edl mobile count";
      return;
    }
    if(this.CustomerForm.controls.webCountEdl.value==""){
      this.ErrorMessage="Please enter edl web count";
      return;
    }

    if(this.CustomerForm.controls.mobileCountEol.value==""){
      this.ErrorMessage="Please enter eol mobile count";
      return;
    }    
    if(this.CustomerForm.controls.webCountEol.value==""){
      this.ErrorMessage="Please enter eol mobile count";
      return;
    }

    if(this.CustomerForm.controls.mobileCountSd.value==""){
      this.ErrorMessage="Please enter sd mobile count";
      return;
    }    
    if(this.CustomerForm.controls.webCountSd.value==""){
      this.ErrorMessage="Please enter sd mobile count";
      return;
    }
    */   
    
   if (this.CustomerForm.valid) {
      if(this.CustomerForm.controls.isEdl.value===true){
          var edl = {
            mobile: Number(this.CustomerForm.controls.mobileCountEdl.value),
            web: Number(this.CustomerForm.controls.webCountEdl.value),
            maxstorage:Number(this.CustomerForm.controls.storageEdl.value)
          }
      }
      if(this.CustomerForm.controls.isEol.value===true){
            var eol = {
              mobile: Number(this.CustomerForm.controls.mobileCountEol.value),
              web: Number(this.CustomerForm.controls.webCountEol.value),
              maxstorage:Number(this.CustomerForm.controls.storageEol.value)
            }
      }     
      if(this.CustomerForm.controls.isSd.value===true){
            var sd = {
            mobile: Number(this.CustomerForm.controls.mobileCountSd.value),
            web: Number(this.CustomerForm.controls.webCountSd.value),
            maxstorage:Number(this.CustomerForm.controls.storageSd.value)
          }
      } 
    
    var requestBody = {
      company: this.CustomerForm.controls.Company.value,
      businessunit: this.CustomerForm.controls.BU.value,
      adminemail: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
       
      license: {
        edl,
      }
    }
     this.adminService.AddCustomer(requestBody).subscribe(dataextis => {
      this.dialogRef.close({
        isOk: true,
        isCompanydialogue: this.data.isCompanydialogue
      });
    });
  }
  }

  reject() {   
    
    this.dialogRef.close({
      isOk: false,
      data: {}
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  setEDLCheckBox(value){
    if(value===false){
    this.CustomerForm.controls['mobileCountEdl'].disable();
    this.CustomerForm.controls['webCountEdl'].disable();
    this.CustomerForm.controls['storageEdl'].disable();
    this.CustomerForm.controls['storageEdl'].setValue("");
  }
  else
  {
    this.CustomerForm.controls['mobileCountEdl'].enable();
    this.CustomerForm.controls['webCountEdl'].enable();
    this.CustomerForm.controls['storageEdl'].enable();
    this.CustomerForm.controls['storageEdl'].setValue("100");
  }
  }

  setEOLCheckBox(value){
    if(value===false){
      this.CustomerForm.controls['mobileCountEol'].disable();
      this.CustomerForm.controls['webCountEol'].disable();
      this.CustomerForm.controls['storageEol'].disable();
      this.CustomerForm.controls['storageEol'].setValue("");
    }
    else
    {
      this.CustomerForm.controls['mobileCountEol'].enable();
      this.CustomerForm.controls['webCountEol'].enable();
      this.CustomerForm.controls['storageEol'].enable();
      this.CustomerForm.controls['storageEol'].setValue("100");
    }
  }

  setSDCheckBox(value){
    if(value===false){
      this.CustomerForm.controls['mobileCountSd'].disable();
      this.CustomerForm.controls['webCountSd'].disable();
      this.CustomerForm.controls['storageSd'].disable();
      this.CustomerForm.controls['storageSd'].setValue("");
    }
    else
    {
      this.CustomerForm.controls['mobileCountSd'].enable();
      this.CustomerForm.controls['webCountSd'].enable();
      this.CustomerForm.controls['storageSd'].enable();
      this.CustomerForm.controls['storageSd'].setValue("100");
    }
  }

  onAdminCheck(event, module) {
    
    if (event.checked  && module == "Edl") {
      this.setEDLCheckBox(true);
    } else if (!event.checked  && module == "Edl") {
      this.setEDLCheckBox(false);
    }

    if (event.checked  && module == "Eol") {
      this.setEOLCheckBox(true);
    } else if (!event.checked  && module == "Eol") {
      this.setEOLCheckBox(false);
    }

    if (event.checked  && module == "Sd") {
      this.setSDCheckBox(true);
    } else if (!event.checked && module == "Sd") {
     this.setSDCheckBox(false);
    }
  }
}
