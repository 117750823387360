import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AdminService } from '../auth/admin.service';
import Constants from '../commonFiles/constant/Constants';
import { ConfirmationData } from '../commonFiles/modalwindow/confirmation-window/confirmation-window.component';
import { AppConfigService } from '../commonFiles/services/app-initializer.service';
import { SpinnerService } from '../commonFiles/services/spinner.service';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {
  s3: any;
  isLoading=true;
  isHideButton=true;
  constructor(
  public appconfig: AppConfigService, 
  public adminService:  AdminService,
  public spinnerService: SpinnerService, 
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationData) { }

      ngOnInit() {
        var body={
        
          emailid:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
          company:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
          businessunit:localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
        
      }
        this.adminService.getUlla(body).subscribe(res=>{
          this.rawData = res.success.Message.eula;
        //  this.rawData = JSON.parse(res.success.Message.eula);
    //  this.rawData=this.rawData.replace(/\n|\r/g, "");
      //let email = res.success.Message.eula;
     //this.rawData=this.rawData.replace(/\\r\\n/g, "");
      
     // console.log(result)

this.rawData
    });
    
     
     this.isLoading =false;
    
  
   
  }
  rawData
   

  
}
