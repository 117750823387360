import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
export interface TypeclassData {
  header: string;
  isNoise: string;
  isVibration: string;
  icon: string;
  primarybtn: string;
  cancelbtn: string;
  type:string;
}
@Component({
  selector: 'app-typeconfirm',
  templateUrl: './typeconfirm.component.html',
  styleUrls: ['./typeconfirm.component.scss']
})

export class TypeconfirmComponent implements OnInit {

  DialogNoiseorVib:string;
flag;
form: FormGroup;
  constructor(private fb:FormBuilder,
    private dialogRef: MatDialogRef<TypeconfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TypeclassData
  ) { 
     dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.form = this.fb.group({
      Vibration:'',
      Noise:''
    });
  }
  handleChange(event)
  {
    this.data.type=event.value;
  }
   accept() {   
     let ModalData ;
     ModalData=this.data;
    this.dialogRef.close({
      isOk: true,
      data:ModalData
    });
  }
  reject() {   
    this.dialogRef.close({
      isOk: false,
      data: {}
    });
  }
}
