
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
declare var require: any
const bicubic = require('bicubic-interpolate');
/*import { MeasurementComponent } from './measurement.component';*/
export interface spectrogramSettings {
  maxValue: number;
  minValue: number;
  minFrequency: number;
  maxFrequency: number;
  minTime: number;
  maxTime: number;
}
@Component({
  selector: 'app-chart-filter',
  templateUrl: './chartfilter.component.html',
  styleUrls: ['./measurement.component.scss']
})
export class ChartFilterComponent {
  form: FormGroup;
  minx;
  maxx;
  miny;
  maxy;
  tempminx;
  tempmaxx;
  tempminy;
  tempmaxy;
  constructor(private fb: FormBuilder,
    private dialogRef: MatDialogRef<ChartFilterComponent>, //--private measure: MeasurementComponent,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }
  ngOnInit() {
    //this.minx = Number(this.data.scale.minx).toFixed(2);
    //this.maxx = Number(this.data.scale.maxx).toFixed(2);
    //this.miny = Number(this.data.scale.miny).toFixed(2);
    //this.maxy = Number(this.data.scale.maxy).toFixed(2);
    this.minx = Math.ceil(this.data.scale.minx);
    this.maxx = Math.ceil(this.data.scale.maxx);
    this.miny = Math.ceil(this.data.scale.miny);
    this.maxy = Math.ceil(this.data.scale.maxy);
    
    this.form = this.fb.group({
      minx: [this.data.selected.minx],
      maxx: [this.data.selected.maxx],
      miny: [this.data.selected.miny],
      maxy: [this.data.selected.maxy],
    });

    this.tempminx = this.form.value.minx;
    this.tempmaxx = this.form.value.maxx;
    this.tempminy = this.form.value.miny;
    this.tempmaxy = this.form.value.maxy;
  }

  ErrorMessage
  save(data) {
    if (data == 2 || data == "2") {

      //this.form = this.fb.group({
      //  minx: [this.tempminx.toString()],
      //  maxx: [this.tempmaxx.toString()],
      //  miny: [this.tempminy.toString()],
      //  maxy: [this.tempmaxy.toString()],
      //});

      this.form.value.minx = this.tempminx;
      this.form.value.maxx = this.tempmaxx;
      this.form.value.miny = this.tempminy.toFixed();
      this.form.value.maxy = this.tempmaxy.toFixed();
    } else {
      if (this.form.value.minx === "" || this.form.value.minx === undefined) {
        this.ErrorMessage = "Please enter x value min";
        return;
      }
      if (this.form.value.maxx === "" || this.form.value.maxx === undefined) {
        this.ErrorMessage = "Please enter x value max";
        return;
      }

      if (this.form.value.miny === "" || this.form.value.miny === undefined) {
        this.ErrorMessage = "Please enter y value min";
        return;
      }
      if (this.form.value.maxy === "" || this.form.value.maxy === undefined) {
        this.ErrorMessage = "Please enter y value max";
        return;
      }
      if (parseFloat(this.form.value.minx) > parseFloat(this.form.value.maxx)) {
        this.ErrorMessage = "Compare min x and max x value ";
        return;
      }
      if (parseFloat(this.form.value.maxx) < parseFloat(this.form.value.minx)) {
        this.ErrorMessage = "Compare min x and max x value ";
        return;
      }
      if (parseFloat(this.form.value.maxx) > this.maxx) {
        this.ErrorMessage = "Maximum value of X " + this.maxx + "";
        return;
      }

      if (parseFloat(this.form.value.minx) < this.minx) {
        this.ErrorMessage = "Minimum value of X " + this.minx + "";
        return;
      }

      if (parseFloat(this.form.value.miny) > parseFloat(this.form.value.maxy)) {
        this.ErrorMessage = "Compare min y and max y value ";
        return;
      }
      if (parseFloat(this.form.value.maxy) < parseFloat(this.form.value.miny)) {
        this.ErrorMessage = "Compare min y and max y value ";
        return;
      }
      if (parseFloat(this.form.value.maxy) > this.maxy) {
        this.ErrorMessage = "Maximum value of Y " + this.maxy + "";
        return;
      }

      if (parseFloat(this.form.value.miny) < this.miny) {
        this.ErrorMessage = "Minimum value of Y " + this.miny + "";
        return;
      }
    }
    //this.measure.GetThirdOctaveChart();
    // this.GetThirdOctaveChart();
    console.log(this.form.value);
    this.dialogRef.close(this.form.value);
  }
  isNumber(evt, isSymbol) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;

    if (isSymbol) {

      if (charCode == 45 || charCode == 46) {
        return true;
      }
      if (charCode > 31 && (charCode < 48 || charCode > 57 && charCode != 45)) {
        return false;
      }
    }
    else {
      if (charCode == 45 || charCode == 46) {
        return true;
      }
      if (charCode > 31 && (charCode < 48 || charCode > 57 && charCode != 45)) {
        return false;
      }
    }
    return true;
  }

  close() {
    this.ErrorMessage = "";
    this.dialogRef.close(this.form.value);
  }
}
