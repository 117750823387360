import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { AdminService } from '../auth/admin.service';
import { NVHDataTags, RootDataTags } from '../commonFiles/model/superadmin.model';
import { ErrorService } from '../commonFiles/services/error.service';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
 

@Component({
  selector: 'app-data-tag',
  templateUrl: './data-tag.component.html',
  styleUrls: ['./data-tag.component.scss']
})
export class DataTagComponent implements OnInit {
  form: FormGroup;
  nvhDataTags :NVHDataTags[]= [];
  rootDataTags :RootDataTags[]= [];
  displayedrootColumns: string[] = [ 'root', 'rootDesc'];
  displayednvhColumns: string[] = ['nvh', 'nvhDesc'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  TagNVHdatasource = new MatTableDataSource<any>();
  TagRootdatasource = new MatTableDataSource<any>();
  defaultTypes ="1"
  constructor(private fb: FormBuilder,
    private adminService: AdminService,
    public errorService: ErrorService,
    public snackBar: MatSnackBar,) { }
flag
  ngOnInit() {
    this.form = this.fb.group({
      description: ['', [Validators.required]],
      nvh:'',
      root:''
    });
    this.fillDataTags();
    this.errorService.setResponseEdlMessage(true);
  }
  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched)
    );
  }
isExist =true;
  Save(){
    if (this.defaultTypes === "1")
      ///NVH
    {
      debugger;
      this.isExist =true;
        var roottags=new NVHDataTags ;
        roottags.nvhDesc=this.form.controls.description.value;
        roottags.nvh=true;
        this.nvhDataTags.forEach(childObj=> {
          if(childObj.nvhDesc.toLowerCase() ===this.form.controls.description.value.toLowerCase())
          {
            this.snackBar.open("Tag already exist", "", {
              duration: 2500,
              verticalPosition: 'top'
            });
            this.isExist =false;
            return;
          } 
       });
if(this.isExist ===true){
       if(this.nvhDataTags.length ===0)
       {
        var roottags=new NVHDataTags ;
        roottags.nvhDesc=this.form.controls.description.value;
        roottags.nvh=true;
        this.nvhDataTags.push(roottags);     
       } else {
         debugger;
        this.nvhDataTags.push(roottags);  
        this.pushData();   
        return 
       }
      }
         
    }  else {
      this.isExist =true;
        var rootTags=new RootDataTags ;
        rootTags.rootDesc=this.form.controls.description.value;
        rootTags.root=true;
        this.rootDataTags.forEach(childObj=> {
        if(childObj.rootDesc.toLowerCase() ===this.form.controls.description.value.toLowerCase())
        {
          this.snackBar.open("Tag already exist", "", {
            duration: 2500,
            verticalPosition: 'top'
          });
          this.isExist =false;
          return;
        } 
      });
      if(this.isExist ===true){
      if(this.rootDataTags.length ===0)
      {
       var rootgs=new RootDataTags ;
       rootgs.rootDesc=this.form.controls.description.value;
       rootgs.root=true;
       this.rootDataTags.push(rootgs);     
      }
      else
      { 
        this.rootDataTags.push(rootTags);      
        this.pushData();
        return
      }
    }
    }
    
   
    if(this.isExist ===true)
      this.form.reset();
  }

  handleChange(event) {
    debugger;
    this.defaultTypes =event.value;
  }  

  fillDataTags()
  {
      this.adminService.getDataTags(localStorage.getItem('SelectedCompanyName'),localStorage.getItem('SelectedBusinessUnit'),"edl").subscribe(res=>
        { 
          for(var i =0;i<res.success.Message.rootcause.length;i++){
            var tags =new RootDataTags;
          
            tags.rootDesc =res.success.Message.rootcause[i]; 
            tags.root = true;
            this.rootDataTags.push(tags)
            } 
            
          for(var i =0;i<res.success.Message.nvhdescriptors.length;i++){
            var nvhtags =new NVHDataTags;    
        
            nvhtags.nvhDesc =res.success.Message.nvhdescriptors[i];
            nvhtags.nvh = true;     
            this.nvhDataTags.push(nvhtags); 
          }          
          this.pushData();
          this.masterToggleRoot();
          this.masterToggleNvh();
        });
  }


  pushData()
  {
    this.TagNVHdatasource.data = this.nvhDataTags;
    this.TagNVHdatasource.paginator = this.paginator;
    this.TagNVHdatasource.sort = this.sort; 
    
    this.TagRootdatasource.data = this.rootDataTags;
    this.TagRootdatasource.paginator = this.paginator;
    this.TagRootdatasource.sort = this.sort;         
  }


  pushServer()
  {
    debugger;
    var nvhArray = this.nvhDataTags.filter(function (el) {
      return el.nvh === true;
    });

    var rootArray = this.rootDataTags.filter(function (el) {
      return el.root === true;
    });
    var nvhnames = nvhArray.map(function(i) {
      return i.nvhDesc;
    });
    var rootcause = rootArray.map(function(i) {
      return i.rootDesc;
    });
    
    var body={
      company:localStorage.getItem('SelectedCompanyName'),
      businessunit:localStorage.getItem('SelectedBusinessUnit'),
      subapp:"edl",
      datatag:{nvhdescriptors:nvhnames,rootcause}
      //company:localStorage.getItem('SelectedCompanyName')
    }
    this.adminService.updateDataTags(body).subscribe(res=>{
     
      if(res.ResponseMetadata.HTTPStatusCode ===200){
        this.nvhDataTags=[];
        this.rootDataTags=[];
       // this.fillDataTags();
        this.form.reset();
        this.fillDataTags();
        window.location.reload();
      }
    })
  
  }
  
  selection = new SelectionModel<NVHDataTags>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedNvh() {
    const numSelected = this.selection.selected.length;
    const numRows = this.TagNVHdatasource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleNvh() {
    console.log(this.isAllSelectedNvh());
    if(this.isAllSelectedNvh()){
        this.selection.clear()
        
          for(var i =0; i< this.nvhDataTags.length;i++)
          {
              this.nvhDataTags[i].nvh =false;
          }          
       
      }
        else{
        this.TagNVHdatasource.data.forEach(row => {
          this.selection.select(row);
          for(var i =0; i< this.nvhDataTags.length;i++)
          {
              this.nvhDataTags[i].nvh =true;
          }          
        });
      }
  }


   
  rootSelection = new SelectionModel<RootDataTags>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedRoot() {
    
    const numSelected = this.rootSelection.selected.length;
    const numRows = this.TagRootdatasource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleRoot() {
    console.log(this.isAllSelectedRoot());
    if(this.isAllSelectedRoot()){
        this.rootSelection.clear() 
        for(var i =0; i< this.rootDataTags.length;i++)
        {
            this.rootDataTags[i].root =false;
        }  
      }else
      {
        this.TagRootdatasource.data.forEach(row => {
          this.rootSelection.select(row);
          for(var i =0; i< this.rootDataTags.length;i++)
          {
              this.rootDataTags[i].root =true;
          }  
        });
      }
  }
}
