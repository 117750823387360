import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import Constants from '../commonFiles/constant/Constants';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService

  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    const callBack = new Observable<any>(cd => { 
      
      if (!this.auth.loginState) {
        this.router.navigate(['/login']);
      }
      else
      if(sessionStorage.getItem(Constants.STORAGE.IDTOKEN)==null)
      {
          this.auth.signOut();
      }
        cd.next(true);
       
      
    })
    return callBack;
  }
}
@Injectable()
export class EDLDashboardGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService

  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    const callBack = new Observable<any>(cd => {
      
      if (this.auth.loginState) {
        var EDLdashbordEnable = false;
        if (localStorage.getItem(Constants.UserInfo.EDL) == 'true') {
          EDLdashbordEnable = true;
        }


        if (EDLdashbordEnable) {
          cd.next(true);
        }
        else {
          this.router.navigate(['/denied-access']);
        }

       
      }
      else
        this.router.navigate(['/login']);
 if(sessionStorage.getItem(Constants.STORAGE.IDTOKEN)==null)
        {
            this.auth.signOut();
        }
    })
    return callBack;
  }
}



@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router?: Router,

  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    const callBack = new Observable<any>(cd => {
     
      if (this.auth.loginState) {
        var isAdminEdl = false;
        var isAdminEol = false;
        var isAdminSd = false;
        if(localStorage.getItem(Constants.UserInfo.EDLADMIN) =="true"){
          isAdminEdl=true;
        }
        if(localStorage.getItem(Constants.UserInfo.EOLADMIN) =="true"){
          isAdminEol=true;
        }
        if(localStorage.getItem(Constants.UserInfo.SDADMIN) =="true"){
          isAdminSd=true;
        }
        if(isAdminSd || isAdminEol|| isAdminEdl){
        cd.next(true);}
      }
      else {
        if(sessionStorage.getItem(Constants.STORAGE.IDTOKEN)==null)
        {
            this.auth.signOut();
        }
        this.router.navigate(['/login']);
      }
     
      
    })
    return callBack;
  }
}


@Injectable()
export class SuperAdminGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router?: Router,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    const callBack = new Observable<any>(cd => {
      
      if (this.auth.loginState) {
        var isAdmin = true;
        localStorage.getItem(Constants.STORAGE.GROUPNAME).split(',').forEach(access => {
          if (access == 'bosch_superadmin')
            isAdmin = true;
        });
        if (isAdmin) {
          cd.next(true);
        }
        else {
          this.router.navigate(['/access-denied']);
        }


      }
      else
        this.router.navigate(['/login']);
      if(sessionStorage.getItem(Constants.STORAGE.IDTOKEN)==null)
      {
          this.auth.signOut();
      }
     
    })
    return callBack;
  }
}


