import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import Constants from "../commonFiles/constant/Constants";
import { ErrorService } from "../commonFiles/services/error.service";
import { AuthService } from "../auth/auth.service";
import { ConfirmationWindowComponent } from "../commonFiles/modalwindow/confirmation-window/confirmation-window.component";
import { MatDialog } from "@angular/material";
import { Auth } from "aws-amplify";
import { AdminService } from "../auth/admin.service";

@Component({
  selector: "app-profile-status",
  templateUrl: "./profile-status-component.component.html",
  styleUrls: ["./profile-status-component.component.scss"],
})
export class ProfileStatusComponentComponent implements OnInit {
  Usermail: string;
  isAdminMenu = false;
  visibleadmin = false;
  isEdlMeasurement = false;
  hideUserName = false;
  isAdminEdl = false;
  isAdminEol = false;
  isAdminSd = false;
  isEdl = false;
  isEol = false;
  isSd = false;
  isBack = false;
  constructor(
    private router: Router,
    public errorService: ErrorService,
    public dialog: MatDialog,
    public authService: AuthService,
    public adminService: AdminService
  ) {
    this.errorService.getResponsePostMeassage().subscribe((res) => {
      this.Usermail = localStorage.getItem(Constants.UserInfo.USERNAME);
      this.hideUserName = this.Usermail == null ? true : false;
    });
    this.errorService.getResponseEdlMeassage().subscribe((res) => {
      this.isEdlMeasurement = res;
      if (res == true) {
        this.isBack = res;
      }
    });
    this.errorService.getResponseEdlAdmin().subscribe((res) => {
      this.isAdminEdl = res;
    });
  }

  ngOnInit() {
    if (
      localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME) ===
        "bosch" &&
      localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT) === "superadmin"
    ) {
      this.isSuperAdminMenu = true;
    } else {
      this.isAdminEdl =
        localStorage.getItem("edlAdmin") == "true" ? true : false;
    }
    this.setActiveColour();

    this.Usermail = localStorage.getItem(Constants.UserInfo.USERNAME);
    this.hideUserName = this.Usermail == null ? true : false;
    this.authService.validateUser();
  }

  profile() {
    this.errorService.setResponseEdlMessage(false);
    this.router.navigate(["/profile"]);
  }
  logout() {
    var dialogRef = this.dialog.open(ConfirmationWindowComponent, {
      data: {
        header: "Logout",
        primarybtn: "Yes",
        message: "Are you sure, want to logout?",
        cancelbtn: "No",
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res.isOk) {
        localStorage.clear();
        Auth.signOut({ global: true }).then((signOutRes) => {});
        this.router.navigate(["/login"]);
      }
    });
  }

  logoutAllDevices() {
    var dialogRef = this.dialog.open(ConfirmationWindowComponent, {
      data: {
        header: "Logout of all devices",
        primarybtn: "Yes",
        message:
          "Are you sure, want to logout of all Web & Mobile devices ? On logging out of a mobile device(s), the un-synced data will be lost and cannot be recovered. Please make sure all the measured data is uploaded before logging out.",
        cancelbtn: "No",
      },
      height: "300px",
      width: "550px",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res.isOk) {
        // localStorage.clear();
        this.adminService.logoutAllDevices().subscribe((res) => {
          // Auth.signOut({ global: true }).then(signOutRes => {
          // });
          //console.log("Response:-", res);
          //console.log("Response 1:-", res.isOk);
            this.router.navigate(["/login"]);
        });
      }
    });
  }

  changepassword() {
    this.errorService.setResponseEdlMessage(false);
    this.router.navigate(["/change-password"]);
  }

  compare() {
    this.setEdlActiveColour();
    this.router.navigate(["/edl/comparemeasurement"]);
  }

  redirectLand() {
    localStorage.setItem("isSD", "false");
    localStorage.setItem("isEdl", "false");
    localStorage.setItem("isEol", "false");
    this.errorService.setResponseEdlMessage(false);
    if (localStorage.getItem(Constants.UserInfo.USERNAME) !== null) {
      this.router.navigate(["/home"]);
    }
  }

  redirecToModule() {
    if (localStorage.getItem("isSD") == "true") {
      //this.router.navigate(['/EDLdashboard']);
    }
    if (localStorage.getItem("isEdl") == "true") {
      this.router.navigate(["edl/all-measurement"]);
    }
    if (localStorage.getItem("isEol") == "true") {
      //this.router.navigate(['/EDLdashboard'])
    }
  }
  isSuperAdminMenu = false;

  redirectBack() {
    switch (this.router.url) {
      case "/change-password":
      case "/user-settings":
      case "/edl/all-measurement":
      case "/users":
      case "/threshold":
      case "/edl/datatag":
      case "/edl/VehicleTestMaster":
        this.errorService.setResponseEdlMessage(false);
        this.isBack = false;
        this.router.navigate(["/home"]);
        break;

      case "/edl/comparemeasurement":
        this.errorService.setResponseEdlMessage(true);
        this.router.navigate(["/edl/all-measurement"]);
        break;
      case "/add-user":
        this.errorService.setResponseEdlMessage(false);
        this.router.navigate(["/users"]);
        break;
      case "/add-threshold":
        this.errorService.setResponseEdlMessage(false);
        this.router.navigate(["/threshold"]);
        break;
    }
  }
  redirecToLand() {
    this.errorService.setResponseEdlMessage(false);
    if (localStorage.getItem("edlAdmin") == "true") {
      this.edlAdminMenuCheck();
    }
    this.isBack = false;
    this.router.navigate(["/home"]);
  }
  setMenu() {
    this.errorService.setResponseEdlMessage(false);
    this.errorService.setResponseEdlAdmin(false);
    this.isBack = false;
  }
  setSuperAdminMenu() {
    this.errorService.setResponseEdlMessage(false);
    this.isBack = true;
  }
  edlAdminMenuCheck() {
    if (localStorage.getItem("edlAdmin") == "true") {
      this.errorService.setResponseEdlAdmin(true);
    } else {
      this.errorService.setResponseEdlAdmin(false);
    }
  }

  setAdminMenu() {
    this.errorService.setResponseEdlMessage(true);
  }

  setTags() {
    localStorage.setItem(
      "SelectedCompanyName",
      localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME)
    );
    localStorage.setItem(
      "SelectedBusinessUnit",
      localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT)
    );
    this.router.navigate(["/edl/datatag"]);
  }

  SetVehicleTestMaster() {
    localStorage.setItem(
      "SelectedCompanyName",
      localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME)
    );
    localStorage.setItem(
      "SelectedBusinessUnit",
      localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT)
    );
    this.router.navigate(["/edl/VehicleTestMaster"]);
  }
  setEdlActiveColour() {
    var strActiveSet = "";
    switch (this.router.url) {
      case "/edl/all-measurement":
      case "/edl/measurement":
        strActiveSet = "activeSet";
        break;
    }
    return strActiveSet;
  }
  setVehicleMasterActiveColor() {
    var strActiveSet = "";
    switch (this.router.url) {
      case "/edl/VehicleTestMaster":
        strActiveSet = "activeSet";
        break;
    }
    return strActiveSet;
  }
  settagActiveColour() {
    var strActiveSet = "";
    switch (this.router.url) {
      case "/edl/datatag":
        strActiveSet = "activeSet";
        break;
    }
    return strActiveSet;
  }

  setEdlCompareActiveColour() {
    var strActiveSet = "";
    switch (this.router.url) {
      case "/edl/comparemeasurement":
        strActiveSet = "activeSet";
        break;
      default:
        strActiveSet = "";
        break;
    }
    return strActiveSet;
  }
  setSuperAdminActiveColour() {
    var strActiveSet = "";
    switch (this.router.url) {
      case "/users":
      case "/add-user":
        strActiveSet = "activeSet";
        break;
      default:
        strActiveSet = "";
        break;
    }
    return strActiveSet;
  }
  setSuperAdminThresholdActiveColour() {
    var strActiveSet = "";
    switch (this.router.url) {
      case "/threshold":
      case "/add-threshold":
        strActiveSet = "activeSet";
        break;
      default:
        strActiveSet = "";
        break;
    }
    return strActiveSet;
  }

  setActiveColour() {
    var strActiveSet = "";
    switch (this.router.url) {
      case "/users":
      case "/add-user":
        strActiveSet = "activeSet";
        this.isBack = true;
        break;
      case "/edl/all-measurement":
      case "/edl/measurement":
      case "/edl/comparemeasurement":
        this.errorService.setResponseEdlMessage(true);
        this.isBack = true;
        strActiveSet = "activeSet";
        break;
      default:
        strActiveSet = "";
        break;
    }
    return strActiveSet;
  }

  checkAdminMenuVisiblisty() {
    if (this.isAdminEdl) {
      switch (this.router.url) {
        case "/edl/all-measurement":
        case "/admin":
        case "/edl/comparemeasurement":
        case "/edl/datatag":
        case "/edl/VehicleTestMaster":
        case "/threshold":
          return true;
        default:
          return false;
      }
    }
  }
  ///TODO : Itesmk
  expirayDate;
  visibleEDL() {
    switch (this.router.url) {
      case "/edl/all-measurement":
      case "/edl/comparemeasurement":
        this.expirayDate = localStorage.getItem("EDLValidity");

        const d = new Date(this.expirayDate * 1000);
        this.expirayDate = d;
        return true;
    }
  }

  gotoHome() {
    localStorage.setItem("isEdl", "false");
    this.isEdlMeasurement = false;
    this.router.navigate(["/home"]);
  }
}
