import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  public showLoader = new Subject<boolean>(); 

  constructor() { }

  private subject = new Subject<any>();

  showSpinner() {
    
      this.showLoader.next(true);
  }

  hideSpinner() {    
      this.showLoader.next(false);
  }

  getMessage(): Observable<any> {
      return this.showLoader.asObservable();
  }


  getErrorMessage(): Observable<any> {
    return this.subject.asObservable();
}

 

private requestAmount = new Subject<any>();

public showErrorMsg = this.requestAmount.asObservable();

 

setErrorMessage(msg) {
    this.requestAmount.next(msg);
}


private messageSource = new BehaviorSubject('-');
currentMessage = this.messageSource.asObservable();

 changeMessage(message: string) {
    this.messageSource.next(message)
  }


  private primaryTest = new BehaviorSubject(Boolean);
  primaryTestApp = this.primaryTest.asObservable();

 pimary(message) {
    this.primaryTest.next(message)
  }

  private secondaryTest = new BehaviorSubject(Boolean);
  secondaryTestApp = this.secondaryTest.asObservable();

 secondary(message) {
    this.secondaryTest.next(message)
  }

}
