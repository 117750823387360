import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

// custom validator to password pattern 
export function PasswordPattern(controlName: string) {
    
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
       
            if (control.errors) {
                // return if another validator has already found an error on the matchingControl
                return;
            }
            var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,}$/;

            if (!re.test(control.value)) {

                control.setErrors({ passwordPattern: false });
            } else {

                control.setErrors(null);
            }
        
    }
}

// custom validator to check that two fields match
export function TextMax(controlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        if (control.errors) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        if (control.value.length < 3) {
            control.setErrors({ maxError: true });
        } else {
            control.setErrors(null);
        }
    }
}


// custom validator to check that two fields match
export function validateCheckBox(admin: string, web: string, mobile: string) {
    return (formGroup: FormGroup) => {
        const controladmin = formGroup.controls[admin];
        const controlweb = formGroup.controls[web];
        const controlmobile = formGroup.controls[mobile];

        if (controladmin.parent.value.isAdmin) {
            controladmin.setErrors(null);
            controlweb.setErrors(null);
            controlmobile.setErrors(null);
            return;
        }
        if (controlweb.parent.value.isWeb) {
            controladmin.setErrors(null);
            controlweb.setErrors(null);
            controlmobile.setErrors(null);
            return;
        }
        if (controlmobile.parent.value.isMobile) {
            controladmin.setErrors(null);
            controlweb.setErrors(null);
            controlmobile.setErrors(null);
            return;
        }
        controladmin.setErrors({ selectCheckAdmin: true });

    }
}

// custom validator to check that two fields match
export function validateCheckBoxDashBoard(admin: string, web: string, mobile: string) {
    return (formGroup: FormGroup) => {
        const controladmin = formGroup.controls[admin];
        const controlweb = formGroup.controls[web];
        const controlmobile = formGroup.controls[mobile];

        if (controladmin.parent.value.isEdl) {
            controladmin.setErrors(null);
            controlweb.setErrors(null);
            controlmobile.setErrors(null);
            return;
        }
        if (controlweb.parent.value.isEol) {
            controladmin.setErrors(null);
            controlweb.setErrors(null);
            controlmobile.setErrors(null);
            return;
        }
        if (controlmobile.parent.value.isSd) {
            controladmin.setErrors(null);
            controlweb.setErrors(null);
            controlmobile.setErrors(null);
            return;
        }
        controladmin.setErrors({ selectCheckDomain: true });

    }
}

// custom validator to check that two fields match
export function CompareValueLess(controlName: string, orginalValue: number) {
    return (formGroup: FormGroup) => {
 
        const control = formGroup.controls[controlName];
        const orgValu = Number(orginalValue);

        if (control.errors) {
            
            return;
        }

        // set error on matchingControl if validation fails
        if (Number(control.value) < orgValu) {
            control.setErrors({ isLessValue: true });
        } else {
            control.setErrors(null);
        }
    }
}
// custom validator to check that two fields match
export function CompareValueGreater(controlName: string, orginalValue: number) {
    return (formGroup: FormGroup) => {
       
        const control = formGroup.controls[controlName];
        const orgValu = Number(orginalValue);
        if (control.errors) {            
            return;
        }
       
        if (Number(control.value) >= orgValu) {
            control.setErrors({ isGreaterValue: true });
        } else {
            control.setErrors(null);
        }
    }
}

// custom validator to check that two fields match
export function CompareValue(toName: string, fromName: string) {
    return (formGroup: FormGroup) => { 
        
        const toNameValue = formGroup.controls[toName];
        const fromNameValue = formGroup.controls[fromName];
        if (toNameValue.errors &&(toNameValue.value=='' ||fromNameValue.value =='')) {            
            return;
        }       
        if (Number(toNameValue.value) >= Number(fromNameValue.value)) {
            toNameValue.setErrors({ isComparefail: true });
            fromNameValue.setErrors({ isComparefail: true });
        } else {
            toNameValue.setErrors(null);
        }
    }
}



//Check Value EDL 
export function CheckValue(edlString: any, eolString: any, sdString: any, companY: any, bU: any,mobileEdl:any,webEdl:any,mobileEol:any,webEol:any,mobileSd:any,webSd:any) {
    
    return (formGroup: FormGroup) => {       
          
        const edlValue = formGroup.controls[edlString].value;
        const eolValue = formGroup.controls[eolString].value;
        const sdValue = formGroup.controls[sdString].value;


        const mEdl = formGroup.controls[mobileEdl].value;
        const wEdl = formGroup.controls[webEdl].value;        

        const mEol = formGroup.controls[mobileEol].value;
        const wEol = formGroup.controls[webEol].value;

        const mSd = formGroup.controls[mobileSd].value;
        const wSd = formGroup.controls[webSd].value;

        if(formGroup.controls[companY].value === "")
        {             
            return false;
        }        
        if(formGroup.controls[bU].value==="")
        {
            formGroup.controls[bU].setErrors({required:true});
            return false;
        }
        var anyOne=false;     
            
        if (edlValue  ===true) {    
            if(mEdl==="" && wEdl===""){
                formGroup.controls[mobileEdl].setErrors({requiredEdl:true});
                formGroup.controls[webEdl].setErrors({requiredEdl:true});
                
            }else{
                formGroup.controls[mobileEdl].setErrors(null);
                formGroup.controls[webEdl].setErrors(null);
                anyOne =true;
            }
        }   
        
        if (eolValue  ===true) {    
            if(mEol==="" && wEol===""){
                formGroup.controls[mobileEol].setErrors({requiredEol:true});
                formGroup.controls[webEol].setErrors({requiredEol:true});
                
            }else{
                formGroup.controls[mobileEol].setErrors(null);
                formGroup.controls[webEol].setErrors(null);
                anyOne =true;
            }
        }

        if (sdValue  ===true) {    
            if(wSd==="" && mSd===""){
                formGroup.controls[mobileSd].setErrors({requiredSd:true});
                formGroup.controls[webSd].setErrors({requiredSd:true});
               
            }else{
                formGroup.controls[mobileSd].setErrors(null);
                formGroup.controls[webSd].setErrors(null);
                anyOne =true;
            }
        }
        if(anyOne ===true){
            formGroup.controls[mobileSd].setErrors(null);
            formGroup.controls[webSd].setErrors(null);
            formGroup.controls[mobileEol].setErrors(null);
            formGroup.controls[webEol].setErrors(null);
            formGroup.controls[mobileEdl].setErrors(null);
            formGroup.controls[webEdl].setErrors(null);
            return;
        }else{
            formGroup.controls[mobileSd].setErrors({requiredSd:true});
            formGroup.controls[webSd].setErrors({requiredSd:true});
            formGroup.controls[mobileEol].setErrors({requiredEol:true});
            formGroup.controls[webEol].setErrors({requiredEol:true});
            formGroup.controls[mobileEdl].setErrors({requiredEdl:true});
            formGroup.controls[webEdl].setErrors({requiredEdl:true});
        }
        
    }
}