import { AuthService } from '../../auth/auth.service'
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,  HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  authToken = null;
  authRefreshToken = null;
  
  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.loginState) {
      
      request = this.addToken(request, this.authService.getAuthToken());
      return next.handle(request).pipe(catchError(error => {
      
        if (error instanceof HttpErrorResponse &&(error.status === 401||error.status === 0)) {  
          
           this.authService.RefreshToken();
           return next.handle(request); 
          //return this.handle401Error(request, next);
        } else{
        return next.handle(request);}
        
      }));
    }
    else
      return next.handle(request)
  }
  private addToken(request: HttpRequest<any>, token: string) { 
    if (request.url.indexOf('authenticated') !== -1) {
      
      var req = request.clone({
        setHeaders: {
          'Authorization':`Bearer ${token}`,
          'Content-Type':'application/json; charset=utf-8',
         
        }
      });
      return req;
    }
    else return request
  }
/*   private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      
      this.refreshTokenSubject.next(null);
      return this.authService.RefreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.AuthenticationResult.IdToken);
          return next.handle(this.addToken(request, token.AuthenticationResult.IdToken));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  } */
}


