import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer-component.component.html',
  styleUrls: ['./app-footer-component.component.scss']
})
export class AppFooterComponent implements OnInit {
  isShow
  constructor(public authService:AuthService) { 
    this.authService.isLoggedIn.subscribe(res => {
     // if(res===true){
        this.isShow =res;
      //}
    });
  }

  ngOnInit() {
  }

}
