import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AdminService } from '../../auth/admin.service';
import Constants from '../../commonFiles/constant/Constants';
import { ErrorService } from '../../commonFiles/services/error.service';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Threshold, ThresholdList, RootcauseArray, RootcauseList } from './../thresholdModel'
import { ThresholdService } from './../threshold.service';
import { ConfirmationWindowComponent } from '../../commonFiles/modalwindow/confirmation-window/confirmation-window.component'
import { double } from '@aws-amplify/core/node_modules/aws-sdk/clients/lightsail';
import { variable } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-add-rootcause',
  templateUrl: './add-rootcause.component.html',
  styleUrls: ['./add-rootcause.component.scss']
})
export class AddRootcauseComponent implements OnInit {

  D2form: FormGroup;
  AllRootThreshold: any;
  RootThreshold: any;
  fftCount: number = 0;
  D2Data: any;
  PREV_DATA: Element;
  ValidationMessage: any;
  MinMaxUnit: any;
  ThresholdUnit: any;
  thresholdlist: ThresholdList;

  FFTData: Element[] = [];
  PromRatioData: Element[] = [];
  OctaveData: Element[] = [];
  ThirdOctaveData: Element[] = [];

  CenterFrequency: any;
  isOctave: Boolean;

  rootcauseForm: FormGroup;
  DefaultColor: any;
  ErrorMessage: any;
  Allthresholdfromdb: any;
  AnalysisArray: any;
  NewRootcause: boolean;
  recored: Element = {
    Position: 1,
    Min: 0,
    Max: 0,
    fc: 0,
    Threshold: 0,
    Action: '0'
  }
  constructor(private fb: FormBuilder, private threshService: ThresholdService,
    public dialogRef: MatDialogRef<AddRootcauseComponent>,
    public dialog: MatDialog,

    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data);
  }
  displayedColumns = ['Position', 'Min', 'Max', 'fc', 'Threshold', 'Action'];
  displayedColumnsOctave = ['fc', 'Threshold', 'Action'];
  fftdataSource: MatTableDataSource<Element>;
  thirddataSource: MatTableDataSource<Element>;
  specpromdataSource: MatTableDataSource<Element>;
  ngOnDestroy() {
    this.data = undefined;
  }
  ngOnInit() {
    this.NewRootcause = true;
    this.CenterFrequency = [];
    this.CenterFrequency = this.data.octaveFreq;
    if (this.data.OpenType == "THRESHEDIT") {
      this.NewRootcause = false;
      console.log(this.data.rootcause);
      this.rootcauseForm = this.fb.group({
        rootcause: this.data.rootcause.rootcause,
        color: this.data.rootcause.color,

      })
      this.FFTData = this.data.rootcause.type[0].Values;
      this.PromRatioData = this.data.rootcause.type[2] == undefined ? [] : this.data.rootcause.type[1].Values;
      this.ThirdOctaveData = this.data.rootcause.type[3] == undefined ? [] : this.data.rootcause.type[2].Values;

    }
    if (this.data.OpenType == "EDIT") {
      this.NewRootcause = false;
      console.log(this.data.rootcause);
      this.rootcauseForm = this.fb.group({
        rootcause: this.data.rootcause.rootcause,
        color: this.data.rootcause.color,

      })
      this.FFTData = this.data.rootcause.type[0].Values;
      this.PromRatioData = this.data.rootcause.type[1] == undefined ? [] : this.data.rootcause.type[1].Values;
      this.ThirdOctaveData = this.data.rootcause.type[2] == undefined ? [] : this.data.rootcause.type[2].Values;
    }
    else if (this.data.OpenType == "ADD") {
      this.NewRootcause = true;
      this.rootcauseForm = this.fb.group({
        rootcause: ['', Validators.required],
        color: Constants.Default_Color,

      })
    }
    if (this.data.formdata.AnalysisType == 'n') {
      this.MinMaxUnit = "Hz"
      this.ThresholdUnit = "dBA"
    }
    else {

      this.MinMaxUnit = "Hz"
      this.ThresholdUnit = "m/s^2"
      //this.MinMaxUnit = "m/s^2"
      //this.ThresholdUnit="dBA"
    }
    this.fftdataSource = new MatTableDataSource(this.FFTData);
    this.thirddataSource = new MatTableDataSource(this.ThirdOctaveData);
    this.specpromdataSource = new MatTableDataSource(this.PromRatioData);

    // this.FFTData=FFT_DATA;
    // this.ThirdOctaveData=ONETHIRD_OCTAVE_DATA;
    // this.PromRatioData=SPECPROM_DATA;
    var OctaveCols = this.displayedColumns;
    this.displayedColumns.splice(3, 1);
    console.log(this.displayedColumns);
    this.displayedColumns.splice(0, 1);
    console.log(this.displayedColumns);

    this.AnalysisArray = [{ analysis: "fft", values: this.fftdataSource, min: Constants.FFTMin_nv, max: Constants.FFTMax_nv, displayCols: this.displayedColumns },
    { analysis: "SpecPromRatio", values: this.specpromdataSource, max: Constants.SpecPromMax_n, min: Constants.SpecPromMin_n, displayCols: this.displayedColumns },
    { analysis: "OnethirdOctave", values: this.thirddataSource, displayCols: this.displayedColumnsOctave },

    ];
    console.log(this.AnalysisArray);
    // if(this.data.OpenType=="EDIT")
    //  this.AnalysisArray=this.AnalysisArray.filter(object=>object.values.data.length>0);
    //  console.log( this.AnalysisArray);
  }

  addNew(AnalysisName) {

    console.log(this.rootcauseForm);
    this.recored = {
      Position: this.FFTData.length + 1,
      Min: 0,
      Max: 0,
      fc: 0,
      Threshold: 0,
      Action: '0'
    };


    if (AnalysisName.toLowerCase() == "fft") {
      this.recored.Position = this.FFTData.length + 1
      this.FFTData.push(this.recored);
      this.AnalysisArray[0].values = new MatTableDataSource<Element>(this.FFTData);
    }
    else if (AnalysisName.toLowerCase() == "specpromratio") {
      this.recored.Position = this.PromRatioData.length + 1
      this.PromRatioData.push(this.recored);
      this.AnalysisArray[1].values = new MatTableDataSource<Element>(this.PromRatioData);
    }
    else if (AnalysisName.toLowerCase() == "onethirdoctave") {
      this.recored.Position = this.ThirdOctaveData.length + 1
      this.ThirdOctaveData.push(this.recored);
      this.AnalysisArray[2].values = new MatTableDataSource<Element>(this.ThirdOctaveData);
    }


  }


  closeDialog() {

    var resultdata: any;
    resultdata = [];
    this.data = undefined;
    this.ClearMatTable();
    this.dialogRef.close('');

  }
  ClearMatTable() {
    var data: Element[] = [];
    this.fftdataSource = new MatTableDataSource(data);
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.fftdataSource.filter = filterValue;
  }
  // updateRowData(row_obj){
  //   this.dataSource = this.dataSource.filter((value,key)=>{
  //     if(value.position == row_obj.position){
  //       value.name = row_obj.name;
  //     }
  //     return true;
  //   });
  // }
  deleteRowData(analysisName, row_obj) {

    var resultdata: any;
    resultdata = [];
    var body = {
      rootcauseName: this.rootcauseForm.controls.rootcause,
      analysisName: analysisName,
      recPosition: row_obj.Position
    }

    var dialogRef = this.dialog.open(ConfirmationWindowComponent, {

      data: {
        header: 'Delete Analysis Record',
        primarybtn: 'Delete',
        message: "Are you sure, want to delete?",
        cancelbtn: 'Cancel',
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res.isOk) {
        
        //debugger;
        //let data = this.FFTData.filter((value, key) => {
        //  return value.Position != row_obj.Position;
        //});
        //this.AnalysisArray[0].values = new MatTableDataSource(data);
        if (analysisName.toLowerCase() == "fft") {
          this.FFTData = this.FFTData.filter(obj => obj.Position != row_obj.Position);

          this.AnalysisArray[0].values = new MatTableDataSource<Element>(this.FFTData);
        }
        else if (analysisName.toLowerCase() == "specpromratio") {
          this.PromRatioData = this.PromRatioData.filter(obj => obj.Position != row_obj.Position);

          this.AnalysisArray[1].values = new MatTableDataSource<Element>(this.PromRatioData);
        }
        else if (analysisName.toLowerCase() == "onethirdoctave") {
          this.ThirdOctaveData = this.ThirdOctaveData.filter(obj => obj.Position != row_obj.Position);
          this.AnalysisArray[2].values = new MatTableDataSource<Element>(this.ThirdOctaveData);
        }
      }
    });



  }


  ThresholdValidation(CurrentData, PrevData): string {
    var ErrorMessage;
    if (CurrentData.Minvalue < PrevData.Max || CurrentData.Minvalue < PrevData.Min || CurrentData.Maxvalue < PrevData.Min || CurrentData.Maxvalue < PrevData.Max) {
      ErrorMessage = "Newly entered Min/Max value should not overlap with previous record."
    }
    return ErrorMessage;
  }

  ThresholdInitValidation(CurrentData): string {

    var datatype: any;
    datatype = this.data.ThresholdUnit.toUpperCase() == "DBA" ? "n" : "v";
    var ErrorMessage;
    var minValue: any;
    var maxValue: any;
    if (CurrentData.Minvalue < 0 || CurrentData.Maxvalue <= 0) {
      ErrorMessage = "Min or Max value should not be less than 0."
    }
    if (CurrentData.Minvalue >= CurrentData.Maxvalue) {
      ErrorMessage = "Max value should not be less than or equal to Min value."
    }
    if (this.data.analysistype == 'FFT') {
      minValue = Constants.FFTMin_nv;
      maxValue = Constants.FFTMax_nv;
      ErrorMessage = this.ValidateMinMaxValue(CurrentData.Minvalue, CurrentData.Maxvalue, minValue, maxValue)
      if (ErrorMessage != "")
        return ErrorMessage;
      if (datatype == "n") {
        minValue = Constants.FFTThreshMin_n;
        maxValue = Constants.FFTThreshMax_n;
      }
      else {
        minValue = Constants.FFTThreshMin_v;
        maxValue = Constants.FFTThreshMax_v;
      }
      ErrorMessage = this.ValidateThresholdRange(CurrentData.Thresholdvalue, CurrentData.Thresholdvalue, minValue, maxValue)
      if (ErrorMessage != "")
        return ErrorMessage;
    }
    else if (this.data.analysistype == 'PR_AVG' && datatype == "n") {
      minValue = Constants.SpecPromMin_n;
      maxValue = Constants.SpecPromMax_n;
      ErrorMessage = this.ValidateMinMaxValue(CurrentData.Minvalue, CurrentData.Maxvalue, minValue, maxValue)
      if (ErrorMessage != "")
        return ErrorMessage;
      minValue = Constants.SpecPromThreshMin_n;
      maxValue = Constants.SpecPromThresMax_n;
      ErrorMessage = this.ValidateThresholdRange(CurrentData.Thresholdvalue, CurrentData.Thresholdvalue, minValue, maxValue)
      if (ErrorMessage != "")
        return ErrorMessage;
    }


    return ErrorMessage;
  }
  ValidateMinMaxValue(CurMin, CurMax, minvalue, maxvalue) {
    var message: any;
    message = "";
    if (CurMin < minvalue || CurMax > maxvalue) {
      message = "Min. and Max. value should be in " + minvalue + "-" + " " + maxvalue + this.data.MinMaxUnit + " range.";
    }
    return message;
  }
  ValidateThresholdRange(CurMin, CurMax, minvalue, maxvalue) {
    var message: any;
    message = "";
    if (CurMin < minvalue || CurMax > maxvalue) {
      message = "Threshold should be in " + minvalue + "-" + maxvalue + " " + this.data.ThresholdUnit + " range";
    }
    return message;
  }
  CheckAnalysisExist() {
    let tempList;
    let IsExist = false;
    tempList = this.AnalysisArray.filter(obj => obj.values.data.length > 0);
    if (tempList.length > 0)
      IsExist = true;
    return IsExist;
  }
  UpdateRootcause() {
    this.NewRootcause = false;
    let IsExist = false;
    IsExist = this.CheckAnalysisExist();
    console.log(this.AnalysisArray);
    this.ErrorMessage = this.ValidateAnalysis();
    if (this.ErrorMessage != "" && this.ErrorMessage != undefined)
      return this.ErrorMessage;
    this.threshService.updateRootCauseList(this.rootcauseForm, this.AnalysisArray);
    this.closeDialog();
  }
  areOverlapping(a, b) {

    return (a.Min <= b.Max) && (a.Max >= b.Min);
  }
  isMinMaxOverlap(a) {
    return (a.Min > a.Max)
  }
  anyOverlap(intervals: any[]) {
    for (let i = 0; i <= intervals.length - 1; i++) {
      if (this.isMinMaxOverlap(intervals[i])) return 2;
      for (let j = i + 1; j < intervals.length; j++) {
        if (this.areOverlapping(intervals[i], intervals[j])) return 1;
      }
    }

    return false;
  }
  ValidateAnalysis() {
    let validateMessage;
    console.log(this.AnalysisArray)
    let tempList = this.AnalysisArray.filter(obj => obj.values.data.length > 0);
    if (tempList.length > 0) {
      let OctaveSet = tempList.filter(obj => obj.analysis == "OnethirdOctave")
      if (OctaveSet.length > 0) {
        const status = OctaveSet[0].values.data.some(user => {
          let counter = 0;
          for (const iterator of OctaveSet[0].values.data) {
            if (iterator.fc === user.fc) {
              counter += 1;
            }
          }
          return counter > 1;
        });
        if (status) {
          validateMessage = "Data is overlapping in OneThird Octave threshold."
          return validateMessage;
        }
      }
      let fftset = tempList.filter(obj => obj.analysis == "fft")
      if (fftset.length > 0) {
        if (this.anyOverlap(fftset[0].values.data) == 1) {
          validateMessage = "Data is overlapping in FFT threshold.Please check."
          return validateMessage;
        }
        if (this.anyOverlap(fftset[0].values.data) == 2) {

          validateMessage = "Min. value is greater than max. value in FFT.Please check."
          return validateMessage;
        }
      }
      let specpromSet = tempList.filter(obj => obj.analysis == "SpecPromRatio")
      if (specpromSet.length > 0) {
        if (this.anyOverlap(specpromSet[0].values.data) == 1) {
          validateMessage = "Duplicate record found in Spec. Prom. Ratio threshold.Please check."
          return validateMessage;
        }
        if (this.anyOverlap(specpromSet[0].values.data) == 2) {
          validateMessage = "Min. value is greater than max. value in Spec. Prom. Ratio."
          return validateMessage;
        }
      }
    }
  }
  AddRootcause() {
    var IsExist;
    this.NewRootcause = true;
    IsExist = false;
    this.ErrorMessage = "";
    let IsExistAnalysis = this.CheckAnalysisExist()
    console.log('while saving');
    console.log(this.AnalysisArray);
    IsExist = this.threshService.CheckRootcauseExist(this.rootcauseForm);
    if (this.rootcauseForm.value.rootcause == "" || this.rootcauseForm.value.rootcause == undefined)
      this.ErrorMessage = "Please enter root cause name.";
    else if (IsExist) {
      this.ErrorMessage = "Entered root cause already exists in the list.";
    } else
      if (!IsExistAnalysis) {
        this.ErrorMessage = "Analysis not added.";
      }
      else {
        this.ErrorMessage = this.ValidateAnalysis();
        if (this.ErrorMessage != "" && this.ErrorMessage != undefined)
          return this.ErrorMessage;

        this.threshService.updateRootCauseList(this.rootcauseForm, this.AnalysisArray);
        this.Allthresholdfromdb = this.threshService.getrootcauselist();

        //super.GetThresholdObj(formdata);
        this.rootcauseForm.reset();
        this.threshService.updateThreshold();

        // this.thresholdupdateRootcause();
        this.threshService.clearroocuaseAfterAdd();
        this.ClearMatTable();
        this.dialogRef.close();
        //this.Allthresholdfromdb[0].type.push({Analysis:"test"});
        //this.isRootCauseAdd=false;
      }

  }
}


export interface Element {
  Position: number;
  Min: number;
  Max: number;
  fc: number;
  Threshold: number;
  Action: string;
}



