import { BrowserModule } from '@angular/platform-browser';
import { NgModule,  APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule,  HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import{NotifierComponent} from './notifier/notifier.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SventaMaterialModule } from './Sventa-material-module';
import { SharedModule } from './shared/shared.module'; 
import { MeasurementComponent } from './measurement/measurement.component';
import { MeasurementService } from './measurement/measurment.service';
import { DataService } from './shared/data.service';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { AuthService } from './auth/auth.service';
import {  EDLDashboardGuard, AdminGuard,AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ForgotPasswordStep1Component,ForgotPassword2Component} from './forgot/forgotPassword.component';
import { GroupsettingsComponent } from './groupsettings/groupsettings.component'
import { DataTablesModule } from 'angular-datatables';
import { AdminService } from './auth/admin.service';
import { NotifierModule, NotifierOptions } from "angular-notifier";
import { ProfileService } from './auth/profile.service'
import { AppConfigService } from './commonFiles/services/app-initializer.service';
import { TranslatePipe } from './commonFiles/pipe/translate.pipe'; 
import { ConfirmationWindowComponent } from './commonFiles/modalwindow/confirmation-window/confirmation-window.component';
import { CompanyRegisterationComponent } from './commonFiles/modalwindow/companyregisteration/companyregisteration.component'
import { AccessdeniedComponent } from './accessdenied/accessdenied.component';
import { ProfileStatusComponentComponent } from './profile-status-component/profile-status-component.component';
import { AppFooterComponent } from './app-footer-component/app-footer-component.component';
import { dashboardService } from './dashboard/dashboard.service';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChartistModule } from 'ng-chartist';
import { AppSpinnerComponent } from './app-spinner/app-spinner.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { SpectrogramFilterComponent } from './spectrogram-filter/spectrogram-filter.component';
import { Spectrogram } from './measurement/Spectrogram';
import { ProminenceSpectrogram } from './measurement/ProminenceSpectroGraph';
import { FileDownloadComponent } from './commonFiles/modalwindow/file-download/file-download.component';
import { TermsComponent } from './terms/terms.component';
import { LandingComponent } from './landing/landing.component';
import { ChangePasswordComponent } from './user-settings/change-password/change-password.component';
import { ShowAccessComponent } from './commonFiles/modalwindow/show-access/show-access.component';
import { AppInterceptor } from './commonFiles/services/app.interceptor';
import { ChartFilterComponent } from './measurement/chartfilter.component';
import { AdminComponent } from './admin/admin/admin.component';
import { TreetableModule } from 'ng-material-treetable';
import { AddUserComponent } from './admin/add-user/add-user.component';
import { UpdateLicenceComponent } from './admin/super-admin/update-licence/update-licence.component';
import { GeoLocationComponent } from './geo-location/geo-location.component';
import { AgmCoreModule } from '@agm/core';            // @agm/core
import { AgmDirectionModule } from 'agm-direction';   
import { CognitoUtil } from './auth/conginto.service';
import { SetFirstComponent } from './set-first/set-first.component';
import { MeasurementHeaderComponent} from './measurement/header.component'
import {MeasurementImageComponent} from'./measurement/Image.component';
import { UpdateStorageComponent } from './admin/super-admin/update-storage/update-storage.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ListAdminComponent } from './admin/super-admin/list-admin/list-admin.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { FullComponent } from './layouts/full/full.component';
import { SubappPermissionComponent } from './commonFiles/modalwindow/subapp-permission/subapp-permission.component';
import { DataTagComponent } from './data-tag/data-tag.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { DataProtectionComponent } from './data-protection/data-protection.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { ThresholdComponent } from './threshold/threshold.component';
import { AddThresholdComponent } from './threshold/add-threshold/add-threshold.component';
import { TypeconfirmComponent } from './typeconfirm/typeconfirm.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ProminenceFilterComponent } from './prominence-filter/prominence-filter.component';
import { ResizableModule } from 'angular-resizable-element';
import { WorkerModule } from 'angular-web-worker/angular';
import { DownloadWorker } from './download.worker';
import { DownloadsComponent } from './downloads/downloads.component';
import { AddRootcauseComponent } from './threshold/add-rootcause/add-rootcause.component';
import { VehicleTestMasterComponent } from './vehicle-test-master/vehicle-test-master.component';



const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'left',
      distance: 12
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadConfigOnAppInit();
  };
};
const workerFn = () => new Worker('./download.worker.ts', { type: 'module' });
@NgModule({
  declarations: [
    AppComponent,   
    AppSpinnerComponent,
    MeasurementComponent,
    UserSettingsComponent,
    LoginComponent, 
    SetFirstComponent,    
    HomeComponent,
    ForgotPassword2Component,
    ForgotPasswordStep1Component,
    GroupsettingsComponent,    
    TranslatePipe,
    ConfirmationWindowComponent,
    AccessdeniedComponent,
    ProfileStatusComponentComponent,
    AppFooterComponent,
    DashboardComponent,    
    AdminDashboardComponent,
    CompanyRegisterationComponent,
    NotifierComponent,
    SpectrogramFilterComponent,
    FileDownloadComponent,
    TermsComponent,
    LandingComponent,
    NotifierComponent,
    ChangePasswordComponent,  
    ShowAccessComponent,
    ChartFilterComponent, 
    AdminComponent,
    AddUserComponent,
    UpdateLicenceComponent,
    GeoLocationComponent, 
    MeasurementHeaderComponent,
    MeasurementImageComponent,
    UpdateStorageComponent,
    ListAdminComponent,
    FullComponent,
    AppHeaderComponent,
    SubappPermissionComponent,
    DataTagComponent,
    PrivacyComponent,
    DataProtectionComponent,
    TermsConditionComponent,
    ThresholdComponent,
    AddThresholdComponent,
    TypeconfirmComponent,
    ProminenceFilterComponent,
    DownloadsComponent,
    AddRootcauseComponent,
    VehicleTestMasterComponent
  ],
  imports: [
    MatSlideToggleModule,
    BrowserModule,
    BrowserAnimationsModule,
    SventaMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,  
    TreetableModule,
    DataTablesModule,
    CommonModule,
    ChartistModule,
    FormsModule,
    ReactiveFormsModule,     
    AgmCoreModule.forRoot({ // @agm/core
      apiKey: 'AIzaSyAwpTPjHhnVfQuq37V-Gc322b42qTKS-Io',
      libraries: ['places', 'drawing', 'geometry'],
    }),
    AgmDirectionModule,     // agm-direction
    NotifierModule.withConfig(customNotifierOptions),
    RouterModule.forRoot(AppRoutes),
    ResizableModule,
    WorkerModule.forWorkers([{worker: DownloadWorker, initFn:workerFn}]),
  ],
  providers: [MeasurementService, DataService, dashboardService, AuthService, AdminService,CognitoUtil,
     EDLDashboardGuard, ProfileService, AppConfigService, AdminGuard, AuthGuard,
     {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
      },
      Spectrogram, ProminenceSpectrogram,  
    { provide: APP_INITIALIZER, useFactory: appInitializerFn, multi: true, deps: [AppConfigService] },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true } ,
    { provide: MatDialogRef, useValue: {} },
	{ provide: MAT_DIALOG_DATA, useValue: [] },
  ],
  entryComponents: [UserSettingsComponent,ConfirmationWindowComponent,SubappPermissionComponent,
    CompanyRegisterationComponent, SpectrogramFilterComponent,FileDownloadComponent,UpdateStorageComponent,TypeconfirmComponent,
    TermsComponent,ShowAccessComponent,ChartFilterComponent,UpdateLicenceComponent,ProminenceFilterComponent,AddRootcauseComponent],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
