import { Component, OnInit, ViewChild } from '@angular/core';

import { NotifierService } from "angular-notifier";
import { bool } from 'aws-sdk/clients/signer';

import {  BehaviorSubject } from 'rxjs';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
@Component({
  selector: 'app-groupsettings',
  templateUrl: './groupsettings.component.html',
  styleUrls: ['./groupsettings.component.css']
})
export class GroupsettingsComponent implements OnInit {
  errorMessage: string;
  //dtOptions: DataTables.Settings = {};
  Userdata: any = {}
  loading: bool;
  private readonly notifier: NotifierService;
  public _tableDataSubscription: BehaviorSubject<any[]>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(notifierService: NotifierService,
   ) {

   
    this.Userdata.email = '';
    this.notifier = notifierService;

  }
  dataSource = new MatTableDataSource<any>();
  ngOnInit() {

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
   
  }

 
}
