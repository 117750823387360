import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { AdminService } from './auth/admin.service';
import { AuthService } from './auth/auth.service';
import Constants from './commonFiles/constant/Constants';
import { TermsComponent } from './terms/terms.component';
import { WorkerClient, WorkerManager, WorkerModule } from 'angular-web-worker/angular';
import { DownloadWorker } from './download.worker';
import { saveAs } from 'file-saver';
import { DownloadService } from './shared/download.service';

export class FileData {
  url: string;
  fileName: string;
  fileType!: string;
  uniqueId!: string;
  downloadProgress!: number;
  isCompleted!: boolean;
  status!: string;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'

})
export class AppComponent implements OnInit {
  isLoggedIn
  public SharedData: string;
  private client: WorkerClient<DownloadWorker>;
  private observable$: Observable<any>;
  private subscription: Subscription;
  private downloadList: FileData[] = [];
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private adminService: AdminService,
    private workerManager: WorkerManager,
    private downloadService: DownloadService
  ) {
    this.matIconRegistry.addSvgIcon(
      'calendar',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/images/calendar.svg')
    );
    this.authService.isLoggedIn.subscribe(res => {
      this.isLoggedIn = res;
      this.authService.setLangdefault();
      if (res === true) {
        if (localStorage.getItem("UllaStatus") === "decline") {
          const dialogRef = this.dialog.open(TermsComponent, {
            width: '650px',

            disableClose: true,
            data: {
              header: "terms"
            },
          });
          dialogRef.afterClosed().subscribe(resModal => {

            if (!resModal.isOk) {
              this.authService.logOut();
            }
            else {
              var body = {
                emailid: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
                company: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
                businessunit: localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
                status: "accept",
                version: Number(localStorage.getItem(Constants.UserInfo.APPVERSION))
              }
              this.adminService.setUlla(body).subscribe(res => {
                if (res.ResponseMetadata.HTTPStatusCode === 200) {
                  var body = {
                    emailid: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
                    company: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
                    businessunit: localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
                  }

                  this.adminService.getUlla(body).subscribe(res => {
                    localStorage.setItem(Constants.UserInfo.ULLASATATUS, res.success.Message.status);
                    localStorage.setItem(Constants.UserInfo.APPVERSION, res.success.Message.version);


                    this.authService.loggedIn.next(true);
                    this.authService.isfirstlogin = true;

                    this.authService.loginState = true;
                    this.router.navigate(['/profile']);
                  });
                }
              });
            }
          });

        }
      }
    });

    this.start();
  }


  private start(): void {
    window.addEventListener("storage", this.storageEventListener.bind(this));
  }
  private onSubject = new Subject<{ key: string, value: any }>();
  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      let v;
      try { v = JSON.parse(event.newValue); }
      catch (e) { v = event.newValue; }
      this.onSubject.next({ key: event.key, value: v });
    }
  }

  onRightClick() {
    return false;
  }
  async ngOnInit() {
    if (this.workerManager.isBrowserCompatible) {
      this.client = this.workerManager.createClient(DownloadWorker);
    } else {
      this.client = this.workerManager.createClient(DownloadWorker, true);
    }
    await this.client.connect();

    this.downloadService.fileSelected.subscribe((fileData: FileData) => {
      this.queueDownload(fileData);
    });

    this.downloadService.clearDownloads.subscribe(() => {
      this.clearCompletedDownloads();
    });

    this.downloadService.getDownloadStatus.subscribe(() => {
         this.downloadService.downloadStatus.emit(this.downloadList);
     });
    this.client.subscribe(w => w.downloadEvent,
      (data) => {
        if (data !== null) {
          this.triggerFileDownload(data);
        }
      },
      (err) => { console.log(err); },
    );
    this.client.subscribe(w => w.progressEvent,
      (data) => {
        if (data !== null) {
          this.updateDownloadProgress(data);
        }
      },
      (err) => { console.log(err); },
    );
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    var dateNow = new Date();
    dateNow.setHours(dateNow.getHours() + 1);
    localStorage.setItem("LastActiveTime", dateNow.toString());
  }

  @HostListener('window:storage ', ['$event'])
  public beforeunloadHandler($event) {
    //if($event.key === 'REQUESTING_SHARED_CREDENTIALS')
  }

  endChat() {
    //this.authService.signOut();
  }

  queueDownload(fileData: FileData) {
   
    fileData.uniqueId = this.getUniqueId(2);
    fileData.downloadProgress = 0;
    fileData.isCompleted = false;
    fileData.status = 'QUEUED';
    this.downloadList.push(fileData);
    this.client.call(w => w.downloadFile(fileData.url, fileData.uniqueId));
  }

  triggerFileDownload(data: any) {
    const fileData = this.downloadList.find(o => {
      return o.uniqueId === data.uniqueId;
    });
    fileData.isCompleted = true;
    fileData.status = 'COMPLETED';
    saveAs(new Blob([data.file], { type: 'plain/text' }), fileData.fileName);
  }

  updateDownloadProgress(data: any) {
    const fileData: FileData = this.downloadList.find(o => {
      return o.uniqueId === data.uniqueId;
    });
    fileData.status = 'IN PROGRESS';
    fileData.downloadProgress = data.progress;
    this.downloadService.downloadStatus.emit(this.downloadList);
  }

  getUniqueId(parts: number): string {
    const stringArr = [];
    for (let i = 0; i< parts; i++) {
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

  clearCompletedDownloads() {
    this.downloadList = this.downloadList.filter(item => {
      return item.isCompleted === false;
    });
    this.downloadService.downloadStatus.emit(this.downloadList);
  }

}
