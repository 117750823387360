export class Threshold{
company:any;
businessunit:string;
machineid:string;
testtype:string;
datatype:string;
threshold:[];
subapp:string;
// LaMin:string;
// LaMax:string;
 LaEq:number;
// LaPeak:string;
// isFFT:string
// isOctave:string;
// isoa:string;
// isNoise:string;
// isVibration:string;
// AnalysisType:string;
}