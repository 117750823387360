import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, OnDestroy, ViewChild, Output, } from '@angular/core';
import { MeasuementInformation, TestLevels } from './measurement.model';
import { DataService } from '../shared/data.service';
import { Spectrogram } from './Spectrogram';
//import { ProminenceSpectrogram } from './ProminenceSpectroGraph';
import { saveAs } from 'file-saver';
import * as Chart from 'chart.js'
import { MatDialog, MatSnackBar } from '@angular/material';
import { AppConfigService } from '../commonFiles/services/app-initializer.service';
import Constants from '../commonFiles/constant/Constants';
import { environment } from '../../environments/environment';
import { SpectrogramFilterServiceService } from '../shared/spectrogram-filter-service.service';
import { SpectrogramFilterComponent } from '../spectrogram-filter/spectrogram-filter.component';
import { SpinnerService } from '../commonFiles/services/spinner.service'
import { ChartFilterComponent } from './chartfilter.component';
import WaveSurfer from 'wavesurfer.js';
import { spectrogramSettings, PRspectrogramSettings } from '../spectrogram-filter/spectrogram-filter-model'
import 'chartjs-plugin-zoom';
import { ProminenceSpectrogram } from './ProminenceSpectroGraph';
import { MeasurementHeaderComponent } from './header.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../auth/admin.service';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { ProminenceFilterComponent } from '../prominence-filter/prominence-filter.component';
import { inflate, deflate } from 'pako';
import * as pako from 'pako';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';

interface Point {
  key: string,
  value: string
}

@Component({
  selector: 'app-measurement',
  templateUrl: './measurement.component.html',
  styleUrls: ['./measurement.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class MeasurementComponent extends Spectrogram implements OnInit, OnDestroy {
  public Spectrumdata: any;
  EnabledButton: string;
  DataName: string;
  labels: any;
  SelectedTrials: string;
  _subscription: any;
  vin: string;
  model: string;
  Test1machineId: string;
  Test2machineId: string;
  TestType1: string;
  TestType2: string;
  date: string;
  isFFTVsTime = false;
  isPRVsTime = false;
  TestList: Object;
  Measurmentdata: MeasuementInformation;
  Testtrials: TestLevels[];
  key: string;
  ChasisNo: string;
  Testdatetime: number;
  OperationTypes: [];
  IsThreshold: boolean;
  labelKeys: Point[];
  FaultKeys: any;
  canvas: any;
  ctx: any;
  s3: any;
  isEDL: boolean;
  EDLPath: string;
  chart: any
  isSpeech = false;
  Audiofile: string;
  hidefft = true;
  hideoverall = true;
  hidewaterfall = true;
  hideprTime = true;
  hideprAvg = true
  xLabelOct = "Frequency (Hz)1";
  yLabelOct = "Sound Pressure Level (dBA)";

  xLabel3Oct = "Frequency (Hz)2";
  yLabel3Oct = "Sound Pressure Level (dBA)";
  hideroctave = true;
  hidethirdocatve = true;
  hideaudio = true;

  axisScales: any = {};
  minx: number = null;
  miny: number = null;
  maxx: number = null;
  maxy: number = null;
  OverallChart: any;
  FFTChart: any;
  OctaveChart: any
  ThirdOctaveChart: any;
  PrAvgChart: any;
  LA: any = {};
  LAValue: any = { LA: {}, LA2: {} }
  wave: WaveSurfer = null;
  isPlay = false;
  iconpath = '../../assets/images/play.svg'
  showAudio = true;
  globalResizeTimer: any;
  Nodata = false;
  isspectogrameloaded = false;
  isPRGraphLoaded = false;
  locdatatest: any
  ThresholdData: { fft: any, octave: any, oa: any, oneOctave: any, prAvg: any, prTime: any, LaMin: any, LaMax: any, LaEq: any, LaPeak: any } = <any>{}
  Test1: { FFT: any; Overalllevel: any, Overall: any, Ocatve: any, ThirdOctave: any, Waterfall: any, ProminenceAvg: any, ProminenceTime: any } = <any>{}
  Test2: { FFT: any; Overalllevel: any, Overall: any, Ocatve: any, ThirdOctave: any, Waterfall: any, ProminenceAvg: any, ProminenceTime: any } = <any>{}

  allthreshold: any
  thresholdLEqValue: any
  NoSpectrumData: any
  NoPrVsData: any
  MaxValuelffty: any = 0;
  MaxValuel3octy: any = 0;
  MaxValuelocty: any = 0;
  MaxValuelevely: any = 0;
  MaxValuelPry: any = 0;
  MaxValuelfftx: any = 0;
  MaxValuel3octx: any = 0;
  MaxValueloctx: any = 0;
  MaxValuelevelx: any = 0;
  MaxValuelPrx: any = 0;
  datasetlength: any = 0;
  TempLabel: any = [];
  Fft1XMax = 0;
  Fft2XMax = 0;
  Fft1YMax = 0;
  Fft2YMax = 0;
  LvT1XMax = 0;
  LvT2XMax = 0;
  LvT1YMax = 0;
  LvT2YMax = 0;
  Oct31XMax = 0;
  Oct32XMax = 0;
  Oct31YMax = 0;
  Oct32YMax = 0;
  Oct1XMax = 0;
  Oct2XMax = 0;
  Oct1YMax = 0;
  Oct2YMax = 0;
  Pvr1XMax = 0;
  Pvr2XMax = 0;
  Pvr1YMax = 0;
  Pvr2YMax = 0;
  @ViewChild(MeasurementHeaderComponent, { static: true }) header;
  constructor(private router: Router,
    public adminservice: AdminService,
    public route: ActivatedRoute,
    public appconfig: AppConfigService,
    public service: DataService,
    public dialog: MatDialog,
    public data: SpectrogramFilterServiceService,
    public snackBar: MatSnackBar,
    public spinnerService: SpinnerService,
    public prsobj: ProminenceSpectrogram,
    private cdr: ChangeDetectorRef) {
    super(appconfig, data, spinnerService);


  }
  ngOnInit() {
    this.allthreshold = [];
    const myObject: MyObject = { color: '', rootcausename: '', anaysis: '', dataset: [] };
    this.NoSpectrumData = false;
    this.NoPrVsData = false;

    if (this.router.url == '/edl/comparemeasurement') {
      this.header.titledata.Iscompare = true;
      this.Fft1XMax = 0;
      this.Fft2XMax = 0;
      this.Fft1YMax = 0;
      this.Fft2YMax = 0;
      this.LvT1XMax = 0;
      this.LvT2XMax = 0;
      this.LvT1YMax = 0;
      this.LvT2YMax = 0;
      this.Oct31XMax = 0;
      this.Oct32XMax = 0;
      this.Oct31YMax = 0;
      this.Oct32YMax = 0;
      this.Oct1XMax = 0;
      this.Oct2XMax = 0;
      this.Oct1YMax = 0;
      this.Oct2YMax = 0;
      this.Pvr1XMax = 0;
      this.Pvr2XMax = 0;
      this.Pvr1YMax = 0;
      this.Pvr2YMax = 0;

    }
    else {
      this.header.titledata.Iscompare = false;
    }
    if (this.header.titledata.Test2) {
      this.header.titledata.Test2 = null;
    }
  }



  async Loadchart() {

    this.Nodata = false;

    this.s3 = await this.appconfig.LoadAWSS3Config(false);
    if (this.wave) {
      this.wave.stop();
      this.isPlay = true;
      this.iconpath = '../../assets/images/play.svg';
      this.cdr.detectChanges();
    }

    try {
      let data = this.header.titledata.Test.Data;

      if (data.isEDL) {

        this.hidefft = true;
        this.hideoverall = true;
        this.hidewaterfall = true;
        this.hideprAvg = true;
        this.hideprTime = true;
        this.hideroctave = true;
        this.hidethirdocatve = true;
        this.hideaudio = true;
        this.isEDL = true;
        this.showAudio = true;
        this.spectrogramData = null;
        this.ProminenceTimeData = null;
        this.isspectogrameloaded = false;
        this.isPRGraphLoaded = false;
        this.EDLPath = 'public/' + localStorage.getItem(Constants.STORAGE.BUCKET) + "/" + data.epochdate + "_" + data.Machineid + "/" + data.Projectname + "_" + data.Testtype;
        localStorage.setItem("PathEDL", this.EDLPath);
        this.Test1machineId = data.Machineid;
        this.TestType1 = data.Testtype;
        ////console.log(data);
        await this.Getthresholddata(data.Model, data.Testtype, data.DataTypes);
        this.Checkoperationexist();

        this.getDiagnosticOutput();
      }
      else {
        this.isEDL = false;
        this.TestList = data.Testcases;
        this.model = data.Model;
        this.vin = data.Vin;
        this.date = data.Date;
      }
    }
    catch (err) {
      this.spinnerService.hideSpinner();
    }
  }

  async Getthresholddata(machineid, testtype, datatype) {
    this.isspectogrameloaded = false;
    //alert(machineid + '_' + testtype + '_' +  datatype)
    await this.adminservice.getthresholddata(localStorage.companyname, localStorage.businessunit, machineid, testtype, "edl", datatype.toLowerCase()).subscribe(res => {
      if (res.success.Message.Items.length != 0)
        this.allthreshold = res.success.Message.Items[0];
      this.allthreshold = this.allthreshold == undefined ? [] : this.allthreshold;

    });
  }

  async SelectTrials(SelectedTrialValue) {
    this.SelectedTrials = SelectedTrialValue;
    this.GetFFTdata();

  }

  ngDoCheck() {


    //redraw the spectrogram whenever the encolsing div size  changes
    if (this.EnabledButton == 'Spectrumdata' && this.Drawablespectrogram.length > 0) {

      let base = document.getElementById('v-pills-waterfall');
      let canvas: any = document.getElementById('canvas');
      if (this.globalResizeTimer !== null) window.clearTimeout(this.globalResizeTimer);

      this.globalResizeTimer = setTimeout(() => {

        let ctx = canvas.getContext("2d");
        canvas.height = base.offsetHeight;
        canvas.width = base.offsetWidth;

        // draw the saved context to changed div size
        ctx.drawImage(this.currentctx, 0, 0, this.currentctx.width, this.currentctx.height, this.leftMargin, this.topMargin, base.offsetWidth - this.rightMargin - this.leftMargin, base.offsetHeight - this.bottomMargin - this.topMargin)
        //this.graphDetails(ctx , this.Spectrumdata.xlable,this.Spectrumdata.ylable,this.Spectrumdata.zlable);
        this.graphDetails(ctx);
      }, 100);

    }
    else if (this.EnabledButton == 'PrvsTime' && this.PRDrawablespectrogram.length > 0) {

      let base = document.getElementById('v-pills-prvstime');
      let canvas: any = document.getElementById('prvstimecanvas');
      if (this.globalResizeTimer !== null) window.clearTimeout(this.globalResizeTimer);

      // this.globalResizeTimer = setTimeout(() => {

      let ctx = canvas.getContext("2d");
      canvas.height = base.offsetHeight;
      canvas.width = base.offsetWidth;

      // draw the saved context to changed div size
      ctx.drawImage(this.PRcurrentctx, 0, 0, this.PRcurrentctx.width, this.PRcurrentctx.height, this.leftMargin, this.topMargin, base.offsetWidth - this.rightMargin - this.leftMargin, base.offsetHeight - this.bottomMargin - this.topMargin)
      //this.graphDetails(ctx , this.Spectrumdata.xlable,this.Spectrumdata.ylable,this.Spectrumdata.zlable);
      this.PRgraphDetails(ctx);
      // }, 100);

    }
  }

  async GetlevelVsTime() {

    this.isFFTVsTime = false;
    this.EnabledButton = 'oneOctave';
    if (this.isEDL)
      this.key = this.EDLPath + "/" + environment.APPINFO.overfilename;
    else
      this.key = environment.AWSINFO.EOLjsonfileSourceFolder + "/" + this.ChasisNo + environment.APPINFO.overfilename + this.SelectedTrials + ".json";

    var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key.replace('.json', '.gz') };
    const data = (await (this.s3.getObject(params, (err, data) => {
      if (err == null ? '' : err.code == "NoSuchKey") {
        this.key = this.EDLPath + "/" + environment.APPINFO.waterfilename;
        var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key.replace('.gz', '.json') };
        this.s3.getObject(params, this.LoadLevelvsTimedata.bind(this))
      }
      else {
        let decompressed = inflate(data.Body, { to: 'string' });
        data.Body = decompressed;
        this.LoadLevelvsTimedata(null, data)
      }
    })));
    //     var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key };
    //  this.s3.getObject(params, this.LoadLevelvsTimedata.bind(this))


  }

  async GetSpectrumdata() {

    //////console.log(new Date().getTime());
    this.isFFTVsTime = true;
    this.EnabledButton = 'Spectrumdata';

    if (this.isEDL) {
      this.key = this.EDLPath + "/" + environment.APPINFO.waterfilename;
    }
    else
      this.key = environment.AWSINFO.EOLjsonfileSourceFolder + "/" + this.ChasisNo + environment.APPINFO.waterfilename + this.SelectedTrials + ".json";

    if (this.spectrogramData == null) {
      let canvas: any;
      canvas = this.canvas = document.getElementById('canvas');
      const context = canvas.getContext('2d');

      context.clearRect(0, 0, canvas.width, canvas.height);

      //////console.log(new Date().getTime());
      this.spinnerService.showSpinner();
      var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key.replace('.json', '.gz') };
      const data = (await (this.s3.getObject(params, (err, data) => {
        if (err == null ? '' : err.code == "NoSuchKey") {
          this.key = this.EDLPath + "/" + environment.APPINFO.waterfilename;
          var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key.replace('.gz', '.json') };
          this.s3.getObject(params, this.LoadSpectrogram.bind(this))
        }
        else {
          let decompressed = inflate(data.Body, { to: 'string' });
          data.Body = decompressed;
          this.LoadSpectrogram(null, data)
        }
      })));
      //   try{
      //  this.s3.getObject(params,this.LoadSpectrogram.bind(this))
      //   }catch(err)
      //   {
      //   
      //     //////console.log(err);
      //   }
      //{
      //  
      //   if(err==null) 
      //     this.LoadSpectrogram.bind(loadeddata);
      //     else
      //       //////console.log(err);
      // });
      //  try{
      //  await this.s3.getObject(params,loadeddata);
      //   //////console.log(loadeddata);
      //   this.LoadSpectrogram('',loadeddata);
      // }catch(err)
      // {
      //   //////console.log(err);
      // }
    }
    else {
      let canvas: any = document.getElementById('canvas');
      let base = document.getElementById('v-pills-waterfall');
      if (canvas.width === 0) {
        let ctx = canvas.getContext("2d");
        canvas.height = base.offsetHeight;
        canvas.width = base.offsetWidth;
        ctx.drawImage(this.currentctx, 0, 0, this.currentctx.width, this.currentctx.height, this.leftMargin, this.topMargin, base.offsetWidth - this.rightMargin - this.leftMargin, base.offsetHeight - this.bottomMargin - this.topMargin)
        this.graphDetails(ctx);
        //this.graphDetails(ctx, this.Spectrumdata.xlable,this.Spectrumdata.ylable,this.Spectrumdata.zlable);
      }
    }
  }

  checkspectrogramspinner() {
    if (this.spectrogramData == null)
      this.spinnerService.showSpinner();
    else
      this.spinnerService.hideSpinner();
  }
  loadFullSpectogramData;
  LoadSpectrogram(err, data) {

    try {
      //////console.log(new Date().getTime());
      if (err == null ? '' : err.code == "NoSuchKey") {
        this.isspectogrameloaded = true;
        this.spinnerService.hideSpinner();
        this.NoSpectrumData = true;
      }
      else
        if (data) {

          this.loadFullSpectogramData = data;
          if (this.spectrogramData == null) {
            this.Spectrumdata = JSON.parse(data.Body.toString("utf-8"))

            localStorage.setItem("DataType", "N")
            if (this.Spectrumdata.yunits === "Pa") {
              localStorage.setItem("DataType", "N")
            }
            else if (this.Spectrumdata.yunits === "m/s2") {
              localStorage.setItem("DataType", "V")
            }


            this.TransposedData = this.Spectrumdata.waterfall;
          }

          if (this.header.titledata.Test.id == "1") {
            this.Test1.Waterfall = JSON.parse(data.Body.toString("utf-8"))
          }
          else {
            this.Test2.Waterfall = JSON.parse(data.Body.toString("utf-8"))
          }

          if (this.Test1.Waterfall && this.Test2.Waterfall) {
            let firstRows = this.Test1.Waterfall.waterfall.length;
            let secondRows = this.Test2.Waterfall.waterfall.length;
            let firstpxPerLine = this.Test1.Waterfall.waterfall[0].length;
            let secondpxPerLine = this.Test2.Waterfall.waterfall[0].length;

            this.TransposedData = [];
            for (let row = 0; row < Math.max(firstRows, secondRows); row++) {

              this.TransposedData[row] = [];
              this.Drawablespectrogram[row] = [];

              for (let px = 0; px < Math.max(firstpxPerLine, secondpxPerLine); px++) {
                this.TransposedData[row][px] = 0;

                if (row < firstRows && px < firstpxPerLine)
                  this.TransposedData[row][px] = this.Test1.Waterfall.waterfall[row][px]

                if (row < secondRows && px < secondpxPerLine)
                  this.TransposedData[row][px] = this.TransposedData[row][px] - this.Test2.Waterfall.waterfall[row][px];
              }
            }

            this.Spectrumdata = this.Test1.Waterfall;
          }

          this.N = Number(this.Spectrumdata.procParams.N);
          this.Fs = Number(this.Spectrumdata.procParams.fs);
          if (this.Test2.Waterfall && this.Test1.Waterfall)
            this.T = Math.max(Number(this.Spectrumdata.procParams.T),
              Number(this.Test2.Waterfall.procParams.T));
          else
            this.T = Number(this.Spectrumdata.procParams.T);

          if (Number.isNaN(this.T)) {
            this.T = 1;
          }
          if (Number.isNaN(this.Fs)) {
            this.Fs = 1;
          }
          if (Number.isNaN(this.N)) {
            this.N = 1;
          }
          this.initializeSpectrogram(this);
          //this.initializeSpectrogram(this, this.Spectrumdata.xlable,this.Spectrumdata.ylable,this.Spectrumdata.zlable);

        }
    }
    catch (err) {
      this.spinnerService.hideSpinner();
    }
  }

  async GetFFTdata() {

    this.isFFTVsTime = false;
    this.EnabledButton = 'FFTdata';
    //////console.log(this.isEDL)
    if (this.isEDL)
      this.key = this.EDLPath + "/" + environment.APPINFO.FFTfilename;
    else
      this.key = environment.AWSINFO.EOLjsonfileSourceFolder + "/" + this.ChasisNo + environment.APPINFO.FFTfilename + this.SelectedTrials + ".json";
    var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key.replace('.json', '.gz') };

    const data = (await (this.s3.getObject(params, (err, data) => {
      //////console.log(err)
      if (err == null ? '' : err.code == "NoSuchKey") {
        var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key.replace('.gz', '.json') };
        this.key = this.EDLPath + "/" + environment.APPINFO.FFTfilename;
        this.s3.getObject(params, this.LoadFFTdata.bind(this))
      }
      else {
        let decompressed = inflate(data.Body, { to: 'string' });
        data.Body = decompressed;
        //////console.log(data)
        this.LoadFFTdata(null, data)
      }
    })));

    //   var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key };
    // this.s3.getObject(params, this.LoadFFTdata.bind(this));
  }


  LoadLevelvsTimedata(err, data) {

    this.thresholdLEqValue = 0;
    let templist = [];
    if (data) {
      //let templist=this.allthreshold.threshold[i].type.filter(obj => obj.Analysis.toLowerCase() ==="fft")
      if (this.allthreshold.threshold != undefined) {
        templist = this.allthreshold.threshold.filter(obj => obj.rootcause == '**rootcause**');

        if (templist.length > 0) {

          this.thresholdLEqValue = templist[0].type[0].Values;
        }
      }
      var overallleveldata = JSON.parse(data.Body.toString("utf-8"))
      var frequencydiff = overallleveldata.procParams.fs / overallleveldata.procParams.N;
      //var frequencydiff = parseInt(frequencydiff1).toFixed(2);
      var Maxfrequency = overallleveldata.procParams.fs / 2;
      var dt = overallleveldata.procParams.dt

      var dataPoints = [];
      var frequency = 0;
      var XMaxValue; var YMaxValue;

      var indexedArray: { [key: string]: string } = overallleveldata.result;
      this.labelKeys = [];
      for (var i = 0; i < Object.keys(indexedArray).length - 1; i++) {
        this.labelKeys.push({ key: Object.keys(indexedArray)[i], value: overallleveldata.result[Object.keys(indexedArray)[i].toString()] })
      }

      this.LA.LAeq = overallleveldata.result["LAeq(dB)"]
      this.LA.LAmax = overallleveldata.result["LAmax(dB)"]
      this.LA.LAmin = overallleveldata.result["LAmin(dB)"]
      this.LA.LApeak = overallleveldata.result["LApeak(dB)"]
      for (var i = 0; i < overallleveldata.result.overalllevel.length; i++) {
        dataPoints.push({ y: overallleveldata.result.overalllevel[i], x: frequency });
        frequency += dt;
      }
      this.operationButton = true;
      this.axisScales.overallmaxY = Math.max.apply(Math, dataPoints.map(function (o) { return o.y; }));
      this.axisScales.overallminY = Math.min.apply(Math, dataPoints.map(function (o) { return o.y; }));
      this.axisScales.overallmaxX = Math.max.apply(Math, dataPoints.map(function (o) { return o.x; }));
      this.axisScales.overallminX = Math.min.apply(Math, dataPoints.map(function (o) { return o.x; }));


      var overalldata = [];
      YMaxValue = Math.ceil(this.axisScales.overallmaxY);
      //;
      //console.log(this.header.titledata.Test.id)
      console.log(overalldata)
      if (this.header.titledata.Test.id == 1) {
        this.Test1.Overalllevel = dataPoints;
        this.header.titledata.Test1.LA.LAeq = overallleveldata.result.LAeq
        this.header.titledata.Test1.LA.LAmax = overallleveldata.result.LAmax;
        this.header.titledata.Test1.LA.LAmin = overallleveldata.result.LAmin
        this.header.titledata.Test1.LA.LApeak = overallleveldata.result.LApeak
        //this.LvT1XMax = Maxfrequency;
        //if (frequency >= Maxfrequency) {
        //  this.LvT1XMax = frequency;
        //}
        this.LvT1XMax = frequency;
        this.LvT1YMax = YMaxValue + 5; //make y-axis with 5 to visible threshold graph which is MAX
        //console.log(this.LvT1YMax + "_1_" + this.LvT1XMax)
      }
      else {
        this.Test2.Overalllevel = dataPoints;
        this.header.titledata.Test2.LA.LAeq = overallleveldata.result.LAeq
        this.header.titledata.Test2.LA.LAmax = overallleveldata.result.LAmax;
        this.header.titledata.Test2.LA.LAmin = overallleveldata.result.LAmin
        this.header.titledata.Test2.LA.LApeak = overallleveldata.result.LApeak
        //this.LvT2XMax = Maxfrequency;
        //if (frequency >= Maxfrequency) {
        //  this.LvT2XMax = frequency;
        //}
        this.LvT2XMax = frequency;
        this.LvT2YMax = YMaxValue + 5; //make y-axis with 5 to visible threshold graph which is MAX
        //console.log(this.LvT2YMax + "_2_" + this.LvT2XMax)
      }
      if (this.Test2.Overalllevel) {

        overalldata.push({
          label: this.header.titledata.Test2.Data.Machineid,
          data: this.Test2.Overalllevel,
          borderColor: 'purple',
          borderWidth: 1,
          pointRadius: 0,
          lineTension: 0,
          fill: false
        });

      }
      if (this.Test1.Overalllevel) {
        overalldata.push({
          label: this.header.titledata.Test1.Data.Machineid,
          data: this.Test1.Overalllevel,
          borderColor: 'rgba(65, 131, 215, 1)',
          borderWidth: 1,
          pointRadius: 0,
          lineTension: 0,
          fill: false
        });
      }

      if (this.header.titledata.Iscompare) {
        // if (this.MaxValuelfftx != undefined && this.MaxValuelfftx != null) {
        if (this.LvT1XMax >= this.LvT2XMax) {
          XMaxValue = this.LvT1XMax;
        } else {
          XMaxValue = this.LvT2XMax;
        }
        this.MaxValuelevelx = XMaxValue;
        if (this.LvT1YMax >= this.LvT2YMax) {
          YMaxValue = this.LvT1YMax;
        } else {
          YMaxValue = this.LvT2YMax;
        }
        this.MaxValuelevely = YMaxValue;

      } else {
        //XMaxValue = Maxfrequency;
        //if (frequency >= Maxfrequency) {
        //  XMaxValue = frequency;
        //}
        XMaxValue = frequency;
        this.MaxValuelevelx = XMaxValue;
        YMaxValue = YMaxValue + 5; //make y-axis with 5 to visible threshold graph which is MAX
        this.MaxValuelevely = YMaxValue;
      }

      //Rootcause logic display -start
      //console.log(this.MaxValuelffty + "MAXLeveTime" + this.MaxValuelfftx)
      console.log(overalldata)
      this.CreateCanvas('levelchartContainer', 'v-pills-level')
      this.ctx = this.canvas.getContext('2d');
      this.OverallChart = new Chart(this.ctx, {
        type: 'line',
        data: {
          datasets: overalldata
        },
        options: {
          animation: {
            onComplete: this.ChartRendercomplete.bind(this)
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, datatoolTip) {
                return parseFloat(tooltipItem.yLabel.toString()).toFixed(2)
              }
            }
          },
          maintainAspectRatio: false,
          legend: {
            display: true
          },
          elements: {
            line: {
              tension: 0
            }
          },
          plugins: {
            zoom: {
              pan: {
                drag: true,
                enabled: true,
                mode: 'x',
                speed: 10,
                threshold: 10,
                rangeMin: {
                  x: 0,
                  y: -100
                },
                rangeMax: {
                  x: XMaxValue,
                  y: YMaxValue
                }
              },
              zoom: {
                enabled: true,
                mode: 'x',
                rangeMin: {
                  x: 0,
                },
                rangeMax: {
                  x: 20,
                }
              }
            }
          },
          responsive: true,
          scales: {
            xAxes: [{
              type: 'linear',
              position: 'bottom',
              scaleLabel: {
                display: true,
                labelString: overallleveldata.xlable === undefined ? 'Time (s)' : overallleveldata.xlable,
              },
              ticks: {
                min: 0,
                max: Math.ceil(XMaxValue),
                //callback: function (value) {
                //  if (Math.floor(parseFloat(value.toString())) === value) {
                //    return value;
                //  }
                //}
              }
            }],

            yAxes: [{
              type: 'linear',
              position: 'left',
              scaleLabel: {
                display: true,
                labelString: overallleveldata.ylable === undefined ? 'Sound Pressure Level (dBA)' : overallleveldata.ylable,
              },
              ticks: {
                min: 0,
                max: Math.ceil(YMaxValue),
                // max:80
              }
            }]
          }
        }
      }).render();
    }
  }
  CreateCanvas(canvasid, divid) {

    this.canvas = document.getElementById(canvasid);
    var canvas = document.createElement('canvas');
    canvas.id = canvasid
    if (document.getElementById(divid)) {
      document.getElementById(divid).replaceChild(canvas, this.canvas)
      this.canvas = document.getElementById(canvasid);
    }
  }
  ChartRendercomplete() {
    this.spinnerService.hideSpinner();
  }
  LoadFFTdata(err, data) {
    //////console.log(this.header.titledata.NoiseVsVib);
    var ColorForRootcause;
    var tempAnalyseData = [];
    var ListRootCause = [];
    var thresholdPoints = [];

    if (data) {
      var fftdata = JSON.parse(data.Body.toString("utf-8"))
      this.DataName = environment.APPINFO.DataNameFFT;
      var frequencydiff = fftdata.procParams.fs / fftdata.procParams.N;
      //var frequencydiff = parseInt(frequencydiff1).toFixed(2);
      var Maxfrequency = fftdata.procParams.fs / 2;
      var dataPoints = [];
      var frequency = 0;
      var XMaxValue;
      dataPoints.push({ y: 0, x: 0 });
      //////console.log(fftdata);
      for (var i = 0; i < fftdata.fftavg.length; i++) {
        if (i == 0) {
          dataPoints.push({ y: fftdata.fftavg[i], x: frequency });

        }
        else {
          frequency += frequencydiff;
          dataPoints.push({ y: fftdata.fftavg[i], x: frequency.toFixed(3) });
        }

      }
      this.axisScales.FFTmaxY = Math.max.apply(Math, dataPoints.map(function (o) { return o.y; }));
      this.axisScales.FFTminY = Math.min.apply(Math, dataPoints.map(function (o) { return o.y; }));
      this.axisScales.FFTmaxX = Math.max.apply(Math, dataPoints.map(function (o) { return o.x; }));
      this.axisScales.FFTminX = Math.min.apply(Math, dataPoints.map(function (o) { return o.x; }));

      this.CreateCanvas('FFTchartContainer', 'v-pills-fftdata')
      //X aixs max value
      var MaxValue = this.axisScales.FFTmaxY;
      let maxthresh = this.getMaxvalueForChartPlot("fft")
      if (this.allthreshold.threshold != undefined) {
        for (var i = 0; i < this.allthreshold.threshold.length; i++) {
          var RootcauseObj = { color: '', rootcausename: '', anaysis: '', dataset: [] }
          let templist = this.allthreshold.threshold[i].type.filter(obj => obj.Analysis.toLowerCase() === "fft")
          if (templist.length > 0) {
            let createobj = [];
            let color = this.allthreshold.threshold[i].color;
            let rootcausename = this.allthreshold.threshold[i].rootcause;
            const array1: [] = templist[0].Values.map(s => (
              createobj.push({ x: s.Min, y: s.Threshold }),
              createobj.push({ x: s.Max, y: s.Threshold }),
              createobj.push(null)
            ));
            if (createobj.length > 0) {
              RootcauseObj.color = color;
              RootcauseObj.rootcausename = rootcausename;
              RootcauseObj.dataset = createobj
              ListRootCause.push(RootcauseObj);
            }
          }

        }
      }
      //rootcause dosplay logic --end
      if (ListRootCause.length > 0)
        this.IsThreshold = true;

      //////console.log(this.header.titledata.Test1);
      this.ctx = this.canvas.getContext('2d');
      var fftdataset = [];
      if (this.header.titledata.Test.id == 1) {
        this.Test1.FFT = dataPoints;
        this.Fft1XMax = Maxfrequency;
        if (frequency >= Maxfrequency) {
          this.Fft1XMax = frequency;
        }

        if (maxthresh != undefined) {
          if (maxthresh > MaxValue)
            MaxValue = Math.ceil(maxthresh);
        }
        this.Fft1YMax = MaxValue + 5; //make y-axis with 5 to visible threshold graph which is MAX
        //console.log(this.Fft1YMax + "_1_" + this.Fft1XMax)
      }
      else {
        this.Test2.FFT = dataPoints;

        this.Fft2XMax = Maxfrequency;
        if (frequency >= Maxfrequency) {
          this.Fft2XMax = frequency;
        }

        if (maxthresh != undefined) {
          if (maxthresh > MaxValue)
            MaxValue = Math.ceil(maxthresh);
        }
        this.Fft2YMax = MaxValue + 5; //make y-axis with 5 to visible threshold graph which is MAX
        //console.log(this.Fft2YMax + "_2_" + this.Fft2XMax)
      }

      if (this.Test2.FFT) {
        fftdataset.push({
          label: this.header.titledata.Test2.Data.Machineid,
          yAxisID: this.header.titledata.Test2.Data.DataTypes == 'V' ? "y-axis-2" : "y-axis-1",
          data: this.Test2.FFT,
          borderColor: 'purple',
          borderWidth: 1,
          pointRadius: 0,
          lineTension: 0,
          fill: false,
        });
      }
      if (this.Test1.FFT) {

        fftdataset.push({
          // label: this.titledata2.Testtype,
          yAxisID: this.header.titledata.Test1.Data.DataTypes == 'V' ? "y-axis-2" : "y-axis-1",
          data: this.Test1.FFT,
          borderColor: 'rgba(64, 170, 219,0.7)',
          borderWidth: 1,
          pointRadius: 0,
          lineTension: 0,
          fill: false,
          label: this.header.titledata.Test1.Data.Machineid,
        });
      }


      if (this.header.titledata.Iscompare) {
        // if (this.MaxValuelfftx != undefined && this.MaxValuelfftx != null) {
        if (this.Fft1XMax >= this.Fft2XMax) {
          XMaxValue = this.Fft1XMax;
        } else {
          XMaxValue = this.Fft2XMax;
        }
        this.MaxValuelfftx = XMaxValue;
        if (this.Fft1YMax >= this.Fft2YMax) {
          MaxValue = this.Fft1YMax;
        } else {
          MaxValue = this.Fft2YMax;

        }
        this.MaxValuelffty = MaxValue;
        // }
        // localStorage.setItem('MaxValuelfftx', XMaxValue);
      } else {
        XMaxValue = Maxfrequency;
        if (frequency >= Maxfrequency) {
          XMaxValue = frequency;
        }
        this.MaxValuelfftx = XMaxValue;
        if (maxthresh != undefined) {
          if (maxthresh > MaxValue)
            MaxValue = Math.ceil(maxthresh);
        }
        MaxValue = MaxValue + 5; //make y-axis with 5 to visible threshold graph which is MAX

        this.MaxValuelffty = MaxValue;
      }

      //Rootcause logic display -start
      //console.log(this.MaxValuelffty + "MAX" + this.MaxValuelfftx)

      if (this.IsThreshold) {
        for (var i = 0; i < ListRootCause.length; i++) {

          fftdataset.push({
            data: ListRootCause[i].dataset,
            borderColor: ListRootCause[i].color,
            borderWidth: 1,
            pointRadius: 0,
            lineTension: 0,
            lineThickness: 5,
            label: ListRootCause[i].rootcausename,
            fill: false,
            hidden: true,
            snapGaps: 100
          });
        }

      }
      ////console.log(fftdata);
      this.FFTChart = new Chart(this.ctx, {
        type: 'line',
        data: {
          datasets: fftdataset
        },
        options: {
          maintainAspectRatio: false,
          animation: {
            onComplete: this.ChartRendercomplete.bind(this)
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, datafft) {
                return parseFloat(tooltipItem.yLabel.toString()).toFixed(2)
              }
            }
          },
          legend: {
            display: true
          },
          elements: {
            line: {
              tension: 0
            }
          },
          plugins: {
            register: {
              beforeDraw: function (c) {
                var ctx = c.chart.ctx;
                ctx.fillRect(0, 0, c.chart.width, c.chart.height);
              }
            },
            zoom: {
              sensitivity: 20,
              pan: {
                drag: true,
                enabled: true,
                mode: 'x',
                speed: 10,
                threshold: 10,
                rangeMax: {
                  x: 24000,
                  y: 200,
                },
                rangeMin: {
                  x: 0,
                  y: -100
                }
              },
              zoom: {
                speed: 0.1,
                enabled: true,
                mode: 'x',
                rangeMax: {
                  x: 24000
                },
                rangeMin: {
                  x: 0
                },
                limits: {
                  min: { x: 20 }
                }

              }
            }
          },
          responsive: true,
          scales: {
            xAxes: [{
              type: 'linear',
              position: 'bottom',
              gridLines: {
                display: true,
              },
              scaleLabel: {
                display: true,
                labelString: (fftdata.xlable === undefined) ? 'Frequency (Hz)' : fftdata.xlable,
              },
              ticks: {
                beginAtZero: true,
                min: 0,

                max: Math.ceil(XMaxValue), //12000,
                callback: function (value) {
                  if (Math.floor(parseFloat(value.toString())) === value) {
                    return value;
                  }
                }
              }
            }],
            yAxes: [{
              id: "y-axis-1",
              type: 'linear',
              position: 'left',

              scaleLabel: {
                display: true,
                labelString: (fftdata.ylable === undefined) ? 'Sound Pressure Level (dBA)1' : fftdata.ylable,
              },
              ticks: {
                min: 0,

                max: (Math.ceil(MaxValue))
              }
            }, {
              id: "y-axis-2",
              type: 'linear',
              position: 'right',
              display: this.header.titledata.NoiseVsVib == true ? true : false,
              scaleLabel: {
                display: true,
                labelString: (fftdata.ylable === undefined) ? 'Vibration Acceleration' : 'Vibration Acceleration',
              },
              ticks: {
                min: 0,

                max: (Math.ceil(MaxValue))
              }
            }]
          }
        }
      }).render();

    }
  }

  EngineSounddata() {
    try {
      if (!this.header.titledata.Iscompare && this.header.titledata.Test.Audio) {

        this.Audiofile = this.header.titledata.Test.Audio;
        this.header.onPreviewPressed(this.header.titledata);
        this.showAudio = false;
      }
    }
    catch (e) {

    }
  }
  ProminenceFilterDialog(): void {
    let minval: any;
    let maxval: any;

    minval = this.minColorValue; maxval = this.maxColorValue;

    const dialogRef = this.dialog.open(ProminenceFilterComponent, {
      width: '600px',

      data: {

        min: minval, max: maxval
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {

        // result.MinMaxFrequencyGroup.maxFrequency=500;

        //frequency multiplication factor
        let FreqMultiplicationFactor = this.Fs / this.N;
        let TimeMultiplicationFactor = Number(this.T) / this.dataColumn;
        // assigning spectrogram global variables
        this.minColorValue = Number(result.MinMaxGroup.min);
        this.maxColorValue = Number(result.MinMaxGroup.max);
        this.minFrequencyRow = (Number(result.MinMaxFrequencyGroup.minFrequency) / FreqMultiplicationFactor);
        this.maxFrequencyRow = (Number(result.MinMaxFrequencyGroup.maxFrequency) / FreqMultiplicationFactor);
        this.minTimeColumn = (Number(result.MinMaxTimeGroup.minTime) / TimeMultiplicationFactor);
        this.maxTimeColumn = (Number(result.MinMaxTimeGroup.maxTime) / TimeMultiplicationFactor);

        // assigning filter values
        let filterSettings = new PRspectrogramSettings(Number(result.MinMaxGroup.min), Number(result.MinMaxGroup.max), Number(result.MinMaxFrequencyGroup.minFrequency), Number(result.MinMaxFrequencyGroup.maxFrequency),
          Number(result.MinMaxTimeGroup.minTime), Number(result.MinMaxTimeGroup.maxTime));
        this.data.PRSetFilteredSettings(filterSettings);
        let canvas: any;
        canvas = document.getElementById('prvstimecanvas');
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        this.isPRGraphLoaded = false;

        // filter the spectrogram
        setTimeout(() => {
          this.FilterSpecPromGraph(this);
          //this.FilterSpectrogram(this, this.Spectrumdata.xlable,this.Spectrumdata.ylable,this.Spectrumdata.zlable);
        }, 0);
      }
    });
  }
  FilterDialog(): void {
    let minval: any;
    let maxval: any;
    if (this.EnabledButton == 'Spectrumdata') {
      minval = this.minColorValue; maxval = this.maxColorValue;
    }
    else {
      minval = this.minColorValue; maxval = this.maxColorValue;
    }
    const dialogRef = this.dialog.open(SpectrogramFilterComponent, {
      width: '600px',

      data: {

        min: minval, max: maxval
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {

        // result.MinMaxFrequencyGroup.maxFrequency=500;
        if (this.EnabledButton == 'Spectrumdata') {
          //frequency multiplication factor
          let FreqMultiplicationFactor = this.Fs / this.N;
          let TimeMultiplicationFactor = Number(this.T) / this.dataColumn;
          // assigning spectrogram global variables
          this.minColorValue = Number(result.MinMaxGroup.min);
          this.maxColorValue = Number(result.MinMaxGroup.max);
          this.minFrequencyRow = (Number(result.MinMaxFrequencyGroup.minFrequency) / FreqMultiplicationFactor);
          this.maxFrequencyRow = (Number(result.MinMaxFrequencyGroup.maxFrequency) / FreqMultiplicationFactor);
          this.minTimeColumn = (Number(result.MinMaxTimeGroup.minTime) / TimeMultiplicationFactor);
          this.maxTimeColumn = (Number(result.MinMaxTimeGroup.maxTime) / TimeMultiplicationFactor);

          // assigning filter values
          let filterSettings = new spectrogramSettings(Number(result.MinMaxGroup.min), Number(result.MinMaxGroup.max), Number(result.MinMaxFrequencyGroup.minFrequency), Number(result.MinMaxFrequencyGroup.maxFrequency),
            Number(result.MinMaxTimeGroup.minTime), Number(result.MinMaxTimeGroup.maxTime));
          this.data.SetFilteredSettings(filterSettings);
          // clear canvas before drawing
          let canvas: any;
          canvas = document.getElementById('canvas');
          const context = canvas.getContext('2d');
          context.clearRect(0, 0, canvas.width, canvas.height);
          this.isspectogrameloaded = false;

          // filter the spectrogram
          setTimeout(() => {
            this.FilterSpectrogram(this);
            //this.FilterSpectrogram(this, this.Spectrumdata.xlable,this.Spectrumdata.ylable,this.Spectrumdata.zlable);
          }, 0);
        } else {
          //frequency multiplication factor
          let FreqMultiplicationFactor = this.Fs / this.N;
          let TimeMultiplicationFactor = Number(this.T) / this.dataColumn;
          // assigning spectrogram global variables
          this.minColorValue = Number(result.MinMaxGroup.min);
          this.maxColorValue = Number(result.MinMaxGroup.max);
          this.minFrequencyRow = (Number(result.MinMaxFrequencyGroup.minFrequency) / FreqMultiplicationFactor);
          this.maxFrequencyRow = (Number(result.MinMaxFrequencyGroup.maxFrequency) / FreqMultiplicationFactor);
          this.minTimeColumn = (Number(result.MinMaxTimeGroup.minTime) / TimeMultiplicationFactor);
          this.maxTimeColumn = (Number(result.MinMaxTimeGroup.maxTime) / TimeMultiplicationFactor);

          // assigning filter values
          let filterSettings = new PRspectrogramSettings(Number(result.MinMaxGroup.min), Number(result.MinMaxGroup.max), Number(result.MinMaxFrequencyGroup.minFrequency), Number(result.MinMaxFrequencyGroup.maxFrequency),
            Number(result.MinMaxTimeGroup.minTime), Number(result.MinMaxTimeGroup.maxTime));
          this.data.PRSetFilteredSettings(filterSettings);
          let canvas: any;
          canvas = document.getElementById('prvstimecanvas');
          const context = canvas.getContext('2d');
          context.clearRect(0, 0, canvas.width, canvas.height);
          this.isPRGraphLoaded = false;

          // filter the spectrogram
          setTimeout(() => {
            this.FilterSpecPromGraph(this);
            //this.FilterSpectrogram(this, this.Spectrumdata.xlable,this.Spectrumdata.ylable,this.Spectrumdata.zlable);
          }, 0);
        }
      }
    });
  }
  async GetOctaveChart() {
    this.isFFTVsTime = false;
    this.EnabledButton = 'Octave';
    if (this.isEDL)
      this.key = this.EDLPath + "/" + environment.APPINFO.Octavefilename;

    else
      this.key = environment.AWSINFO.EOLjsonfileSourceFolder + "/" + this.ChasisNo + environment.APPINFO.Octavefilename + this.SelectedTrials + ".gz";
    var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key.replace('.json', '.gz') };

    const data = (await (this.s3.getObject(params, (err, data) => {
      if (err == null ? '' : err.code == "NoSuchKey") {
        var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key.replace('.gz', '.json') };

        this.key = this.EDLPath + "/" + environment.APPINFO.FFTfilename;
        this.s3.getObject(params, this.LoadOctavedata.bind(this))
      }
      else {
        let decompressed = inflate(data.Body, { to: 'string' });
        data.Body = decompressed;
        this.LoadOctavedata(null, data)
      }
    })));
    // var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key };
    // this.s3.getObject(params, this.LoadOctavedata.bind(this));
  }

  getDiagnosticOutput() {
    //;
    this.key = this.EDLPath + "/" + "ml_output.json";

    var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key };
    this.s3.getObject(params, this.loadFaultData.bind(this));
  }
  loadFaultData(err, data) {

    var Outputset = [];
    if (data) {
      var Faultdata = JSON.parse(data.Body.toString("utf-8"));
      if (Faultdata.prediction.diagnosticoutput != "") {
        Faultdata.prediction.diagnosticoutput.forEach((element) => {
          Outputset.push(element);
        });
      }

      this.FaultKeys = Outputset;
    }
  }
  GetSpeechData() {
    this.isFFTVsTime = false;
    this.EnabledButton = 'Octave';


    this.key = this.EDLPath + "/" + "ml_output.json";

    var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key };
    this.s3.getObject(params, this.loadSpeech.bind(this));
  }
  statusTest = "N/A";
  loadSpeech(err, data) {

    if (data) {
      var ocatvedata = JSON.parse(data.Body.toString("utf-8"));

      if (ocatvedata.prediction != undefined) {
        if (ocatvedata.prediction.machinestatus != undefined)
          this.spinnerService.changeMessage(ocatvedata.prediction.machinestatus);

        if (this.header.titledata.Iscompare) {

          if (this.header.titledata.Test.id == "1") {
            if (ocatvedata.prediction.voiceactivity.toLowerCase() === "speech") {
              this.header.titledata.Test1.isSpeech = true;
              this.spinnerService.pimary(true);
              this.isSpeech = false;
            } else {
              this.header.titledata.Test1.isSpeech = false;
              this.spinnerService.pimary(false);
            }
          }

          if (this.header.titledata.Test.id == "2") {
            if (ocatvedata.prediction.voiceactivity.toLowerCase() === "speech") {
              this.header.titledata.Test2.isSpeech = true;
              this.spinnerService.secondary(true);
              this.isSpeech = false;
            } else {
              this.header.titledata.Test2.isSpeech = false;
              this.spinnerService.secondary(false);
            }
          }
        } else {

          if (ocatvedata.prediction.voiceactivity.toLowerCase() === "speech") {
            this.spinnerService.pimary(true);
          }
          else {
            this.spinnerService.pimary(false);
          }

        }
      }
    }
    else {
      if (this.header.titledata.Test.id == "2") {
        this.spinnerService.secondary(false);
      }
      if (this.header.titledata.Test.id == "1") {
        this.spinnerService.pimary(false);
      }
    }
  }


  LoadOctavedata(err, data) {

    if (data) {

      var ocatvedata = JSON.parse(data.Body.toString("utf-8"))
      var frequencydiff = ocatvedata.procParams.fs / ocatvedata.procParams.N;
      //var frequencydiff = parseInt(frequencydiff1).toFixed(2);
      var Maxfrequency = ocatvedata.procParams.fs / 2;
      this.DataName = environment.APPINFO.DataNameFFT;
      var Label = []
      var dataset = []
      var index = 0;
      var XMaxValue; var MaxOctaveValue;
      this.xLabelOct = ocatvedata.xlable;
      this.yLabelOct = ocatvedata.ylable;
      //;
      var startindex = 0;
      var postive = false;
      var frequency = 0;
      for (var i = 0; i < ocatvedata.octave.length; i++) {
        if (i == 0) {
          dataset.push(ocatvedata.octave[i].toFixed(2))

        }
        else {
          Label.push(ocatvedata.octfc[i].toString());
          dataset.push(ocatvedata.octave[i].toFixed(2))
          frequency += frequencydiff;
        }
        //Label.push(ocatvedata.octfc[i].toFixed(2).toString());
        //dataset.push(ocatvedata.octave[i].toFixed(2))
        if (ocatvedata.octave[i].toFixed(2) < 0 && !postive)
          index++
        else postive = true
      }
      if (index > 0)
        startindex = Label[index - 1];
      this.axisScales.octaveStartindex = startindex;
      this.axisScales.octavemaxY = Math.max.apply(Math, dataset);
      this.axisScales.octaveminY = Math.min.apply(Math, dataset);
      this.axisScales.octavemaxX = Math.max.apply(Math, Label);
      this.axisScales.octaveminX = startindex;
      this.CreateCanvas('barchartContainer', 'v-pills-oct')
      this.ctx = this.canvas.getContext('2d');
      MaxOctaveValue = Math.ceil(this.axisScales.octavemaxY);
      var octavedata = [];
      if (this.header.titledata.Test.id == 1) {
        this.Test1.Ocatve = dataset;
        this.Oct1XMax = Maxfrequency;
        if (frequency >= Maxfrequency) {
          this.Oct1XMax = frequency;
        }
        this.Oct1YMax = MaxOctaveValue + 5; //make y-axis with 5 to visible threshold graph which is MAX
        //console.log(this.Oct1YMax + "_1_" + this.Oct1XMax)
      }
      else {
        this.Test2.Ocatve = dataset;
        this.Oct2XMax = Maxfrequency;
        if (frequency >= Maxfrequency) {
          this.Oct2XMax = frequency;
        }
        this.Oct2YMax = MaxOctaveValue + 5; //make y-axis with 5 to visible threshold graph which is MAX
        //console.log(this.Oct2YMax + "_2_" + this.Oct2XMax)
      }
      if (this.header.titledata.Iscompare) {
        // if (this.MaxValuelOctx != undefined && this.MaxValuelOctx != null) {
        if (this.Oct1XMax >= this.Oct2XMax) {
          XMaxValue = this.Oct1XMax;
        } else {
          XMaxValue = this.Oct2XMax;
        }
        this.MaxValueloctx = XMaxValue;
        if (this.Oct1YMax >= this.Oct2YMax) {
          MaxOctaveValue = this.Oct1YMax;
        } else {
          MaxOctaveValue = this.Oct2YMax;
        }
        this.MaxValuelocty = MaxOctaveValue;
        // }
        // localStorage.setItem('MaxValuelOctx', XMaxValue);
      } else {
        XMaxValue = Maxfrequency;
        if (frequency >= Maxfrequency) {
          XMaxValue = frequency;
        }
        this.MaxValueloctx = XMaxValue;

        MaxOctaveValue = MaxOctaveValue + 5; //make y-axis with 5 to visible threshold graph which is MAX
        this.MaxValuelocty = MaxOctaveValue;
      }
      //console.log(this.MaxValuelocty + "MAX" + this.MaxValueloctx)

      if (this.Test2.Ocatve) {

        octavedata.push({
          label: this.header.titledata.Test2.Data.Machineid,
          data: this.Test2.Ocatve,
          backgroundColor: 'rgba(104, 26, 167, 0.5)',// 'purple',

        });
      }
      if (this.Test1.Ocatve) {
        octavedata.push({
          //label: JSON.parse(localStorage.getItem('octavelabel1')),
          data: this.Test1.Ocatve,
          backgroundColor: 'rgba(64, 170, 219,0.8)', //'rgba(64, 170, 219,1)',
          // fillColor: "rgba(65,131,215,1)",
          borderColor: 'rgba(83, 202, 214, 0.15)',
          order: 2,
          label: this.header.titledata.Test1.Data.Machineid,
        })

      }

      var options = {
        maintainAspectRatio: false,
        animation: {
          onComplete: this.ChartRendercomplete.bind(this)
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, dataoctv) {
              return parseFloat(tooltipItem.yLabel.toString()).toFixed(2)
            }
          }
        },

        legend: {
          display: true,
        },

        plugins: {
          zoom: this.OctavechartZoom
        },
        responsive: true,
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.xLabelOct == undefined ? 'Frequency (Hz)2' : this.xLabelOct,
            },
            ticks: {
              min: 0,
              beginAtZero: true,
              max: Math.ceil(XMaxValue)
            },
            stacked: true,
            id: "bar-x-axis1",
            barThickness: 30,
          }, {
            display: false,
            stacked: true,
            id: "bar-x-axis2",
            barThickness: 70,
            // these are needed because the bar controller defaults set only the first x axis properties
            type: 'category',
            categoryPercentage: 0.8,
            barPercentage: 0.9,
            gridLines: {
              offsetGridLines: true
            },
            offset: true
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.yLabelOct == undefined ? 'Sound Pressure Level (dBA)2' : this.yLabelOct,
            },
            stacked: false,
            ticks: {
              min: 0,
              beginAtZero: true,
              max: Math.ceil(MaxOctaveValue),
              callback: function (value) {
                if (Math.floor(parseFloat(value.toString())) === value) {
                  return value;
                }
              }
            }
          }]

        }
      };
      this.Octavescale.xAxes[0].ticks.min = startindex;
      this.OctaveChart = new Chart(this.ctx, {
        type: 'bar',
        data: {
          labels: Label,
          datasets: octavedata,

        },
        options: options
      }).render();
    }
  }

  async GetThirdOctaveChart() {

    this.isFFTVsTime = false;
    this.EnabledButton = '3rdOctave';
    var ListRootCause = [];
    if (this.isEDL)
      this.key = this.EDLPath + "/" + environment.APPINFO.ThirdOctavefilename;
    else
      this.key = environment.AWSINFO.EOLjsonfileSourceFolder + "/" + this.ChasisNo + environment.APPINFO.Octavefilename + this.SelectedTrials + ".json";

    var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key.replace('.json', '.gz') };

    const data = (await (this.s3.getObject(params, (err, data) => {
      if (err == null ? '' : err.code == "NoSuchKey") {
        var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key.replace('.gz', '.json') };
        this.key = this.EDLPath + "/" + environment.APPINFO.FFTfilename;
        this.s3.getObject(params, this.Load3rdOctavedata.bind(this))
      }
      else {
        let decompressed = inflate(data.Body, { to: 'string' });
        data.Body = decompressed;
        this.Load3rdOctavedata(null, data)
      }
    })));

    //   var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key };
    // this.s3.getObject(params, this.Load3rdOctavedata.bind(this));
  }
  Load3rdOctavedata(err, data) {

    var ColorForRootcause;
    var thresholdPoints = [];
    var tempAnalyseData = [];
    var ListRootCause = [];

    if (data) {

      var ocatve3rddata = JSON.parse(data.Body.toString("utf-8"))
      var frequencydiff = ocatve3rddata.procParams.fs / ocatve3rddata.procParams.N;
      //var frequencydiff = parseInt(frequencydiff1).toFixed(2);
      var Maxfrequency = ocatve3rddata.procParams.fs / 2;
      this.DataName = environment.APPINFO.DataNameFFT;
      var dataPoints = [];
      var Label = [];

      var MaxOctaveValue;
      var threspos = [];
      this.xLabel3Oct = ocatve3rddata.xlable;
      this.yLabel3Oct = ocatve3rddata.ylable;
      let ThreshOctavelist = [];
      var dataset = []
      let Octavelist = [];
      var index = 0;
      var startindex = 0;
      var frequency = 0;
      var XMaxValue;
      var postive = false;
      for (var i = 0; i < ocatve3rddata.octave.length; i++) {
        if (i == 0) {
          dataset.push(ocatve3rddata.octave[i].toFixed(2))

        }
        else {
          Label.push(ocatve3rddata.octfc[i].toString());
          dataset.push(ocatve3rddata.octave[i].toFixed(2))
          frequency += frequencydiff;
        }
        if (ocatve3rddata.octave[i].toFixed(2) < 0 && !postive)
          index++
        else postive = true
      }
      if (this.header.titledata.Iscompare) {
        // if (this.header.titledata.Test.id == 1) {
        if (this.TempLabel.length > Label.length) {
          Label = this.TempLabel;
        } else {

          this.TempLabel = Label;
        }
        //} else {
        //  if (TempLabel.length > Label.length) {
        //    Label = TempLabel;
        //  }
        //}

      }
      //////console.log(Label);
      //////console.log(dataset);
      if (index > 0)
        startindex = Label[index - 1];
      this.axisScales.thirdoctaveStartindex = startindex;
      this.axisScales.thirdoctavemaxY = Math.max.apply(Math, dataset);
      this.axisScales.thirdoctaveminY = Math.min.apply(Math, dataset);

      var maxxlab = Math.max.apply(Math, Label);
      //if (this.header.titledata.Iscompare) {
      //  if (maxxlab < 16000)
      //    maxxlab = 24000;
      //}
      this.axisScales.thirdoctavemaxX = maxxlab;
      this.axisScales.thirdoctaveminX = Math.min.apply(Math, Label);

      this.CreateCanvas('thirdOctchartContainer', 'v-pills-oct3')
      this.ctx = this.canvas.getContext('2d');
      var octave3data = [];
      MaxOctaveValue = Math.ceil(this.axisScales.thirdoctavemaxY);

      //Threshold display logig-start
      if (this.allthreshold.threshold != undefined) {
        //////console.log(this.allthreshold.threshold);
        for (var i = 0; i < this.allthreshold.threshold.length; i++) {
          var RootcauseObj = { color: '', rootcausename: '', anaysis: '', threshset: [] }

          let templist = this.allthreshold.threshold[i].type.filter(obj => obj.Analysis.toLowerCase() === "onethirdoctave")
          if (templist.length > 0) {

            let color = this.allthreshold.threshold[i].color;
            let rootcausename = this.allthreshold.threshold[i].rootcause;



            const array1: [] = templist[0].Values.map(s => (

              Octavelist.push({ x: s.fc, y: s.Threshold })
              //Octavelist.push({ x: s.fc, y: s.Threshold }),
              //,Octavelist.push(null)
              /* Octavelist.push({ x: s.fc, y: parseFloat(s.Threshold) })*/
              /*         Octavelist.push({ x: s.fc.toString(), y: s.Threshold })*/
              //createobj.push(s.Threshold)

            ));

            if (templist[0].Values.reduce((acc, obj) => acc = acc > obj.Threshold ? acc : obj.Threshold, 0) > MaxOctaveValue)
              MaxOctaveValue = Math.ceil(templist[0].Values.reduce((acc, obj) => acc = acc > obj.Threshold ? acc : obj.Threshold, 0));

            if (Octavelist.length > 0) {

              RootcauseObj.color = color;
              RootcauseObj.rootcausename = rootcausename;
              RootcauseObj.threshset = Octavelist;
              ListRootCause.push(RootcauseObj);
              ThreshOctavelist.push(Octavelist);
            }

          }

        }
      }


      if (ThreshOctavelist.length > 0 && ThreshOctavelist[0] != undefined) {
        var ThreshOct = ThreshOctavelist[0];
        for (var i = 0; i < ThreshOctavelist[0].length; i++) {
          for (var j = 0; j < Label.length; j++) {
            ////////console.log(ThreshOct[i].x + "+" + parseFloat(Label[j]).toFixed(2))
            if (ThreshOct[i].x == parseFloat(Label[j]).toFixed(2)) {
              //dataset.splice(j, 0, 0);
              //Label[j].push(ThreshOct[i].x.toString());
              //dataset[j].push(ThreshOct[i].y.toFixed(2));
              threspos.push({ x: j, y: ThreshOct[i].y.toFixed(2) });
              break;
            }
            else if (ThreshOct[i].x < parseFloat(Label[j]).toFixed(2)) {
              Label.splice(j, 0, ThreshOct[i].x.toString());
              dataset.splice(j, 0, 0);
              //Label[j].push(ThreshOct[i].x.toString());
              //dataset[j].push(ThreshOct[i].y.toFixed(2));
              threspos.push({ x: j, y: ThreshOct[i].y.toFixed(2) });
              break;
            }

          }
        }
      }

      //////console.log(dataset);
      ////////console.log(threspos);
      var thretitile = "";

      if (this.header.titledata.Test.id == 1) {
        if (this.header.titledata.Iscompare) {
          if (this.datasetlength > dataset.length) { //to display equal length of x-axis while Compare
            let tdata = new Array(this.datasetlength).fill(0.00);
            for (var j = 0; j < dataset.length; j++) {
              tdata[j] = dataset[j];
            }
            dataset = [];
            dataset = tdata;
          }
        }
        this.Test1.ThirdOctave = dataset;
        thretitile = this.header.titledata.Test1.Data.Machineid + "Threshold";
        this.datasetlength = dataset.length;

        this.Oct31XMax = Maxfrequency;
        if (frequency >= Maxfrequency) {
          this.Oct31XMax = frequency;
        }
        this.Oct31YMax = MaxOctaveValue + 5; //make y-axis with 5 to visible threshold graph which is MAX
        //console.log(this.Oct31YMax + "_1_" + this.Oct31XMax)
        ////console.log(dataset)
      }
      else {

        if (this.header.titledata.Iscompare) {
          if (this.datasetlength > dataset.length) { //to display equal length of x-axis while Compare
            let tdata = new Array(this.datasetlength).fill(0.00);
            for (var j = 0; j < dataset.length; j++) {
              tdata[j] = dataset[j];
            }
            dataset = [];
            dataset = tdata;
          }
        }
        this.Test2.ThirdOctave = dataset;

        thretitile = this.header.titledata.Test2.Data.Machineid + "Threshold";
        this.datasetlength = dataset.length;
        this.Oct32XMax = Maxfrequency;
        if (frequency >= Maxfrequency) {
          this.Oct32XMax = frequency;
        }
        this.Oct32YMax = MaxOctaveValue + 5; //make y-axis with 5 to visible threshold graph which is MAX
        //console.log(this.Oct32YMax + "_2_" + this.Oct32XMax)
        ////console.log(dataset)
      }

      if (this.header.titledata.Iscompare) {
        // if (this.MaxValuelOctx != undefined && this.MaxValuelOctx != null) {
        if (this.Oct31XMax >= this.Oct32XMax) {
          XMaxValue = this.Oct31XMax;
        } else {
          XMaxValue = this.Oct32XMax;
        }
        this.MaxValuel3octx = XMaxValue;
        if (this.Oct31YMax >= this.Oct32YMax) {
          MaxOctaveValue = this.Oct31YMax;
        } else {
          MaxOctaveValue = this.Oct32YMax;
        }
        this.MaxValuel3octy = MaxOctaveValue;
        // }
        // localStorage.setItem('MaxValuelOctx', XMaxValue);
      } else {
        XMaxValue = Maxfrequency;
        if (frequency >= Maxfrequency) {
          XMaxValue = frequency;
        }
        this.MaxValuel3octx = XMaxValue;

        MaxOctaveValue = MaxOctaveValue + 5; //make y-axis with 5 to visible threshold graph which is MAX
        this.MaxValuel3octy = MaxOctaveValue;
      }
      //console.log(this.MaxValuel3octy + "MAX" + this.MaxValuel3octx)
      //  this.Octavescale.xAxes[0].ticks.min = startindex;

      if (this.Test2.ThirdOctave) {

        octave3data.push({

          label: this.header.titledata.Test2.Data.Machineid,
          data: this.Test2.ThirdOctave,
          backgroundColor: 'rgba(104, 26, 167, 0.5)',// 'purple',


        });
      }
      if (this.Test1.ThirdOctave) {

        octave3data.push({
          //label: this.titledata1.Testtype,
          data: this.Test1.ThirdOctave,
          backgroundColor: 'rgba(64, 170, 219,0.8)', //'rgba(83, 202, 214, 0.15)',//
          borderColor: 'rgba(83, 202, 214, 0.15)',
          borderWidth: 2,
          label: this.header.titledata.Test1.Data.Machineid,

          order: 2
        })

      }
      let tdata = new Array(dataset.length).fill(0);
      if (threspos.length > 0) {
        for (var i = 0; i < dataset.length; i++) {
          for (var j = 0; j < threspos.length; j++) {
            if (i == threspos[j].x) {
              tdata[i] = threspos[j].y;
            }

          }
        }
      }
      //////console.log(tdata);
      if (threspos.length > 0) {
        if (ListRootCause.length > 0) {
          octave3data.push({

            data: tdata,
            backgroundColor: 'rgba(191, 192, 194,0.2)',
            borderColor: 'rgba(17, 107, 149, 0.84)',
            borderWidth: 2,
            label: ListRootCause[0].rootcausename,

            order: 1
          })
        } else {
          octave3data.push({

            data: tdata,
            backgroundColor: 'rgba(191, 192, 194,0.2)',
            borderColor: 'rgba(17, 107, 149, 0.84)',
            borderWidth: 2,
            label: thretitile,

            order: 1
          })
        }

      }

      //for (var i = 0; i < ListRootCause.length; i++) {
      //  //////console.log(ListRootCause[i].threshset);
      //  octave3data.push({
      //    data: ListRootCause[i].threshset,
      //    type: 'bar',
      //    order: 1,
      //    backgroundColor: 'rgba(191, 192, 194,0.2)',
      //    borderColor: ListRootCause[i].color,
      //    borderWidth: 1,
      //    label: ListRootCause[i].rootcausename,
      //    //labels: Label,
      //    hidden: true,
      //  })

      //}
      var options = {

        maintainAspectRatio: false,
        animation: {
          onComplete: this.ChartRendercomplete.bind(this)
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, dataoct3) {

              return parseFloat(tooltipItem.yLabel.toString()).toFixed(2)
            }
          }
        },
        legend: {
          display: true
        },

        plugins: {
          register: {
            beforeDraw: function (c) {
              var ctx = c.chart.ctx;
              ctx.fillStyle = "#fff";
              ctx.fillRect(0, 0, c.chart.width, c.chart.height);
              ctx.fill();
            }
          },
          zoom: this.OctavechartZoom
        },
        responsive: true,
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.xLabel3Oct == undefined ? 'Frequency (Hz)2' : this.xLabel3Oct,
            },
            ticks: {
              min: 0,
              max: Math.ceil(XMaxValue),//16000,
              beginAtZero: true,

            },
            stacked: true,
            id: "bar-x-axis1",
            //  barThickness: 30,
          }, {
            display: false,
            stacked: true,
            id: "bar-x-axis2",
            barThickness: 70,
            // these are needed because the bar controller defaults set only the first x axis properties
            type: 'category',
            //  categoryPercentage: 0.8,
            // barPercentage: 0.9,
            gridLines: {
              offsetGridLines: true
            },

            offset: true
          }],
          yAxes: [{
            position: 'left',
            scaleLabel: {
              display: true,
              labelString: this.yLabel3Oct == undefined ? 'Sound Pressure Level (dBA)2' : this.yLabel3Oct,
            },

            ticks: {
              min: 0,
              max: Math.ceil(MaxOctaveValue),
              beginAtZero: true,
              callback: function (value) {
                if (Math.floor(parseFloat(value.toString())) === value) {
                  return value;
                }
              }
            }
          }]

        }
      };
      ////;
      //this.ThirdOctaveChart = new Chart(this.ctx, {
      //  type: 'bar',
      //  data: {


      //    datasets: octave3data
      //  },
      //  options : {

      //    maintainAspectRatio: false,
      //    animation: {
      //      onComplete: this.ChartRendercomplete.bind(this)
      //    },
      //    tooltips: {
      //      callbacks: {
      //        label: function (tooltipItem, dataoct3) {
      //         
      //          return parseFloat(tooltipItem.yLabel.toString()).toFixed(2)
      //        }
      //      }
      //    },
      //    legend: {
      //      display: true
      //    },

      //    plugins: {
      //      register: {
      //        beforeDraw: function (c) {
      //          var ctx = c.chart.ctx;
      //          ctx.fillStyle = "#fff";
      //          ctx.fillRect(0, 0, c.chart.width, c.chart.height);
      //          ctx.fill();
      //        }
      //      },
      //      zoom: this.OctavechartZoom
      //    },
      //    responsive: true,
      //    scales: {
      //      xAxes: [{
      //        scaleLabel: {
      //          display: true,
      //          labelString: this.xLabel3Oct == undefined ? 'Frequency (Hz)2' : this.xLabel3Oct,
      //        },
      //        ticks: {
      //          min: 0,
      //          max: 16000,
      //          beginAtZero: true,

      //        },
      //        stacked: true,
      //        id: "bar-x-axis1",
      //        //  barThickness: 30,
      //      }, {
      //        display: false,
      //        stacked: true,
      //        id: "bar-x-axis2",
      //        /* barThickness: 70,*/
      //        // these are needed because the bar controller defaults set only the first x axis properties
      //        type: 'category',
      //        //  categoryPercentage: 0.8,
      //        // barPercentage: 0.9,
      //        gridLines: {
      //          offsetGridLines: true
      //        },
      //        offset: true
      //      }],
      //      yAxes: [{
      //        position: 'left',
      //        scaleLabel: {
      //          display: true,
      //          labelString: this.yLabel3Oct == undefined ? 'Sound Pressure Level (dBA)2' : this.yLabel3Oct,
      //        },

      //        ticks: {
      //          min: 0,
      //          max: this.GetNextTick(parseInt(MaxOctaveValue)),
      //          beginAtZero: true,
      //          callback: function (value) {
      //            if (Math.floor(parseFloat(value.toString())) === value) {
      //              return value;
      //            }
      //          }
      //        }
      //      }]

      //    }
      //  }
      //}).render();
      this.ThirdOctaveChart = new Chart(this.ctx, {
        type: 'bar',
        data: {
          labels: Label,
          datasets: octave3data
        },
        options: options
      }).render();


    }
  }
  async GetPrVsTimeChart() {

    this.isPRVsTime = true;
    this.EnabledButton = 'PrvsTime';


    if (this.isEDL)
      this.key = this.EDLPath + "/" + environment.APPINFO.PRTFileName;
    else
      this.key = environment.AWSINFO.EOLjsonfileSourceFolder + "/" + this.ChasisNo + environment.APPINFO.PRTFileName + this.SelectedTrials + ".json";

    if (this.ProminenceTimeData == null) {
      let canvas: any;
      canvas = this.canvas = document.getElementById('prvstimecanvas');
      const context = canvas.getContext('2d');

      context.clearRect(0, 0, canvas.width, canvas.height);


      this.spinnerService.showSpinner();
      var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key.replace('.json', '.gz') };
      // this.s3.getObject(params, this.LoadProminenceVsTimedata.bind(this))
      const data = (await (this.s3.getObject(params, (err, data) => {
        if (err == null ? '' : err.code == "NoSuchKey") {
          this.key = this.EDLPath + "/" + environment.APPINFO.PRTFileName;
          var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key.replace('.gz', '.json') };
          this.s3.getObject(params, this.LoadProminenceVsTimedata.bind(this))
        }
        else {
          let decompressed = inflate(data.Body, { to: 'string' });
          data.Body = decompressed;
          this.LoadProminenceVsTimedata(null, data)
        }
      })));
    }
    else {
      let canvas: any = document.getElementById('prvstimecanvas');
      let base = document.getElementById('v-pills-prvstime');
      if (canvas.width === 0) {
        let ctx = canvas.getContext("2d");
        canvas.height = base.offsetHeight;
        canvas.width = base.offsetWidth;
        ctx.drawImage(this.PRcurrentctx, 0, 0, this.PRcurrentctx.width, this.PRcurrentctx.height, this.leftMargin, this.topMargin, base.offsetWidth - this.rightMargin - this.leftMargin, base.offsetHeight - this.bottomMargin - this.topMargin)
        this.graphDetails(ctx);
        //this.graphDetails(ctx, this.Spectrumdata.xlable,this.Spectrumdata.ylable,this.Spectrumdata.zlable);
      }
    }
  }
  promTimeData;
  LoadProminenceVsTimedata(err, data) {

    try {
      if (err == null ? '' : err.code == "NoSuchKey") {
        this.spinnerService.hideSpinner();
        this.NoPrVsData = true;
        this.isPRGraphLoaded = true;
      }
      else if (data) {
        this.NoPrVsData = false;
        this.promTimeData = data;
        if (this.ProminenceTimeData == null) {
          this.promTimeData = JSON.parse(data.Body.toString("utf-8"))
          localStorage.setItem("DataType", "N")
          if (this.promTimeData.yunits === "Pa") {
            localStorage.setItem("DataType", "N")
          }
          else if (this.promTimeData.yunits === "m/s2") {
            localStorage.setItem("DataType", "V")
          }


          this.PRTransposedData = this.promTimeData.prvstime;
        }

        if (this.header.titledata.Test.id == "1") {
          this.Test1.ProminenceTime = JSON.parse(data.Body.toString("utf-8"))
        }
        else {
          this.Test2.ProminenceTime = JSON.parse(data.Body.toString("utf-8"))
        }

        if (this.Test1.ProminenceTime && this.Test2.ProminenceTime) {
          let firstRows = this.Test1.ProminenceTime.prvstime.length;
          let secondRows = this.Test2.ProminenceTime.prvstime.length;
          let firstpxPerLine = this.Test1.ProminenceTime.prvstime[0].length;
          let secondpxPerLine = this.Test2.ProminenceTime.prvstime[0].length;

          this.PRTransposedData = [];
          for (let row = 0; row < Math.max(firstRows, secondRows); row++) {

            this.PRTransposedData[row] = [];
            this.PRDrawablespectrogram[row] = [];

            for (let px = 0; px < Math.max(firstpxPerLine, secondpxPerLine); px++) {
              this.PRTransposedData[row][px] = 0;

              if (row < firstRows && px < firstpxPerLine)
                this.PRTransposedData[row][px] = this.Test1.ProminenceTime.prvstime[row][px]

              if (row < secondRows && px < secondpxPerLine)
                this.PRTransposedData[row][px] = this.PRTransposedData[row][px] - this.Test2.ProminenceTime.prvstime[row][px];
            }
          }

          this.promTimeData = this.Test1.ProminenceTime;
        }

        this.N = Number(this.promTimeData.procParams.N);
        this.Fs = Number(this.promTimeData.procParams.fs);
        if (this.Test2.ProminenceTime && this.Test1.ProminenceTime)
          this.T = Math.max(Number(this.promTimeData.procParams.T),
            Number(this.Test2.ProminenceTime.procParams.T));
        else
          this.T = Number(this.promTimeData.procParams.T);

        if (Number.isNaN(this.T)) {
          this.T = 1;
        }
        if (Number.isNaN(this.Fs)) {
          this.Fs = 1;
        }
        if (Number.isNaN(this.N)) {
          this.N = 1;
        }
        this.initializePRSpectrogram(this);

        //this.initializeSpectrogram(this, this.Spectrumdata.xlable,this.Spectrumdata.ylable,this.Spectrumdata.zlable);

      }
    }
    catch (err) {
      this.spinnerService.hideSpinner();
    }
  }
  async GetPrAvgChart() {
    this.isFFTVsTime = false;
    this.EnabledButton = 'PrAvg';

    if (this.isEDL)
      this.key = this.EDLPath + "/" + environment.APPINFO.PRAvgFileName;
    else
      this.key = environment.AWSINFO.EOLjsonfileSourceFolder + "/" + this.ChasisNo + environment.APPINFO.Octavefilename + this.SelectedTrials + ".json";
    var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key.replace('.json', '.gz') };

    const data = (await (this.s3.getObject(params, (err, data) => {
      if (err == null ? '' : err.code == "NoSuchKey") {
        var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key.replace('.gz', '.json') };
        this.key = this.EDLPath + "/" + environment.APPINFO.FFTfilename;
        this.s3.getObject(params, this.LoadPrAvgdata.bind(this))
      }
      else {
        let decompressed = inflate(data.Body, { to: 'string' });
        data.Body = decompressed;
        this.LoadPrAvgdata(null, data)
      }
    })));

    // var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: this.key };
    // this.s3.getObject(params, this.LoadPrAvgdata.bind(this));
  }
  LoadPrAvgdata(err, data) {
    ////console.log(data);
    var ListRootCause = [];
    if (data) {
      var pravgdata = JSON.parse(data.Body.toString("utf-8"))
      var fs = pravgdata.procParams.fs
      var N = pravgdata.procParams.N
      var xsteps = fs / N;
      var dataPoints = [];
      var frequency = 0;
      var Maxfrequency = pravgdata.procParams.fs / 2;


      for (var i = 0; i < pravgdata.pravg.length; i++) {
        if (i == 0) {
          dataPoints.push({ y: pravgdata.pravg[i], x: frequency });
        } else {
          dataPoints.push({ y: pravgdata.pravg[i], x: frequency });
          frequency += xsteps;
        }
      }
      this.operationButton = true;
      this.axisScales.PrAvgmaxY = Math.max.apply(Math, dataPoints.map(function (o) { return o.y; }));
      this.axisScales.PrAvgminY = Math.min.apply(Math, dataPoints.map(function (o) { return o.y; }));
      this.axisScales.PrAvgmaxX = Math.max.apply(Math, dataPoints.map(function (o) { return o.x; }));
      this.axisScales.PrAvgminX = Math.min.apply(Math, dataPoints.map(function (o) { return o.x; }));
      var MaxPrValue;
      MaxPrValue = Math.ceil(this.axisScales.PrAvgmaxY);
      let MaxPrThresh = this.getMaxvalueForChartPlot("specpromratio") //taking specProAvg y-axis bcz there is data for Pr_Avg 
      if (MaxPrThresh != undefined) {
        if (MaxPrValue < MaxPrThresh)
          MaxPrValue = Math.ceil(MaxPrThresh);
      }
      var XMaxValue;

      //Rootcause logic display -start
      ////console.log(this.allthreshold.threshold)
      if (this.allthreshold.threshold != undefined) {
        for (var i = 0; i < this.allthreshold.threshold.length; i++) {
          var RootcauseObj = { color: '', rootcausename: '', anaysis: '', dataset: [] }
          let templist = this.allthreshold.threshold[i].type.filter(obj => obj.Analysis.toLowerCase() === "specpromratio") //taking fft threshold bcz there is data for Pr_Avg 
          if (templist.length > 0) {
            let createobj = [];
            let color = this.allthreshold.threshold[i].color;
            let rootcausename = this.allthreshold.threshold[i].rootcause;
            const array1: [] = templist[0].Values.map(s => (
              createobj.push({ x: s.Min, y: s.Threshold }),
              createobj.push({ x: s.Max, y: s.Threshold }),
              createobj.push(null)
            ));
            if (createobj.length > 0) {
              RootcauseObj.color = color;
              RootcauseObj.rootcausename = rootcausename;
              RootcauseObj.dataset = createobj
              ListRootCause.push(RootcauseObj);
            }
          }

        }
      }
      var prdata = [];
      ////console.log(ListRootCause)
      //rootcause dosplay logic --end
      if (ListRootCause.length > 0)
        this.IsThreshold = true;

      if (this.header.titledata.Test.id == "1") {
        this.Test1.Overall = dataPoints;
        this.Pvr1XMax = Maxfrequency;
        if (frequency >= Maxfrequency) {
          this.Pvr1XMax = frequency;
        }
        this.Pvr1YMax = MaxPrValue + 5; //make y-axis with 5 to visible threshold graph which is MAX
        //console.log(this.Pvr1YMax + "_1_" + this.Pvr1XMax)
      }
      else {
        this.Test2.Overall = dataPoints;
        this.Pvr2XMax = Maxfrequency;
        if (frequency >= Maxfrequency) {
          this.Pvr2XMax = frequency;
        }
        this.Pvr2YMax = MaxPrValue + 5; //make y-axis with 5 to visible threshold graph which is MAX
        //console.log(this.Pvr2YMax + "_2_" + this.Pvr2XMax)
      }

      if (this.header.titledata.Iscompare) {
        // if (this.MaxValuelfftx != undefined && this.MaxValuelfftx != null) {
        if (this.Pvr1XMax >= this.Pvr2XMax) {
          XMaxValue = this.Pvr1XMax;
        } else {
          XMaxValue = this.Pvr2XMax;
        }
        this.MaxValuelPrx = XMaxValue;
        if (this.Pvr1YMax >= this.Pvr2YMax) {
          MaxPrValue = this.Pvr1YMax;
        } else {
          MaxPrValue = this.Pvr2YMax;

        }
        this.MaxValuelPry = MaxPrValue;
        // }
        // localStorage.setItem('MaxValuelfftx', XMaxValue);
      } else {
        XMaxValue = Maxfrequency;
        if (frequency >= Maxfrequency) {
          XMaxValue = frequency;
        }
        this.MaxValuelPrx = XMaxValue;
        MaxPrValue = MaxPrValue + 5; //make y-axis with 5 to visible threshold graph which is MAX

        this.MaxValuelPry = MaxPrValue;
      }

      //Rootcause logic display -start
      //console.log(this.MaxValuelPry + "MAX" + this.MaxValuelPrx)


      if (this.Test2.Overall) {

        prdata.push({
          label: this.header.titledata.Test2.Data.Machineid,
          data: this.Test2.Overall,
          borderColor: 'purple',
          borderWidth: 1,
          pointRadius: 0,
          lineTension: 0,
          fill: false
        });

      }
      if (this.Test1.Overall) {
        prdata.push({
          //label: this.titledata1.Testtype,
          data: this.Test1.Overall,
          borderColor: 'rgba(65, 131, 215, 1)',
          borderWidth: 1,
          pointRadius: 0,
          lineTension: 0,
          fill: false,
          label: this.header.titledata.Test1.Data.Machineid,
        });
      }
      ////console.log(this.IsThreshold)
      if (this.IsThreshold) {
        for (var i = 0; i < ListRootCause.length; i++) {
          prdata.push({
            data: ListRootCause[i].dataset,
            borderColor: ListRootCause[i].color,
            borderWidth: 1,
            pointRadius: 0,
            lineTension: 0,
            lineThickness: 5,
            label: ListRootCause[i].rootcausename,
            fill: false,
            hidden: true,
            snapGaps: 100
          });
        }

      }
      ////console.log(prdata)
      this.CreateCanvas('PrAvgchartContainer', 'v-pills-pravg')
      this.ctx = this.canvas.getContext('2d');
      this.PrAvgChart = new Chart(this.ctx, {
        type: 'line',
        data: {
          datasets: prdata
        },
        options: {
          animation: {
            onComplete: this.ChartRendercomplete.bind(this)
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, datatoolTip) {
                return parseFloat(tooltipItem.yLabel.toString()).toFixed(2)
              }
            }
          },
          maintainAspectRatio: false,
          legend: {
            display: true
          },
          elements: {
            line: {
              tension: 0
            }
          },
          plugins: {
            register: {
              beforeDraw: function (c) {
                var ctx = c.chart.ctx;
                ctx.fillRect(0, 0, c.chart.width, c.chart.height);
              }
            },
            zoom: {
              sensitivity: 20,
              pan: {
                drag: true,
                enabled: true,
                mode: 'x',
                speed: 10,
                threshold: 10,
                rangeMin: {
                  x: 0,
                  y: -100
                },
                rangeMax: {
                  x: 16000,
                  y: 200
                }
              },
              zoom: {
                speed: 0.1,
                enabled: true,
                mode: 'x',
                rangeMin: {
                  x: 0,
                },
                rangeMax: {
                  x: 16000,
                },
                limits: {
                  min: { x: 20 }
                }
              }
            }
          },
          responsive: true,
          scales: {
            xAxes: [{
              type: 'linear',
              position: 'bottom',
              scaleLabel: {
                display: true,
                labelString: pravgdata.xlable === undefined ? 'Frequency (Hz)' : pravgdata.xlable,
              },
              ticks: {
                min: 0,
                max: Math.ceil(XMaxValue),   //16000,
                callback: function (value) {
                  if (Math.floor(parseFloat(value.toString())) === value) {
                    return value;
                  }
                }
              }
            }],

            yAxes: [{
              type: 'linear',
              position: 'left',
              scaleLabel: {
                display: true,
                labelString: pravgdata.ylable === undefined ? 'Spec Prominence Ratio (dB)' : pravgdata.ylable,
              },
              ticks: {
                min: 0,
                max: MaxPrValue
              }
            }]
          }
        }
      }).render();
    }
  }

  Checkoperationexist() {
    if (this.isEDL)
      this.key = this.EDLPath + "/"
    else
      this.key = environment.AWSINFO.EOLjsonfileSourceFolder + "/" + this.ChasisNo + environment.APPINFO.FFTfilename + this.SelectedTrials + ".json";
    var params = { Bucket: environment.AWSINFO.rekognitionBucket, Prefix: this.key };
    this.s3.listObjectsV2(params, this.Enablebuttons.bind(this));
  }
  operationButton = false;
  Enablebuttons(err, data) {

    this.spinnerService.showSpinner();
    if (data) {


      data.Contents.forEach(element => {

        if (element.Key.indexOf(environment.APPINFO.FFTfilename) != -1) {
          /* if (element.Key.indexOf(environment.APPINFOjson.FFTfilename) != -1)*/
          this.hidefft = false;
        }

        else if
          (element.Key.indexOf(environment.APPINFOjson.FFTfilename) != -1)
          this.hidefft = false;

        if (element.Key.indexOf(environment.APPINFO.overfilename) != -1) {

          this.hideoverall = false;
        }
        else if (element.Key.indexOf(environment.APPINFO.overfilename) != -1)
          this.hideoverall = false;

        if (element.Key.indexOf(environment.APPINFO.waterfilename) != -1) {
          this.hidewaterfall = false;
        }
        else if (element.Key.indexOf(environment.APPINFOjson.waterfilename) != -1)
          this.hidewaterfall = false;

        if (element.Key.indexOf(environment.APPINFO.Octavefilename) != -1 && element.Key.indexOf(environment.APPINFO.ThirdOctavefilename) < 0) {
          this.hideroctave = false;
        }
        else if (element.Key.indexOf(environment.APPINFOjson.Octavefilename) != -1 && element.Key.indexOf(environment.APPINFOjson.ThirdOctavefilename) < 0)
          this.hideroctave = false;
        if (element.Key.indexOf(environment.APPINFO.ThirdOctavefilename) != -1) {
          this.hidethirdocatve = false;
        }
        else if (element.Key.indexOf(environment.APPINFOjson.ThirdOctavefilename) != -1)
          this.hidethirdocatve = false;

        if (element.Key.indexOf(environment.APPINFO.PRTFileName) != -1) {
          this.hideprTime = false;
        }
        else if (element.Key.indexOf(environment.APPINFOjson.PRTFileName) != -1)  /*307 Issue*/
          this.hideprTime = false;
        if (element.Key.indexOf(environment.APPINFO.PRAvgFileName) != -1) {

          this.hideprAvg = false;
        }
        else if (element.Key.indexOf(environment.APPINFOjson.PRAvgFileName) != -1) {
          this.hideprAvg = false;
        }



        if (element.Key.indexOf(environment.APPINFO.AudioFile) != -1) {
          this.hideaudio = false;
        }
        else if (element.Key.indexOf(environment.APPINFO.AudioFile1) != -1) {
          this.hideaudio = false;
        }
        else if (element.Key.indexOf(environment.APPINFOjson.AudioFile) != -1)
          this.hideaudio = false;
        else if (element.Key.indexOf(environment.APPINFOjson.AudioFile1) != -1)
          this.hideaudio = false;
      });
      if (!this.hidefft || !this.hideoverall || !this.hidewaterfall || !this.hideroctave || !this.hidethirdocatve || !this.hideprAvg || !this.hideprTime) {

        this.Nodata = false
        this.isSpeech = false;

        if (!this.hidewaterfall)
          this.GetSpectrumdata();
        if (!this.hidefft)
          //
          this.GetFFTdata();
        if (!this.hideprAvg)
          this.GetPrAvgChart();
        if (!this.hideoverall)
          this.GetlevelVsTime();
        if (!this.hidethirdocatve)
          //
          this.GetThirdOctaveChart();
        if (!this.hideprTime)
          this.GetPrVsTimeChart();

        if (!this.hideroctave) {
          this.GetOctaveChart();
          this.GetSpeechData();
        }

        if (!this.header.titledata.Iscompare)
          this.EngineSounddata();



      }
      else {
        this.Nodata = true;
        this.spinnerService.hideSpinner();
        this.operationButton = true;
      }
      if (!this.hidefft)
        this.EnabledButton = 'FFTdata';
      else if (!this.hideroctave)
        this.EnabledButton = 'Octave'
      else if (!this.hidethirdocatve)
        this.EnabledButton = '3rdOctave'
      else if (!this.hideoverall)
        this.EnabledButton = 'oneOctave'
      else if (!this.hideprTime)
        this.EnabledButton = 'PrvsTime'
      else if (!this.hideprAvg)
        this.EnabledButton = 'PrAvg'
      else if (!this.hidewaterfall)
        this.EnabledButton = 'Spectrumdata'
      this.isFFTVsTime = false;
    }
    else {
      this.spinnerService.hideSpinner();
      this.operationButton = true;
    }
  }
  Reset() {

    this.minx = null;
    this.maxx = null;
    this.miny = null;
    this.maxy = null;
    if (this.EnabledButton == 'oneOctave')
      this.GetlevelVsTime();
    else if (this.EnabledButton == 'Octave') {
      this.GetOctaveChart();
      this.GetSpeechData();
    }
    else if (this.EnabledButton == '3rdOctave')
      this.GetThirdOctaveChart();

    else if (this.EnabledButton == 'PrvsTime')
      this.LoadProminenceVsTimedata(null, this.promTimeData);
    else if (this.EnabledButton == 'FFTdata')
      this.GetFFTdata();
    else if (this.EnabledButton == 'PrAvg')
      this.GetPrAvgChart();
    else if (this.EnabledButton == 'Spectrumdata')
      //////console.log('before get obj' + new Date().getTime());
      try {
        this.LoadSpectrogram(null, this.loadFullSpectogramData)
      }
      catch (err) {
        //////console.log(err);
      }
  }

  SaveChart() {
    let canvas: any

    if (this.EnabledButton == 'oneOctave')
      canvas = document.getElementById('levelchartContainer');
    else if (this.EnabledButton == 'Octave')
      canvas = document.getElementById('barchartContainer');
    else if (this.EnabledButton == '3rdOctave')
      canvas = document.getElementById('thirdOctchartContainer');
    else if (this.EnabledButton == 'PrvsTime')
      canvas = document.getElementById('prvstimecanvas');
    else if (this.EnabledButton == 'PrAvg')
      canvas = document.getElementById('PrAvgchartContainer');
    else if (this.EnabledButton == 'Spectrumdata')
      canvas = document.getElementById('canvas');
    else
      canvas = document.getElementById('FFTchartContainer');
    const context = canvas.getContext('2d');
    context.save();
    context.globalCompositeOperation = 'destination-over';
    context.fillStyle = 'white';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.restore();
    canvas.toBlob(this.saveimage.bind(this))
  }
  saveimage(blob) {
    saveAs(blob, this.EnabledButton + ".png");
  }


  Rescale() {

    if (this.EnabledButton == 'Spectrumdata')
      this.FilterDialog()
    else if (this.EnabledButton == 'PrvsTime')
      this.ProminenceFilterDialog()
    else {
      var header = '';
      let scale: any = {}
      let selected: any = {}
      if (this.EnabledButton == 'oneOctave') {
        header = 'Level vs Time';
        scale.minx = 0
        scale.maxx = this.MaxValuelevelx
        /* scale.miny = this.axisScales.overallminY
        scale.maxy = this.axisScales.overallmaxY */

        scale.miny = -100
        scale.maxy = this.MaxValuelevely
        selected.minx = this.OverallChart.options.scales.xAxes[0].ticks.min
        selected.maxx = this.OverallChart.options.scales.xAxes[0].ticks.max
        selected.miny = this.OverallChart.options.scales.yAxes[0].ticks.min
        selected.maxy = this.OverallChart.options.scales.yAxes[0].ticks.max

      }

      else if (this.EnabledButton == 'PrAvg') {
        header = 'PR Average';
        scale.minx = 0
        scale.maxx = this.MaxValuelPrx
        scale.miny = -100
        scale.maxy = this.MaxValuelPry

        // scale.miny = -100
        // scale.maxy = 200
        selected.minx = this.PrAvgChart.options.scales.xAxes[0].ticks.min
        selected.maxx = this.PrAvgChart.options.scales.xAxes[0].ticks.max
        selected.miny = this.PrAvgChart.options.scales.yAxes[0].ticks.min
        selected.maxy = this.PrAvgChart.options.scales.yAxes[0].ticks.max

      }
      else if (this.EnabledButton == 'Octave') {

        header = 'Octave';
        scale.minx = 0;//this.axisScales.octaveminX.toString();
        scale.maxx = this.MaxValueloctx;
        /*  scale.miny = this.axisScales.octaveminY
         scale.maxy = this.axisScales.octavemaxY */
        scale.miny = -100
        scale.maxy = this.MaxValuelocty
        selected.minx = this.OctaveChart.options.scales.xAxes[0].ticks.min
        selected.maxx = this.OctaveChart.options.scales.xAxes[0].ticks.max
        selected.miny = this.OctaveChart.options.scales.yAxes[0].ticks.min
        selected.maxy = this.OctaveChart.options.scales.yAxes[0].ticks.max
        //this.GetNextTick(Math.round(this.axisScales.octavemaxY))
        this.GetOctaveChart();

      }
      else if (this.EnabledButton == '3rdOctave') {
        header = '3rdOctave';
        scale.minx = 0
        scale.maxx = this.MaxValuel3octx
        //  scale.miny = this.axisScales.thirdoctaveminY
        // scale.maxy = this.axisScales.thirdoctavemaxY 
        scale.miny = -100
        scale.maxy = this.MaxValuel3octy

        selected.minx = this.ThirdOctaveChart.options.scales.xAxes[0].ticks.min
        selected.maxx = this.ThirdOctaveChart.options.scales.xAxes[0].ticks.max
        selected.miny = this.ThirdOctaveChart.options.scales.yAxes[0].ticks.min
        selected.maxy = this.ThirdOctaveChart.options.scales.yAxes[0].ticks.max
        this.GetThirdOctaveChart();
      }
      else if (this.EnabledButton == 'FFTdata') {
        header = 'FFTdata';
        /* scale.minx = this.axisScales.FFTminX.toFixed(2)
        scale.maxx = this.axisScales.FFTmaxX.toFixed(2)
        scale.miny = this.axisScales.FFTminY.toFixed(2)
        scale.maxy = this.axisScales.FFTmaxY.toFixed(2) */
        scale.minx = 0;
        scale.maxx = this.MaxValuelfftx
        scale.miny = -100
        scale.maxy = this.MaxValuelffty

        selected.minx = this.FFTChart.options.scales.xAxes[0].ticks.min
        selected.maxx = this.FFTChart.options.scales.xAxes[0].ticks.max
        selected.miny = this.FFTChart.options.scales.yAxes[0].ticks.min
        selected.maxy = this.FFTChart.options.scales.yAxes[0].ticks.max

      }

      const dialogRef = this.dialog.open(ChartFilterComponent, {

        width: '600px',
        data: { header: header, active: this.EnabledButton, scale: scale, selected: selected, primarybtn: 'Set', cancelbtn: 'Cancel' }
      });

      dialogRef.afterClosed().subscribe(result => {


        if (result != null) {
          this.minx = parseFloat(result.minx);
          this.maxx = parseFloat(result.maxx);
          this.miny = parseFloat(result.miny);
          this.maxy = parseFloat(result.maxy);

          if (this.EnabledButton == '3rdOctave') {

            this.ThirdOctaveChart.options.scales.yAxes[0].ticks.min = this.miny;
            this.ThirdOctaveChart.options.scales.yAxes[0].ticks.max = this.maxy;
            this.ThirdOctaveChart.options.scales.xAxes[0].ticks.max = this.maxx;
            var res3Oct = this.ThirdOctaveChart.data.labels.filter(x => Math.ceil(x) >= this.minx && Math.ceil(x) <= this.maxx)

            var arrayRes = [];
            var indexArray = [];
            res3Oct.forEach(element => {
              arrayRes.push(element);
            });

            for (var i = 0; i < this.ThirdOctaveChart.data.labels.length; i++) {
              arrayRes.forEach(resSingle => {
                if (resSingle === this.ThirdOctaveChart.data.labels[i]) {
                  indexArray.push(i - 1)
                }
              });
            }
            var dataSet = [];
            if (!this.header.titledata.Iscompare) {
              indexArray.forEach(index => {
                dataSet.push(this.ThirdOctaveChart.data.datasets[0].data[index + 1]);
              });
              this.ThirdOctaveChart.data.datasets[0].data = dataSet;
            }
            var primary = [];
            var secondary = [];
            if (this.header.titledata.Iscompare) {
              indexArray.forEach(index => {
                if (this.ThirdOctaveChart.data.datasets[0] != undefined) {
                  primary.push(this.ThirdOctaveChart.data.datasets[0].data[index + 1]);
                }
                if (this.ThirdOctaveChart.data.datasets[1] != undefined) {
                  secondary.push(this.ThirdOctaveChart.data.datasets[1].data[index + 1]);
                }
              });

              dataSet.push(primary);
              dataSet.push(secondary);
              this.ThirdOctaveChart.data.datasets[0].data = primary;
              this.ThirdOctaveChart.data.datasets[1].data = secondary;
            }



            this.ThirdOctaveChart.data.labels = res3Oct;
            this.ThirdOctaveChart.options.scales.xAxes[0].ticks.stepSize = 1;
            this.ThirdOctaveChart.options.scales.yAxes[0].ticks.stepSize = 1;
            this.ThirdOctaveChart.update();


          }
          else if (this.EnabledButton == 'Octave') {

            this.OctaveChart.options.scales.yAxes[0].ticks.min = this.miny;
            this.OctaveChart.options.scales.yAxes[0].ticks.max = this.maxy;
            this.OctaveChart.options.scales.xAxes[0].ticks.max = this.maxx;
            this.OctaveChart.options.scales.xAxes[0].ticks.min = this.minx;
            var res = this.OctaveChart.data.labels.filter(x => Math.ceil(x) >= this.minx && Math.ceil(x) <= this.maxx);

            var arrayRes = [];
            var indexArray = [];
            res.forEach(element => {
              arrayRes.push(element);
            });
            for (var i = 0; i < this.OctaveChart.data.labels.length; i++) {
              arrayRes.forEach(resSingle => {
                if (resSingle === this.OctaveChart.data.labels[i]) {
                  indexArray.push(i - 1)
                }
              });
            }
            var dataSet = [];
            if (!this.header.titledata.Iscompare) {
              indexArray.forEach(index => {
                dataSet.push(this.OctaveChart.data.datasets[0].data[index + 1]);
              });
              this.OctaveChart.data.datasets[0].data = dataSet;
            }
            var primary = [];
            var secondary = [];
            if (this.header.titledata.Iscompare) {
              indexArray.forEach(index => {
                if (this.OctaveChart.data.datasets[0] != undefined) {
                  primary.push(this.OctaveChart.data.datasets[0].data[index + 1]);
                }
                if (this.OctaveChart.data.datasets[1] != undefined) {
                  secondary.push(this.OctaveChart.data.datasets[1].data[index + 1]);
                }
              });
              dataSet.push(primary);
              dataSet.push(secondary);
              this.OctaveChart.data.datasets[0].data = primary;
              this.OctaveChart.data.datasets[1].data = secondary;
            }

            this.OctaveChart.data.labels = res;
            this.OctaveChart.options.scales.xAxes[0].ticks.stepSize = 1;
            this.OctaveChart.options.scales.yAxes[0].ticks.stepSize = 1;
            this.OctaveChart.update();
          }

          //setTimeout(function () {
          if (selected.minx == result.minx && selected.maxx == result.maxx && selected.miny == result.miny && selected.maxy == result.maxy) {
            return;
          }
          else {

            if (this.EnabledButton == 'oneOctave') {

              this.OverallChart.options.scales.yAxes[0].ticks.min = this.miny;
              this.OverallChart.options.scales.yAxes[0].ticks.max = this.maxy;
              this.OverallChart.options.scales.xAxes[0].ticks.min = this.minx;
              this.OverallChart.options.scales.xAxes[0].ticks.max = this.maxx;
              this.OverallChart.options.scales.xAxes[0].ticks.stepSize = 2;
              this.OverallChart.options.scales.yAxes[0].ticks.stepSize = 1;
              this.OverallChart.update();
            }
            else if (this.EnabledButton == 'PrAvg') {

              this.PrAvgChart.options.scales.yAxes[0].ticks.min = this.miny;
              this.PrAvgChart.options.scales.yAxes[0].ticks.max = this.maxy;
              this.PrAvgChart.options.scales.xAxes[0].ticks.min = this.minx;
              this.PrAvgChart.options.scales.xAxes[0].ticks.max = this.maxx;
              this.PrAvgChart.options.scales.xAxes[0].ticks.stepSize = 2;
              this.PrAvgChart.options.scales.yAxes[0].ticks.stepSize = 1;
              this.PrAvgChart.update();
            }

            else if (this.EnabledButton == 'FFTdata') {
              this.FFTChart.options.scales.yAxes[0].ticks.min = this.miny;
              this.FFTChart.options.scales.yAxes[0].ticks.max = this.maxy;
              this.FFTChart.options.scales.xAxes[0].ticks.min = this.minx;
              this.FFTChart.options.scales.xAxes[0].ticks.max = this.maxx;
              this.FFTChart.options.scales.xAxes[0].ticks.stepSize = 20;
              this.FFTChart.options.scales.yAxes[0].ticks.stepSize = 1;
              this.FFTChart.update();
            }
          }
          // }, 3000);
        }
      })
    }
  }



  OctavechartZoom = {
    pan: {
      drag: true,
      enabled: true,
      mode: 'xy',
      speed: 10,
      threshold: 10,
      rangeMax: {
        y: 200
      },
      rangeMin: {
        y: -100
      },
    },
    zoom: {
      enabled: true,
      mode: 'xy',
      rangeMax: {
        y: 200
      },
      rangeMin: {
        y: -100
      },
    }
  }
  OctaveThirdscale = {
    xAxes: [{
      position: 'bottom',
      scaleLabel: {
        display: true,
        labelString: this.xLabel3Oct == undefined ? 'Frequency (Hz)1' : this.xLabel3Oct,
      },
      ticks: {
        min: 0,
        beginAtZero: true,

      }
    }],
    yAxes: [{
      position: 'left',
      scaleLabel: {
        display: true,
        labelString: this.yLabel3Oct == undefined ? 'Sound Pressure Level (dBA)1' : this.yLabel3Oct,
      },
      ticks: {
        min: 0,
        beginAtZero: true,
        callback: function (value) {
          if (Math.floor(parseFloat(value.toString())) === value) {
            return value;
          }
        }
      }
    }]
  }

  Octavescale = {
    xAxes: [{
      position: 'bottom',
      scaleLabel: {
        display: true,
        labelString: this.xLabelOct == undefined ? 'Frequency (Hz)2' : this.xLabelOct,
      },
      ticks: {
        min: 0,
        beginAtZero: true,

      }
    }],
    yAxes: [{
      position: 'left',
      scaleLabel: {
        display: true,
        labelString: this.yLabelOct == undefined ? 'Sound Pressure Level (dBA)2' : this.yLabelOct,
      },
      ticks: {
        min: 0,
        beginAtZero: true,
        callback: function (value) {
          if (Math.floor(parseFloat(value.toString())) === value) {
            return value;
          }
        }
      }
    }]
  }
  getMaxvalueForChartPlot(chartname) {
    var ListRootCause = [];
    if (this.allthreshold.threshold != undefined) {
      for (var i = 0; i < this.allthreshold.threshold.length; i++) {
        var RootcauseObj = { color: '', rootcausename: '', anaysis: '', dataset: [] }
        let templist = this.allthreshold.threshold[i].type.filter(obj => obj.Analysis.toLowerCase() === chartname)
        if (templist.length > 0) {
          let createobj = [];
          let color = this.allthreshold.threshold[i].color;
          let rootcausename = this.allthreshold.threshold[i].rootcause;
          const array1: [] = templist[0].Values.map(s => (
            createobj.push({ x: s.Min, y: s.Threshold }),
            createobj.push({ x: s.Max, y: s.Threshold }),
            createobj.push(null)
          ));
          var MaxValue = templist[0].Values.reduce((acc, createobj) => acc = acc > createobj.Threshold ? acc : createobj.Threshold, 0);
          RootcauseObj.color = color;
          RootcauseObj.rootcausename = rootcausename;
          RootcauseObj.dataset = createobj
          ListRootCause.push(RootcauseObj);

        }

      }
    }
    else
      MaxValue = -1;
    return MaxValue;
  }
  GetNextTick(value) {
    let divider = 10
    while (value % divider !== 0) {
      value++;
    }
    return value;
  }

  ngOnDestroy() {
    this.header = {};
    if (this.wave) {
      this.wave.stop();
      this.wave = null;
    }
  }

}
class Pair<T1, T2> {
  private key: T1;
  private value: T2;

  constructor(key: T1, value: T2) {
    this.key = key;
    this.value = value;
  }

  getKey() {
    return this.key;
  }

  getValue() {
    return this.value;
  }


}
interface MyObject {
  color: string;
  rootcausename: string;
  anaysis: string;
  dataset: []
}
