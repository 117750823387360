export class spectrogramSettings {
    maxValue: number;
    minValue: number;
    minFrequency: number;
    maxFrequency: number;
    minTime: number;
    maxTime: number;
    constructor(  minValue: number,maxValue: number,minFrequency: number, maxFrequency: number,
        minTime: number, maxTime: number) {
            this.maxValue = maxValue;
            this.minValue = minValue; 
            this.minFrequency = Number(minFrequency.toFixed(1)); //display only till 1 digit of precision
            this.maxFrequency = Number(maxFrequency.toFixed(1));
            this.minTime = Number(minTime.toFixed(1));
            this.maxTime = Number(maxTime.toFixed(1));
         }
  }
  export class PRspectrogramSettings {
    maxValue: number;
    minValue: number;
    minFrequency: number;
    maxFrequency: number;
    minTime: number;
    maxTime: number;
    constructor(  minValue: number,maxValue: number,minFrequency: number, maxFrequency: number,
        minTime: number, maxTime: number) {
            this.maxValue = maxValue;
            this.minValue = minValue; 
            this.minFrequency = Number(minFrequency.toFixed(1)); //display only till 1 digit of precision
            this.maxFrequency = Number(maxFrequency.toFixed(1));
            this.minTime = Number(minTime.toFixed(1));
            this.maxTime = Number(maxTime.toFixed(1));
         }
  }