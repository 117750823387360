import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  Validators,
  FormGroup,
  FormBuilder,
  FormControl
} from '@angular/forms';
import {
  AdminService
} from '../../auth/admin.service';
import Constants from '../../commonFiles/constant/Constants';
import {
  ErrorService
} from '../../commonFiles/services/error.service';
import {
  Router, ActivatedRoute, ParamMap
} from '@angular/router';
import {
  MatSnackBar,
  MatDialog,
  MatAccordion
} from '@angular/material';
import {
  MatTableDataSource
} from '@angular/material';
import {
  AuthService
} from '../../auth/auth.service';
import {
  MatTable
} from '@angular/material/table';

import {
  ThresholdService
} from './../threshold.service';


import {
  AddRootcauseComponent
} from '../../threshold/add-rootcause/add-rootcause.component';
import {
  ConfirmationWindowComponent
} from '../../commonFiles/modalwindow/confirmation-window/confirmation-window.component'
import { constants } from 'os';
import { Location } from '@angular/common';
import { Threshold } from '../thresholdModel'
import { VehicleModel, TestType } from '../../commonFiles/model/superadmin.model';
@Component({
  selector: 'app-add-threshold',
  templateUrl: './add-threshold.component.html',
  styleUrls: ['./add-threshold.component.scss']
})
export class AddThresholdComponent implements OnInit {
  VehicleModels = [];
  TestTypes = [];
  threshdata: Threshold;
  ThresholdErrorMessage
  ErrorMessage
  threasholds
  companies
  BuList
  ModelList
  Title: any
  CreateBtnText: any;
  datatype: any;
  thresholdform: FormGroup;
  selectedModule: any
  AllAnalysisThreshold: any;
  DisplayRootcause: Boolean;
  ThreshCancel: Boolean;
  ThreshCreate: Boolean;
  RootcauseExist: Boolean;
  Allthresholdfromdb: any;
  IsSuperAdmin: Boolean;
  NoiseVibDisable: Boolean;
  DefaultColor: any;
  isVibration: Boolean;
  CenterFrequency: any;
  AnalysisArray: any;
  dataToEdit: any;
  @ViewChild(MatTable, {
    static: true
  }) table: MatTable<any>;
  panelOpenState = false;


  @ViewChild('accordion', {
    static: false
  }) Accordion: MatAccordion

  constructor(public fb: FormBuilder,
    public adminService: AdminService,
    public authService: AuthService,
    public errorService: ErrorService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public threshService: ThresholdService,
    public router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {

    this.Allthresholdfromdb = [];
    this.errorService.getResponsePostMeassage().subscribe(errormessagedisplay => {
      if (errormessagedisplay != "A group with the name already exists.")
        this.ErrorMessage = errormessagedisplay;
    });
  }
  RootdisplayedColumns: string[] = ['name', 'action'];
  ngOnDestroy() {
    this.dataToEdit = undefined;
    this.Allthresholdfromdb = [];
    this.threshService.ClearThreshold();
    this.threshService.ClearEditableThreshdata();
  }
  ngOnInit() {

    this.Allthresholdfromdb.type = [];
    this.Allthresholdfromdb = [];
    this.authService.validateUser();
    this.dataToEdit = undefined;
    if (this.threshService.getThreshDataInChild().length > 0)
      this.dataToEdit = this.threshService.getThreshDataInChild()[0];
    this.selectedModule = 'EDAV'
    this.NoiseVibDisable = false;
    if (this.dataToEdit != undefined) {

      this.Title = "Edit Threshold";
      this.CreateBtnText = "Update"
      this.ThreshCreate = false;
      this.initthresholdForm();
      this.InitEditableForm();
      this.fillCompany();
    }
    else if (this.dataToEdit == undefined) {
      this.Title = "Add Threshold";
      this.CreateBtnText = "Create"
      this.ThreshCreate = true;
      this.datatype = 'n';
      this.initthresholdForm();
      this.CheckIsSuperAdmin();
      if (this.IsSuperAdmin == false) {
        this.companies = [localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME)]
        this.BuList = [localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT)]
      }
      else {
        this.fillCompany();
      }
      this.datatype = "n";
      this.isVibration = false;

    }
    this.errorService.setResponseEdlMessage(true);
    this.adminService.getTestTypes(this.thresholdform.controls.companyName.value.toString().trim(), this.thresholdform.controls.businessUnit.value.toString().trim(), "edl").subscribe(res => {

      //for (var i = 0; i < res.success.Message.length; i++) {
      this.TestTypes = res.success.Message;
      console.log(this.TestTypes)
      //var Tests = new TestType;
      //Tests.TestName = res.success.Message[i];
      //Tests.Test = true;
      //this.TestTypes.push(Tests)
      //}


    });
    this.adminService.getVehicleModel(this.thresholdform.controls.companyName.value.toString().trim(), this.thresholdform.controls.businessUnit.value.toString().trim(), "edl").subscribe(res => {

      // for (var i = 0; i < res.success.Message.length; i++) {
      this.VehicleModels = res.success.Message;
      console.log(this.VehicleModels)
      //var Vehicles = new VehicleModel;

      //Vehicles.VehicleName = res.success.Message[i];
      //Vehicles.Vehicle = true;
      //this.VehicleModels.push(Vehicles);
      //}


    });

  }
  InitEditableForm() {
    let GeneralValues = [];
    this.NoiseVibDisable = true;
    if (this.dataToEdit.threshold.find(obj => obj.rootcause == Constants.GenRootConstantName) != undefined)
      GeneralValues = this.dataToEdit.threshold.find(obj => obj.rootcause == Constants.GenRootConstantName).type;

    this.threshdata = {
      company: this.dataToEdit.cb.split('%%')[0],
      bu: this.dataToEdit.cb.split('%%')[1],
      machineid: this.dataToEdit.smdt.split('%%')[1],
      testtype: this.dataToEdit.smdt.split('%%')[3],
      datatype: this.dataToEdit.smdt.split('%%')[2],
      threshold: [],
      subapp: "edl",
      laeq: GeneralValues.length == 0 || GeneralValues == undefined ? 0 : GeneralValues[0].Values,
    }
    this.thresholdform.patchValue({
      companyName: this.dataToEdit.cb.split('%%')[0],
      businessUnit: this.dataToEdit.cb.split('%%')[1],
      machineid: this.dataToEdit.smdt.split('%%')[1],
      testtype: this.dataToEdit.smdt.split('%%')[3],
      AnalysisType: this.dataToEdit.smdt.split('%%')[2],
      // color:this.dataToEdit.color,
      LaEq: GeneralValues[0] == undefined ? '' : GeneralValues[0].Values,
    });
    this.isVibration = this.dataToEdit.smdt.split('%%')[2] == "n" ? false : true;
    this.threshService.AddEditableRootcause(this.dataToEdit.threshold.filter(obj => obj.rootcause != Constants.GenRootConstantName));
    this.Allthresholdfromdb = this.threshService.getrootcauselist();

    //this.Allthresholdfromdb =this.dataToEdit.threshold.filter(obj => obj.rootcause !=Constants.GenRootConstantName);
  }
  initthresholdForm() {

    this.NoiseVibDisable = false;
    this.threshdata = {
      company: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
      bu: localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
      machineid: '',
      testtype: '',
      datatype: '',
      threshold: [],
      subapp: "edl",
      laeq: null,
    }

    if (this.IsSuperAdmin) {
      this.thresholdform = this.fb.group({
        companyName: ['', Validators.required],
        businessUnit: ['', Validators.required],
        machineid: ['', Validators.required],
        testtype: ['', Validators.required],
        AnalysisType: 'n',
        LaEq: ''
      });

    }
    else {
      this.thresholdform = this.fb.group({
        companyName: [localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME)],
        businessUnit: [localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT)],
        machineid: ['', Validators.required],
        testtype: ['', Validators.required],
        AnalysisType: 'n',
        LaEq: ''
      });
    }
    this.datatype = "n";
  }
  CheckIsSuperAdmin() {
    if (localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME) == "bosch" && localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT) == "superadmin")
      this.IsSuperAdmin = true;
    else
      this.IsSuperAdmin = false;
  }
  fillCompany() {
    this.adminService.GetCompanyList().subscribe(data => {
      this.companies = data.success.Message;
    });
    this.CompanySelect();
  }
  CompanySelect() {
    if (this.thresholdform.controls.companyName.value != undefined) {
      this.adminService.GetBUList(this.thresholdform.controls.companyName.value.toString().trim()).subscribe(data => {
        this.BuList = data.success.Message;
      });
    }
    this.businessSelect();
  }
  compareObjects(o1: any, o2: any): boolean {

    return o1 === o2 && o1 === o2;
  }
  businessSelect() {

    if (this.thresholdform.controls.businessUnit.value != undefined && this.thresholdform.controls.companyName.value != undefined) {
      this.adminService.GetVehicleModel(this.thresholdform.controls.companyName.value.toString().trim(), this.thresholdform.controls.businessUnit.value.toString().trim(), "edl").subscribe(data => {
        this.ModelList = data.success.Message;
        if (this.ModelList == undefined)
          this.ModelList = [];
        console.log(this.ModelList);
      });
      this.adminService.getTestTypes(this.thresholdform.controls.companyName.value.toString().trim(), this.thresholdform.controls.businessUnit.value.toString().trim(), "edl").subscribe(res => {

        //for (var i = 0; i < res.success.Message.length; i++) {
        this.TestTypes = res.success.Message;
        console.log(this.TestTypes)
        //var Tests = new TestType;
        //Tests.TestName = res.success.Message[i];
        //Tests.Test = true;
        //this.TestTypes.push(Tests)
        //}


      });
      this.adminService.getVehicleModel(this.thresholdform.controls.companyName.value.toString().trim(), this.thresholdform.controls.businessUnit.value.toString().trim(), "edl").subscribe(res => {

        // for (var i = 0; i < res.success.Message.length; i++) {
        this.VehicleModels = res.success.Message;
        console.log(this.VehicleModels)
        //var Vehicles = new VehicleModel;

        //Vehicles.VehicleName = res.success.Message[i];
        //Vehicles.Vehicle = true;
        //this.VehicleModels.push(Vehicles);
        //}


      });

    }
    else {
      this.snackBar.open("Please select Company Name and Business Unit.", "", {
        duration: 2500,
        verticalPosition: 'top'
      });
    }
  }

  thresholdupdateRootcause() {


    //this.Allthresholdfromdb.push({rootcause:"brush2"});
    this.Allthresholdfromdb = this.threshService.getrootcauselist();

  }
  isFieldInvalid(field: string) {
    return (
      (!this.thresholdform.get(field).valid && this.thresholdform.get(field).touched) ||
      (this.thresholdform.get(field).untouched)
    );
  }
  onAnalysischange(event, Type) {

    this.datatype = event.value;
    if (this.datatype == "v") {
      this.isVibration = true;
    }
    else {
      this.isVibration = false;
    }
  }
  CheckDisable() {
    if ((this.thresholdform.valid == true && this.thresholdform.controls.LaEq.value != '') || (!this.thresholdform.invalid == true && this.Allthresholdfromdb.length > 0))
      //if((this.thresholdform.valid==true&& this.thresholdform.controls.LaEq.value!=null)|| (!this.thresholdform.invalid==true && this.Allthresholdfromdb.length>0))
      return false;
    else
      return true;
    //return this.thresholdform.invalid;
  }
  AddRootcausebtn() {
    var OpenType = "ADD"
    if (this.datatype == "n")
      this.CenterFrequency = Constants.Octave_N_FC;
    else
      this.CenterFrequency = Constants.Octave_V_FC;
    this.NoiseVibDisable = false;

    var dialogRef = this.dialog.open(AddRootcauseComponent, {
      width: '950px!important',
      height: '650px',
      disableClose: true,
      data: {
        header: 'Add Root cause Details',
        primarybtn: 'Done',
        cancelbtn: 'Cancel',
        rootcause: [],
        formdata: this.thresholdform.value,
        octaveFreq: this.CenterFrequency,

        OpenType: OpenType,
        // EditList: this.GetArrayObject(AnalysisList)
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == '') {

      }
      this.Allthresholdfromdb = this.threshService.getrootcauselist();

    });
  }
  async CreateThreshold() {
    var body;
    body = this.ThresholdObj(this.thresholdform);
    if (this.isVibration) {
      body.datatype = 'v';
    }
    if (this.thresholdform.invalid) {
      this.ErrorMessage = "Please enter all the mandatory fields fields."
    }
    else {
      var threshdata = this.threshService.getallthresholds(body, this.ThreshCreate);
      if (threshdata.threshold.length == 0) {
        this.snackBar.open("No rootcause added.", "", {
          duration: 2500,
          verticalPosition: 'top'
        });
      }
      if (this.ThreshCreate) {
        if (threshdata.threshold.length == 0) {
          this.snackBar.open("No rootcause added.", "", {
            duration: 2500,
            verticalPosition: 'top'
          });
        }
        else {
          await this.adminService.thresholdadd(threshdata).subscribe(res => {
            if (res.ResponseMetadata.HTTPStatusCode == 200) {

              var message;
              message = "Threshold added successfully.";
              this.initthresholdForm();
              this.threshService.ClearThreshold();
              this.router.navigate(['/threshold']);

            }
            else {
              message = "Some error occured while creating threshold.";
            }
            this.snackBar.open(message, "", {
              duration: 2500,
              verticalPosition: 'top'
            });
          });
        }
      }
      else {
        //var threshdata = this.threshService.getallthresholds(body,this.ThreshCreate);
        await this.adminService.thresholdupdate(threshdata).subscribe(res => {
          if (res.ResponseMetadata.HTTPStatusCode == 200) {

            var message;
            message = "Threshold updated successfully.";
            this.threshService.ClearThreshold();
            this.threshService.ClearEditableThreshdata();
            this.threshService.ClearThreshold();
            this.router.navigate(['/threshold']);
          }
          else {
            message = "Some error occured while updating threshold.";
          }
          this.snackBar.open(message, "", {
            duration: 2500,
            verticalPosition: 'top'
          });
        });
      }




    }
  }

  ThresholdObj(form) {
    var obj = {
      company: this.thresholdform.value.companyName,
      businessunit: this.thresholdform.value.businessUnit,
      subapp: "edl",
      machineid: this.thresholdform.value.machineid,
      testtype: this.thresholdform.value.testtype,
      datatype: this.datatype,
      genthreshold: { Analysis: "Laeq", Values: this.thresholdform.value.LaEq }
      //threshold:threasholds
    }
    return obj;

  }
  threshcancel() {

    this.threshService.ClearThreshold();
    this.Allthresholdfromdb = this.threshService.getrootcauselist();
    this.initthresholdForm();
    this.router.navigate(['/threshold']);

  }

  //code for rootcause add functionalities
  GetArrayObject(array) {
    if (array.length == 0)
      return { Values: [] };
    else
      return array[0];
  }

  //  getlocalDialogData()
  //  {

  //  }


  validateOverall(laeqvalue) {
    var ErrorMessage: any;
    if (this.datatype == "n") {
      if (laeqvalue < Constants.OverallMin_n || laeqvalue > Constants.OverallMax_n) {
        ErrorMessage = "LEq value should be in " + Constants.OverallMin_n + "-" + Constants.OverallMax_n + " range.";
      }
    }
    else {
      if (laeqvalue < Constants.OverallMin_v || laeqvalue > Constants.OverallMax_v) {
        ErrorMessage = "LEq value should be in " + Constants.OverallMin_v + "-" + Constants.OverallMax_v + " range.";
      }
    }
    return ErrorMessage;

  }


  cancel() {

    this.threshService.clearroocuaseAfterAdd();
    this.AddRootcausebtn();
    this.ngOnInit();
    // this.isRootCauseAdd=false;
  }
  deleteRootCause(rootcausename) {
    var body = {
      rootname: rootcausename,
    }
    var dialogRef = this.dialog.open(ConfirmationWindowComponent, {

      data: {
        header: 'Delete Rootcause',
        primarybtn: 'Delete',
        message: "Are you sure, want to delete?",
        cancelbtn: 'Cancel',
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res.isOk) {
        this.Allthresholdfromdb = this.threshService.deleterootcause(body);
        //   this.adminService.deleteUser(body).subscribe(res=>{

        //   this.snackBar.open("Rootcause deleted successfully", "", {
        //     duration: 2500,
        //     verticalPosition: 'top'
        //   });
        // });
      }
    });
  }
  EditRootcause(rootcausename) {
    var body = {
      rootname: rootcausename,
    }

    var OpenType = "EDIT";
    if (this.datatype == "n")
      this.CenterFrequency = Constants.Octave_N_FC;
    else
      this.CenterFrequency = Constants.Octave_V_FC;
    this.NoiseVibDisable = true;
    var rootcauseList = this.threshService.getrootcauselist();
    rootcauseList = rootcauseList.filter(obj => obj.rootcause == rootcausename);

    var dialogoverallRef = this.dialog.open(AddRootcauseComponent, {
      width: '1500px!important',
      height: '500px',
      disableClose: true,
      data: {
        header: 'Edit Root cause',
        primarybtn: 'Update',
        cancelbtn: 'Cancel',
        rootcause: rootcauseList[0],
        octaveFreq: this.CenterFrequency,
        formdata: this.thresholdform.value,
        OpenType: OpenType
      }
    });
    dialogoverallRef.afterClosed().subscribe(res => {

      this.Allthresholdfromdb = this.threshService.UpdateRootcauseColor(res);
      //this.threshService.UpdateRootcauseColor(res)
      //  this.getCompanyUser();
      if (res != '') {
        this.snackBar.open("Data updated successfully.", "", {
          duration: 2500,
          verticalPosition: 'top'
        });
      }

    });
  }
  deleteAnalysis(rootcause, AnalysisName) {
    var body = {
      rootcauseName: rootcause,
      analysisName: AnalysisName,
    }
    var dialogRef = this.dialog.open(ConfirmationWindowComponent, {

      data: {
        header: 'Delete Analysis',
        primarybtn: 'Delete',
        message: "Are you sure, want to delete?",
        cancelbtn: 'Cancel',
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res.isOk) {
        this.Allthresholdfromdb = this.threshService.deleteAnalysis(body);
        //   this.adminService.deleteUser(body).subscribe(res=>{

        //   this.snackBar.open("Rootcause deleted successfully", "", {
        //     duration: 2500,
        //     verticalPosition: 'top'
        //   });
        // });
      }
    });
  }


}
