export class User {
    CompanyName:string;
    BusinessUnit:string;
    Email:string;  
    FName:string;
    LName:string;
    Status:string;
    Group:string;  
    IsEdl:boolean;
    IsEol:boolean;
    IsSd:boolean;
    IsEdlAdmin:boolean;
    IsEdlWeb:boolean;
    IsEdlMobile:boolean;
    IsEolAdmin:boolean;
    IsEolWeb:boolean;
    IsEolMobile:boolean;
    IsSdAdmin:boolean;
    IsSdWeb:boolean;
    IsSdMobile:boolean;
    ValidityEdl:string; 
    ValidityEol:string; 
    ValiditySd:string; 
    Role:string

    IsTap:boolean;
    IsEnable:boolean;
    IsAdmin:boolean;
    IsWeb:boolean;
    IsMobile:boolean;
    Validity:string; 
}

export class UserList { 
    CompanyName: string;   
    BusinessUnit: string;
    Validity: string;
    Web: string;
    Mobile: string;
    Storage: string;
    MaxStorage:string;
    DaysAgo:string;
    UserList: User[]= [];   
}

export class StorageList { 
    CompanyName: string;   
    BusinessUnit: string;
    MaxStorage: string;
    UsedStorage: string;
}

export class EDLList{
    EDLUserList:UserList[]=[];
}  
export class EOLList{
    EOLUserList:UserList[]=[];
}  

export class SDList{
    SDUserList:UserList[]=[];
}  

export class Storage{
    Storage:StorageList[]=[];
}  
 

export class AppUserInfo{    
    UserCompanyName: string;   
    UserBusinessUnit: string;
    Email:string;  
    FName:string;
    Role:string;
    LName:string;
    Status:string;
    IsAppTap:string;
    IsEnable:boolean;
    IsAdmin:boolean;
    IsWeb:boolean;
    IsMobile:boolean;
    Validity: string;
}

export class AppCompanyUser{
    CompanyName: string;   
    BusinessUnit: string;
    DaysAgo: string;
    Web: string;
    IsAppTap:string;
    Mobile: string;
    Storage: string;
    MaxStorage:string;
    UserList: AppUserInfo[]= [];
}

export class AppProductUsers{
    AppCompany: AppCompanyUser[]= [];
}

export class AppCompanyThreashold{    
    CompanyName: string;   
    BusinessUnit: string;
    MachineId:string;  
    TestType:string;
    Thresholds:string;
    Type:string;
  thresholdList:ThreasholdInfo[]=[];
}
export class ThreasholdInfo{
  
    MachineId:string;  
    TestType:string;
    Thresholds:string;
    Type:string;
    DualThreshold:boolean;
}
export class AppProductThresholds{
    Appthreshold: AppCompanyThreashold[]= [];
}


export class RootDataTags{
    root:boolean;
    rootDesc:string;
}

export class NVHDataTags{
    nvh:boolean;
    nvhDesc:string;
    
}
export class VehicleModel{
    Vehicle:boolean;
    VehicleName:string;
}

export class TestType{
    Test:boolean;
    TestName:string;
    
}