import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../commonFiles/services/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './app-spinner.component.html',
  styleUrls: ['./app-spinner.component.scss']
})
export class AppSpinnerComponent implements OnInit {

  showLoader: boolean =false;
  constructor(private spinnerService: SpinnerService) {
    
    spinnerService.getMessage().subscribe(t => this.showLoader = t);
  }

  ngOnInit() {
  }

}
