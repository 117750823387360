import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../../auth/admin.service';
import { MatSort, MatPaginator, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import Constants from '../../commonFiles/constant/Constants';
import { ShowAccessComponent } from '../../commonFiles/modalwindow/show-access/show-access.component';
import { environment } from '../../../environments/environment'; 
import { ConfirmationWindowComponent } from '../../commonFiles/modalwindow/confirmation-window/confirmation-window.component';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { validateCheckBox } from '../../commonFiles/utility/common.validator'; 
import { AuthService } from '../../auth/auth.service';
import * as JWT from 'jwt-decode';
import { SubappPermissionComponent } from '../../commonFiles/modalwindow/subapp-permission/subapp-permission.component';

export interface PeriodicElement {
  email: string;
  company: string;
  position: number;
  bu: string;
  phone: string;
  validity: string;
  userstatus: string
}
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  Userdata: any = {};
  errorMessage: string
  Admin = false;
  Web = false;
  Mobile = false;
  currentMail
  private emailRegex = "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  Userdatasource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['email', 'firsname', 'lastname', 'role', 'userstatus', 'action'];
  selection = new SelectionModel<PeriodicElement>(true, []);
  filterValues = {};
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  form: FormGroup;
  inputmail: string

  constructor(private adminService: AdminService, 
    public snackBar: MatSnackBar,
    private authService: AuthService,
    private dialog: MatDialog, 
    private fb: FormBuilder,
    ) {
     
  }
  disabled
  display
  ngOnInit() {  
    this.form = this.fb.group({
      inputmail: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      isAdmin: [''],
      isWeb: [''],
      isMobile: ['']
    }, {
      validator: [validateCheckBox('isAdmin', 'isWeb', 'isMobile')]
    });
    this.getCompanyUser(); 
    this.authService.validateUser();
  }


  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched)
    );
  }
  getCompanyUser() {
    let userdata: any[] = [];
    var position = 0;
    this.adminService.geAdminUserList("edl").subscribe(users => {
   
      
      users.Users.forEach(userdt => {
        position += 1;
        var userid = userdt.Username;
        var status = ""
        var email = ""
        var phone = ""
        var web = false;
        var mobile = false;
        var edl = ""
        var eol = "";
        var sd = "";
        var role = "";
        var firstname = "";
        var isRole=false;
        var isEdlEnable =false;
        var lastname = "";
        let validity: string
        status = userdt.UserStatus;
        
        userdt.Attributes.forEach(attr => {
          if (attr.Name == "given_name")
          {
          firstname = attr.Value.trim().toLowerCase();}
          if (attr.Name == "family_name"){
          lastname = attr.Value.trim().toLowerCase();}
          if (attr.Name == "email")
            email = attr.Value.trim().toLowerCase();
          if (attr.Name == "phone_number")
            phone = attr.Value;
          if (attr.Name == "custom:validity")
            validity = new Date(parseInt(attr.Value) * 1000).toString();
          if (attr.Name == "custom:edlweb")
            web = attr.Value==='True'?true:false;
          if (attr.Name == "custom:edlmobile")
            mobile = (attr.Value==='True')?true:false;
          if (attr.Name == "custom:edl")
            edl = attr.Value;
          if (attr.Name == "custom:eol")
            eol = attr.Value;
          if (attr.Name == "custom:sd")
            sd = attr.Value;
          if (attr.Name == "custom:edladmin"){
            role = attr.Value == 'True' ? 'Admin' : '';      
            if(role==='Admin')   
              isRole = true;
          }
          if (attr.Name == "custom:edlenable")
            isEdlEnable = attr.Value == 'True' ? true : false;      
        });

         
var allRoles = role + ( mobile!=false?  role!=""?' Mobile ':'Mobile'  + '' : '' )    +   (web !=false?  ' '+ 'Web':'') 
        if (!this.checkCurrentUser(email))
          userdata.push({ userid: userid, position: position, email: email, firstname: firstname, lastname: lastname, phone: phone, validity: validity, userstatus: status, web: web, mobile: mobile, edl: edl, eol: eol, sd: sd, role: allRoles,isAdmin:isRole ,isenabled:isEdlEnable});
      });
      this.Userdatasource.data = userdata;
      this.Userdatasource.paginator = this.paginator;
      this.Userdatasource.sort = this.sort; 
    })
  }



  onGroupchange(event, group) {
    if (event.checked && group == 'Admin') {
      this.Admin = true;
      this.Mobile = true;
      this.Web = true; 
    }
    else if(group == 'Admin')
    {
      this.Mobile = false;
      this.Web = false;
    }

    
  }

  checkCurrentUser(email) {
    if (localStorage.getItem(Constants.STORAGE.EMAIL_ADDRESS).toLowerCase() == email.toLowerCase()) {
      return true;
    }
    return false;
  }

  getUserData() {
    var email = this.inputmail;
    var company = localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME);
    var bu = localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT);
    var decodedtoken = JWT( localStorage.getItem(Constants.STORAGE.IDTOKEN));
    
    var userdata = {
      company: company,
      
      businessunit: bu,
      emailid: email,
      adminemail: localStorage.getItem(Constants.STORAGE.EMAIL_ADDRESS),      
      isadminsuper: false,  
      apps:{ 
        edl: {
          web: this.form.controls.isWeb.value== true ? true : (this.form.controls.isAdmin.value===true)?true:false,
          validity:decodedtoken["custom:edlvalidity"],
          mobile:  this.form.controls.isMobile.value== true ? true : (this.form.controls.isAdmin.value===true)?true:false,
          admin: this.form.controls.isAdmin.value == true ? true : false,
      },     
     },      
    }
    return userdata;
  }
  SendInvite() {    
    var data = this.getUserData()
    if (data != null) {
      this.adminService.SendInvite(data).subscribe(res => {
        if (res.ResponseMetadata.HTTPStatusCode === 200) {
          this.form.reset();
          this.getCompanyUser();
      }
      });
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.Userdatasource.filter = filterValue.trim().toLowerCase();
  }
   

  resendInvite(user) {
    var body = {      
      emailid: user.email,
      adminemail: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
      company: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
      businessunit:localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
      isadminsuper:  false ,
      subapp: "edl"
    }
    var dialogRef = this.dialog.open(ConfirmationWindowComponent, {
      width:'400px',
      data: {
      
        header: 'Resend user Invite',
        primarybtn: 'Re-Send',
        message: "Are you sure, want to resent invite this user?",
        cancelbtn: 'Cancel',
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res.isOk) {
        this.adminService.resendInvite(body).subscribe(sendslist => {
          this.snackBar.open("User invitation resend successfully ", "", {
            duration: 2500,
            verticalPosition: 'top'
          });

        });
      }
    });
  }
 


  editRole(company){     
    var body=
      {
        emailid: company.email,
        company: localStorage.getItem('companyname'),
        businessunit: localStorage.getItem('businessunit'),
        subapp: "edl",
        adminrole: company.isAdmin,
        isadminsuper: false
      }
    
      var dialogRef = this.dialog.open(SubappPermissionComponent, {
          width:'450px',
          data:{
            header:'Sup App Permission',
            data:body,
            primarybtn: 'Edit',
            cancelbtn: 'Cancel',
          }     
      });
      dialogRef.afterClosed().subscribe(res => {     
          if (res.isOk) {
            this.getCompanyUser();
            this.snackBar.open(res.message, "", {
              duration: 2500,
              verticalPosition: 'top'
            });
          }
      });
  }
  editAccess(user) {
    var body=
        {
        email: user.email,
        company: localStorage.getItem('companyname'),
        businessunit: localStorage.getItem('businessunit'),
        adminemail: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
        enable: user.isenabled,
        subapp: "edl",
        web: user.web,
        mobile: user.mobile,
        isadminsuper: false,
        disable:false
        }

    var dialogRef = this.dialog.open(ShowAccessComponent, {
      width:'40vw',
      data:{
        header:'Edit Access Rights',
        data:body,
        primarybtn: 'Edit',
        cancelbtn: 'Cancel',
        issuperAdmin:false
      }     
    });
    dialogRef.afterClosed().subscribe(res => {     
      if (res.isOk) {       
        this.getCompanyUser();
        this.snackBar.open(res.message, "", {
          duration: 2500,
          verticalPosition: 'top'
        });
      }
    });
  }


  disableEnableUser(user) {
    var body=
        {
        email: user.email,
        company: localStorage.getItem('companyname'),
        businessunit: localStorage.getItem('businessunit'),
        adminemail: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
        enable: user.isenabled,
        subapp: "edl",
        web: user.web,
        mobile: user.mobile,
        isadminsuper: false,
        disable:true
        }

    var dialogRef = this.dialog.open(ShowAccessComponent, {
      width:'40vw',
      data:{
        header:'Edit Access Rights',
        data:body,
        primarybtn: 'Edit',
        cancelbtn: 'Cancel',
        issuperAdmin:false,
       
      }     
    });
    dialogRef.afterClosed().subscribe(res => {     
      if (res.isOk) {       
        this.getCompanyUser();
        this.snackBar.open(res.message, "", {
          duration: 2500,
          verticalPosition: 'top'
        });
      }
    });
  }

}
