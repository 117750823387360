import { Component, OnInit } from '@angular/core';
import { MatDialogRef, ErrorStateMatcher } from "@angular/material";
import { FormGroup, FormBuilder,FormControl, ValidatorFn, AbstractControl, ValidationErrors, FormGroupDirective, NgForm } from '@angular/forms';
import { SpectrogramFilterServiceService } from '../shared/spectrogram-filter-service.service';
import { spectrogramSettings } from './spectrogram-filter-model';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-spectrogram-filter',
  templateUrl: './spectrogram-filter.component.html',
  styleUrls: ['./spectrogram-filter.component.scss']
})
export class SpectrogramFilterComponent  implements OnInit  {
  
  form: FormGroup;
  min
  max
  minFrequency
  maxFrequency
  minTime
  maxTime
  OriginalSettings:spectrogramSettings;
  FilteredSettings:spectrogramSettings;
  PRFilteredSettings:spectrogramSettings;
  constructor( private fb: FormBuilder,
    public authService:AuthService,
    private dialogRef: MatDialogRef<SpectrogramFilterComponent>,
    private data :SpectrogramFilterServiceService) { 
      
    }
  MinMaxRangeValidator(min: number, max: number): ValidatorFn {

      return (control: AbstractControl): { [key: string]: boolean } | null => {
  
          if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {
  
              return { 'colorRange': true };
  
          }
  
          return null;
  
      };
  
  }
  
  static MinMaxChildrenRange(c: FormGroup): ValidationErrors | null {
    
    if (c.get('min').value === ""||c.get('max').value === "") {
        return { empty: true};
    }
    let min = Number(c.get('min').value);
    let max = Number(c.get('max').value);
    if(min >max){
    return {InRange:true};}
    if(min > max-10){ 
      return {diffRange: true};
    }
    return null;
}
static MinMaxFrequencyChildrenRange(c: FormGroup): ValidationErrors | null {
  if (c.get('minFrequency').value === ""||c.get('maxFrequency').value === "") {
      return { empty: true};
  }
 
  if(Number(c.get('minFrequency').value) > Number(c.get('maxFrequency').value)){
  return {InRange:true};}
  if(Number(c.get('minFrequency').value) > Number(c.get('maxFrequency').value)-500){ 
    return {diffRange: true};
  }
  return null;
}

 static MinMaxTimeChildrenRange(c: FormGroup): ValidationErrors | null {
  if (c.get('minTime').value === ""||c.get('maxTime').value === ""){ 
      return { empty: true};
  }
  if(Number(c.get('minTime').value) > Number(c.get('maxTime').value))
  return {InRange:true};
  if(Number(c.get('minTime').value) > Number(c.get('maxTime').value)-2){ 
    return {diffRange: true};
  }
  return null;
}
  ngOnInit() {
    // get original and filtered values
    this.data.OriginalSettingsObservable.subscribe(originalSettings => this.OriginalSettings = originalSettings);
     this.data.FilteredSettingsObservable.subscribe(filteredSettings => this.FilteredSettings = filteredSettings);
     this.data.PRFilteredSettingsObservable.subscribe(PRfilteredSettings => this.PRFilteredSettings = PRfilteredSettings);

    // initialise form and validators
  this.form = this.fb.group({
    MinMaxGroup: this.fb.group({
      min: [this.FilteredSettings.minValue, [this.MinMaxRangeValidator(this.OriginalSettings.minValue, this.OriginalSettings.maxValue)]],
      max: [this.FilteredSettings.maxValue, [this.MinMaxRangeValidator(this.OriginalSettings.minValue, this.OriginalSettings.maxValue)]],
    },{ validator:  SpectrogramFilterComponent.MinMaxChildrenRange}),
    MinMaxFrequencyGroup: this.fb.group({
    minFrequency : [this.FilteredSettings.minFrequency,[this.MinMaxRangeValidator(this.OriginalSettings.minFrequency, this.OriginalSettings.maxFrequency)]],
    maxFrequency : [this.FilteredSettings.maxFrequency,[this.MinMaxRangeValidator(this.OriginalSettings.minFrequency, this.OriginalSettings.maxFrequency)]],
  },{ validator:  SpectrogramFilterComponent.MinMaxFrequencyChildrenRange}),
  MinMaxTimeGroup: this.fb.group({
    minTime : [this.FilteredSettings.minTime,[this.MinMaxRangeValidator(this.OriginalSettings.minTime, this.OriginalSettings.maxTime)]],
    maxTime : [this.FilteredSettings.maxTime,[this.MinMaxRangeValidator(this.OriginalSettings.minTime, this.OriginalSettings.maxTime)]],
  },{ validator:  SpectrogramFilterComponent.MinMaxTimeChildrenRange}),
   
});
this.authService.validateUser();
  }

  save(){
if(this.form.controls.MinMaxGroup.errors==null &&this.form.controls.MinMaxFrequencyGroup.errors==null &&this.form.controls.MinMaxTimeGroup.errors==null )
{
  this.dialogRef.close(this.form.value);
}
else{
   return;
} 
  }

  close() {
    this.dialogRef.close();
}

}
class crossFieldErrorValidator implements ErrorStateMatcher{
  isErrorState(control:FormControl | null,form:FormGroupDirective | NgForm |null): boolean{

return control.dirty && form.invalid;
  }
}
