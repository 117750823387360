import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AppConfigService } from './app-initializer.service';
import Constants from '../constant/Constants';
import { ErrorService } from './error.service';
import { AuthService } from '../../auth/auth.service';


export class BaseLayerService {
  ErrorMessage;

  private isError = new Subject();
  constructor(
    public errorService: ErrorService,
    protected apiBase?: string,
    protected http?: HttpClient,
    protected spinnerService?: SpinnerService,
    protected router?: Router,
    protected appConfig?: AppConfigService,
    protected authService?: AuthService,
  ) { }

  protected get<T>(method: string, params?: HttpParams | { [param: string]: string | string[]; }) {
    const callBack = new Observable<T>(cd => {
      this.spinnerService.showSpinner();
      this.http.get<T>(this.getUrl(this.apiBase, method)).subscribe(
        response => {
          this.responseOk(response);
          cd.next(response);
          cd.complete();
          this.spinnerService.hideSpinner();
        },
        error => {
          this.spinnerService.hideSpinner();
          this.responseError(error);
        });
    });
    return callBack;
  }

  protected post<T>(method: string, body: any, params?: HttpParams | { [param: string]: string | string[]; }) {
    const callBack = new Observable<T>(cd => {
      this.spinnerService.showSpinner();
      this.http.post<T>(this.getUrl(this.apiBase, method), body).subscribe(
        response => {
          this.responseOk(response);
          cd.next(response);
          cd.complete();
          this.spinnerService.hideSpinner();
        },
        error => {
          this.spinnerService.hideSpinner();
          this.responseError(error);
        });
    });
    return callBack;
  }
  protected put<T>(method: string, body: any, params?: HttpParams | { [param: string]: string | string[]; }) {

    const callBack = new Observable<T>(cd => {
      this.spinnerService.showSpinner();
      this.http.put<T>(this.getUrl(this.apiBase, method), body).subscribe(
        response => {
          this.responseOk(response);
          cd.next(response);
          cd.complete();
          this.spinnerService.hideSpinner();
        },
        error => {
          this.spinnerService.hideSpinner();
          this.responseError(error);
        });
    });
    return callBack;
  }

  protected delete<T>(method: string, body: any, params?: HttpParams | { [param: string]: string | string[]; }) {
    const options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem(Constants.STORAGE.TOKEN),
        'Content-Type': 'application/json'
      }),
      body: body
    }
    const callBack = new Observable<any>(cd => {
      this.spinnerService.showSpinner();
      this.http.delete<any>(this.getUrl(this.apiBase, method), options).subscribe(
        response => {
          this.responseOk(response);
          cd.next(response);
          cd.complete();
          this.spinnerService.hideSpinner();
        },
        error => {
          this.spinnerService.hideSpinner();
          this.responseError(error);
        });
    });
    return callBack;
  }

  // To show the error message, when reponse bad execution result (user handled exception)
  private responseOk(response: any) {


  }


  // To Show the error message, when reponse bad execution result (user unhandled exception)
  private responseError(error: any) {

    if (error.status === 401) {
      if (error.url.indexOf('authenticated') !== -1) {
        throw error;
      } else {
        this.isError.next(error.error.Message);
      }
      //this.router.navigate(['/denied-access']);
    }
    else if (error.status === 403) {
      if (error.error.Message == "Access denied") {
        this.isError.next('You are not authorized to login. Please contact administrator');
        this.errorService.setResponsePostMessage('You are not authorized to login. Please contact administrator');
      } else {
        this.isError.next(error.error.Message);
        this.errorService.setResponsePostMessage(error.error.Message);
      }
    }
    else if (error.error != null && error.error.isOk === false && error.error.message !== '') {
      //this.notifierInfo.error(error.error.message);

      this.errorService.setResponsePostMessage(error.error.Message);
      this.isError.next(error.error.Message);
      console.error(error.error.message);
    } else if (error.statusText === 'Unknown Error') { // show message when cannot connect to server
      // this.notifierInfo.error('Server cannot be reached');
      this.isError.next('Server cannot be reached');
      //this.ErrorMessage ='Server cannot be reached';
    } else if (error.status === 400) {

      if (error.error.Message != "Internal Error") {
        this.isError.next(error.error.Message);
        this.errorService.setResponsePostMessage(error.error.Message);
        if (error.error.Message != "true" || error.error.Message != true) {

          this.spinnerService.setErrorMessage(error.error.Message);
        }
      }
    }
    else {

      this.isError.next(error);
      console.error(error);
      this.authService.logOut();
    }
  }

  public getUrl(apiBase: any, method: any) {
    var url = this.gethostname(apiBase);
    return url + "/" + method;
  }


  public getError() {
    return this.isError.asObservable();
  }
  gethostname(url) {
    if (window.location.hostname == 'localhost') {
      return StringFormat(url, 'prod');
    }
    else if (window.location.hostname.indexOf('dev') !== -1) {
      return StringFormat(url, 'dev');
    }
    else if (window.location.hostname.indexOf('test') !== -1) {
      return StringFormat(url, 'test');
    }
    else {
      return StringFormat(url, 'prod');
    }
  }

}

const StringFormat = (str: string, ...args: string[]) =>
  str.replace(/{(\d+)}/g, (match, index) => args[index] || '')
