import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import {  MatDialog } from '@angular/material';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { environment } from '../../environments/environment';
import Constants from '../commonFiles/constant/Constants';
import { MustMatch } from '../commonFiles/utility/common.validator';
import { TermsComponent } from '../terms/terms.component';
import { AdminService } from '../auth/admin.service';
export class NewPasswordUser {
  username: string;
  oldPassword: string;
  password: string;
}

@Component({
  selector: 'app-set-first',
  templateUrl: './set-first.component.html',
  styleUrls: ['./set-first.component.scss']
})
export class SetFirstComponent implements OnInit {
  registrationUser: NewPasswordUser;
  router: Router;
  form: FormGroup;
  errorMessage: string;
  loading = false;
  constructor(private auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    private adminService: AdminService,    
    private dialog: MatDialog) {
    this.router = router;
    this.onInit();
  }

  onInit() {


    this.form = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.compose([Validators.required, Validators.pattern(new RegExp(environment.PasswordPattern))])],
      confirmpassword: ['', Validators.compose([Validators.required, Validators.pattern(new RegExp(environment.PasswordPattern))])],     
    }
      , {
        validator: [MustMatch('password', 'confirmpassword')]
      });
    this.form.controls['userName'].disable();

    this.form.controls['userName'].setValue(localStorage.getItem(Constants.STORAGE.EMAIL_ADDRESS));
    this.errorMessage = null;

  }

  ngOnInit() {
    this.errorMessage = null;
    localStorage.setItem(Constants.UserInfo.ULLASATATUS,"decline");
    console.log("Checking if the user is already authenticated. If so, then redirect to the secure site");
  }

  onRegister() {
    this.loading = true;
    this.errorMessage = null;

    var serviceModal = {
      username: this.form.controls.userName.value,
      password: this.form.controls.password.value,
      oldPassword: localStorage.getItem("temp"),
    };



    this.auth.SetScreenNewPassword(serviceModal, this);
  }

  cognitoCallback(message: any, result: any) {
    
 

    if (result != null) {
        localStorage.setItem(Constants.STORAGE.REFRESHTOKEN, result.refreshToken.token);
        localStorage.setItem(Constants.STORAGE.TOKEN, result.accessToken.jwtToken);      
        localStorage.setItem(Constants.STORAGE.ACCESSTOKEN, result.accessToken.jwtToken);
        localStorage.setItem(Constants.STORAGE.IDTOKEN, result.idToken.jwtToken);
        localStorage.setItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME, result.idToken.payload["custom:company"]);
        localStorage.setItem(Constants.UserInfo.BUSINESS_UNIT, result.idToken.payload["custom:businessunit"]);      
    

        if (result.idToken.payload["cognito:edlvalidity"] != '' ||result.idToken.payload["cognito:edlvalidity"] != undefined && result.idToken.payload["custom:edlvalidity"].length >= 10) {
          localStorage.setItem("EDLValidity",result.idToken.payload["custom:edlvalidity"]);
        }
        if (result.idToken.payload["cognito:eolvalidity"] != undefined && result.idToken.payload["custom:eolvalidity"].length >= 10) {
          result.idToken.payload.setItem("EOLValidity",result.idToken.payload["custom:eolvalidity"]);
        }
        if (result.idToken.payload["cognito:sdvalidity"] != undefined &&result.idToken.payload["custom:sdvalidity"].length >= 10) {
          localStorage.setItem("SDValidity",result.idToken.payload["custom:sdvalidity"]);
        }


        var bodyValidy = {
          company:  localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
         
          businessunit: localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
          emailid: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
          apps: {
              edl:{
                  validity: parseInt(result.idToken.payload["custom:edlvalidity"])
                  }, 
              eol:{
                  validity: parseInt(result.idToken.payload["custom:eolvalidity"]!=result.idToken.payload["custom:eolvalidity"]!=undefined?0:result.idToken.payload["custom:eolvalidity"])
                  },
              sd:{
                  validity: parseInt(result.idToken.payload["custom:sdvalidity"]!=result.idToken.payload["custom:sdvalidity"]!=undefined?0:result.idToken.payload["custom:eolvalidity"])
                }
            }
        };
        

        var body={
        
            emailid:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
            company:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
            businessunit:localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
          
        }
        this.adminService.getUlla(body).subscribe(res=>{
         
              localStorage.setItem(Constants.UserInfo.APPVERSION,res.success.Message.version);      
        const dialogRef = this.dialog.open(TermsComponent, {
          width: '650px',
         disableClose:true,
         data:{
          header:"terms"
        },
        });

        dialogRef.afterClosed().subscribe(res => {
          
          if(!res.isOk){
            var bodyAccept={
              emailid:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
              company:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
              businessunit:localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
              status:"decline",
              version:Number(localStorage.getItem(Constants.UserInfo.APPVERSION))
            }
            this.adminService.setUlla(bodyAccept).subscribe(res=>{
              if(res.ResponseMetadata.HTTPStatusCode===200){
                this.auth.logOut();
              }
            });

          }
          else{
            var bodyAccept={
              emailid:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
              company:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
              businessunit:localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
              status:"accept",
              version:Number(localStorage.getItem(Constants.UserInfo.APPVERSION))
            }
            this.adminService.setUlla(bodyAccept).subscribe(res=>{
              if(res.ResponseMetadata.HTTPStatusCode===200){
                var body={
                  emailid:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
                  company:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
                  businessunit:localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
                }
                /** Get Ulla  */
                this.adminService.getUlla(body).subscribe(res=>{
                  /** Update Validity for first time login */
                  localStorage.setItem(Constants.UserInfo.ULLASATATUS,res.success.Message.status);
                  localStorage.setItem(Constants.UserInfo.APPVERSION,res.success.Message.version);

                  this.adminService.setValidity(bodyValidy).subscribe(res=>{
                    this.auth.loggedIn.next(true);
                    this.auth.isfirstlogin =true;
                    
                    this.auth.loginState = true;     
                    this.router.navigate(['/profile']);    
                  });  

                });
              }
            });
         


      
      }
      });
    });
    }
    if (message != null) { //error
      this.errorMessage = message;
      this.loading = false;
    } 
  }
 

  get f() {
    return this.form.controls;
  }
}
