import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AdminService } from '../auth/admin.service';
import { AuthService } from '../auth/auth.service';
import  Constants from '../commonFiles/constant/Constants';
import { AppCompanyThreashold, AppProductThresholds, ThreasholdInfo } from '../commonFiles/model/superadmin.model';
import {TypeconfirmComponent} from '../typeconfirm/typeconfirm.component'
import {Threshold} from '../commonFiles/model/threshold.model'
import{ConfirmationWindowComponent} from '../commonFiles/modalwindow/confirmation-window/confirmation-window.component';
import { ResizeEvent } from 'angular-resizable-element';
import {ThresholdService} from '../threshold/threshold.service'
import { ErrorService } from '../commonFiles/services/error.service';

@Component({
  selector: 'app-threshold',
  templateUrl: './threshold.component.html',
  styleUrls: ['./threshold.component.scss']
})

export class ThresholdComponent implements OnInit {

  dynamicTabName;
  displayedColumns: string[] = [ 'machineid', 'testtype', 'userstatus','action'];

  setTapName:string;
  showedTap;
   appCompanyThresholdOut :AppCompanyThreashold=new  AppCompanyThreashold();
 appProductThresholds :AppProductThresholds =new AppProductThresholds();

  saveStorage=[];
  saveLicence=[];
  saveUserData=[];

  DialogNoiseorVib:string;
  DialogVibration:boolean=false;
  labelWeb = 'Web';
  labelMobile = 'Mobile';
  labelStorage = 'Storage';
  labelValitdy = 'Validity';

  IsSuperAdmin:Boolean;
  constructor(private router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private authService: AuthService,
    private adminService: AdminService,
    public errorService: ErrorService,
    public threshService: ThresholdService,) { }

  ngOnInit() {
        // EDL is renamed to EDAV
    this.dynamicTabName = ['EDAV', 'EOL', 'SD'];    
    this.isTabClick(0);
    this.errorService.setResponseEdlMessage(true); 
  }

  isTabClick(num) {
    this.showedTap =num;
    switch (num) {      
      case 0: // EDL is renamed to EDAV
      this.setTapName ="EDL";
    
        break;
      case 1: // EOL
      this.setTapName ="EOL";
        break;
      case 2: // SD
      this.setTapName ="SD";
        break;
       
    }
 
    localStorage.setItem("ThreshSelectedTab", this.setTapName);
    this.saveStorage =[];
    this.saveLicence=[];
    this.saveUserData=[];
    this.CheckIsSuperAdmin();
    this.fillthresholdData();  
  }
  
   groupBy( array , f )
  {
    var groups = {};
    array.forEach( function( o )
    {
      var group = JSON.stringify( f(o) );
      groups[group] = groups[group] || [];
      groups[group].push( o );  
    });
    return Object.keys(groups).map( function( group )
    {
      return groups[group]; 
    })
  }
  CheckIsSuperAdmin()
	{
if(localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME)=="bosch" && localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT)=="superadmin")
this.IsSuperAdmin=true;
else
this.IsSuperAdmin=false;	
}
  fillthresholdData() {
     
    let funProdctThreshold =new AppCompanyThreashold();
    this.adminService.getallthresholds(this.setTapName.toLowerCase()).subscribe(res => { 
    
 let companythreshold=new AppCompanyThreashold();
 let thresholdinfo=new ThreasholdInfo();
 let appCompanyThreshold=new AppCompanyThreashold();
 let arraycompany=[];
 this.appProductThresholds.Appthreshold =[]; 
 if(res.length>0)
 {
   
   if(this.IsSuperAdmin==false)
{
  res=res.filter(obj=>obj.company==localStorage.getItem("companyname") && obj.businessunit==localStorage.getItem("businessunit"))
}
  var result = this.groupBy(res, function(item)
  {
    return [item.company, item.businessunit];
  });

   for(var i in result)
   {
    if(result[i].length>0)
     {
      appCompanyThreshold=new AppCompanyThreashold();
    appCompanyThreshold.CompanyName =   result[i][0].company;  
    appCompanyThreshold.BusinessUnit =  result[i][0].businessunit;  
    
    var groupedlist=result[i];
    for(var j in groupedlist)
    {
      thresholdinfo=new ThreasholdInfo();
      thresholdinfo.Type=groupedlist[j].datatype;
      thresholdinfo.MachineId=groupedlist[j].machineid;
      thresholdinfo.TestType=groupedlist[j].testtype;
      appCompanyThreshold.thresholdList.push(thresholdinfo);
    }
   
    arraycompany.push(appCompanyThreshold); 
     }
   }
   for(var index in arraycompany)
{
this.appProductThresholds.Appthreshold.push(arraycompany[index]);
}
 }
 else{
  this.appProductThresholds.Appthreshold=[];
 }  
    });
}
getThresholdToedit(CompanyName,BusinessUnit,MachineId,TestType,subapp,datavalue)
{
  this.adminService.getthresholddata(CompanyName,BusinessUnit,MachineId,TestType,this.setTapName.toLowerCase(),datavalue).subscribe(res=>
    { 
      this.threshService.passdataTochild(res.success.Message.Items)
    this.router.navigateByUrl('/add-threshold', { state: res.success.Message.Items })

    });
}
deleteThreshold(threshold,CompanyName,BusinessUnit)
{
  
  var body=new Threshold();
  body.company=CompanyName;
  body.businessunit=BusinessUnit;
  body.subapp=this.setTapName.toLowerCase();
  body.datatype=threshold.Type;
  body.machineid=threshold.MachineId;
  body.testtype=threshold.TestType;
  var dialogRef = this.dialog.open(ConfirmationWindowComponent, {
       
    data: {
      header: 'Delete threshold',
      primarybtn: 'Delete',
      message: "Are you sure, want to delete?",
      cancelbtn: 'Cancel',
    }
  });
  dialogRef.afterClosed().subscribe(res => {
      if(res.isOk) 
      {
     this.adminService.DeleteThresholdData(body).subscribe(res => {
    if (res.ResponseMetadata.HTTPStatusCode == 200) {
      
       this.snackBar.open("Threshold deleted successfully.", "", {
         duration: 2500,
         verticalPosition: 'top'
       });
       this.ngOnInit();
    }
    else
    {
      this.snackBar.open("Some error occured while deleting threshold.", "", {
        duration: 2500,
        verticalPosition: 'top'
      });
    }
  });
      }
  });
  
}

  editThreshold(threshold, CompanyName, BusinessUnit) {
    debugger
    let datavalue:any;
    if(threshold.DualThreshold==true)
    {
var dialogRef = this.dialog.open(TypeconfirmComponent, {
  width:'500px',
  height:'250px',
  
  data:{
    type:"n",
    header:'Edit Noise or Vibration?',
    primarybtn: 'Proceed',
    cancelbtn: 'Close',
  }
 
});

dialogRef.afterClosed().subscribe(res => {    
 
  if (res.isOk) {
    datavalue=res.data.type;
    this.isTabClick(this.showedTap);    
    this.snackBar.open(res.message, "", {
      duration: 2500,
      verticalPosition: 'top'
    });
  this.getThresholdToedit(CompanyName,BusinessUnit,threshold.MachineId,threshold.TestType,this.setTapName.toLowerCase(),datavalue)

  }
});
  
    }
 else{
  if(threshold.Type=="n")
  datavalue="n";
  else
  datavalue="v";
  this.getThresholdToedit(CompanyName,BusinessUnit,threshold.MachineId,threshold.TestType,this.setTapName.toLowerCase(),datavalue)

 }
  }
 
  AddThreshold() {
  
    this.router.navigate(['/add-threshold'])
      .then(() => {
        window.location.reload();
      });
    //this.router.navigate(['/add-threshold']);
  }

  onResizeEnd(event: ResizeEvent, columnName): void {
    if (event.edges.right) {
     const cssValue = event.rectangle.width + 'px';
     const columnElts = document.getElementsByClassName('mat-column-' + columnName);
     //this.columnWidths[columnName.field] = event.rectangle.width;
     for (let i = 0; i < columnElts.length; i++) {
       const currentEl = columnElts[i] as HTMLDivElement;
       currentEl.style.width = cssValue;
     }
    }
 }
 
}
