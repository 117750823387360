import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { AppConfigService } from '../../services/app-initializer.service';
import { environment } from '../../../../environments/environment';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { DownloadService } from '../../../shared/download.service';
import { animate, state, style, transition, trigger } from '@angular/animations';


export class FileData {
  url: string;
  fileName: string;
  fileType!: string;
  uniqueId!: string;
  downloadProgress!: number;
  isCompleted!: boolean;
  isSelected!: boolean;
  status!: string;
}


export class DownloadItem {
  url: string;
  name: string;
  files: FileData[] = [];
}

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', minHeight: "*" })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FileDownloadComponent implements OnInit, OnDestroy {

  s3: any;
  processedFiles: any[] = [];
  rawFiles: any[] = [];
  fallbackFiles: any = {};
  selectAll = false;

  constructor(
    private dialogRef: MatDialogRef<FileDownloadComponent>,
    public appconfig: AppConfigService,
    private translate: TranslatePipe,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DownloadItem[],
    private downloadService: DownloadService
  ) {
    // Diasable/ Enable modal dialog close, when click out side the modal
    dialogRef.disableClose = true;
  }

  async ngOnInit() {
    this.s3 = await this.appconfig.LoadAWSS3Config(false);
    this.rawFiles = environment.RAW_FILES;
    this.processedFiles = environment.PROCESSED_FILES;
    this.fallbackFiles = environment.FALLBACK_FILE_LIST;
  }


  queueDownload() {
    this.rawFiles.forEach(o => {
      if (o.isSelected) {
        this.data.forEach(item => {
          const fileData = new FileData();
          fileData.fileName = o.fileName;
          fileData.fileType = o.fileType;
          this.triggerDownload(item, fileData);
        });
      }
    });

    this.processedFiles.forEach(o => {
      if (o.isSelected) {
        this.data.forEach(item => {
          const fileData = new FileData();
          fileData.fileName = o.fileName;
          fileData.fileType = o.fileType;
          this.triggerDownload(item, fileData);
        });
      }
    });
  }

  triggerDownload(item, file) {
    const fileData = new FileData();
    fileData.fileName = file.fileName;
    const params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: item.url + '/' + file.fileName };
    this.s3.headObject(params, (err, metadata) => {
      if (err && err.code === 'NotFound') {
        if (file.fileName == "audio.mp3") {
          file.fileName = "audio.wav";
          fileData.fileName = file.fileName;
          const params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: item.url + '/' + file.fileName };
          this.s3.getSignedUrlPromise('getObject', params).then(url => { fileData.url = url; this.downloadService.fileSelected.emit(fileData); });
        } else {
          if (this.fallbackFiles[file.fileName]) {
            params.Key = item.url + '/' + this.fallbackFiles[file.fileName];
            this.s3.getSignedUrlPromise('getObject', params).then(url => { fileData.url = url; this.downloadService.fileSelected.emit(fileData); });
          }
        }
      } else {
        this.s3.getSignedUrlPromise('getObject', params).then(url => { fileData.url = url; this.downloadService.fileSelected.emit(fileData); });
      }
    });
  }




  reject() {
    this.dialogRef.close({
      isOk: false,
      data: { status: 'cancel' }
    });
  }

  setAll(completed: boolean) {
    this.selectAll = completed;
    this.rawFiles.forEach(o => o['isSelected'] = completed);
    this.processedFiles.forEach(o => o['isSelected'] = completed);
  }

  ngOnDestroy() {
    this.selectAll = false;
    this.rawFiles.forEach(o => o['isSelected'] = false);
    this.processedFiles.forEach(o => o['isSelected'] = false);
  }

}


