import { Injectable, EventEmitter} from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  data: any;
// lang: any;
  activeLangChanged = new EventEmitter();
  private _activeLang = '';
  private _fallbackLang = 'en';

  constructor(private http: HttpClient) { }

  use(lang: string): Promise<{}> {
    return new Promise<{}>(
      (resolve, reject) => {
        const path = `assets/i18n/${lang || 'en'}.json`;

        this.http.get<{}>(path).subscribe(
          translation => {
            this.data = Object.assign({}, translation || {});
            resolve(this.data);
          },
          err => {
            this.data = {};
            resolve(this.data);
          }
        );
      }
    );
  }

  public active(lang: any): any {
    
    if (lang) {
    localStorage.setItem('lang', lang);
   }

    const oldValue = this._activeLang;

    const newValue = localStorage.getItem('lang') || this._fallbackLang;
    


    if (oldValue !== newValue) {
      this._activeLang = newValue;
      this.use(newValue).then(
        () => {
          this.activeLangChanged.next({
            
            oldValue: oldValue,
            newValue: newValue
          });
        }
      );
    }
  }
  ngOnInit(){
    localStorage.getItem('lang');
  //   this._activeLang = JSON.parse(localStorage.getItem('lang'));
  //   localStorage.removeItem('lang'); // to clear it again.
  // }
  }
}
