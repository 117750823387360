import { Injectable } from "@angular/core";
import Constants from "../commonFiles/constant/Constants";
import { AppConfigService } from "../commonFiles/services/app-initializer.service";
import { Observable } from "rxjs";
import { SpinnerService } from "../commonFiles/services/spinner.service";
import { BaseLayerService } from "../commonFiles/services/BaseLayerService";
import { Router } from "@angular/router";
import { TranslateService } from "../commonFiles/services/translate.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ErrorService } from "../commonFiles/services/error.service";

@Injectable()
export class AdminService extends BaseLayerService {
  constructor(
    public router: Router,

    protected translate: TranslateService,
    protected spinner: SpinnerService,
    private appconfig: AppConfigService,
    public errorService: ErrorService,
    protected http: HttpClient
  ) {
    super(
      errorService,
      environment.AWSINFO.Userurl,
      http,
      spinner,
      router,
      appconfig
    );
  }

  GetCompanyList(): Observable<any> {
    var body = {
      adminemail: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
    };
    return this.post<any>("companylist", body);
  }
  GetBUList(companyname): Observable<any> {
    var body = {
      adminemail: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
      company: companyname,
    };
    return this.post<any>("bulist", body);
  }
  GetVehicleModel(companyName, businessUnit, subapp): Observable<any> {
    return this.get<any>(
      "models?company=" +
        companyName +
        "&businessunit=" +
        businessUnit +
        "&subapp=" +
        subapp
    );
  }
  AddCustomer(companydata): Observable<any> {
    return this.post<any>("company", companydata);
  }

  SendInvite(userdata): Observable<any> {
    return this.post<any>("user", userdata);
  }

  Getuserlist(): Observable<any> {
    var suemail = localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL);
    return this.get<any>("allusers?adminemail=" + suemail);
  }
  GetCompanyuserlist(): Observable<any> {
    var groupname = localStorage.getItem(Constants.STORAGE.GROUPNAME);
    var suemail = localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL);

    return this.get<any>(
      "userslist?adminemail=" + suemail + "&groupname=" + groupname
    );
  }
  ChangeRole(isadmin, email) {
    var body = {
      username: email,
      groupname: localStorage.getItem(Constants.STORAGE.GROUPNAME),
      adminemail: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
      adminrole: isadmin,
    };
    return this.post<any>("adminrole", body);
  }
  DeleteProject(project) {
    var body = {
      company: localStorage
        .getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME)
        .split("-")[0],

      businessunit: localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
      subapp: "edl",
      machineid: project.MachineId,
      t: project.epochdate,
      username: project.Userid,
      adminemail: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
    };
    return this.delete<any>("metadata", body);
  }
  GetProject() {
    return this.get<any>(
      "metadata?company=" +
        localStorage
          .getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME)
          .split("-")[0] +
        "&businessunit=" +
        localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT) +
        "&subapp=edl"
    );
  }

  getAdminUser(): Observable<any> {
    var groupname = localStorage.getItem(Constants.STORAGE.GROUPNAME);
    var suemail = localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL);
    return this.get<any>(
      "userslist?adminemail=" + suemail + "&groupname=" + groupname
    );
  }

  setTerms() {
    var body = {
      access: localStorage.getItem(Constants.STORAGE.TOKEN),
      terms: true,
    };
    return this.post<any>("terms", body);
  }

  getSuperAdminUserList(app, isAdmin = true) {
    var body = {
      adminemail: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
      subapp: app,
      company: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
      businessunit: localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
      isadminsuper: true,
    };
    return this.post<any>("users", body);
  }

  geAdminUserList(app) {
    var body = {
      adminemail: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
      subapp: app,
      company: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
      businessunit: localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
      isadminsuper: false,
    };
    return this.post<any>("users", body);
  }
  resendInvite(body) {
    return this.post<any>("resend", body);
  }
  getValidity(company, bUnit) {
    return this.get<any>(
      "validity?company=" + company + "&businessunit=" + bUnit
    );
  }

  getLicence(subApp) {
    return this.get<any>("license?subapp=" + subApp);
  }
  getallthresholds(subApp) {
    return this.get<any>("allthresholds?subapp=" + subApp);
  }
  getthresholddata(
    company,
    businessunit,
    machineid,
    testtype,
    subApp,
    datatype
  ) {
    return this.get<any>(
      "threshold?company=" +
        company +
        "&businessunit=" +
        businessunit +
        "&machineid=" +
        machineid +
        "&testtype=" +
        testtype +
        "&subapp=" +
        subApp +
        "&datatype=" +
        datatype
    );
  }

  thresholdadd(thresholdata): Observable<any> {
    return this.post<any>("threshold", thresholdata);
  }
  thresholdupdate(thresholdata): Observable<any> {
    var result = this.put<any>("threshold", thresholdata);
    return result;
  }
  DeleteThresholdData(thresholdata): Observable<any> {
    var result = this.delete<any>("threshold", thresholdata);
    return result;
  }

  updateLicence(body) {
    return this.put<any>("license", body);
  }
  setValidity(body) {
    return this.post<any>("validity", body);
  }

  updateValidyEdl(edlValidy): Observable<any> {
    var body = {
      company: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),

      businessunit: localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
      emailid: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
      apps: {
        edl: {
          validity: edlValidy,
        },
      },
    };
    return this.post<any>("validity", body);
  }

  updateValidyEol(edlValidy): Observable<any> {
    var body = {
      company: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),

      businessunit: localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
      emailid: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
      apps: {
        eol: {
          validity: edlValidy,
        },
      },
    };
    return this.post<any>("validity", body);
  }

  updateValidySd(edlValidy): Observable<any> {
    var body = {
      company: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),

      businessunit: localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
      emailid: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
      apps: {
        sd: {
          validity: edlValidy,
        },
      },
    };
    return this.post<any>("validity", body);
  }

  updateValidity(body) {
    return this.put<any>("validity", body);
  }

  userAdd(companydata): Observable<any> {
    return this.post<any>("user", companydata);
  }

  updateProfile(firstName, lastName, phnoeno) {
    var body = {
      firstname: firstName,
      lastname: lastName,
      emailid: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
      phoneno: phnoeno,
    };
    return this.put<any>("profile", body);
  }

  setMFA(mailId, isenabled): Observable<any> {
    var body = {
      emailid: mailId,
      enabled: isenabled,
    };
    return this.post<any>("mfa", body);
  }

  updateStorage(body) {
    return this.put<any>("storage", body);
  }
  deleteUser(body) {
    return this.delete<any>("user", body);
  }

  deleteCurrentaccount() {
    return this.delete<any>("user", null);
  }

  getStorage(subApp) {
    return this.get<any>("storage?subapp=" + subApp);
  }

  getUlla(body) {
    return this.post<any>("eula", body);
  }

  setUlla(body) {
    return this.put<any>("eula", body);
  }

  updateplatform(body) {
    return this.put<any>("platform", body);
  }

  updateuserstatustoggle(body) {
    return this.put<any>("userstatustoggle", body);
  }
  updateadminrole(body) {
    return this.post<any>("adminrole", body);
  }

  getDataTags(company, businessunit, subApp) {
    return this.get<any>(
      "tags?company=" +
        company +
        "&businessunit=" +
        businessunit +
        "&subapp=" +
        subApp
    );
  }
  getVehicleModel(company, businessunit, subApp) {
    return this.get<any>(
      "models?company=" +
        company +
        "&businessunit=" +
        businessunit +
        "&subapp=" +
        subApp
    );
  }
  getTestTypes(company, businessunit, subApp) {
    return this.get<any>(
      "testtypes?company=" +
        company +
        "&businessunit=" +
        businessunit +
        "&subapp=" +
        subApp
    );
  }

  updateDataTags(body) {
    return this.put<any>("tags", body);
  }
  UpdateVehicleModel(body) {
    debugger;
    return this.put<any>("models", body);
  }
  UpdateTestTypes(body) {
    debugger;
    return this.put<any>("testtypes", body);
  }

  getPrivacy() {
    return this.get<any>("privacy");
  }

  getDataProtection() {
    return this.get<any>("dataprotection");
  }

  logoutAllDevices() {
    var body = {
      lf: "w",
    };
    return this.post<any>("verifylogin", body);
  }
}
