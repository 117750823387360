import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AdminService } from '../../auth/admin.service';
import Constants from '../../commonFiles/constant/Constants';
import { ErrorService } from '../../commonFiles/services/error.service';
import { CompanyRegisterationComponent } from '../../commonFiles/modalwindow/companyregisteration/companyregisteration.component';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  ErrorMessage
  companies
  BuList
  isEdlEnable = false;
  isEolEnable = false;
  isSdEnable = false;

  EdlValidity = "";
  EolValidity = "";
  SdValidity = "";

  noLicence
  form: FormGroup;
  constructor(private fb: FormBuilder,
    private adminService: AdminService,
    private authService: AuthService,
    private errorService: ErrorService,
    public snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.errorService.getResponsePostMeassage().subscribe(errormessagedisplay => {
if(errormessagedisplay!="A group with the name already exists.")
      this.ErrorMessage = errormessagedisplay;
    });
  }

  private emailRegex = "[a-zA-Z0-9-.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  ngOnInit() {

    this.form = this.fb.group({
      emailId: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      businessUnit: ['', Validators.required],
      companyName: ['', Validators.required],
      validityEdl: ['0'],
      validityEol: ['0'],
      validitySd: ['0'],
      isEdl: [true],
      isEol: [true],
      isSd: [true],
      isAdminEdl: true,
      isWebEdl: true,
      isMobileEdl: true,
      isAdminEol: true,
      isWebEol: true,
      isMobileEol: true,
      isAdminSd: true,
      isWebSd: true,
      isMobileSd: true

    }); 
    this.filCompany();
    this.disabledAccess = true;
    this.authService.validateUser();
  }
  disabledAccess

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched)
    );
  }
  onGroupchange(event, group) {
    
  }
  onAdminCheck(event, module, group) {
    if (event.checked && group == "admin" && module == "Edl") {
      this.setEDLCheckBox(true);
    } else if (!event.checked && group == "admin" && module == "Edl") {
      this.setEDLCheckBox(false);
    }

    if (event.checked && group == "admin" && module == "Eol") {
      this.setEOLCheckBox(true);
    } else if (!event.checked && group == "admin" && module == "Eol") {
      this.setEOLCheckBox(false);
    }

    if (event.checked && group == "admin" && module == "Sd") {
      this.setSDCheckBox(true);
    } else if (!event.checked && group == "admin" && module == "Sd") {
     this.setSDCheckBox(false);
    }
  }
  setSDCheckBox(value){
    this.form.controls['isWebSd'].setValue(value);
      this.form.controls['isMobileSd'].setValue(value);
  }

  setEDLCheckBox(value){
    this.form.controls['isWebEdl'].setValue(value);
    this.form.controls['isMobileEdl'].setValue(value);
  }

  setEOLCheckBox(value){
    this.form.controls['isWebEol'].setValue(value);
    this.form.controls['isMobileEol'].setValue(value);
  }
  filCompany() {
    this.adminService.GetCompanyList().subscribe(data => {
      this.companies = data.success.Message;
    });
  }
  CompanySelect() {    
    if (this.form.controls.companyName.value.length > 0) {
      this.adminService.GetBUList(this.form.controls.companyName.value.trim()).subscribe(data => {
        this.BuList = data.success.Message;
      });
    }
  }


  SendInvite() {
    
    if (this.form.controls.emailId.value === "") {
      this.snackBar.open("Please enter email Id", "", {
        duration: 2500,
        verticalPosition: 'top'
      });
      return;
    }
   
    if (this.form.controls.companyName.value === "") {
      this.snackBar.open("Please select company name", "", {
        duration: 2500,
        verticalPosition: 'top'
      });
      return;
    }
    if (this.form.controls.businessUnit.value === "") {
      this.snackBar.open("Please select business name", "", {
        duration: 2500,
        verticalPosition: 'top'
      });
      return;
    }
    var body;
    if (this.form.controls.businessUnit.value==="superadmin" && this.form.controls.companyName.value==="bosch" )
      body = this.superAdminObj();
    else
      body = this.superUserObj();
    this.adminService.userAdd(body).subscribe(res => {
      if (res.ResponseMetadata.HTTPStatusCode == 200) {
        
        this.router.navigate(['/users'])
      }
    });

  }
  superUserObj() {

    if(this.isEdlEnable){
      var edl ={      
          validity: this.EdlValidity.length ===10?this.EdlValidity:this.form.controls.validityEdl.value,
          web:  true,
          mobile: true,
          admin:true,       
      }
    }

    if(this.isEolEnable){
      var eol ={      
          validity: this.EolValidity.length ===10?this.EolValidity:this.form.controls.validityEol.value,
          web:  true,
          mobile: true,
          admin:true,       
      }
    }


    if(this.isSdEnable){
      var sd ={      
          validity: this.SdValidity.length ===10?this.SdValidity:this.form.controls.validitySd.value,
          web:  true,
          mobile: true,
          admin:true,       
      }
    }
    var obj = {
      company: this.form.controls.companyName.value,
    
      businessunit: this.form.controls.businessUnit.value,
      emailid: this.form.controls.emailId.value,
      adminemail: localStorage.getItem(Constants.STORAGE.EMAIL_ADDRESS),
      isadminsuper: true,
      apps: {
        edl: 
          edl
        ,
        eol: 
         eol
        ,
        sd: 
         sd
        
      }
    }
 
    if(obj.apps.edl===undefined)
    delete obj.apps.edl;
    if(obj.apps.eol===undefined)
    delete obj.apps.eol;
    if(obj.apps.sd===undefined)
    delete obj.apps.sd;
    


    return obj;
    }

   
  superAdminObj() {
    var obj = {
      company: this.form.controls.companyName.value,
      
      businessunit: this.form.controls.businessUnit.value,
      emailid: this.form.controls.emailId.value,
      adminemail: localStorage.getItem(Constants.STORAGE.EMAIL_ADDRESS),
      isadminsuper: true,
      apps: {
         edl: {
          validity: "200",
          web:  true,
          mobile: true,
          admin:true,
        },
        eol: {
          validity:"200",
          web:true,
          mobile: true,
          admin: true,
        },
        sd: {
          validity: "200"    ,
          web: true,
          mobile: true,
          admin: true,
        }
      }
    }
    
    return obj


  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  cancel(){
    this.router.navigate(['/users'])
  }

  CreateCustomer(option) {
    if (option == 'company') {
      var dialogRef = this.dialog.open(CompanyRegisterationComponent, {
        width: '40vw',
        data: {
          header: 'Add New Company',
          primarybtn: 'Add',
          cancelbtn: 'Cancel',
          data: '',
          isCompanydialogue: true
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res.isOk) {
          if (res.isCompanydialogue) {
            this.filCompany();
            this.ErrorMessage  ="";
            this.snackBar.open("Company added successfully", "", {
              duration: 2500,
              verticalPosition: 'top'
            });
          }
        }
      });
    }
    else {
      if (this.form.controls.companyName.value == "" || this.form.controls.companyName.value == null) {
        this.snackBar.open("Please select company name", "", {
          duration: 2500,
          verticalPosition: 'top'
        });
        return;
      }
      var dialogCompany = this.dialog.open(CompanyRegisterationComponent, {
        width: '40vw',
        data: {
          header: 'Add New Business Unit',
          primarybtn: 'Add',
          cancelbtn: 'Cancel',
          data: '',
          CompanyName: this.form.controls.companyName.value,
          isCompanydialogue: false
        }
      });
      dialogCompany.afterClosed().subscribe(res => {
        if (res.isOk) {
         
            this.CompanySelect();
            this.snackBar.open("Business unit added successfully", "", {
              duration: 2500,
              verticalPosition: 'top'
            });
      
        }
      });
    }
    this.ErrorMessage  ="";
  }
  checkSuperAdmin = false;
  businessSelect() {
   
    if (this.form.controls.companyName.value.toLocaleLowerCase() == "bosch" && this.form.controls.businessUnit.value.toLocaleLowerCase() == "superadmin") {     
      this.checkSuperAdmin = true;
    }
    else {
      this.fillValidity();
      this.checkSuperAdmin = false;
    }
  }
  EdlValidityDate
  EolValidityDate
  SdValidityDate

  EdlValidityEpoch
  EolValidityEpoch
  SdValidityEpoch
  fillValidity() {    
    if (this.form.controls.companyName.value.length > 0) {
      this.adminService.getValidity(this.form.controls.companyName.value.trim(),this.form.controls.businessUnit.value.trim()).subscribe(data => {
        if(data.ResponseMetadata.HTTPStatusCode ===200)
        {
          this.isEdlEnable = data.ResponseMetadata.subapp.edl.enable ;
          this.isEolEnable = data.ResponseMetadata.subapp.eol.enable ;
          this.isSdEnable = data.ResponseMetadata.subapp.sd.enable ;

          this.EdlValidity = data.ResponseMetadata.subapp.edl.edlvalidity.toString() ;
          this.EolValidity = data.ResponseMetadata.subapp.eol.eolvalidity.toString() ;
          this.SdValidity = data.ResponseMetadata.subapp.sd.sdvalidity.toString() ;
      
          this.form.controls['validityEdl'].setValue(this.EdlValidity);
          this.form.controls['validityEol'].setValue(this.EolValidity);
          this.form.controls['validitySd'].setValue(this.SdValidity);

          if(this.EdlValidity.length ===10)
          {
            this.EdlValidityDate = this.epochToJsDate(this.EdlValidity);
            this.form.controls['validityEdl'].setValue(this.EdlValidityDate);
          }
          if(this.EolValidity.length ===10)
          {
            this.EolValidityDate = this.epochToJsDate(this.EolValidity);
            this.form.controls['validityEol'].setValue(this.EolValidityDate);
          }
          if(this.SdValidity.length ===10)
          {
            this.SdValidityDate = this.epochToJsDate(this.SdValidity);
            this.form.controls['validitySd'].setValue(this.SdValidityDate);
            this.SdValidityEpoch=this.SdValidityDate;
          }

       

        if(this.SdValidity!='-1'){
          this.form.controls['validitySd'].disable();
        }
        else{
          this.form.controls['validitySd'].enable();
          this.form.controls['validitySd'].setValue('');
        }
        if(this.EolValidity!='-1')
          this.form.controls['validityEol'].disable();
        else{
          this.form.controls['validityEol'].enable();
          this.form.controls['validityEol'].setValue('');
        }
        if(this.EdlValidity!='-1')
          this.form.controls['validityEdl'].disable();
        else{
          this.form.controls['validityEdl'].enable();
          this.form.controls['validityEdl'].setValue('');
        }

        }
        
      });
    }

   
  }

   epochToJsDate(ts){
    // ts = epoch timestamp
    // returns date obj
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

    var date =  new Date((ts*1000))
var format = this.formatAMPM(date);
    return date.getDate() +' ' +monthNames[date.getMonth()] + ' '+date.getFullYear()+ ',' + ' ' +format;
}
formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

}
