import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import {UserSettingsComponent} from '../../../user-settings/user-settings.component'
import { AuthService } from '../../../auth/auth.service';
import Constants from '../../../commonFiles/constant/Constants';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {

  UserId:string;
  displayname:string;
  constructor(public dialog: MatDialog,private authService:AuthService)
  {
     this.displayname=localStorage.getItem(Constants.UserInfo.DISPLAYNAME)
  }
  Signout()
  {
    
    this.authService.signOut();
  }
  openUsersDialog()
  {
    const dialogRef = this.dialog.open(UserSettingsComponent, {
      height: '93%',
      width: '50%',
      position:{ top: '70px',right:'100px'},
      data: { UserId: this.UserId},
    
    });
   
    dialogRef.afterClosed().subscribe(result => {});
   
  }
  
}
