
import {  EDLDashboard } from '../dashboard/dashboardModels'
import { Subject, ReplaySubject } from 'rxjs';



export class DataService {

    setResponsePostMessage(message) {
        this.responsePost.next(message);
    }
    getResponsePostMeassage() {
        return this.responsePost.asObservable();
    }
    public responsePost = new Subject<any[]>();

    SelectedVehicleInfo: Subject<any> = new ReplaySubject<EDLDashboard[]>();
    MeasurementTypeInfo: Subject<any> = new ReplaySubject<any>();
    //enl = english;
    constructor() {
        let storedProp = localStorage.getItem('storedProp');

        if (storedProp)
            this.SetVehicelInfo(JSON.parse(storedProp), false);
    }

    setVehicleResponsePostMessage(message) {

        this.responseVehiclePost = message;
        localStorage.setItem('measuremantdata', JSON.stringify(message))
    }
    getVehicleResponsePostMeassage() {

        return localStorage.getItem('measuremantdata');
    }
    public responseVehiclePost = new EDLDashboard();

    public setImage(message) {
        this.MeasurementTypeInfo.next(message)
    }
    public getImage() {
        return this.MeasurementTypeInfo.asObservable()
    }
    public setImage1(message) {
        this.MeasurementTypeInfo.next(message)
    }
    public getImage1() {
        return this.MeasurementTypeInfo.asObservable()
    }
    public SetVehicelInfo(vehicleInfo: any, storeProp: boolean = false) {
        if (storeProp)
            localStorage.setItem('storedProp', JSON.stringify(vehicleInfo));
        this.SelectedVehicleInfo.next(vehicleInfo);
    }
} 