import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { AppConfigService } from '../../services/app-initializer.service';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { AdminService } from '../../../auth/admin.service';
import Constants from '../../constant/Constants';

@Component({
  selector: 'app-CompanyRegisteration',
  templateUrl: './show-access.component.html',
  styleUrls: ['./show-access.component.scss']
})
export class ShowAccessComponent implements OnInit {
 
 
  UserTypes: any;
  disabled=true;
isSuperadmin=true;
  constructor(
    private dialogRef: MatDialogRef<ShowAccessComponent>,
     public appconfig: AppConfigService,
     private translate: TranslatePipe,
     private adminService: AdminService,
     public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
 console.log(this.data.data)
    // Diasable/ Enable modal dialog close, when click out side the modal
    dialogRef.disableClose = true;
  this.disabled =true;
  }

  ngOnInit() {
   this. UserTypeInfo();
  
  }
  
  
  UserTypeInfo() {
  
    this.UserTypes = [
     
      { name: this.translate.transform("Mobile"), checked: this.data.data.mobile  }, 
      { name: this.translate.transform("Web"), checked: this.data.data.web },
    ]
  }


  accept() {  
    
    if(this.data.data.disable===false){
    var body={
          adminemail:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
          company:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
          businessunit:localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
          subapp:"edl",
          web:this.UserTypes[1].checked,
          mobile:this.UserTypes[0].checked,
          isadminsuper:this.data.issuperAdmin,
          emailid:this.data.data.email
        } 
     this.adminService.updateplatform(body).subscribe(res=>{
        if(res.ResponseMetadata.HTTPStatusCode==200)
        {
          this.dialogRef.close({
            isOk: true,
            message: 'Permission updated succesfully'
          });
        }
     });
    }
    else if(this.data.data.disable===true){
      var bodyDisable={
        adminemail:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
        company:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
        businessunit:localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
        subapp:"edl",
        web:this.UserTypes[1].checked,
        mobile:this.UserTypes[0].checked,
        isadminsuper:this.data.issuperAdmin,
        emailid:this.data.data.email,
        enable:!this.data.data.enable,
      } 
   this.adminService.updateuserstatustoggle(bodyDisable).subscribe(res=>{
      if(res.ResponseMetadata.HTTPStatusCode==200)
      {
        this.dialogRef.close({
          isOk: true,
          message: 'Permission updated succesfully'
        });
      }
   });
    }
  }

  reject() {
    this.dialogRef.close({
      isOk: false,      
      data: {status:'cancel'}
    });
  }
}
