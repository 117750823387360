import { Injectable, EventEmitter } from '@angular/core';


export class FileData {
  url: string;
  fileName: string;
  fileType!: string;
  uniqueId!: string;
  downloadProgress!: number;
  isCompleted!: boolean;
  status!: string;
}

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  fileList: string[];

  fileSelected = new EventEmitter<FileData>();

  downloadStatus = new EventEmitter<FileData[]>();

  clearDownloads = new EventEmitter<any>();
  
  getDownloadStatus = new EventEmitter<any>();
  constructor() { }


}
