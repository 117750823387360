import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '../commonFiles/services/translate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from './../auth/auth.service';
import { MatDialog } from '@angular/material';
import Constants from '../commonFiles/constant/Constants';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ErrorService } from '../commonFiles/services/error.service';
import { AdminService } from '../auth/admin.service';
import { TermsComponent } from '../terms/terms.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  private formSubmitAttempt: boolean;
  passwordsDoNotMatch: boolean;
  translateLangs = [];
  isTranslate: boolean;
  loading = false;
  ErrorMessage;
  redirectUrl;
  private emailRegex = "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";

  constructor(
    private translateService: TranslateService,
    protected http: HttpClient,
    private router: Router,
    public errorService: ErrorService,
    public adminService: AdminService,
    private dialog: MatDialog,
    private authService: AuthService,
    private route: ActivatedRoute, private fb: FormBuilder,
  ) {
    this.authService.setLangdefault();

    // this.pageTitleService.pageTitle = this.appConfigService.applicationName;
    this.isTranslate = false;
    this.errorService.getResponsePostMeassage().subscribe(errormessagedisplay => {

      if (errormessagedisplay != true) {
        this.ErrorMessage = errormessagedisplay;
      }


    });
  }
  currentURL
  ngOnInit() {
    this.authService.setLangdefault();
    this.form = this.fb.group({
      userName: [null, Validators.compose([Validators.required, Validators.pattern(this.emailRegex)])],
      password: ['', Validators.compose([Validators.required, Validators.pattern(new RegExp(environment.PasswordPattern))])],
    });
    this.translateLogo();
  }

  translateLogo() {
    for (var languageInfo = 0; languageInfo < environment.translateLang.length; languageInfo++) {
      this.translateLangs.push(environment.translateLang[languageInfo]);
    }
    this.isTranslate = environment.istranslate;
  }
  ngAfterViewInit(): void {

    this.authService.signOut();
    this.route.queryParams.subscribe(params => {
      this.redirectUrl = params['url'];
    });
    this.authService.setLangdefault();
  }
  async onSubmit() {
    this.loading = true;
    this.ErrorMessage = ""
    if (this.form.valid) {
      localStorage.setItem(Constants.UserInfo.ATTRIBUTES_EMAIL, this.form.value.userName.trim());

      this.authService.signIn(this.form.value.userName.trim(), this.form.value.password.trim())
        .subscribe(
          user => {
            if (this.authService.loginState && localStorage.getItem(Constants.STORAGE.TERMS) != "true" && user.challengeName != 'NEW_PASSWORD_REQUIRED') {
              var body = {
                emailid: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
                company: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
                businessunit: localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
              }

              this.adminService.getUlla(body).subscribe(res => {

                localStorage.setItem(Constants.UserInfo.ULLASATATUS, res.success.Message.status);
                localStorage.setItem(Constants.UserInfo.APPVERSION, res.success.Message.version);
                if (localStorage.getItem("UllaStatus") === "decline") {
                  const dialogRef = this.dialog.open(TermsComponent, {
                    width: '650px',
                    disableClose: true,
                    data: {
                      header: "terms"
                    },
                  });
                  dialogRef.afterClosed().subscribe(resModal => {
                    if (!resModal.isOk) {
                      this.authService.logOut();
                    }
                    else {
                      var body = {
                        emailid: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
                        company: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
                        businessunit: localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
                        status: "accept",
                        version: Number(localStorage.getItem(Constants.UserInfo.APPVERSION))
                      }
                      this.adminService.setUlla(body).subscribe(res => {
                        if (res.ResponseMetadata.HTTPStatusCode === 200) {
                          var body = {
                            emailid: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
                            company: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
                            businessunit: localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
                          }

                          this.adminService.getUlla(body).subscribe(res => {
                            localStorage.setItem(Constants.UserInfo.ULLASATATUS, res.success.Message.status);
                            localStorage.setItem(Constants.UserInfo.APPVERSION, res.success.Message.version);
                            this.router.navigate(["/home"]);
                          });
                        }
                      });
                    }
                  });
                }
                else {
                  this.router.navigate(["/home"]);
                }
              });
            }
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              this.router.navigate(['/set-first']);
            }
          },
          error => {
            this.loading = false;
            this.ErrorMessage = error.message;
          });
    }
    else {
      this.loading = false;
    }
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  // Set language 
  setLang(lang) {
    this.translateService.active(lang);
    this.translateService.activeLangChanged.emit();
  }

}
