
import { AppConfigService } from '../commonFiles/services/app-initializer.service';
import {  Injectable } from '@angular/core';
import { SpectrogramFilterServiceService } from '../shared/spectrogram-filter-service.service';
import { leftMargin, rightMargin, bottomMargin, topMargin, colorMapWidth, colorScale_1 } from './SpectrogramSettings';
import { SpinnerService } from '../commonFiles/services/spinner.service'
import { PRspectrogramSettings } from '../spectrogram-filter/spectrogram-filter-model';
declare var require: any
const bicubic = require('bicubic-interpolate');

@Injectable()
export class ProminenceSpectrogram {
  N: number;
  Fs: number;
  T: number;
  dataRow: number;
  dataColumn: number;
  leftMargin: number;
  rightMargin: number;
  bottomMargin: number;
  topMargin: number;
  colorScale: number[];
  colorMapWidth: number;
  PRminColorValue: number = Number.POSITIVE_INFINITY;
  PRmaxColorValue: number = Number.NEGATIVE_INFINITY;
  PRminFrequencyRow: number;
  PRmaxFrequencyRow: number;
  PRminTimeColumn: number;
  PRmaxTimeColumn: number;
  spectrogramData: number[][];
  ProminenceTimeData:number[][];
  TransposedData: number[][];
  PRTransposedData: number[][];
  Drawablespectrogram: number[][] = [];
  PRDrawablespectrogram: number[][] = [];
  xLabel:string;
  yLabel:string;
  zLabel:string;
  PRxLabel:string;
  PRyLabel:string;
  PRzLabel:string;
  currentctx: any;
  PRcurrentctx: any;
  tempCanvas: any;
  PRtempCanvas: any;
  isspectogrameloaded = false;
  isPRGraphLoaded=false;
  mdata: any;
  constructor(public appconfig: AppConfigService, public data: SpectrogramFilterServiceService, public spinnerService: SpinnerService) {

    this.ProminenceTimeData = [];
    this.TransposedData = [];
  }

 
  InitializeProminenceSpectrum(obj) {
    this.mdata = obj;
    this.isPRGraphLoaded = false;
    let canvas: any;
    this.PRxLabel =obj.promTimeData.xlable;
    this.PRyLabel =obj.promTimeData.ylable;
    this.PRzLabel =obj.promTimeData.zlable;

    canvas = document.getElementById('prvstimecanvas');
    const context = canvas.getContext('2d');
 
    context.clearRect(0, 0, canvas.width, canvas.height);

    setTimeout(() => {

      //margins and colorscale to draw axis and colormap 
      this.leftMargin = leftMargin;
      this.rightMargin = rightMargin;
      this.bottomMargin = bottomMargin;
      this.topMargin = topMargin;
      this.colorMapWidth = colorMapWidth;
      this.colorScale = colorScale_1;

      //transposing array so that it becomes column - Time and row - Frequency
      this.ProminenceTimeData = this.PRTransposedData[0].map((_, colIndex) => this.PRTransposedData.map(row => row[colIndex]));
      this.dataRow = this.ProminenceTimeData.length;
      this.dataColumn = this.ProminenceTimeData[0].length;

      //frequency conversion factor (Fs and N recieved from json file) , this factor converts the row number to equivalent frequency.
      let FreqMultiplicationFactor = this.Fs / this.N;
      //time conversion factor (T is the total time recieved from json file) , this factor converts the column to equivalent time.
      let TimeMultiplicationFactor = Number(this.T) / this.dataColumn;
      let Rows = this.dataRow;
      let pxPerLine = this.dataColumn;

      // Maximum and Minimum values set for graph intialisation
      this.PRminFrequencyRow = 0;
      this.PRmaxFrequencyRow = Rows;
      this.PRmaxTimeColumn = pxPerLine;
      this.PRminTimeColumn = 0;
      this.PRminColorValue = -100;
      this.PRmaxColorValue = 200;

      // initialised original settings for filter service , to set min and max range in filter dialog box 
      let OriginalSettings = new PRspectrogramSettings(this.PRminColorValue, this.PRmaxColorValue, this.PRminFrequencyRow * FreqMultiplicationFactor, this.PRmaxFrequencyRow * FreqMultiplicationFactor,
        this.PRminTimeColumn * TimeMultiplicationFactor, this.PRmaxTimeColumn * TimeMultiplicationFactor);
      this.data.SetOriginalSettings(OriginalSettings);

      // User requirement to show graph initially till 12000 frequency , so 12000 frequency converted to
      // equivalent row in matrix using frequency conversion factor 
        // Rescale range setting for z Axis
      //noise
      if(localStorage.getItem("DataType")=="N"){
        this.PRmaxFrequencyRow = 12000 / FreqMultiplicationFactor;
        this.PRminColorValue = 0;
        this.PRmaxColorValue = 10;
        }
        // Rescale range setting for y zxis Vibration 
        if(localStorage.getItem("DataType")=="V"){
        this.PRmaxFrequencyRow = 6000 / FreqMultiplicationFactor;
        this.PRminColorValue = 0;
        this.PRmaxColorValue = 10;
        }
      //Filtered values initialised for filter service , to show the current graph is filtered to these values
      let filterSettings = new PRspectrogramSettings(this.PRminColorValue, this.PRmaxColorValue, this.PRminFrequencyRow * FreqMultiplicationFactor, this.PRmaxFrequencyRow * FreqMultiplicationFactor,
        this.PRminTimeColumn * TimeMultiplicationFactor, this.PRmaxTimeColumn * TimeMultiplicationFactor);
      this.data.PRSetFilteredSettings(filterSettings);


      this.PRDrawablespectrogram = this.ProminenceTimeData;

      //filter the graph for current values
      this.FilterSpecPromGraph("");



    }, 0);
    this.spinnerService.hideSpinner()
  }

  FilterSpecPromGraph(obj) {

    //get total number of rows and columns(pxperline) to draw on canvas 
    let pxPerLine = Math.floor(this.PRmaxTimeColumn) - Math.floor(this.PRminTimeColumn);
    let Rows = Math.floor(this.PRmaxFrequencyRow) - Math.floor(this.PRminFrequencyRow);

    let tempSpectrogram: number[][] = [];
    this.PRDrawablespectrogram = [];

    //get the part from original matrix to be drawn as new matrix
    for (let row = 0; row < Rows; row++) {
      tempSpectrogram[row] = [];
      this.PRDrawablespectrogram[row] = [];
      for (let px = 0; px < pxPerLine; px++) {

        tempSpectrogram[row][px] = this.ProminenceTimeData[row + Math.floor(this.PRminFrequencyRow)][px + Math.floor(this.PRminTimeColumn)];
        this.PRDrawablespectrogram[row][px] = tempSpectrogram[row][px];

      }
    }
    // interpolate the values as required and draw on the canvas
    this.GeneratePRVsTimeGraph(tempSpectrogram);

  }

  GeneratePRVsTimeGraph(spectrogramData) {
   
    let baseCanvas: any = document.getElementById('prvstimecanvas');
    let baseCtx = baseCanvas.getContext("2d");
    var base: any = document.getElementById('v-pills-prvstime');

    //canvas height and width be equal to enclosing div(base)
    baseCanvas.height = base.offsetHeight;
    baseCanvas.width = base.offsetWidth ;

    

    //drawing width for spectrogram after removing margins for axis labelling and colormap
    let drawingWidth = baseCanvas.width - this.rightMargin - this.leftMargin;
    let drawingHeight = baseCanvas.height - this.bottomMargin - this.topMargin;

    // drawable matrix rows and columns
    let OriginalRows = spectrogramData.length;
    let OriginalPxPerLine = spectrogramData[0].length;

    // interpolator takes a matrix and creates a function to provide interpolation values , extrapolate is true  
    // for boundary values to be considered too 
    // one thing to be noted is this function changes matrix such as to add extrapolation by adding few rows and columns
    let gridInterpolator = bicubic.createGridInterpolator(spectrogramData
      , { extrapolate: true });

    // calculating the size of matrix to be created according to the size of canvas , maximum of them is considered
    let RequiredPxPerLine = OriginalPxPerLine < drawingWidth ? drawingWidth : OriginalPxPerLine;
    let RequiredRows = OriginalRows < drawingHeight ? drawingHeight : OriginalRows;

    // to maintain a descent aspect ratio. 
    // the idea is to create a bigger or equal size matrix as canvas size, using this interpolator function ,
    // and then to downscale using drawImage library (for possible better precision).
    // As RequiredPxPerLine(Time) has significantly less points than  RequiredRows(frequency) , so RequiredPxPerLine(Time)
    // raised to a ratio of RequiredRows(frequency) 
    // This can be verified for specific scenarios or changed to optimise the precision
    let scale = OriginalRows / drawingHeight;
    RequiredPxPerLine = Math.floor(drawingWidth * scale);

    // initialise factors to get values from interpolator function (factors are steps)
    let xCord = 0;
    let yCord = 0;
    let yFactor = OriginalRows / RequiredRows;
    let xFactor = OriginalPxPerLine / RequiredPxPerLine;
    let Interpolatedspectrogram: number[][] = [];

    // temporary canvas to draw the interpolated large image 
    this.PRtempCanvas = document.createElement('canvas');
    this.PRtempCanvas.width = RequiredPxPerLine;
    this.PRtempCanvas.height = RequiredRows;
    let ctx = this.PRtempCanvas.getContext("2d");
    let imgObj = ctx.getImageData(0, 0, this.PRtempCanvas.width, this.PRtempCanvas.height);

    //calculating and drawing the interpolated matrix
    for (let row = 0; row < RequiredRows; row++) {
      Interpolatedspectrogram[row] = [];
      xCord = xCord + yFactor;
      yCord = 0;
      for (let px = 0; px < RequiredPxPerLine; px++) {
        yCord = yCord + xFactor;
        if (xCord > OriginalRows) {
          xCord = Math.floor(xCord);

        }
        if (yCord > OriginalPxPerLine) {
          yCord = Math.floor(yCord);

        }
        // getting interpolated values as required
        let point = gridInterpolator(xCord, yCord);
        Interpolatedspectrogram[row][px] = point;

        //position of pixel to draw the value (from bottom left to bottomright then progressively to top)
        let i = 4 * ((RequiredRows - row - 1) * RequiredPxPerLine + px);

        let rgba = this.getColor(Interpolatedspectrogram[row][px]);
        // lookup color rgba values

        imgObj.data[i] = rgba[0];   // red
        imgObj.data[i + 1] = rgba[1]; // green
        imgObj.data[i + 2] = rgba[2]; // blue
        imgObj.data[i + 3] = rgba[3]; // alpha
      }
    }
    ctx.putImageData(imgObj, 0, 0);

    // Now downscaling  the temporary canvas to fit the size of canvas using drawimage function
    baseCtx.drawImage(this.PRtempCanvas, 0, 0, this.PRtempCanvas.width, this.PRtempCanvas.height, this.leftMargin, this.topMargin, baseCanvas.width - this.rightMargin - this.leftMargin, baseCanvas.height - this.bottomMargin - this.topMargin);

    // saving context to use anywhere else (this will keep last initialised or filtered canvas context to be reused)
    this.PRcurrentctx = this.PRtempCanvas;

    //add the axis and colormap
    this.PRgraphDetails(baseCtx);

  }

  PRgraphDetails(baseCtx: CanvasRenderingContext2D) {

    //label axis with values
    this.DrawAxis(baseCtx);

    //colormap plotting with labels
    this.PRplotColorMap(baseCtx);

    //Naming the axis 
    this.displayAxisName(baseCtx, this.PRxLabel, this.PRyLabel, this.PRzLabel);
    baseCtx.stroke();

    // drawing spectrogram is completed 
    this.isPRGraphLoaded = true;

  }
  DrawLine(baseCtx: CanvasRenderingContext2D, x1: number, x2: number, y1: number, y2: number) {

    baseCtx.moveTo(x1, y1);
    baseCtx.lineTo(x2, y2);

  }
  displayAxisName(baseCtx: CanvasRenderingContext2D, xAxis: string, yAxis: string,zAxis) {
    baseCtx.fillStyle = 'black';
    baseCtx.font = '10pt Calibri';

    // draw x-Axis Name (Time)
    let baseCanvas = baseCtx.canvas;
    let bottomIndex = baseCanvas.height - this.bottomMargin;     
    baseCtx.fillText(xAxis, 500, bottomIndex + 35);

    // draw y-Axis Name (Frequency)
    let leftIndex = this.leftMargin - 60;
    let topIndex = this.topMargin + 150;
    baseCtx.save();
    baseCtx.translate(leftIndex, topIndex);
    baseCtx.rotate(-Math.PI / 2);
    baseCtx.textAlign = "center";
    baseCtx.font = '10pt Calibri';
    baseCtx.fillText(yAxis, 0, 0);

    baseCtx.translate(this.leftMargin-125, topIndex +868);
    baseCtx.rotate(-Math.PI / 190);
    baseCtx.fillText(zAxis, 0, 0);

    
    baseCtx.restore();

  }
  
  PRplotColorMap(baseCtx: CanvasRenderingContext2D) {
  this.PRminColorValue=0;
  this.PRmaxColorValue=10;

   let baseCanvas = baseCtx.canvas;
    let rightIndex = (baseCanvas.width - this.rightMargin);
    let colorMapLength = baseCanvas.height - this.bottomMargin - this.topMargin;
    let factor = this.colorScale.length / colorMapLength;
    let factor2 = this.colorScale.length / (this.PRmaxColorValue - this.PRminColorValue);
    let verticalDist = baseCanvas.height - this.bottomMargin;
    let index = 0;
    let rgba;
    for (let i = 0; i < colorMapLength; i++) {

      index = Math.floor(factor * i);
      rgba = this.convertInttoRGBA(this.colorScale[index]);

      //label after every 25 pixels
      if (i % 25 === 0) {

        baseCtx.font = '10pt Calibri';
        baseCtx.textAlign = 'center';
        baseCtx.fillStyle = "black";
        baseCtx.strokeStyle = "#9b9996";
        baseCtx.lineWidth = 0.5;

        let val = Math.floor((index / factor2) + this.PRminColorValue);

        this.DrawLine(baseCtx, rightIndex + 10, rightIndex + 20, verticalDist, verticalDist);
        baseCtx.fillText(val.toString(), rightIndex + 27, verticalDist + 3);

      }

      //draw color in colormap
      baseCtx.fillStyle = "rgba(" + rgba[0] + "," + rgba[1] + "," + rgba[2] + "," + rgba[3] + ")";
      baseCtx.fillRect(rightIndex +2, verticalDist, 12, 1);
      verticalDist--;

    }
   }
  DrawAxis(baseCtx: CanvasRenderingContext2D) {
    let baseCanvas = baseCtx.canvas;
    baseCtx.font = '10pt Calibri';
    baseCtx.textAlign = 'center';
    baseCtx.fillStyle = "black";
    baseCtx.strokeStyle = "#9b9996";
    baseCtx.lineWidth = 0.5;

    let drawingWidth = baseCanvas.width - this.rightMargin - this.leftMargin ;
    let drawingHeight = baseCanvas.height - this.bottomMargin - this.topMargin ;

    // 10 horizontal divisions 
    let horizontalStep = drawingWidth / 10;
    let diff = (this.PRmaxTimeColumn - this.PRminTimeColumn) * (this.T / this.dataColumn);
    let value_x = diff / 10;

    for (let i = 0; i <= 10; i++) {
      var x = Math.floor(i * horizontalStep); // position to draw
      var value = (i * value_x) + (this.PRminTimeColumn * (this.T / this.dataColumn));// value to draw
      // for drawing x axis
      this.DrawLine(baseCtx, x + this.leftMargin, x + this.leftMargin, baseCanvas.height - (this.bottomMargin - 10), baseCanvas.height - (this.bottomMargin - 3));
      baseCtx.fillText(value.toFixed(1), x + this.leftMargin, baseCanvas.height - (this.bottomMargin - 20));
    }


    // 10 vertical divisions 
    let verticalSteps = drawingHeight / 10;
    let value_y = (this.PRmaxFrequencyRow - this.PRminFrequencyRow) / 10;
    for (let i = 0; i <= 10; i++) {
      var y = Math.floor(i * verticalSteps); // position to draw
      var valueVertical = ((i * value_y) + this.PRminFrequencyRow) * (this.Fs / this.N); // value to draw
      // for drawing y axis
      this.DrawLine(baseCtx, this.leftMargin - 8, this.leftMargin - 2, baseCanvas.height - (y + this.bottomMargin), baseCanvas.height - (y + this.bottomMargin));
      baseCtx.fillText(valueVertical.toFixed(1), (this.leftMargin - 25), baseCanvas.height - (y + this.bottomMargin - 3));
    }
  }
  getColor(pxData: number) {
    // if value out of range show the closest valid range color
    if (pxData <= this.PRminColorValue) {
      return this.convertInttoRGBA(this.colorScale[0]);
    }
    else if (pxData >= this.PRmaxColorValue) {
      return this.convertInttoRGBA(this.colorScale[this.colorScale.length - 1]);
    }

    // map value range to color range over the length  of colormap
    let factor = this.colorScale.length / (this.PRmaxColorValue - this.PRminColorValue);
    let index: number = Math.floor(factor * (pxData - this.PRminColorValue));

    if (index > this.colorScale.length - 1) {
      index = this.colorScale.length - 1;
    } else if (index < 0) {
      index = 0;
    }
    return this.convertInttoRGBA(this.colorScale[index]);



  }
  convertInttoRGBA(Int: number) {

    return [(Int >>> 16) & 0xFF, (Int >>> 8) & 0xFF, Int & 0xFF, (Int >>> 24) & 0xFF];

  }

}