
import { Injectable } from '@angular/core';
import { AppConfigService } from '../commonFiles/services/app-initializer.service';
import { BaseLayerService } from '../commonFiles/services/BaseLayerService';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from '../commonFiles/services/spinner.service';
import { Router } from '@angular/router'; 
import { environment } from '../../environments/environment';
import Constants from '../commonFiles/constant/Constants';
import { ErrorService } from '../commonFiles/services/error.service';

@Injectable()
export class ProfileService extends BaseLayerService {
    constructor(private appconfig: AppConfigService,
        protected http: HttpClient,
        protected spinnerService: SpinnerService,
        protected router: Router,
        public errorService: ErrorService, 
    ) {

        super(errorService,environment.AWSINFO.Userurl, http, spinnerService, router, appconfig)
    }
   

    GetUserProfile(): any {
        var body = {           
            emailid: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
        }
        return this.post<any>('profile',body)
    }

    ChangePassword(oldpsw, newpsw) {
        var body = {
            "oldpassword":oldpsw,
            "newpassword":newpsw,
            "access":localStorage.getItem(Constants.STORAGE.ACCESSTOKEN)
        }
        return this.post<any>('password', body)
    }
}