
import { Component,  ElementRef, OnInit,  Renderer } from '@angular/core';
import { Router } from '@angular/router';
import * as Chartist from 'chartist';
import { ChartType, ChartEvent } from 'ng-chartist'; 
import { MatDialog} from '@angular/material';
import { dashboardService } from './dashboard.service';
import { DataService } from '../shared/data.service';
import { AuthService } from '../auth/auth.service'
import { AppConfigService } from '../commonFiles/services/app-initializer.service';
import { TranslateService } from '../commonFiles/services/translate.service';
import { TranslatePipe } from '../commonFiles/pipe/translate.pipe';

declare var require: any;
const data: any = require('./data.json');
export interface Chart {
	type: ChartType;
	data: Chartist.IChartistData;
	options?: any;
	responsiveOptions?: any;
	events?: ChartEvent;
}

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],

})
export class DashboardComponent implements OnInit {

	_subscription: any;
	heatmapwidth = 600;
	heatmapheight = 400;
	heatmaptype = "heatmap";
	heatmapdataFormat = "json";
	warningcount: number;
	criticalcount: number;
	goodcount: number;
	totalcount: number;
	connectedcount: number;
	offlinecount: number;
	donuteChart1: Chart;
	isloaded: boolean;
	xAxis: Object;
	

	yAxis = {
		labels: Object,
		title: "VIN",

	};
	elementRef: ElementRef;
	ngAfterContentChecked() {
		//this.isloaded=false;
		this.addXaxisEvent();
	}
	ngAfterViewChecked() {
		
	}
	ngAfterViewInit() {

	}
	addXaxisEvent() {


		if (document.getElementById('containerXAxisLabels') != null) {


			if (!this.isloaded) {
				for (var i = 0; i < document.getElementById('containerXAxisLabels').children.length; i++) {
					var xaxisname = document.getElementById('containerXAxisLabels').children[i].innerHTML;

					document.getElementById('containerXAxisLabels').children[i].innerHTML = '<a     style="color:blue; text-decoration:underline;cursor:pointer;" >' + xaxisname + '</a>'
					document.getElementById('containerXAxisLabels').children[i].addEventListener('click', this.ShowMeasurements.bind(this))
				}
				this.isloaded = true;
				return;
			}
		}
	}
	ShowMeasurements(obj) {
		
		this.SetVehiceInfo(obj.target.innerHTML);
		this.router.navigate(['/processed-details'])
	}
	ngOnInit() {
		
	
		this.ShowCurrentdateStatus();

	}

	dataSource: Object[];
	ModelSelected: string;
	Testdate: Date;
	SelectedDate: string;
	constructor(public router: Router, public dialog: MatDialog,
		 public service: dashboardService, 
		 public dataservice: DataService,  
		 public authService: AuthService,
		  elementRef: ElementRef, public renderer: Renderer,
		  public translate: TranslatePipe,
		  public appConfigService: AppConfigService,
		  public translateService: TranslateService
		  ) {
			this.appConfigService.loadConfigOnAppInit();
		this.elementRef = elementRef;
	
	}


	SetVehiceInfo(Vin) {
	
		let vechileData: any;
		//data = new Sharingdata();
		vechileData.Date = this.SelectedDate;
		vechileData.Model = this.ModelSelected;
		vechileData.Testcases = this.yAxis.labels;
		vechileData.Vin = Vin;
		vechileData.isEDL=false;
		this.dataservice.SetVehicelInfo(vechileData, true);
	}
	loadchartsdata() {
		this.getStatusCount();
		this.loadpiechart();

	}
	openDialog(){
		
	}
	ShowCurrentdateStatus() {
		this.ModelSelected = 'Maruthi Dezire';
		this.SelectedDate = '06-Feb-2020';
		var chartsource = null;
		this.LoadAxis(chartsource);
		this.dataSource = chartsource.source;
		this.loadchartsdata();
	}
	getStatusCount() {
		this.warningcount = 0;
		this.criticalcount = 0;
		this.goodcount = 0;
		let arr = [];
		this.dataSource.forEach(x => {
			arr.push(x)

		})
		arr.forEach(x => {
			x.forEach(element => {

				if (parseInt(element) >= 31 && parseInt(element) <= 70) {
					this.warningcount += 1;
				}
				else if (parseInt(element) >= 71 && parseInt(element) <= 99) {
					this.criticalcount += 1;
				}
				else if (parseInt(element) >= 1 && parseInt(element) <= 30) {
					this.goodcount += 1;
				}
				
			});

		});




	}
	LoadAxis(axisdata) {


		this.xAxis = {
			labels: axisdata.xaxis,
			opposedPosition: true,
			title: 'VIN Number'

		};
		this.yAxis.labels = axisdata.yaxis


	}

	primaryXAxis: Object = { title: 'VIN Number' };
	titleSettings = {
		text: 'Sales Revenue per Employee (in 1000 US$)',
		textStyle: {
			size: '15px',
			fontWeight: '500',
			fontStyle: 'Normal',
			fontFamily: 'Segoe UI'
		}
	};
	public paletteSettings: Object = {
		palette: [
			{ startValue: 1, endValue: 30, minColor: '#2fff00', maxColor: '#2fff00' },
			{ startValue: 31, endValue: 70, minColor: '#fdff00', maxColor: '#fdff00' },
			{ startValue: 71, endValue: 99, minColor: '#FF0000', maxColor: '#FF0000' }
		],
		type: "Fixed"
	};
	public legendSettings: Object = {
		visible: true,
		enableSmartLegend: true
	};
	 

	loadpiechart() {
		this.donuteChart1 = {
			type: 'Pie',
			data: {
				series: [this.goodcount, this.warningcount, this.criticalcount]
			}
			,
			options: {
				donut: true,
				height: 260,
				showLabel: false,
				donutWidth: 70
			}
		};
	}

}
