import { Component, OnInit } from '@angular/core';
import Constants from '../commonFiles/constant/Constants';
import { Router } from '@angular/router';
import { ErrorService } from '../commonFiles/services/error.service';
import { AuthService } from '../auth/auth.service';
import { AdminService } from '../auth/admin.service';
import { MatDialog } from '@angular/material';
import { TermsComponent } from '../terms/terms.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  userName

  constructor(private router: Router,
    public errorService: ErrorService,
    public authService: AuthService,
    private dialog: MatDialog,
    public adminService: AdminService) {
      
  }
  ngOnInit() {
    this.userName = localStorage.getItem(Constants.UserInfo.USERNAME);
    if (localStorage.getItem(Constants.UserInfo.USERNAME) === null) {
        this.router.navigate(['/profile']);      
      }
      
    this.authService.validateUser();    
  }


  isEdl() {
    if (localStorage.getItem("edl") == null && localStorage.getItem("edl") == undefined) {     
      return !this.authService.getEDlEnable();
    }
    return localStorage.getItem("edl") === "true" ? false : true;
  }

  isEol() {
    if (localStorage.getItem("eol") == null && localStorage.getItem("eol") == undefined) {
      return !this.authService.getEOlEnable();
    }
    return localStorage.getItem("eol") === "true" ? false : true;
  }

  isSd() {
    if (localStorage.getItem("sd") == null && localStorage.getItem("sd") == undefined) {
      return !this.authService.getSDEnable();
    }
    return localStorage.getItem("sd") === "true" ? false : true;
  }
  edl() {   
    localStorage.setItem("isEdl","true")
    this.errorService.setResponseEdlMessage(true);
   
    if(localStorage.getItem("edlAdmin")=="true"){
      if (localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME) === 'bosch' && localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT) === 'superadmin') {
      this.errorService.setResponseEdlAdmin(false);
    }else
    {
      this.errorService.setResponseEdlAdmin(true);
    }
    }
    this.router.navigate(["edl/all-measurement"]);
  }

  eol() {   
    localStorage.setItem("isEol","true")
   
    this.router.navigate(["/eoldashboard"]);
  }

  sd() {   
    localStorage.setItem("isSD","true")
  
    this.router.navigate(["/sddashboard"]);
  }


}
