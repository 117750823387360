import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from '../../../../environments/environment';
import { AdminService } from '../../../auth/admin.service';
import { AuthService } from '../../../auth/auth.service';
import Constants from '../../../commonFiles/constant/Constants';


export interface ConfirmationData {
  header: string;
  message: string;
  icon: string;
  primarybtn: string;
  cancelbtn: string;
  company:string
  bussinessUnit:string  
  subapp:string;
  isStorage:boolean;
  placeholder:string;
}

@Component({
  selector: 'app-update-storage',
  templateUrl: './update-storage.component.html',
  styleUrls: ['./update-storage.component.scss']
})
export class UpdateStorageComponent implements OnInit {
  placeHolderMessage
  CustomerForm;
  ErrorMessage;
  somePlaceholder : string = "";
  constructor(private dialogRef: MatDialogRef<UpdateStorageComponent>,
    private fb: FormBuilder,
    private authService: AuthService,
    private adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationData) {
       this.somePlaceholder =this.data.placeholder;
     }

  ngOnInit() {       
    this.CustomerForm = this.fb.group({
      companyName: [this.data.company, Validators.required],
      businessUnit: [this.data.bussinessUnit, Validators.required],
      storage:['', Validators.required],
    });
    this.authService.validateUser();   
   
  }
  accept(){   
    if(this.data.isStorage)
    {  
      var body={
        company:this.CustomerForm.controls.companyName.value,
       
        businessunit:this.CustomerForm.controls.businessUnit.value,
        adminemail:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
        subapp:this.data.subapp,
        maxstorage:this.CustomerForm.controls.storage.value      
      }   
    this.adminService.updateStorage(body).subscribe(res=>{      
        if(res.ResponseMetadata.HTTPStatusCode==200){
          this.dialogRef.close({
            isOk: true,
          });
        }else{
          this.reject();
        }
    });
  }
  else
  {
    var bodyValidity={
      company:this.CustomerForm.controls.companyName.value,
  
      businessunit:this.CustomerForm.controls.businessUnit.value,
      adminemail:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
      subapp:this.data.subapp,     
     validity:parseInt(this.CustomerForm.controls.storage.value),
    }
     
    this.adminService.updateValidity(bodyValidity).subscribe(res=>{      
        if(res.ResponseMetadata.HTTPStatusCode==200){
          this.dialogRef.close({
            isOk: true,
          });
        }else{
          this.reject();
        }
    });
  }
  
  }

  reject() {   
    this.dialogRef.close({
      isOk: false,
      data: {}
    });
  }

}
