import { Component, OnInit, Input } from "@angular/core";
import Constants from "../commonFiles/constant/Constants";
import { AppConfigService } from "../commonFiles/services/app-initializer.service";
import { DataService } from "../shared/data.service";
import { MatSnackBar, MatDialog } from "@angular/material";
import { FileDownloadComponent } from "../commonFiles/modalwindow/file-download/file-download.component";
import { SpinnerService } from "../commonFiles/services/spinner.service";
import { FlexAlignStyleBuilder } from "@angular/flex-layout";
import { ConfirmationWindowComponent } from "../commonFiles/modalwindow/confirmation-window/confirmation-window.component";
import { AdminService } from "../auth/admin.service";
import { Router } from "@angular/router";

@Component({
    selector: 'app-measurement-image',
    templateUrl: './image.component.html',
    styleUrls: ['./measurement.component.scss']
})
export class MeasurementImageComponent implements OnInit {
    @Input() Headerdata: any;
    Test = { Image1: <any>[], Image2: <any>[] }
    s3: any;
    isinitiated = false
    isSpeechPrimary;
    isSpeechSecondary;
 debugger;
    rootcausedataTags='';
    specifycausedataTags
    isVibration =false;
    constructor(public appconfig: AppConfigService, 
        public service: DataService,
        public snackBar: MatSnackBar, 
        public router: Router, 
        public spinnerService:SpinnerService,
        public adminService:AdminService,
        public dialog: MatDialog, ) {           
            this.spinnerService.primaryTestApp.subscribe(message => {this.isSpeechPrimary = message;
            });
            this.spinnerService.secondaryTestApp.subscribe(message => {this.isSpeechSecondary = message;
            });
        }
    roottags=true;
    specfiytags=true;
    ngOnDestroy()
    {
        this.Headerdata={};
    }
    async ngOnInit() {
debugger;
        this.isSpeechSecondary=false;
        this.isSpeechPrimary=false;

        if (this.router.url != '/edl/comparemeasurement') {
          var tag = JSON.parse(this.service.getVehicleResponsePostMeassage()).DataTags.rootcause; 
        if(tag){ 
            for(var i=0;i<tag.length;i++){
                if(tag[i]!==""){
                this.roottags =false;
                this.rootcausedataTags +=tag[i] +", ";
                }
            }
            this.rootcausedataTags =this.rootcausedataTags.slice(0, -2);
        }

        var subtag = JSON.parse(this.service.getVehicleResponsePostMeassage()).DataTags.nvhdescriptors; 
        if(subtag){ 
            this.specifycausedataTags ='';
             for(var i=0;i<subtag.length;i++){                 
                 if(subtag[i]!==""){
                 this.specfiytags =false;
                 this.specifycausedataTags +=subtag[i] +", ";
                 }
             }
             this.specifycausedataTags =this.specifycausedataTags.slice(0, -2);
            }
        }        
    }
    download() {        
        var data=this.Headerdata.Test1.Data;
      
        var filepath='public/' + localStorage.getItem(Constants.STORAGE.BUCKET) + "/" + data.epochdate + "_" + data.Machineid + "/" + data.Projectname + "_" + data.Testtype;
        const dialogRef = this.dialog.open(FileDownloadComponent, {
    
          width: '800px',
          data: [{
            url: filepath, name: data.epochdate + '_' + data.Machineid
          }]
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result != null) {
            if (result.data.status == 'Failed')
              this.snackBar.open("Download faild", "", {
                duration: 2500,
                verticalPosition: 'top'
              });
          }
        });
      }


    slideIndex = 0;
    SelectedImg: any;
    slideIndex1 = 0;
    SelectedImg1: any;
    openModal(url) {

        document.getElementById('imgModal').style.display = "block";
    }
    closeModal() {
        document.getElementById('imgModal').style.display = "none";
    }
    openModal1(url) {

        document.getElementById('imgModal1').style.display = "block";
    }
    closeModal1() {
        document.getElementById('imgModal1').style.display = "none";
    }
    plusSlides(n) {
        this.showSlides(this.slideIndex += n);
    }
    currentSlide(n, id) {
        this.showSlides(this.slideIndex = n);
    }
    currentSlide1(n, id) {
        this.showSlides1(this.slideIndex1 = n);
    }

    showSlides(slideIndex);

    showSlides(n) {
       
        let i;
        const slides = document.getElementsByClassName("img-slides1") as HTMLCollectionOf<HTMLElement>;
        const dots = document.getElementsByClassName("images") as HTMLCollectionOf<HTMLElement>;
        if (n > slides.length) {
            this.slideIndex = 1
        }
        if (n < 1) {
            this.slideIndex = slides.length
        }
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }
        slides[this.slideIndex - 1].style.display = "block";
        if (dots && dots.length > 0) {
            dots[this.slideIndex - 1].className += " active";
        }
    }
    showSlides1(slideIndex1);
    showSlides1(n) {
        let i;
        const slides1 = document.getElementsByClassName("img-slides2") as HTMLCollectionOf<HTMLElement>;
        const dots1 = document.getElementsByClassName("images") as HTMLCollectionOf<HTMLElement>;
        if (n > slides1.length) {
            this.slideIndex = 1
        }
        if (n < 1) {
            this.slideIndex1 = slides1.length
        }
        for (i = 0; i < slides1.length; i++) {
            slides1[i].style.display = "none";
        }
        for (i = 0; i < dots1.length; i++) {
            dots1[i].className = dots1[i].className.replace(" active", "");
        }
        slides1[this.slideIndex1 - 1].style.display = "block";
        if (dots1 && dots1.length > 0) {
            dots1[this.slideIndex1 - 1].className += " active";
        }
    }

    delete(){
        var dialogRef = this.dialog.open(ConfirmationWindowComponent, {
            data: {
              header: 'Delete Test',
              primarybtn: 'Delete',
              message: "Are you sure, want to delete?",
              cancelbtn: 'Cancel',
            }
          });
          dialogRef.afterClosed().subscribe(res => {
                  if (res.isOk) {

                    var project  =JSON.parse(this.service.getVehicleResponsePostMeassage());

                    var body ={
                        company:localStorage.getItem("companyname"),
                        businessunit:localStorage.getItem("businessunit"),
                        subapp:"edl",
                        machineid:project.Machineid,
                        t:project.epochdate,
                        username:project.Userid,
                        adminemail:localStorage.getItem("email")
                    }
                       
                            
                    this.adminService.DeleteProject(body).subscribe((data) => {       
                        this.snackBar.open("Selected tests are deleted", "", {
                          duration: 2500,
                          verticalPosition: 'top'
                        });
                        this.router.navigate(['/edl/all-measurement']);
                      },
                      err=>{
                        this.snackBar.open("partially deleted due to error", "", {
                          duration: 2500,
                          verticalPosition: 'top'
                        });
                        this.router.navigate(['/edl/all-measurement']);
                         
                      })
                  }
                });
                
    }

}
