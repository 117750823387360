import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { MeasurementComponent } from './measurement/measurement.component';
import { UserSettingsComponent } from './user-settings/user-settings.component'
import { LoginComponent } from './login/login.component';
import {  EDLDashboardGuard, AdminGuard, SuperAdminGuard, AuthGuard } from './auth/auth.guard';

import { ForgotPassword2Component, ForgotPasswordStep1Component } from './forgot/forgotPassword.component'

import {AccessdeniedComponent} from './accessdenied/accessdenied.component'
import {DashboardComponent} from './dashboard/dashboard.component'
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { LandingComponent } from './landing/landing.component';
import { ChangePasswordComponent } from './user-settings/change-password/change-password.component';
import { AdminComponent } from './admin/admin/admin.component';

import { AddUserComponent } from './admin/add-user/add-user.component'; 
import { GeoLocationComponent } from './geo-location/geo-location.component';
import { SetFirstComponent } from './set-first/set-first.component';
import { ListAdminComponent } from './admin/super-admin/list-admin/list-admin.component';
import { TermsComponent } from './terms/terms.component';
import { DataTagComponent } from './data-tag/data-tag.component';
import { DataProtectionComponent } from './data-protection/data-protection.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { ThresholdComponent } from './threshold/threshold.component';
import { AddThresholdComponent } from '../app/threshold/add-threshold/add-threshold.component';
import { DownloadsComponent } from './downloads/downloads.component';
import {VehicleTestMasterComponent} from '../app/vehicle-test-master/vehicle-test-master.component'
export const AppRoutes: Routes = [
      { path: 'set-first', component: SetFirstComponent },
      { path: '', component: LoginComponent },
      { path: 'login', component: LoginComponent },     
      { path: 'confirm-password/:email', component: ForgotPassword2Component },
      { path: 'forgotPassword', component: ForgotPasswordStep1Component },  
    {
      path: '',
      component: FullComponent,
      children:
      [
        
        { path: 'home', component:LandingComponent,canActivate: [AuthGuard]},
        { path: 'edl/all-measurement', component:AdminDashboardComponent, canActivate: [EDLDashboardGuard]},
        { path: 'dashboard', component:DashboardComponent,canActivate: [AuthGuard]},
        { path: 'denied-access', component:AccessdeniedComponent,canActivate: [AuthGuard]},     
        { path: 'edl/measurement', component: MeasurementComponent,canActivate: [AuthGuard] },
        
        { path: 'edl/comparemeasurement', component: MeasurementComponent,canActivate: [AuthGuard] },
        
        { path: 'processed-details', component: MeasurementComponent,canActivate: [AuthGuard] }, 
        { path: 'profile', component: UserSettingsComponent,canActivate: [AuthGuard] },
        { path: 'terms', component: TermsComponent,canActivate: [AuthGuard] },
        { path: 'change-password', component: ChangePasswordComponent },
        { path: 'admin', component: AdminComponent,canActivate :[AdminGuard]},
        { path: 'threshold', component: ThresholdComponent,canActivate :[AdminGuard]},
        { path: 'users', component: ListAdminComponent,canActivate :[AdminGuard]},       
        { path: 'access-denied', component: ListAdminComponent,canActivate :[SuperAdminGuard] },
        { path: 'add-user', component: AddUserComponent,canActivate: [AuthGuard] }, 
        { path: 'add-threshold', component: AddThresholdComponent,canActivate: [AuthGuard] }, 
 { path: 'add-thresholdWithdata', component: AddThresholdComponent,data : {some_data : 'some value'} ,canActivate: [AuthGuard]},
      { path: 'geo', component: GeoLocationComponent,canActivate: [AdminGuard] }, 
        { path: 'datatag', component: DataTagComponent,canActivate: [AuthGuard] }, 
        { path: 'privacy', component: PrivacyComponent,canActivate: [AuthGuard] }, 
        { path: 'dataprotection', component: DataProtectionComponent,canActivate: [AuthGuard] }, 
        { path: 'edl/datatag', component: DataTagComponent,canActivate: [AdminGuard] }, 
        { path: 'edl/VehicleTestMaster', component: VehicleTestMasterComponent,canActivate: [AdminGuard] }, 
        { path: 'terms-condition', component: TermsConditionComponent,canActivate: [AdminGuard] },
        { path: 'downloads', component: DownloadsComponent, canActivate: [AuthGuard] },
      ]
    },
    { path: '**', component:AccessdeniedComponent},
  ]
