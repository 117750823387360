import { CarModels, ChartData, TrialData, EDLDashboard } from './dashboardModels';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppConfigService } from '../commonFiles/services/app-initializer.service';

import { DataService } from '../shared/data.service';
import Constants from '../commonFiles/constant/Constants';
import { environment } from '../../environments/environment';
import { MatTableDataSource } from '@angular/material';
import { SpinnerService } from '../commonFiles/services/spinner.service';
import { AuthService } from '../auth/auth.service';
import { BaseLayerService } from '../commonFiles/services/BaseLayerService';
import { ErrorService } from '../commonFiles/services/error.service';


@Injectable()
export class dashboardService extends BaseLayerService {
  private models: CarModels[];
  private chartdata: ChartData[];
  private Trialdata: TrialData[];
  public EDLdata: EDLDashboard[] = [];
  minDate: Date;
  maxDate: Date;
  s3: any;
  index = 0;
  public EDLdataSource = new MatTableDataSource<any>();

  constructor(private appconfig: AppConfigService, protected http: HttpClient, public dataservice: DataService, public spinnerService: SpinnerService, public authservice: AuthService, public errorService: ErrorService,
  ) {
    super(errorService, environment.AWSINFO.Userurl, http)
    this.s3 = this.appconfig.LoadAWSS3Config(false);
    localStorage.setItem('filter', "")

    this.FilterEDLSource();
    //this.Loaddata();

  }
  Loaddata(FromDate, ToDate, ProjectName, Testtype, MachineId, UserId, lastevalMachineId, lastevalTime, lastevaluserId, vehiclemodel) {

    this.edldatas = [];
    this.GetEDLDashboard(FromDate, ToDate, ProjectName, Testtype, MachineId, UserId, lastevalMachineId, lastevalTime, lastevaluserId, vehiclemodel);
  }
  FilterEDLSource() {
    this.EDLdataSource.filterPredicate = (data: any, filter: any) => {
      let filtered = true;

      if (filter.FromDate != null && filter.FromDate != "") {
        filtered = filtered && filter.FromDate.setHours(0, 0, 0, 0) <= data.datetime.setHours(0, 0, 0, 0)
      }
      if (filter.ToDate != null && filter.ToDate != "") {
        filtered = filtered && (this.compareDate(data.datetime, filter.ToDate));
      }

      if (filter.ProjectName != null && filter.ProjectName.length > 0) {

        filtered = filtered && (data.Projectname.toLowerCase().indexOf(filter.ProjectName.trim().toLowerCase()) != -1);
      }
      if (filter.Testtype != null && filter.Testtype.length > 0) {
        filtered = filtered && (data.Testtype.toLowerCase().indexOf(filter.Testtype.trim().toLowerCase()) != -1);
      }
      if (filter.MachineId != null && filter.MachineId.length > 0) {
        filtered = filtered && (data.Machineid.toLowerCase().indexOf(filter.MachineId.trim().toLowerCase()) != -1);
      }

      if (filter.UserId != null && filter.UserId.length > 0) {
        filtered = filtered && (data.Userid.toLowerCase().indexOf(filter.UserId.trim().toLowerCase()) != -1);
      }

      return filtered;
    };
  }
  applyFilter(formval) {

    this.EDLdataSource.filter = formval;
    localStorage.setItem('filter', JSON.stringify(formval))
  }
  public onFromDate(event): void {
    this.minDate = event;
  }
  public onToDate(event): void {
    this.maxDate = event;
  }
  compareDate(date1, date2) {
    if (date1.setHours(0, 0, 0, 0) > date2.setHours(0, 0, 0, 0))
      return false;
    /*   if (date1.getMonth() > date2.getMonth())
          return false;
      if (date1.getDate() > date2.getDate())
          return false; */

    return true;
  }
  private Reloaddata(callback, currentrecord) {
    this.Loaddata("", '', '', '', '', '', '', '', '', '')
    if (localStorage.getItem('filter') != "")
      this.applyFilter(JSON.parse(localStorage.getItem('filter')))

  }
  getNextEDLRecord(currentrecord) {

  }
  getPreviousEDLRecord(currentrecord) {
    if (typeof (this.EDLdataSource) !== 'undefined' && this.EDLdataSource.filteredData.length > 0) {
      var indexprev = this.EDLdataSource.filteredData.findIndex(x => x.id == currentrecord);
      if (indexprev - 1 > -1) {
        return this.EDLdataSource.filteredData[indexprev - 1];
      }
      else {
        return this.EDLdataSource.filteredData[indexprev];
      }
    }
    else
      this.Reloaddata(this.getNextEDLRecord, currentrecord)

  }

  async readJSON(key) {
    var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: key };
    const data = (await (this.s3.getObject(params).promise())).Body.toString('utf-8');
    return JSON.parse(data);
  }
  edldatas: any[] = []
  public async GetEDLDashboard(fromdate, todate, projectname, testType, machineId, userid, lastmachineId, lasttime, lastuserid, vehiclemodel) {

    this.edldatas = []
    const now = new Date()
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);
    var company = localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME);
    var bu = localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT);

    var buildUrl = '';
    buildUrl += '&count=100';

    if (lastmachineId != "" && lastmachineId != null) {
      buildUrl += '&lastevalmachine=' + lastmachineId;
    }

    if (lasttime != "" && lasttime != null) {
      buildUrl += '&lastevaltime=' + lasttime;
    }

    if (lastuserid != "" && lastuserid != null) {
      buildUrl += '&lastevalemail=' + lastuserid;
    } else {
      if (userid != "" && userid != null) {
        buildUrl += '&lastevalemail=' + userid;
      }
    }
    if (fromdate != "" && fromdate != null) {
      buildUrl += '&from=' + fromdate * 1000;
    }
    if (todate != "" && todate != null) {
      var date = new Date(todate).setMinutes(1415.4);
      buildUrl += '&to=' + date * 1000;
    }
    else {
      buildUrl += '&to=' + secondsSinceEpoch;
    }
    if (projectname != "" && projectname != null)
      buildUrl += '&project=' + projectname;
    if (machineId != "" && machineId != null)
      buildUrl += '&machineid=' + machineId;
    if (testType != "" && testType != null)
      buildUrl += '&testtype=' + testType;
    if (userid != "" && userid != null)
      buildUrl += '&emailid=' + userid;
    if (vehiclemodel != "" && vehiclemodel != null)
      buildUrl += '&vehiclemodel=' + vehiclemodel;
   
    console.log(buildUrl)
    await this.get<any>('metadata?company=' + company + '&businessunit=' + bu + '&subapp=edl&sort=d' + buildUrl).toPromise().then(data => {

      for (var index = 0; index < data.length; index++) {


        var date = new Date(parseInt(data[index].tme.split('_')[0]) * 1000);
        var epochdate = data[index].tme.split('_')[0];
        var projectname = data[index].p;
        var TestType = data[index].tt;
        var Machineid = data[index].tme.split('_')[1];
        var Userid = data[index].tme.split('_')[2]
        var Comments = data[index].c;
        var status = "NA";
        var dataType = "NA";
        if (data[index].t_status != undefined) {
          status = data[index].t_status;
        }

        var datatags = data[index].datatag;
        if (data[index].datatype != undefined) {
          dataType = data[index].datatype;
        }

        this.edldatas.push({ 'id': index, 'datetime': date, 'epochdate': epochdate, 'Projectname': projectname, 'Testtype': TestType, 'Machineid': Machineid, 'Userid': Userid, 'Comments': Comments, 'Status': status, 'dataType': dataType, 'tags': datatags });
      }

      this.EDLdataSource.data = [];
      this.EDLdataSource.data = this.edldatas.sort((a, b) => parseInt(b.epochdate) - parseInt(a.epochdate))
      this.spinnerService.hideSpinner();
    })
  }

  EDLDashboardData(fromdate, todate, projectname, testType, machineId, userid, lastmachineId, lasttime, lastuserid, vehiclemodel) {
    const now = new Date()
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);
    var company = localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME);
    var bu = localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT);

    var buildUrl = '';
    buildUrl += '&count=100';

    if (lastmachineId != "" && lastmachineId != null) {
      buildUrl += '&lastevalmachine=' + lastmachineId;
    }

    if (lasttime != "" && lasttime != null) {
      buildUrl += '&lastevaltime=' + lasttime;
    }

    if (lastuserid != "" && lastuserid != null) {
      buildUrl += '&lastevalemail=' + lastuserid;
    } else {
      if (userid != "" && userid != null) {
        buildUrl += '&lastevalemail=' + userid;
      }
    }
    if (fromdate != "" && fromdate != null) {
      buildUrl += '&from=' + fromdate * 1000;
    }
    if (todate != "" && todate != null) {
      var date = new Date(todate).setMinutes(1415.4);
      buildUrl += '&to=' + date * 1000;
    }
    else {
      buildUrl += '&to=' + secondsSinceEpoch;
    }
    if (projectname != "" && projectname != null)
      buildUrl += '&project=' + projectname;
    //if (Model != "" && Model != null)
    //  buildUrl += '&model=' + Model;

    if (machineId != "" && machineId != null)
      buildUrl += '&machineid=' + machineId;
    if (testType != "" && testType != null)
      buildUrl += '&testtype=' + testType;
    if (userid != "" && userid != null)
      buildUrl += '&emailid=' + userid;
    if (vehiclemodel != "" && vehiclemodel != null)
      buildUrl += '&vehiclemodel=' + vehiclemodel;
   

    // return this.get<any>('metadata?from=1505852089&to='+secondsSinceEpoch+'&company=' + company +  '&businessunit=' + bu + '&subapp=edl&count=10&sort=d');
    console.log(buildUrl)
    return this.get<any>('metadata?company=' + company + '&businessunit=' + bu + '&subapp=edl&sort=d' + buildUrl);
  }
}
