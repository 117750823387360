import { OnInit, Component, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { DataService } from "../shared/data.service";
import { SpinnerService } from "../commonFiles/services/spinner.service";
import { dashboardService } from "../dashboard/dashboard.service";
import Constants from "../commonFiles/constant/Constants";
import { ErrorService } from "../commonFiles/services/error.service";
import WaveSurfer from 'wavesurfer.js';
import { trigger, transition, style, animate, state } from "@angular/animations";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { AppConfigService } from "../commonFiles/services/app-initializer.service";
import { AdminDashboardComponent } from "../admin-dashboard/admin-dashboard.component";
import { ResizeEvent } from 'angular-resizable-element';
export interface Testdata {
  NoiseVsVib: boolean;
  Iscompare: boolean;
  Test: { id: any, Data: any, Audio: any, Image: any };
  Test1: { LA: any, Data: any, Audio: any, Image: any };
  Test2: { LA: any, Data: any, Audio: any, Image: any }
}

export interface VechileTestData {
  Iscompare: boolean;
  OwnerTest: { id: any, Data: any, Audio: any, Image: any };
  Owner: { LA: any, Data: any, Audio: any, Image: any };
  Competitor: { LA: any, Data: any, Audio: any, Image: any };
}
@Component({
  selector: 'app-measurement-header',
  templateUrl: './header.component.html',
  styleUrls: ['./measurement.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('560ms ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('560ms ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
        , state('*', style({ opacity: 1 })),
      ]
    )
  ]
})

export class MeasurementHeaderComponent implements OnInit {
  titledata: Testdata = <Testdata>{};
  titledata2: any;
  currentrecord: number;
  showImage = false
  showImage2 = false
  LA1: any = {};

  TestId = "1";
  IsComparableMeasurement = false;
  wave: WaveSurfer = null;
  isPlay = false;

  primaryTest = [];
  primaryTestLabel = [];
  secondayTest = [];
  secondayTestLabel = [];


  wave2: WaveSurfer = null;
  isPlay2 = false;
  iconpath = '../../assets/images/play.svg'
  iconpath2 = '../../assets/images/play.svg'
  showAudio = true;
  showAudio1 = true;
  showAudio2 = true;
  statusMsg = "-"
  s3: any;
  @Output("Loadchart") Loadchart: EventEmitter<any> = new EventEmitter();
  isSpeech
  isDisableTest1 = false;
  isDisableTest2 = false;
  constructor(private cdr: ChangeDetectorRef, public appconfig: AppConfigService, private router: Router, public service: DataService,
    public spinnerService: SpinnerService, private dashboardservice: dashboardService, public errorService: ErrorService, public dialog: MatDialog,) {
    this.titledata.Iscompare = false;
    this.titledata.NoiseVsVib = false;
    this.titledata.Test = <any>{}
    this.spinnerService.currentMessage.subscribe(message => {
      this.statusMsg = message;
    });


  }

  async ngOnInit() {
    //debugger;
    if (this.wave) {
      this.wave.stop();
      this.isPlay = true;
      this.iconpath = '../../assets/images/play.svg';
      this.cdr.detectChanges();
    }
    if (this.wave2) {
      this.wave2.stop();
      this.isPlay2 = true;
      this.iconpath2 = '../../assets/images/play.svg';
      this.cdr.detectChanges();
    }
    this.s3 = await this.appconfig.LoadAWSS3Config(false);

    this.errorService.setResponseHeaderMessage(true);

    this.IsComparableMeasurement = false;

    if (this.router.url == '/edl/comparemeasurement') {
      //debugger;
      this.IsComparableMeasurement = true;
    }

    if (!this.IsComparableMeasurement)
      //debugger;
      this.LoadParamdata(null);


  }
  async LoadParamdata(records) {

    let data: any;
    if (records == null)
      data = JSON.parse(this.service.getVehicleResponsePostMeassage());
    else {
      data = records;
      this.service.setVehicleResponsePostMessage(data);
    }
    this.titledata.Iscompare = this.IsComparableMeasurement;
    this.titledata.Test.id = this.TestId;

    if (data && data.isEDL) {
      this.currentrecord = data.id;




      if (this.TestId == "1") {
        this.titledata.Test.Data = data;
        this.titledata.Test1 = { Data: data, LA: {}, Audio: null, Image: null }

      }
      else {
        this.titledata.Test.Data = data;
        this.titledata.Test2 = { Data: data, LA: {}, Audio: null, Image: null }
      }

      if (this.titledata.Test.id == "1") {
        this.getAudio(this.titledata.Test1.Data, environment.APPINFO.AudioFile)

      }
      if (this.titledata.Test.id == "2") {
        this.getAudio(this.titledata.Test2.Data, environment.APPINFO.AudioFile)

      }
      if (this.titledata.Test1 != undefined && this.titledata.Test2 != undefined) {
        //debugger;
        if (this.titledata.Test1.Data.DataTypes != this.titledata.Test2.Data.DataTypes)
          this.titledata.NoiseVsVib = true;
        else
          this.titledata.NoiseVsVib = false;
      }
      this.GetFFTdata();
      this.loadImage();
    }

  }
  Compare(Testid) {

    this.TestId = Testid;
    const dialogRef = this.dialog.open(AdminDashboardComponent, {
      width: '75%',
      height: '75%',
      data: {
        isCompare: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result != null) {

        if (this.TestId == "1") {
          this.isDisableTest2 = true;

          if (this.primaryTest.length > 0) {
            this.primaryTest = [];
            this.primaryTestLabel = [];
          }
          this.spinnerService.pimary(false);
        }
        if (this.TestId == "2") {
          this.isDisableTest1 = true;
          if (this.secondayTest.length > 0) {
            this.secondayTest = [];
            this.secondayTestLabel = [];
          }
          this.spinnerService.secondary(false);
        }
        this.LoadParamdata(result.data);
      }
    })
  }
  GetPrevious() {
    console.log(this.currentrecord)
    if (this.currentrecord <= 0) {
      return
    }
    else {
      this.spinnerService.showSpinner();
      this.currentrecord = this.currentrecord - 1
      this.nextPrev(this.currentrecord);
    }
  }
  isNextEnable = true
  GetNext() {
    console.log(this.currentrecord)
    this.spinnerService.showSpinner();
    this.currentrecord = this.currentrecord + 1
    this.nextPrev(this.currentrecord);
    this.isNextEnable = false;

  }
  async nextPrev(val) {
    await this.dashboardservice.EDLDashboardData('', '', '', '', '', '', '', '', '', '').subscribe(res => {
      console.log(res);
      if (res.Data[val] != undefined) {
        var data = res.Data[val];
        var epoch = data.tme.split('%')[0];
        var projectname = data.p;
        var TestType = data.tt;
        var Machineid = data.tme.split('%')[1];
        var Userid = data.tme.split('%')[2]
        //debugger;
        var vehicleModelname = data.vm;

        var status = "";
        var dataType = "NA";

        if (data.t_status != undefined) {
          if (data.t_status === 0)
            status = "OK";
          else if (data.t_status === 1) {
            status = "NOK";
          }
          else {
            status = "___";
          }
        } else {
          status = "___";
        }

        if (data.datatype != undefined) {
          dataType = data.datatype;
        }

        var body = {
          isEDL: true,
          epochdate: epoch,
          Userid: Userid,
          Testtype: TestType,
          Projectname: projectname,
          Model: vehicleModelname,
          Machineid: Machineid,
          DataTag: data.datatag,
          DataTypes: dataType,
          Comments: data.c,
          //id:this.currentrecord+1
          id: this.currentrecord
        }

        this.isNextEnable = true;
        var nextrecord = res.Data[val + 1];
        nextrecord.isEDL = true;
        this.spinnerService.hideSpinner();
        this.LoadParamdata(body);

      }
    });
  }
  onClick() {
    this.showImage = !this.showImage;
    this.showImage2 = !this.showImage2;
  }
  onClick2() {
    this.showImage2 = !this.showImage2;
    this.showImage = !this.showImage;
  }
  public onPreviewPressed(titledata): void {
    this.titledata = titledata;
    this.generateWaveform();
    this.cdr.detectChanges();

    Promise.resolve().then(() => {
      if (this.titledata.Test1 && this.titledata.Test.id == "1") {
        this.wave.load(this.titledata.Test1.Audio);
        this.isPlay = true;
        this.wave.setVolume(100)
      }
      if (this.titledata.Test2 && this.titledata.Iscompare && this.titledata.Test.id == 2) {
        this.wave2.load(this.titledata.Test2.Audio);
        this.isPlay2 = true;
        this.wave2.setVolume(100)
        this.cdr.detectChanges();
      }
    });
  }

  onPlay(player) {
    if (player == 1) {
      this.isPlay = false
      this.iconpath = '../../assets/images/pause.svg'
      this.wave.play();

      if (this.wave2) {
        this.wave2.stop();
        this.isPlay2 = true;
        this.iconpath2 = '../../assets/images/play.svg';
        this.cdr.detectChanges();
      }
    }
    else {
      this.isPlay2 = false
      this.iconpath2 = '../../assets/images/pause.svg'
      this.wave2.play();
      if (this.wave) {
        this.wave.stop();
        this.isPlay = true;
        this.iconpath = '../../assets/images/play.svg';
        this.cdr.detectChanges();
      }
    }
    this.cdr.detectChanges();
  }

  onStopPressed(player): void {
    if (player == 1) {
      this.isPlay = true
      this.iconpath = '../../assets/images/play.svg'
      this.wave.pause();
    }
    else {
      this.isPlay2 = true
      this.iconpath2 = '../../assets/images/play.svg'
      this.wave2.pause();
    }

    this.cdr.detectChanges();
  }

  generateWaveform(): void {
    if (this.titledata.Test1 && this.titledata.Test.id == 1) {
      if (this.wave) {
        var old = document.getElementById('waveform1')
        document.getElementById('wavecontainer1').removeChild(old);
        var newelem = document.createElement('div');
        newelem.style.position = "relative";
        newelem.style.alignItems = "center";
        newelem.style.justifyContent = "center"
        newelem.id = "waveform1"
        document.getElementById('wavecontainer1').appendChild(newelem)
      }

      /*   Promise.resolve(null).then(() => {  */
      this.wave = WaveSurfer.create({
        container: '#waveform1',
        waveColor: 'darkgray',
        progressColor: '#005691',
        pixelRatio: 1,
        hideScrollbar: true,
        /*  backend: 'MediaElement', */
        height: 30,
        requestAnimationFrame: true,
        barHeight: 15, // the height of the wave
        barGap: 2

      });
      this.wave.on('finish', () => {
        this.iconpath = '../../assets/images/play.svg'
        this.isPlay = true;
        this.cdr.detectChanges();
      });

      this.wave.on('audioprocess', () => {
        if (this.wave.isPlaying()) {
          if (this.wave.getDuration() != null) {
            var totalTime = this.wave.getDuration(),
              currentTime = this.wave.getCurrentTime(),
              remainingTime = totalTime - currentTime;
            document.getElementById('time-total1').innerText = remainingTime.toFixed(1);
          }
        }
        this.cdr.detectChanges();
      });

      this.wave.on('ready', () => {
        this.isPlay = true
        if (this.wave != null) {
          document.getElementById('time-total1').innerText = this.wave.getDuration() == null ? 0 : this.wave.getDuration().toFixed(1)
        }
        this.cdr.detectChanges();
      });
    }
    if (this.titledata.Test2 && this.titledata.Iscompare && this.titledata.Test.id == 2) {
      if (this.wave2) {
        var oldChild = document.getElementById('waveform2')
        document.getElementById('wavecontainer2').removeChild(oldChild);
        var newelement = document.createElement('div');
        newelement.style.position = "relative";
        newelement.style.alignItems = "center";
        newelement.style.justifyContent = "center"
        newelement.id = "waveform2"
        document.getElementById('wavecontainer2').appendChild(newelement)
      }

      this.wave2 = WaveSurfer.create({
        container: '#waveform2',
        waveColor: 'darkgray',
        progressColor: 'purple',
        pixelRatio: 1,
        hideScrollbar: true,
        /*  backend: 'MediaElement', */
        height: 30,
        requestAnimationFrame: true,
        barHeight: 15, // the height of the wave
        barGap: 2
      });

      this.wave2.on('finish', () => {
        this.iconpath2 = '../../assets/images/play.svg'
        this.isPlay2 = true;
        this.cdr.detectChanges();
      });

      this.wave2.on('audioprocess', () => {
        if (this.wave2.isPlaying()) {
          var totalTime = this.wave2.getDuration(),
            currentTime = this.wave2.getCurrentTime(),
            remainingTime = totalTime - currentTime;
          document.getElementById('time-total2').innerText = remainingTime.toFixed(1);
        }
        this.cdr.detectChanges();
      });

      this.wave2.on('ready', () => {
        this.isPlay2 = true
        document.getElementById('time-total2').innerText = this.wave2.getDuration().toFixed(1)
        this.cdr.detectChanges();
      });
    }

  }

  getAudio(data, filename) {
    var audiopath = 'public/' + localStorage.getItem(Constants.STORAGE.BUCKET) + "/" + data.epochdate + "_" + data.Machineid + "/" + data.Projectname + "_" + data.Testtype;
    var params = {
      Prefix: audiopath,
      Bucket: environment.AWSINFO.rekognitionBucket
    }

    try {
      this.s3.listObjects(params, this.checkfileexist.bind(this))
    
    }
    catch (e) {
      if (this.titledata.Test.id == '1') {
        this.showAudio1 = false
      }
      else {
        this.showAudio2 = false
      }
    }
  }

  checkfileexist(err, result) {
    
    try {
      if (result && result.Contents.filter(x => x.Key.indexOf('.mp3') > -1).length > 0) {
        var data = this.titledata.Test.Data
        if (this.titledata.Test.id == '1')
          data = this.titledata.Test1.Data
        else
          data = this.titledata.Test2.Data
        var audiopath = 'public/' + localStorage.getItem(Constants.STORAGE.BUCKET) + "/" + data.epochdate + "_" + data.Machineid + "/" + data.Projectname + "_" + data.Testtype;

        var key = audiopath + "/" + environment.APPINFO.AudioFile

        var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: key };
        if (this.titledata.Test.id == '1') {
          this.showAudio1 = true
          this.s3.getSignedUrl('getObject', params, this.EngineSounddata.bind(this));
        }
        else {
          this.showAudio2 = true
          this.s3.getSignedUrl('getObject', params, this.EngineSounddata.bind(this));
        }

      }
      else if (result && result.Contents.filter(x => x.Key.indexOf('.wav') > -1).length > 0) {
        var data = this.titledata.Test.Data
        if (this.titledata.Test.id == '1')
          data = this.titledata.Test1.Data
        else
          data = this.titledata.Test2.Data
        var audiopath = 'public/' + localStorage.getItem(Constants.STORAGE.BUCKET) + "/" + data.epochdate + "_" + data.Machineid + "/" + data.Projectname + "_" + data.Testtype;

        var key = audiopath + "/" + environment.APPINFO.AudioFile1

        var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: key };
        if (this.titledata.Test.id == '1') {
          this.showAudio1 = true
          this.s3.getSignedUrl('getObject', params, this.EngineSounddata.bind(this));
        }
        else {
          this.showAudio2 = true
          this.s3.getSignedUrl('getObject', params, this.EngineSounddata.bind(this));
        }

      }
      else {

        if (this.titledata.Test.id == '1') {
          this.showAudio1 = false
        }
        else {
          this.showAudio2 = false
        }
      }

    }
    catch (e) {

    }
    this.Loadchart.emit();
    this.cdr.detectChanges();
  }
  EngineSounddata(err, data) {
    console.log(data)
    if (data) {
      this.titledata.Test.Audio = data;
      if (this.titledata.Test1 && this.titledata.Test.id == 1) {
        this.showAudio1 = true;
        this.titledata.Test1.Audio = data;

      }
      if (this.titledata.Test2 && this.titledata.Test.id == 2) {
        this.showAudio2 = true;
        this.titledata.Test2.Audio = data;
      }
      if (this.titledata.Iscompare)
        this.onPreviewPressed(this.titledata);
    }

    this.cdr.detectChanges();
  }

  loadImage() {

    if (this.titledata && this.titledata.Test) {
      //debugger;
      var data = this.titledata.Test.Data;
      var Imagepath = 'public/' + localStorage.getItem(Constants.STORAGE.BUCKET) + "/" + data.epochdate + "_" + data.Machineid + "/" + data.Projectname + "_" + data.Testtype;
      var params = {
        Prefix: Imagepath,
        Bucket: environment.AWSINFO.rekognitionBucket
      }
      this.s3.listObjects(params, this.getImageUrl.bind(this));
    }


  }
  getImageUrl(err, data) {
    this.titledata.Test.Image = [];
    if (this.titledata.Test.id == '1') {
      this.titledata.Test1.Image = [];
      this.isDisableTest2 = false;
    }
    else {
      this.titledata.Test2.Image = [];
      this.isDisableTest1 = false;

    }

    if (data) {
      var imagelist = data.Contents.filter(x => x.Key.indexOf('.jpg') > -1 || x.Key.indexOf('.png') > -1 || x.Key.indexOf('.jpeg') > -1);
      if (imagelist != undefined && imagelist.length > 0) {
        imagelist.forEach(filename => {
          var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: filename.Key };

          this.s3.getSignedUrl('getObject', params, this.ShowImage.bind(this));

        })
      }
    }
  }
  ShowImage(err, data) {

    if (data) {
      this.titledata.Test.Image.push(data)
      if (this.titledata.Test.id == '1')
        this.titledata.Test1.Image.push(data)
      else
        this.titledata.Test2.Image.push(data)

    }

  }

  ngOnDestroy() {
    this.titledata = <any>{}
    this.statusMsg = "-";
    if (this.wave) {
      this.wave.stop();
      this.wave = null;
    }
    if (this.wave2) {
      this.wave2.stop();
      this.wave2 = null;
    }
  }
  setActiveTestMenu(test) {
    if (test == "Test1" && !this.isDisableTest1) {

      return "secondary_test";
    } else if (test == "Test1" || this.isDisableTest1) {

      return "grey_button";
    }

    if (test == "Test2" && !this.isDisableTest2) {

      return "primary_test";
    } else if (test == "Test2" || this.isDisableTest2) {

      return "grey_button ";
    }

  }
  labelKeys = [];
  GetFFTdata() {
    //debugger;
    if (this.titledata.Iscompare == false) {
      var key = localStorage.getItem('PathEDL') + "/" + environment.APPINFO.overfilename;
      var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: key };
    }
    else {
      if (this.titledata.Test.id == '1')
        var data = this.titledata.Test1.Data;
      else
        var data = this.titledata.Test2.Data;
      var EDLPath = 'public/' + localStorage.getItem(Constants.STORAGE.BUCKET) + "/" + data.epochdate + "_" + data.Machineid + "/" + data.Projectname + "_" + data.Testtype;
      localStorage.setItem("PathEDL", EDLPath);
      var key = localStorage.getItem('PathEDL') + "/" + environment.APPINFO.overfilename;
      var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: key };
    }
    this.s3.getObject(params, this.LoadLevelvsTimedata.bind(this));
  }
  GetMLOutput() {
    //debugger;
    var key = localStorage.getItem('PathEDL') + "/" + "ml_output.json";
    var params = { Bucket: environment.AWSINFO.rekognitionBucket, Key: key };
    this.s3.getObject(params, this.MLOutput.bind(this));
  }

  MLOutput(err, data) {
    if (data) {
      var overallleveldata = JSON.parse(data.Body.toString("utf-8"));
      if (this.titledata.Test.id == '1') {
        this.primaryTestLabel.push({ key: "STATUS", value: overallleveldata.prediction.machinestatus });
      } else if (this.titledata.Test.id == '2') {
        this.secondayTestLabel.push({ key: "STATUS", value: overallleveldata.prediction.machinestatus })
      }

      if (this.titledata.Test.id == '1') {
        this.primaryTestLabel.push({ key: "", value: '' });
      } else if (this.titledata.Test.id == '2') {
        this.secondayTestLabel.push({ key: "", value: '' })
      }
    }
  }
  LoadLevelvsTimedata(err, data) {
    console.log(data);
    if (data) {
      var overallleveldata = JSON.parse(data.Body.toString("utf-8"));
      var indexedArray: { [key: string]: string } = overallleveldata.result;
      this.labelKeys = [];
      //debugger;
      for (var i = 0; i < Object.keys(indexedArray).length - 1; i++) {
        this.labelKeys.push({ key: Object.keys(indexedArray)[i], value: overallleveldata.result[Object.keys(indexedArray)[i].toString()] })
        if (this.titledata.Test.id == '1') {
          this.primaryTest.push({ key: Object.keys(indexedArray)[i], value: overallleveldata.result[Object.keys(indexedArray)[i].toString()] });

        } else if (this.titledata.Test.id == '2') {
          this.secondayTest.push({ key: Object.keys(indexedArray)[i], value: overallleveldata.result[Object.keys(indexedArray)[i].toString()] })

        }

      }
      if (this.titledata.Test.id == '1' && this.titledata.Test.Data.DataTypes == 'V')
        this.primaryTest.push({ key: "", value: '' })
      if (this.titledata.Test.id == '2' && this.titledata.Test.Data.DataTypes == 'V')
        this.secondayTest.push({ key: "", value: '' })

      var obj = JSON.parse(this.service.getVehicleResponsePostMeassage());
      if (obj.datetime) {
        if (this.titledata.Test.id == '1') {
          this.primaryTestLabel.push({ key: "DATETIME", value: obj.datetime });
        } else if (this.titledata.Test.id == '2') {
          this.secondayTestLabel.push({ key: "DATETIME", value: obj.datetime })
        }
      }

      if (obj.Userid) {
        if (this.titledata.Test.id == '1') {
          this.primaryTestLabel.push({ key: "USERID", value: obj.Userid });
        } else if (this.titledata.Test.id == '2') {
          this.secondayTestLabel.push({ key: "USERID", value: obj.Userid })
        }
        this.GetMLOutput();
      }


    }
  }


  checkStatus(status, label) {
    if (status === 'STATUS') {

      if (label === 'OK')
        return '#7FBE78';
      else
        return 'red';
    }
  }
  onResizeEnd(event: ResizeEvent, columnName): void {

    if (event.edges.right || event.edges.left) {

      const cssValue = event.rectangle.width + 'px';

      const columnElts = document.getElementsByClassName(columnName);

      //this.columnWidths[columnName.field] = event.rectangle.width;

      for (let i = 0; i < columnElts.length; i++) {

        const currentEl = columnElts[i] as HTMLDivElement;

        currentEl.style.width = cssValue;

      }

    }

  }

}
