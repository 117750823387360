import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { DownloadService } from '../shared/download.service';

export interface windowdata {
  header: string;
  cancelbtn: string;
  primarybtn: string;
  url: any;

}

export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}

export class FileData {
  url: string;
  fileName: string;
  fileType!: string;
  uniqueId!: string;
  downloadProgress!: number;
  isCompleted!: boolean;
  status!: string;
}

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit {

  selectAll = false;
  s3: any;
  title = 'Grid Grouping';
  _alldata: any[];
  columns: any[];
  displayedColumns: string[];
  groupByColumns: string[] = [];
  worker: any;

  public dataSource = new MatTableDataSource<any | Group>([]);
  private downloadList: FileData[] = [];


  constructor(private downloadService: DownloadService) { }

  ngOnInit() {
    this.columns = [
      {
        field: 'index',
        header: 'Index',
        cell: (element: FileData) => `${element['index']}`
      }, {
        field: 'fileName',
        header: 'File',
        cell: (element: FileData) => `${element.fileName}`
      }, {
        field: 'fileType',
        header: 'Type',
        cell: (element: FileData) => `${element.fileType}`
      }, {
        field: 'downloadProgress',
        header: 'Progress',
        cell: (element: FileData) => `${element.downloadProgress}`
      }, {
        field: 'status',
        header: 'Status',
        cell: (element: FileData) => `${element.status}`
      }];
    this.displayedColumns = this.columns.map(column => column.field);
   // this.displayedColumns.push('index');
   // this.groupByColumns = ['brand'];
    // data.data.forEach((item, index) => {
    //   item['id'] = index + 1;
    // });
    // this._alldata = data.data;
    // this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    // this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
    // this.dataSource.filter = performance.now().toString();
    this.downloadService.getDownloadStatus.emit();
    this.downloadService.downloadStatus.subscribe((data: any) => {
      data.forEach((item, index) => {
      item['index'] = index + 1;
      });
      this.dataSource.data = data;
    });
  }

  groupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, true);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  checkGroupByColumn(field, add ) {
    let found = null;
    for (const column of this.groupByColumns) {
      if (column === field) {
        found = this.groupByColumns.indexOf(column, 0);
      }
    }
    if (found != null && found >= 0) {
      if (!add) {
        this.groupByColumns.splice(found, 1);
      }
    } else {
      if ( add ) {
        this.groupByColumns.push(field);
      }
    }
  }

  unGroupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, false);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  // below is for grid row grouping
  customFilterPredicate(data: any | Group, filter: string): boolean {
    return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
  }

  getDataRowVisible(data: any): boolean {
    const groupRows = this.dataSource.data.filter(
      row => {
        if (!(row instanceof Group)) {
          return false;
        }
        let match = true;
        this.groupByColumns.forEach(column => {
          if (!row[column] || !data[column] || row[column] !== data[column]) {
            match = false;
          }
        });
        return match;
      }
    );

    if (groupRows.length === 0) {
      return true;
    }
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.dataSource.filter = performance.now().toString();  // bug here need to fix
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map(
        row => {
          const result = new Group();
          result.level = level + 1;
          result.parent = parent;
          for (let i = 0; i <= level; i++) {
            result[groupByColumns[i]] = row[groupByColumns[i]];
          }
          return result;
        }
      ),
      JSON.stringify);

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    return item.level;
  }

  clear() {
    this.downloadService.clearDownloads.emit();
  }

}
