import { Component, OnInit, Inject, HostListener, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from '../auth/admin.service';
import Constants from '../commonFiles/constant/Constants';
import { AppConfigService } from '../commonFiles/services/app-initializer.service';
import { SpinnerService } from '../commonFiles/services/spinner.service';

export interface ConfirmationData {
  header: string;
  message: string;
  icon: string;
  primarybtn: string;
  cancelbtn: string;
}

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  s3: any;
  isLoading=true;
  isHideButton=true;
  constructor(private dialogRef: MatDialogRef<TermsComponent>,
  public appconfig: AppConfigService, 
  public adminService:  AdminService,
  private eleRef:ElementRef,
  public spinnerService: SpinnerService, 
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationData) { }

      ngOnInit() {
        this.disableBtn =true;
      var body={
        
        emailid:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
        company:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME),
        businessunit:localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT),
      
    }
    this.adminService.getUlla(body).subscribe(res=>{
      this.rawData = res.success.Message.eula;
    //  this.rawData=this.rawData.replace(/\n|\r/g, "");
      //let email = res.success.Message.eula;
      //this.rawData=this.rawData.replace(/\\r\\n/g, "");
      // console.log(result)

this.rawData
    });
    
      if( this.data !=null && this.data.header != undefined){
        this.isHideButton=true;
      }else{
        this.isHideButton =false;
      }
     this.isLoading =false;
    
    var version = localStorage.getItem(Constants.UserInfo.APPVERSION);
  
   
  }
  rawData
   
 
  save() {
    this.dialogRef.close({
      isOk: true,
      data: {}
    });
  }

  reject() {
    this.dialogRef.close({
      isOk: false,
      data: {}
    });
  }
  disableBtn:boolean=true; 
  @HostListener('scroll', ['$event']) 
  scrollHandler(event) {
    let scrollHeight = Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
    if(event.srcElement.scrollTop>(scrollHeight*2)){
      this.disableBtn =false;
    }
   
  }
  onAgreechange(event, agree) {
    this.disableBtn = true;
    if (event.checked) {
      this.disableBtn = false;
    }

  }
  calculateClasses(){
    if(this.disableBtn)
    return "gray";
  }
  
}
