import { stringify } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { DistinctSubscriber } from 'rxjs/internal/operators/distinct';
import{Threshold,ThresholdList,RootcauseArray,RootcauseList} from './thresholdModel'
import { AdminService } from '../auth/admin.service';
import { Observable } from 'rxjs';
import  Constants from '../commonFiles/constant/Constants';
@Injectable({
  providedIn: 'root'
})
export class ThresholdService {
  Rootcause:{rootcause:string,color:string,type:any[]}={rootcause:"",color:"",type:[]};
  RootcauseList:any[]=[];
  Analysis:{Analysis:string,Values:any[]}={Analysis:"",Values:[]};
  AnalysisList:any[]=[];
  Thresholdlist:any[]=[];
  accordionList:any[]=[];
  counter:number=0
  Rootcauseformdata
  DisplayRootcause:boolean=false;
  Threshold:{company:string,businessunit:string,subapp:string,machineid:string,datatype:string,testtype:string,gen_threshold:any[],threshold:any[]}={company:"",businessunit:"",subapp:"",machineid:"",datatype:"",testtype:"",gen_threshold:[],threshold:[]}
private isFFT:Boolean;
private isOctave:Boolean;
private isThirdOctave:Boolean;
private isPRAvg:Boolean;
private ThrehdataFromParent:any;

  constructor(private adminservice:AdminService) { }
  passdataTochild(dataFromParent)
  {
    this.ThrehdataFromParent=[];
    if(dataFromParent!=null && dataFromParent!=undefined)
    this.ThrehdataFromParent=dataFromParent;

  }
  ClearEditableThreshdata()
  {
    this.ThrehdataFromParent=[];
  }
  getThreshDataInChild()
  {
    return this.ThrehdataFromParent==undefined?[]:this.ThrehdataFromParent;

  }
  getrootcauselist()
{
  return this.RootcauseList;
}

  
  AddEditableRootcause(rootcauselist)
  {
    this.RootcauseList.push.apply(this.RootcauseList,rootcauselist);
  }
 
  updateRootCauseList(form,AnalysisArray)
  {
    let index=-1;
    let Rootcause={rootcause:"",color:"",type:new Array()};

   Rootcause.rootcause=form.value.rootcause;
   Rootcause.color=form.value.color;
   this.AnalysisList=[];
 
for(let i=0;i<AnalysisArray.length;i++)
{
  if(AnalysisArray[i].values!=undefined)
  {
  let Analysis={Analysis: "", Values: new Array()};
  Analysis.Analysis=AnalysisArray[i].analysis;
    Analysis.Values = AnalysisArray[i].values.data;
    for (let j = 0; j < Analysis.Values.length; j++) {
      Analysis.Values[j].Position = j + 1;

    }
  let templist=this.AnalysisList.filter(obj => obj.Analysis !==Analysis.Analysis)
  this.AnalysisList=templist;
  this.AnalysisList.push(Analysis);
  }
  else
  continue;
}
Rootcause.type.push.apply(Rootcause.type,this.AnalysisList);
 index=this.RootcauseList.findIndex(obj=>obj.rootcause==Rootcause.rootcause)
if(index>=0)
 this.RootcauseList[index]=Rootcause;
else
    this.RootcauseList.push(Rootcause);
   // this.GetRootCauseCard(Rootcause)
}
  deleterootcause(rootcause)
  {
  this.RootcauseList = this.RootcauseList.filter(obj => obj.rootcause !== rootcause.rootname);
  this.updateThreshold();
  return  this.RootcauseList;
  }
  UpdateRootcauseColor(data)
  {
    for(var i=0;i<this.RootcauseList.length;i++)
    {
if(this.RootcauseList[i].rootcause==data.rootcause)
{
  this.RootcauseList[i].color=data.color;
}

    }
  //this.RootcauseList = this.RootcauseList.filter(obj => obj.rootcause !== rootcause.rootname);
    return  this.RootcauseList;
  }
  
  deleteAnalysis(body)
  {
    const modified = this.RootcauseList.map(function(ComparisonResult) {
    if(ComparisonResult.rootcause==body.rootcauseName)
    {
      ComparisonResult.type = ComparisonResult.type.filter(obj => obj.Analysis !== body.analysisName);
      return ComparisonResult;
    }
    }).filter(({ type }) => type.length);
    this.updateThreshold()
    //this.AnalysisList = this.AnalysisList.filter(obj => obj.Analysis !== Analysis.AnalysisName);
    return  this.RootcauseList;
  
  }
 
 
  clearroocuaseAfterAdd()
  {
this.AnalysisList=[];
//this.RootcauseList=[];
this.Analysis={Analysis: "", Values: []};
this.Rootcause={rootcause:"", color:"",type:[]};
    }
    CheckRootcauseExist(rootcauseForm)
    {
      var temlist;
      temlist= this.RootcauseList.filter(obj => obj.rootcause ===rootcauseForm.controls.rootcause.value);
      return temlist.length>0?true:false;
    }
    ClearThreshold()
    {
      this.AnalysisList=[];
this.RootcauseList=[];
this.Analysis={Analysis: "", Values: []};
this.Rootcause={rootcause:"",color:"",type:[]};
this.Thresholdlist=[];
    }
  updateThreshold()
  {
this.Threshold.threshold=[];
    this.Threshold.threshold.push.apply(this.Threshold.threshold,this.RootcauseList);

  }
  getallthresholds(thresholdform,threshcreate)
  {
    var result="";
this.Threshold.company=thresholdform.company;
this.Threshold.businessunit=thresholdform.businessunit;
this.Threshold.subapp=thresholdform.subapp;
this.Threshold.machineid=thresholdform.machineid;
this.Threshold.testtype=thresholdform.testtype;
this.Threshold.datatype=thresholdform.datatype;
// this.Threshold.threshold.push(thresholdform.genthreshold);
let Rootcause={rootcause:"",color:"",type:new Array()};
Rootcause.rootcause=Constants.GenRootConstantName;
Rootcause.type.push(thresholdform.genthreshold);
if(threshcreate)
{
this.AnalysisList.push(thresholdform.genthreshold);
if(this.Threshold.threshold[0]!=undefined)
{
let tempRootcauselist=this.Threshold.threshold[0];
tempRootcauselist.type.filter(obj=>obj.Values.length>0)
}
 this.Threshold.threshold.push(Rootcause);
}
else
{
  this.updateThreshold();
 //this.Threshold.threshold.push.apply(this.Threshold.threshold,this.RootcauseList);
 this.Threshold.threshold=this.Threshold.threshold.filter(obj=>obj.rootcause!=Rootcause.rootcause);
 this.Threshold.threshold.push(Rootcause);
}
return this.Threshold;

  }
 

}
