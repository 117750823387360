import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface ConfirmationData {
  header: string;
  message: string;
  icon: string;
  primarybtn: string;
  cancelbtn: string;
}


@Component({
  selector: 'app-confirmation-window',
  templateUrl: './confirmation-window.component.html',
  styleUrls: ['./confirmation-window.component.scss']
})
export class ConfirmationWindowComponent implements OnInit {

  
  constructor(
    private dialogRef: MatDialogRef<ConfirmationWindowComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationData
  ) { 
     // Diasable/ Enable modal dialog close, when click out side the modal
     dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  accept() {
    this.dialogRef.close({
      isOk: true,
      data: {}
    });
  }

  reject() {
    this.dialogRef.close({
      isOk: false,
      data: {}
    });
  }

}
