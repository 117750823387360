import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Validators, FormBuilder } from '@angular/forms';
import Constants from '../../../commonFiles/constant/Constants';
import { environment } from '../../../../environments/environment';
import { AdminService } from '../../../auth/admin.service';
import { AuthService } from '../../../auth/auth.service';

export interface ConfirmationData {
  header: string;
  message: string;
  icon: string;
  primarybtn: string;
  cancelbtn: string;
  company:string
  bussinessUnit:string
  webCount:string;
  mobileCount:string;
  subapp:string;
}

@Component({
  selector: 'app-update-licence',
  templateUrl: './update-licence.component.html',
  styleUrls: ['./update-licence.component.scss']
})
export class UpdateLicenceComponent implements OnInit {
  companyDisable;
  CustomerForm;
  ErrorMessage;
  constructor(  private dialogRef: MatDialogRef<UpdateLicenceComponent>,
    private fb: FormBuilder,
    private authService: AuthService,
    private adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationData) { }

  ngOnInit() {   
    this.companyDisable =true;
    this.CustomerForm = this.fb.group({
      companyName: [this.data.company, Validators.required],
      businessUnit: [this.data.bussinessUnit, Validators.required],
      mobileValidity:[this.data.mobileCount, Validators.required],
      webValidity:[this.data.webCount, Validators.required],
    });
    this.authService.validateUser();
  }
  accept(){
    var body={
      company:this.CustomerForm.controls.companyName.value,     
      businessunit:this.CustomerForm.controls.businessUnit.value,
      adminemail:localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
      subapp:this.data.subapp,
      mobile:parseInt(this.CustomerForm.controls.mobileValidity.value),
      web:parseInt(this.CustomerForm.controls.webValidity.value),
    }
     
    this.adminService.updateLicence(body).subscribe(res=>{      
        if(res.ResponseMetadata.HTTPStatusCode==200){
          this.dialogRef.close({
            isOk: true,
          });
        }
    });
  }

  reject() {   
    this.dialogRef.close({
      isOk: false,
      data: {}
    });
  }

}
