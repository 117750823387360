import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AdminService } from '../../../auth/admin.service';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { AppConfigService } from '../../services/app-initializer.service';

@Component({
  selector: 'app-subapp-permission',
  templateUrl: './subapp-permission.component.html',
  styleUrls: ['./subapp-permission.component.scss']
})
export class SubappPermissionComponent implements OnInit {
  UserTypes: any;
  disabled=true;

  constructor(
    private dialogRef: MatDialogRef<SubappPermissionComponent>,
     public appconfig: AppConfigService,
     private translate: TranslatePipe,
     private adminService: AdminService,
     public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  
    // Diasable/ Enable modal dialog close, when click out side the modal
    dialogRef.disableClose = true;
  this.disabled =true;
  }
  ngOnInit() {
  }


  reject() {
    this.dialogRef.close({
      isOk: false,
      
      data: {status:'cancel'}
    });
  }


  accept() {
         this.adminService.updateadminrole(this.data.data).subscribe(res=>{
    if(res.ResponseMetadata.HTTPStatusCode==200)
    {
      this.dialogRef.close({
        isOk: true,
        message: 'Permission updated succesfully'
      });
    }
         });
         
        
      }

}
