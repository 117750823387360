import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AdminService } from '../../../auth/admin.service';
import { AuthService } from '../../../auth/auth.service';
import Constants from '../../../commonFiles/constant/Constants';
import { ConfirmationWindowComponent } from '../../../commonFiles/modalwindow/confirmation-window/confirmation-window.component';
import { ShowAccessComponent } from '../../../commonFiles/modalwindow/show-access/show-access.component';
import { SubappPermissionComponent } from '../../../commonFiles/modalwindow/subapp-permission/subapp-permission.component';
import { AppCompanyUser, AppProductUsers, AppUserInfo } from '../../../commonFiles/model/superadmin.model';
import { UpdateLicenceComponent } from '../update-licence/update-licence.component';
import { UpdateStorageComponent } from '../update-storage/update-storage.component';
import { ResizeEvent } from 'angular-resizable-element';
import { ErrorService } from '../../../commonFiles/services/error.service';

@Component({
  selector: 'app-list-admin',
  templateUrl: './list-admin.component.html',
  styleUrls: ['./list-admin.component.scss']
})
export class ListAdminComponent implements OnInit {
  dynamicTabName;
  displayedColumns: string[] = ['name', 'email', 'role', 'userstatus', 'action'];

  setTapName: string;
  showedTap;
  appListUser: AppProductUsers = new AppProductUsers();
  appCompanyUser: AppCompanyUser = new AppCompanyUser();
  appProductUsers: AppProductUsers = new AppProductUsers();

  saveStorage = [];
  saveLicence = [];
  saveUserData = [];

  labelWeb = 'Web';
  labelMobile = 'Mobile';
  labelStorage = 'Storage';
  labelValitdy = 'Validity';


  constructor(private router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public errorService: ErrorService,
    private authService: AuthService,
    private adminService: AdminService) { }

  ngOnInit() {
    // EDL is renamed to EDAV
    this.dynamicTabName = ['EDAV', 'EOL', 'SD'];
    this.isTabClick(0);
    this.errorService.setResponseEdlMessage(true); 
  }

  isTabClick(num) {
    this.showedTap = num;
    switch (num) {
      case 0: // EDL is renamed to EDAV
        this.setTapName = "EDL";
        break;
      case 1: // EOL
        this.setTapName = "EOL";
        break;
      case 2: // SD
        this.setTapName = "SD";
        break;
    }
    this.appListUser = null;
    this.saveStorage = [];
    this.saveLicence = [];
    this.saveUserData = [];
    this.fillData()
    this.fillLicence();
    this.fillStorage();
  }
  fillLicence() {
    this.adminService.getLicence(this.setTapName.toLowerCase()).subscribe(res => {
      res.forEach(element => {
        var storageInfo = {
          company: element.cb.split('_')[0],
          businessUnit: element.cb.split('_')[1],
          maxstorage: element.license[this.setTapName.toLowerCase()] == undefined ? 0 : element.license[this.setTapName.toLowerCase()].maxstorage,
          mobile: element.license[this.setTapName.toLowerCase()] == undefined ? 0 : element.license[this.setTapName.toLowerCase()].mobile,
          web: element.license[this.setTapName.toLowerCase()] == undefined ? 0 : element.license[this.setTapName.toLowerCase()].web
        };
        this.saveLicence.push(storageInfo);
      });
      //   this.fillStorage();
    });
  }

  fillStorage() {
    this.adminService.getStorage(this.setTapName.toLowerCase()).subscribe(res => {
      res.forEach(element => {
        var storageInfo = {
          company: element.cb.split('_')[0],
          businessUnit: element.cb.split('_')[1],
          maxStorage: element.license[this.setTapName.toLowerCase()].maxstorage,
          usedStorage: element.license[this.setTapName.toLowerCase()].usedstorage,
        };
        this.saveStorage.push(storageInfo);
      });
      // this.fillData();      
    });

  }

  fillData() {
    this.adminService.getSuperAdminUserList(this.setTapName.toLowerCase()).subscribe(res => {
      var result = res.Users.filter(function (row) {
        return row.Attributes;
      });
      result.forEach(element => {
        var userInfo = this.fillUser(element, this.setTapName.toLowerCase());
        this.saveUserData.push(userInfo);
      });
      this.setUserData();
    });
  }
  setUserData() {
    this.appProductUsers.AppCompany = [];
    var uniques = Array.from(new Set(this.saveUserData.map(obj => JSON.stringify(({ businessUnit: obj.UserBusinessUnit, companyName: obj.UserCompanyName })))));
    uniques.forEach(element => {

      this.appCompanyUser = new AppCompanyUser();
      this.appCompanyUser.UserList = [];
      let json = JSON.parse(element);

      var datas = this.saveUserData.filter(e => e.UserBusinessUnit == json.businessUnit && e.UserCompanyName == json.companyName && e.IsAppTap.toLowerCase() == this.setTapName.toLowerCase());

      datas.sort(function (a, b) {
        var nameA = a.Email.toLowerCase(), nameB = b.Email.toLowerCase();
        if (nameA < nameB)
          return -1
        if (nameA > nameB)
          return 1
        return 0;
      });

      datas.forEach(sdData => {
        this.appCompanyUser.UserList.push(sdData);
        this.appCompanyUser.DaysAgo = sdData.Validity;
        this.appCompanyUser.DaysAgo = sdData.Validity.toString().length === 10 ? new Date(parseInt(sdData.Validity) * 1000).toString() : "-";
      });

      this.appCompanyUser.BusinessUnit = json.businessUnit;
      this.appCompanyUser.CompanyName = json.companyName;

      var filterStorage = this.saveStorage.filter(t => t.company == json.companyName && t.businessUnit == json.businessUnit)
      filterStorage.forEach(storage => {
        this.appCompanyUser.MaxStorage = storage.maxStorage;
        this.appCompanyUser.Storage = storage.usedStorage;
      });

      var filterlicence = this.saveLicence.filter(t => t.company == json.companyName && t.businessUnit == json.businessUnit)
      filterlicence.forEach(licence => {
        this.appCompanyUser.Mobile = licence.mobile;
        this.appCompanyUser.Web = licence.web;
      });
      this.appProductUsers.AppCompany.push(this.appCompanyUser);
    });
    this.appProductUsers.AppCompany.sort(function (a, b) {
      var af = a.CompanyName;
      var bf = b.CompanyName;
      var as = a.BusinessUnit;
      var bs = b.BusinessUnit;
      if (af == bf) {
        return (as < bs) ? -1 : (as > bs) ? 1 : 0;
      } else {
        return (af < bf) ? -1 : 1;
      }
    });
  }

  fillUser(element, app) {
    var userInfo = new AppUserInfo();
    userInfo.UserBusinessUnit = element.Attributes.find(r => r.Name == "custom:businessunit").Value;
    userInfo.UserCompanyName = element.Attributes.find(r => r.Name == "custom:company").Value;
    userInfo.Email = element.Attributes.find(r => r.Name == "email").Value;

    var valueRole = "";
    if (this.showedTap === 0) {
      userInfo.IsAppTap = app;
      userInfo.Validity = element.Attributes.find(r => r.Name == "custom:edlvalidity") === undefined ? "0" : element.Attributes.find(r => r.Name == "custom:edlvalidity").Value;
      userInfo.IsEnable = element.Attributes.find(r => r.Name == "custom:edlenable").Value === 'True';
      userInfo.IsAdmin = element.Attributes.find(r => r.Name == "custom:edladmin").Value === 'True';
      userInfo.IsWeb = element.Attributes.find(r => r.Name == "custom:edlweb").Value === 'True';
      userInfo.IsMobile = element.Attributes.find(r => r.Name == "custom:edlmobile").Value === 'True';
    }

    if (this.showedTap === 1) {
      userInfo.IsAppTap = app;
      userInfo.Validity = element.Attributes.find(r => r.Name == "custom:eolvalidity") === undefined ? "0" : element.Attributes.find(r => r.Name == "custom:eolvalidity").Value;
      userInfo.IsEnable = element.Attributes.find(r => r.Name == "custom:eolenable").Value === 'True';
      userInfo.IsAdmin = element.Attributes.find(r => r.Name == "custom:eoladmin").Value === 'True';
      userInfo.IsWeb = element.Attributes.find(r => r.Name == "custom:eolweb").Value === 'True';
      userInfo.IsMobile = element.Attributes.find(r => r.Name == "custom:eolmobile").Value === 'True';
    }

    if (this.showedTap === 2) {
      userInfo.IsAppTap = app;
      userInfo.Validity = element.Attributes.find(r => r.Name == "custom:sdvalidity") === undefined ? "0" : element.Attributes.find(r => r.Name == "custom:sdvalidity").Value;
      userInfo.IsEnable = element.Attributes.find(r => r.Name == "custom:sdenable").Value === 'True';
      userInfo.IsAdmin = element.Attributes.find(r => r.Name == "custom:sdadmin").Value === 'True';
      userInfo.IsWeb = element.Attributes.find(r => r.Name == "custom:sdweb").Value === 'True';
      userInfo.IsMobile = element.Attributes.find(r => r.Name == "custom:sdmobile").Value === 'True';
    }


    if (userInfo.IsWeb === true)
      valueRole += 'Web ,';
    if (userInfo.IsMobile === true)
      valueRole += 'Mobile ';
    valueRole = valueRole.replace(/,\s*$/, "");

    if (userInfo.IsAdmin === true)
      valueRole = 'Admin '

    userInfo.Role = valueRole
    userInfo.Status = element.UserStatus;
    if (element.UserStatus === 'CONFIRMED') {
      userInfo.LName = element.Attributes.find(r => r.Name == "family_name") == undefined ? '' : element.Attributes.find(r => r.Name == "family_name").Value;
      userInfo.FName = element.Attributes.find(r => r.Name == "given_name") == undefined ? '' : element.Attributes.find(r => r.Name == "given_name").Value;
    }
    return userInfo;
  }

  resend(businessUnit, email, company) {

    var body = {
      emailid: email,
      adminemail: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
      company: company,
      businessunit: businessUnit,
      isadminsuper: true,
      subapp: "na"
    }
    this.sendInvite(body);
  }

  sendInvite(body) {
    this.adminService.resendInvite(body).subscribe(res => {
      // if (res.ResponseMetadata.HTTPStatusCode) {
      this.snackBar.open("Re-send invitation sent successfully", "", {
        duration: 2500,
        verticalPosition: 'top'
      });
      // }
    });
  }
  updateValidity(company, Bunit) {
    var dialogRef = this.dialog.open(UpdateStorageComponent, {
      width: '40vw',
      data: {
        header: 'Update validity',
        primarybtn: 'Save',
        cancelbtn: 'Cancel',
        placeholder: '+/- Validity days',
        company: company,
        bussinessUnit: Bunit,
        subapp: this.setTapName.toLowerCase(),
        isStorage: false
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res.isOk) {
        this.isTabClick(this.showedTap);
        this.snackBar.open("Company validity updated successfully", "", {
          duration: 2500,
          verticalPosition: 'top'
        });
      }
    });
  }

  updateStorage(company, Bunit) {
    var dialogRef = this.dialog.open(UpdateStorageComponent, {
      width: '40vw',
      data: {
        header: 'Update Storage',
        primarybtn: 'Save',
        cancelbtn: 'Cancel',
        placeholder: 'GB',
        company: company,
        bussinessUnit: Bunit,
        subapp: this.setTapName.toLowerCase(),
        isStorage: true
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res.isOk) {
        this.isTabClick(this.showedTap);
        this.snackBar.open("Storage information updated successfully", "", {
          duration: 2500,
          verticalPosition: 'top'
        });
      }
    });
  }

  updateLicence(company, Bunit) {
    var dialogRef = this.dialog.open(UpdateLicenceComponent, {

      data: {
        header: 'Update Licenses',
        primarybtn: 'Save',
        cancelbtn: 'Cancel',
        company: company,
        bussinessUnit: Bunit,
        webCount: '',
        mobileCount: '',
        subapp: this.setTapName.toLowerCase(),
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res.isOk) {
        this.isTabClick(this.showedTap);
        this.snackBar.open("Licence updated successfully", "", {
          duration: 2500,
          verticalPosition: 'top'
        });
      }
    });
  }

  AddInvite() {
    this.router.navigate(['/add-user']);
  }

  deleteUser(company, mailId, buint) {
    var body = {
      emailid: mailId,
      company: company,
      businessunit: buint
    }
    var dialogRef = this.dialog.open(ConfirmationWindowComponent, {

      data: {
        header: 'Delete User',
        primarybtn: 'Delete',
        message: "Are you sure, want to delete?",
        cancelbtn: 'Cancel',
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res.isOk) {
        this.adminService.deleteUser(body).subscribe(res => {

          this.isTabClick(this.showedTap);
          this.snackBar.open("User deleted successfully", "", {
            duration: 2500,
            verticalPosition: 'top'
          });
        });
      }
    });
  }


  editRole(company) {

    var body =
    {
      emailid: company.Email,
      company: company.UserCompanyName,
      businessunit: company.UserBusinessUnit,
      subapp: "edl",
      adminrole: company.IsAdmin,
      isadminsuper: true
    }

    var dialogRef = this.dialog.open(SubappPermissionComponent, {
      width: '40vw',
      data: {
        header: 'Sup App Permission',
        data: body,
        primarybtn: 'Edit',
        cancelbtn: 'Cancel',
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res.isOk) {
        this.isTabClick(this.showedTap);
        this.snackBar.open(res.message, "", {
          duration: 2500,
          verticalPosition: 'top'
        });
      }
    });
  }


  editAccess(user) {


    var body =
    {
      email: user.Email,
      company: user.UserCompanyName,
      businessunit: user.UserBusinessUnit,
      adminemail: localStorage.getItem(Constants.UserInfo.ATTRIBUTES_EMAIL),
      enable: true ? 'True' : 'False',
      subapp: "edl",
      web: user.IsWeb ? 'True' : 'False',
      mobile: user.IsMobile ? 'True' : 'False',
      isadminsuper: true
    }




    var dialogRef = this.dialog.open(ShowAccessComponent, {
      width: '40vw',
      data: {
        header: 'Edit Access Rights',
        data: body,
        primarybtn: 'Edit',
        cancelbtn: 'Cancel',
        issuperAdmin: true
      }

    });
    dialogRef.afterClosed().subscribe(res => {
      if (res.isOk) {
        this.isTabClick(this.showedTap);
        this.snackBar.open(res.message, "", {
          duration: 2500,
          verticalPosition: 'top'
        });
      }
    });
  }

  tag(companyName, businessUnit) {

    localStorage.setItem('SelectedCompanyName', companyName);
    localStorage.setItem('SelectedBusinessUnit', businessUnit);
    this.router.navigate(['/datatag']);
  }
  VehicleTestMaster(companyName, businessUnit) {

    localStorage.setItem('SelectedCompanyName', companyName);
    localStorage.setItem('SelectedBusinessUnit', businessUnit);
    this.router.navigate(['/VehicleTestMaster']);
  }

  onResizeEnd(event: ResizeEvent, columnName): void {
    if (event.edges.right) {
      const cssValue = event.rectangle.width + 'px';
      const columnElts = document.getElementsByClassName('mat-column-' + columnName);
      //this.columnWidths[columnName.field] = event.rectangle.width;
      for (let i = 0; i < columnElts.length; i++) {
        const currentEl = columnElts[i] as HTMLDivElement;
        currentEl.style.width = cssValue;
      }
    }
  }


}
