import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { AdminService } from '../auth/admin.service';
import { VehicleModel, TestType } from '../commonFiles/model/superadmin.model';
import { SelectionModel } from '@angular/cdk/collections';
import { ErrorService } from '../commonFiles/services/error.service';
@Component({
  selector: 'app-vehicle-test-master',
  templateUrl: './vehicle-test-master.component.html',
  styleUrls: ['./vehicle-test-master.component.scss']
})
export class VehicleTestMasterComponent implements OnInit {

  form: FormGroup;
  VehicleModels :VehicleModel[]= [];
  TestTypes :TestType[]= [];
  displayedVehicleColumns: string[] = [ 'Vehicle', 'VehicleName'];
  displayedTestColumns: string[] = ['Test', 'TestName'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  Vehicledatasource = new MatTableDataSource<any>();
  TestTypedatasource = new MatTableDataSource<any>();
  defaultTypes = "1"
  IsVehicle: any;
  IsType: any;
  constructor(private fb: FormBuilder,
    public errorService: ErrorService,
    private adminService: AdminService,
    public snackBar: MatSnackBar,) { }
flag
  ngOnInit() {
    this.IsVehicle = true;
    this.form = this.fb.group({
      description: ['', [Validators.required]],
      Vehicle:'',
      Test:''
    });
    this.fillTestTypes();
    this.fillVehicleModel();
    this.errorService.setResponseEdlMessage(true); 
  }
  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched)
    );
  }
isExist =true;
  Save() {
    debugger;
    if(this.defaultTypes==="1") ///Vehicle
    {  
      this.isExist =true;
        var vehicles=new VehicleModel ;
        vehicles.VehicleName=this.form.controls.description.value;
        vehicles.Vehicle=true;
        this.VehicleModels.forEach(childObj=> {
          if(childObj.VehicleName.toLowerCase() ===this.form.controls.description.value.toLowerCase())
          {
            this.snackBar.open("Vehicle Model already exist", "", {
              duration: 2500,
              verticalPosition: 'top'
            });
            this.isExist =false;
            return;
          } 
       });
if(this.isExist ===true){
  if(this.VehicleModels.length ===0)
       {
    this.VehicleModels.push(vehicles);
    this.pushVehicleData();
 
       }else{
        this.VehicleModels.push(vehicles);  
    this.pushVehicleData();
  
        return 
       }
      }
         
    } 
     else
      {
      this.isExist =true;
        var tests=new TestType ;
        tests.TestName=this.form.controls.description.value;
        tests.Test=true;
        this.TestTypes.forEach(childObj=> {
        if(childObj.TestName.toLowerCase() ===this.form.controls.description.value.toLowerCase())
        {
          this.snackBar.open("Test Type already exist", "", {
            duration: 2500,
            verticalPosition: 'top'
          });
          this.isExist =false;
          return;
        } 
      });
      if(this.isExist ===true){
      if(this.TestTypes.length ===0)
      {
        //var rootgs = new TestType;
        //rootgs.TestName = this.form.controls.description.value;
        //rootgs.Test = true;
        this.TestTypes.push(tests);
        this.pushTestData();
       
      }
      else
      { 
        this.TestTypes.push(tests);      
        this.pushTestData();
        return
      }
    }
    }
    
   
     //if(this.isExist ===true)
     //  this.form.reset();
  }

  handleChange(event) {
    debugger;
    this.defaultTypes = event.value;

    if (this.defaultTypes == '1') {
      this.IsVehicle = true;
      this.IsType = false;
    }
    else {
      this.IsVehicle = false;
      this.IsType = true;
    }
  }  
  fillTestTypes()
  {
      this.adminService.getTestTypes(localStorage.getItem('SelectedCompanyName'),localStorage.getItem('SelectedBusinessUnit'),"edl").subscribe(res=>
      {
      
        for (var i = 0; i < res.success.Message.length;i++){
            var Tests =new TestType;
          Tests.TestName = res.success.Message[i];
            Tests.Test = true;
            this.TestTypes.push(Tests)
            } 
          this.pushTestData();
        this.masterToggleTestType();
    
        });
        
  }
  fillVehicleModel()
  {
    
        this.adminService.getVehicleModel(localStorage.getItem('SelectedCompanyName'),localStorage.getItem('SelectedBusinessUnit'),"edl").subscribe(res=>
        {
     
            for(var i =0;i<res.success.Message.length;i++){
              var Vehicles =new VehicleModel;    
          
              Vehicles.VehicleName =res.success.Message[i];
              Vehicles.Vehicle = true;     
              this.VehicleModels.push(Vehicles); 
            }
      
                    
            this.pushVehicleData();
          this.masterToggleVehicle();
        
          });

      
  }


  pushVehicleData()
  {
    this.Vehicledatasource.data = this.VehicleModels;
    this.Vehicledatasource.paginator = this.paginator;
    this.Vehicledatasource.sort = this.sort; 
  }
    pushTestData()
    {
    this.TestTypedatasource.data = this.TestTypes;
    this.TestTypedatasource.paginator = this.paginator;
    this.TestTypedatasource.sort = this.sort;         
  }


  pushServer()
  {
    var VehicleArray = this.VehicleModels.filter(function (el) {
      return el.Vehicle === true;
    });

    var TestArray = this.TestTypes.filter(function (el) {
      return el.Test === true;
    });
    var VehicleNames = VehicleArray.map(function (i) {
   
      return i.VehicleName;
    });
    var TestNames = TestArray.map(function(i) {
      return i.TestName;
    });
    
    var Testbody={
      company:localStorage.getItem('SelectedCompanyName'),
      businessunit:localStorage.getItem('SelectedBusinessUnit'),
      subapp:"edl",
      testtypes:TestNames
      //company:localStorage.getItem('SelectedCompanyName')
    }
    var Vehiclebody={
      company:localStorage.getItem('SelectedCompanyName'),
      businessunit:localStorage.getItem('SelectedBusinessUnit'),
      subapp:"edl",
      models: VehicleNames
   /*   datatag: { nvhdescriptors: VehicleNames, TestNames}*/
      //company:localStorage.getItem('SelectedCompanyName')
    }
    this.adminService.UpdateTestTypes(Testbody).subscribe(res => {
      debugger;
      if (res.ResponseMetadata.HTTPStatusCode === 200) {
        this.TestTypes = [];
      /*  this.VehicleModels = [];*/
       /* this.TestTypes = [];*/
        // this.fillDataTags();
        this.form.reset();
        this.fillTestTypes();
       /* this.fillVehicleModel();*/
        window.location.reload();
      

      }
    })
    this.adminService.UpdateVehicleModel(Vehiclebody).subscribe(res => {
      debugger;
      if(res.ResponseMetadata.HTTPStatusCode ===200){
     /*   this.TestTypes = [];*/
        this.VehicleModels = [];
      
      /*  this.VehicleModels = [];*/
        /*    this.masterdata(Testbody);*/
         this.form.reset();
        this.fillVehicleModel();
      /*  this.fillTestTypes();*/
      
        window.location.reload();
       // this.fillDataTags();
      
      
      }
    })

   
  
  }

  selection = new SelectionModel<VehicleModel>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedNvh() {
   
    const numSelected = this.selection.selected.length;
    const numRows = this.Vehicledatasource.data.length;

    return numSelected === numRows;
  
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleVehicle() {

    console.log(this.isAllSelectedNvh());
    if (this.isAllSelectedNvh()) {
        this.selection.clear()
        
          for(var i =0; i< this.VehicleModels.length;i++)
          {
              this.VehicleModels[i].Vehicle =false;
          }          
       
      }
        else{
        this.Vehicledatasource.data.forEach(row => {
          this.selection.select(row);
          for(var i =0; i< this.VehicleModels.length;i++)
          {
              this.VehicleModels[i].Vehicle =true;
          }          
        });
      }
  }


   
  TestSelection = new SelectionModel<TestType>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedRoot() {
  
    const numSelected = this.TestSelection.selected.length;
    const numRows = this.TestTypedatasource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleTestType() {
    debugger;
    console.log(this.isAllSelectedRoot());
    if (this.isAllSelectedRoot()){
        this.TestSelection.clear() 
        for(var i =0; i< this.TestTypes.length;i++)
        {
            this.TestTypes[i].Test =false;
        }  
      }else
      {
        this.TestTypedatasource.data.forEach(row => {
          this.TestSelection.select(row);
          for(var i =0; i< this.TestTypes.length;i++)
          {
              this.TestTypes[i].Test =true;
          }  
        });
      }
  }

}
